import { ActionCreator, on, ReducerTypes } from '@ngrx/store';

import { ListingsState } from '@listings/store/states/listings.state';
import * as recentlyViewedListings from '../actions/recently-viewed-listings.actions';

export const recentlyViewedListingsReducerTypes: ReducerTypes<ListingsState, readonly ActionCreator[]>[] = [
    on(recentlyViewedListings.loadRecentlyViewedListing, (state) => ({ ...state, recentlyViewedListingsLoaded: false, isRecentlyViewedListingsLoading: true })),
    on(recentlyViewedListings.loadRecentlyViewedListingSuccess, (state, { recentlyViewedlistings }) => {
        return {
            ...state,
            recentlyViewedListings: recentlyViewedlistings,
            recentlyViewedListingsLoaded: true,
            isRecentlyViewedListingsLoading: false
        };
    }),
    on(recentlyViewedListings.loadRecentlyViewedListingFailed, (state) => {
        return {
            ...state,
            recentlyViewedListingsLoaded: false,
            isRecentlyViewedListingsLoading: true
        };
    })
];