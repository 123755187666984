import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap } from 'rxjs';

import * as onboardingActions from '../actions/onboarding.actions';
import { OnboardingStoreService } from '../services/onboarding-store.service';
import { ToastService } from '@core-services/toast.service';
import { OnboardingApiService } from '../services/onboarding-api.service';

@Injectable()
export class OnboardingEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly toastService: ToastService,
        private readonly onboardingApiService: OnboardingApiService,
        private readonly onboardingStoreService: OnboardingStoreService,
    ) { }

    public readonly loadOnboardingData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(onboardingActions.loadOnboardingData),
            switchMap(() => this.onboardingApiService.loadOnboardingData()))
    );

    public readonly markAsViewed$ = createEffect(() =>
        this.actions$.pipe(
            ofType(onboardingActions.markAsViewed),
            concatLatestFrom(() => this.onboardingStoreService.viewedSlides$),
            switchMap(([, slideIds]) => this.onboardingApiService.markAsViewed(slideIds)))
    );

    public readonly markAsViewedSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(onboardingActions.markAsViewedSuccess),
            map(() => onboardingActions.resetState()))
    );

    public readonly onboardingErrors$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    onboardingActions.loadOnboardingDataFailed,
                    onboardingActions.markAsViewedFailed
                ),
                tap((error) => this.toastService.showServerError(error.error)),
            ),
        { dispatch: false }
    );
}