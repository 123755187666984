<div class="folder-edit-box">
    <div class="folder-edit-title">{{ (manageFolderDialogData?.id == null ?
        'CREATE_EDIT_FOLDER_DIALOG.TITLES.CREATE' :
        'CREATE_EDIT_FOLDER_DIALOG.TITLES.EDIT' ) | translate }}</div>
    <rpc-icon-button class="folder-edit-close" [iconName]="'cross'" [title]="'CREATE_EDIT_FOLDER_DIALOG.BUTTONS.CLOSE'"
        [mat-dialog-close]="false" (click)='close()'>
    </rpc-icon-button>

    <add-edit-folder-form class="folder-edit-form-wrap" [name]="manageFolderDialogData?.name"
        [id]="manageFolderDialogData?.id" (manageFolderClicked)="manageFolder($event)" (canceled)="close()"
        [submitButtonText]="manageFolderDialogData?.id == null ? 'MANAGE_FOLDER_FORM.BUTTONS.CREATE' : 'MANAGE_FOLDER_FORM.BUTTONS.EDIT'">
    </add-edit-folder-form>
</div>