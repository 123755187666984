export const locale = {
    lang: 'en',
    data: {
        REMOVE_FOLDER_DIALOG: {
            TITLES: {
                DELETE_FOLDER: 'Delete Folder',
                CONFIRMATION_MESSAGE_1: 'Are you sure you want to remove',
                CONFIRMATION_MESSAGE_2: 'folder?',
                DELETE_LISTINGS: 'Delete all listings inside the folder'
            },
            BUTTONS: {
                CLOSE: 'Close',
                CANCEL: 'Cancel',
                REMOVE: 'Remove',
            }
        }
    }
};