export const locale = {
    lang: 'en',
    data: {
        LISTING_MANAGEMENT_DIALOG: {
            TITLES: {
                TITLE: 'Are you sure you want to remove listing from your portfolio?',
                CONFIRMATION_MESSAGE_1: 'Are you sure you want to remove ',
                CONFIRMATION_MESSAGE_2: 'from your portfolio?',
                CONFIRMATION_MESSAGE_3: 'from your portfolio entirely?',
                LISTING: ' listing ',
                LISTINGS: ' listings ',
                WARNINGS: {
                    MESSAGE_1: 'Selected list contains already removed listing(s).',
                }
            },
            BUTTONS: {
                CLOSE: 'Close',
                CANCEL: 'Cancel',
                REMOVE: 'Remove',
                DELETE: 'Delete'
            }
        }
    }
};