export const locale = {
    lang: 'en',
    data: {
        FOLDERS_COMMON: {
            ERRORS: {
                FOLDER_NAME_EXISTS: 'A folder by that name is already in use.',
                FOLDER_DELETION_FAILED: 'Folder deletion failed'
            }
        }
    }
};