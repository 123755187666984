import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as appActions from '@core-layout/app/store/actions/app.actions';

@Injectable({ providedIn: 'root' })
export class AppStoreService {

    constructor(
        private readonly store: Store
    ) { }

    public loadDomainCompanyConfiguration(): void {
        this.store.dispatch(appActions.loadDomainCompanyConfiguration());
    }

    public loadCompanyConfigurationByInternalId(internalCompanyId: number): void {
        this.store.dispatch(appActions.loadCompanyConfigurationByInternalId({ internalCompanyId }));
    }
}