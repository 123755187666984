import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { EMPTY, filter, map, switchMap, tap } from 'rxjs';

import { UserStoreService } from '@auth/store/services/user-store.service';
import { RpcRoute } from '@core-layout/app/models/rpc-route';
import { RouteService } from '@core-layout/app/services/route.service';
import { ToastService } from '@core-services/toast.service';
import { ListingActivityType } from '@customer-activity/enums/listing-activity-type.enum';
import { DEFAULT_FOLDER_ATTACHMENT_DIALOG_CONFIG } from '@folders/components/folder-attachment-dialog/constants/folder-attachment-dialog.constants';
import { FolderAttachmentDialogComponent } from '@folders/components/folder-attachment-dialog/folder-attachment-dialog.component';
import { IFolderAttachmentDialogData } from '@folders/components/folder-attachment-dialog/models/folder-attachment-dialog-data';
import { DEFAULT_FOLDER_MANAGEMENT_DIALOG_CONFIG } from '@folders/components/folder-management-dialog/constants/folder-management-dialog.constants';
import { FolderManagementDialogComponent } from '@folders/components/folder-management-dialog/folder-management-dialog.component';
import { IFolderManagementDialogData } from '@folders/components/folder-management-dialog/models/folder-management-dialog-data';
import { FOLDER_ERRORS_MAP } from '@folders/constants/folder-errors.constants';
import { FolderManagementAction } from '@folders/enums/folder-management-action.enum';
import { RemoveFolderListingsModel } from '@folders/models/remove-folder-listings-model';
import { FolderErrorsService } from '@folders/services/folder-errors.service';
import { GalleryViewTypes } from '@gallery-view/enums/gallery-view-types';
import { ListingActivityHelper } from '@listings/services/listing-activity.helper';
import * as listingActivityActions from '@listings/store/actions/listing-activity.actions';
import * as listingsActions from '@listings/store/actions/listings.actions';
import * as listingActions from '@listings/store/actions/listings.actions';
import { ListingsStoreService } from '@listings/store/services/listings-store.service';
import * as myListingsActions from '@my-listings/store/actions/my-listings.actions';
import { CustomerSettings } from '@settings/models/settings/customer-settings';
import { SettingsHelper } from '@settings/settings.helper';
import * as settingsActions from '@settings/store/actions/settings.actions';
import { SettingsStoreService } from '@settings/store/services/settings-store.service';
import * as foldersActions from '../actions/folders.actions';
import { FoldersApiService } from '../services/folders-api.service';
import { FoldersStoreReadService } from '../services/folders-store-read.service';
import { FolderSettingsHelper } from './folder-settings.helper';
import { ListingFolderIdsMap } from '@folders/models/listing-folder-ids-map';
import { ListingFolderIdsMapHelper } from '../helpers/listing-folder-ids-map.helper';
import { ActivityListingCandidate } from '@listings/models/api/set-listing-activity-request';
import { FolderNewMatch } from '@folders/models/folder-new-match';
import { FoldersNewMatchUpdateService } from '../services/folders-new-match-update.service';
import { GoogleAnalyticsStoreService } from '@core-layout/app/store/services/google-analytics-store.service';
import { FoldersService } from '../services/folders.service';
import { RouterStoreService } from '@core-layout/app/store/services/router-store.service';
import { AddEditFolderDialogComponent } from '@folders/components/add-edit-folder-dialog/add-edit-folder-dialog.component';
import { DEFAULT_MANAGE_FOLDER_DIALOG_CONFIG } from '@folders/components/add-edit-folder-dialog/constants/add-edit-folder-dialog.constants';
import { IManageFolderModel } from '@folders/models/manage-folder-model';
import { addPageEvent } from '@core-layout/app/store/actions/google-analytics.actions';
import { GoogleAnalyticsEventName } from 'app/modules/core-modules/enums/google-analytics-event-name';

@Injectable()
export class FoldersEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly foldersApiService: FoldersApiService,
        private readonly settingsStoreService: SettingsStoreService,
        private readonly foldersStoreReadService: FoldersStoreReadService,
        private readonly userStoreService: UserStoreService,
        private readonly folderErrorsService: FolderErrorsService,
        private readonly matDialog: MatDialog,
        private readonly listingsStoreService: ListingsStoreService,
        private readonly toaster: ToastService,
        private readonly routeService: RouteService,
        private readonly googleAnalyticsStoreService: GoogleAnalyticsStoreService,
        private readonly routerStoreService: RouterStoreService,
    ) { }

    public readonly load$ = createEffect(() => this.actions$.pipe(
        ofType(foldersActions.load),
        concatLatestFrom(() => this.foldersStoreReadService.areFoldersLoaded$),
        switchMap(([, areFoldersLoaded]) => areFoldersLoaded
            ? EMPTY
            : [
                foldersActions.loadFolders({ shouldSetLoading: true }),
                foldersActions.loadListingFolderIdsMappings(),
                foldersActions.loadFolderSavedSearches(),
                foldersActions.loadFolderNewMatches()
            ]
        )
    ));

    public readonly loadFolders$ = createEffect(() => this.actions$.pipe(
        ofType(foldersActions.loadFolders),
        switchMap(() => this.foldersApiService.getCustomFolders())
    ));

    public readonly loadFolderSavedSearches$ = createEffect(() => this.actions$.pipe(
        ofType(foldersActions.loadFolderSavedSearches),
        switchMap(() => this.foldersApiService.getFolderSavedSearches())
    ));

    public readonly loadFolderNewMatches$ = createEffect(() => this.actions$.pipe(
        ofType(foldersActions.loadFolderNewMatches),
        switchMap(() => this.foldersApiService.getFolderNewMatches())
    ));

    public readonly loadListingsFolders$ = createEffect(() => this.actions$.pipe(
        ofType(foldersActions.loadListingFolderIdsMappings),
        switchMap(() => this.foldersApiService.getListingFolderIdsMappings())
    ));

    public readonly setDefaultFolder$ = createEffect(() => this.actions$.pipe(
        ofType(foldersActions.setDefaultFolder),
        concatLatestFrom(() => this.settingsStoreService.getSettings()),
        map(([{ folderId }, oldSettings]) => {
            const newSettings: CustomerSettings = {
                ...oldSettings,
                layoutSettings: {
                    ...oldSettings.layoutSettings,
                    defaultFolderId: folderId
                }
            };

            return settingsActions.updateSettings({ newSettings, oldSettings });
        })
    ));

    public readonly update$ = createEffect(() => this.actions$.pipe(
        ofType(foldersActions.update),
        switchMap(({ folderData }) => this.foldersApiService.update(folderData))
    ));

    public readonly create$ = createEffect(() => this.actions$.pipe(
        ofType(foldersActions.create),
        concatLatestFrom(() => this.userStoreService.getUser()),
        switchMap(([{ name }, customer]) => this.foldersApiService.create(name, customer.customerId))
    ));

    public readonly createSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(foldersActions.createSuccess),
        concatLatestFrom(() => this.settingsStoreService.getSettings()),
        map(([{ id }, currentSettings]) => {
            const foldersSettings = [...currentSettings.layoutSettings.foldersSettings, SettingsHelper.createDefaultFolderSettings(id)];
            const settings = SettingsHelper.updateWithFoldersSettings(currentSettings, foldersSettings);

            return settingsActions.setSettings({ settings });
        })
    ));

    public readonly setDefaultFolderSettingsWhereNotExists$ = createEffect(() => this.actions$.pipe(
        ofType(foldersActions.loadFoldersSuccess),
        concatLatestFrom(() => [this.settingsStoreService.getSettings(), this.foldersStoreReadService.systemFolders$]),
        map(([{ folders }, currentSettings, systemFolders]) => {
            const settings = FolderSettingsHelper.fillWithDefaultFoldersSettings(currentSettings, folders, systemFolders);

            return settingsActions.setSettings({ settings });
        })
    ));

    public readonly openFolderManagementDialog$ = createEffect(
        () => this.actions$.pipe(
            ofType(foldersActions.openFolderManagementDialog),
            map(({ shouldUnselectListings, listingId }) => {
                const dialogConfig: MatDialogConfig<IFolderManagementDialogData> = {
                    ...DEFAULT_FOLDER_MANAGEMENT_DIALOG_CONFIG,
                    data: { listingId, shouldUnselectListings }
                };

                this.matDialog.open(FolderManagementDialogComponent, dialogConfig);
            })
        ),
        { dispatch: false }
    );

    public readonly openFolderAttachmentDialog$ = createEffect(
        () => this.actions$.pipe(
            ofType(foldersActions.openFolderAttachmentDialog),
            concatLatestFrom(({ folderId, listingId }) => [
                this.foldersStoreReadService.getFolderById(folderId),
                listingId == null
                    ? this.foldersStoreReadService.selectedListingsFolderManagerInfo$
                    : this.foldersStoreReadService.getListingFolderManagerInfoById(listingId).pipe(map(listing => [listing]))
            ]),
            switchMap(([{ listingId, shouldUnselectListings }, folder, listings]) => {
                const listingsToAttach = listings.filter(({ foldersIds }) => !foldersIds.includes(folder.id));

                if (folder == null || listingsToAttach.length === 0) {
                    return [foldersActions.openFolderManagementDialog({ shouldUnselectListings, listingId })];
                }

                const dialogConfig: MatDialogConfig<IFolderAttachmentDialogData> = {
                    ...DEFAULT_FOLDER_ATTACHMENT_DIALOG_CONFIG,
                    data: { listings: listingsToAttach, folder, listingId, shouldUnselectListings }
                };

                this.matDialog.open(FolderAttachmentDialogComponent, dialogConfig);

                return EMPTY;
            })
        )
    );

    public readonly foldersErrors$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    foldersActions.updateFailed,
                    foldersActions.createFailed,
                    foldersActions.removeFailed
                ),
                concatLatestFrom(() => this.foldersStoreReadService.foldersError$),
                tap(([, error]) => this.folderErrorsService.showError(error)),
            ),
        { dispatch: false }
    );

    public readonly remove$ = createEffect(() => this.actions$.pipe(
        ofType(foldersActions.remove),
        concatLatestFrom(({ model }) => [
            this.foldersStoreReadService.getListingsIdsAttachedOnlyToThisFolder(model.id),
            this.foldersStoreReadService.customFolders$
        ]),
        switchMap(([{ model, isFromStateOnly }, listingsIds, folders]) => {
            return isFromStateOnly ? EMPTY : this.foldersApiService.removeFolder(
                new RemoveFolderListingsModel(model.id, listingsIds, model.isRemoveListings),
                folders
            );
        }))
    );

    public readonly unselectFolderOnRemove$ = createEffect(() => this.actions$.pipe(
        ofType(foldersActions.remove),
        concatLatestFrom(() => [this.foldersStoreReadService.selectedFolderId$, this.routerStoreService.url$]),
        filter(([{ model: { id } }, selectedFolderId]) => selectedFolderId === id),
        map(([, selectedFolderId, url]) => {
            const isOnSelectedFolderPage = url === RpcRoute.FolderFullPath.slice(0, RpcRoute.FolderFullPath.lastIndexOf('/') + 1).concat(selectedFolderId.toString());

            if (isOnSelectedFolderPage) {
                this.routeService.navigate(RpcRoute.Portfolio).catch(() => { });
            }

            return foldersActions.unselectFolder();
        }))
    );

    public readonly removeSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(foldersActions.removeSuccess),
        concatLatestFrom(() => [
            this.settingsStoreService.getSettings()
        ]),
        switchMap(([{ model }, currentSettings]) => {
            const foldersSettings = currentSettings.layoutSettings.foldersSettings.filter(folderSettings => folderSettings.folderId !== model.id);

            const settings = SettingsHelper.updateWithFoldersSettings(currentSettings, foldersSettings, currentSettings.layoutSettings.defaultFolderId === model.id);

            const actions: Action[] = [
                settingsActions.setSettings({ settings }),
                foldersActions.unselectFolder(),
                myListingsActions.setViewType({ viewType: GalleryViewTypes.Folders })
            ];

            return model.isRemoveListings
                ? actions.concat(listingActions.softDeleteRequested({ listingIds: model.listingIds, isFromStateOnly: true }))
                : actions;
        })
    ));

    public readonly addListingsToPortfolio$ = createEffect(() => this.actions$.pipe(
        ofType(foldersActions.manageListingsFolders),
        concatLatestFrom(() => [
            this.listingsStoreService.getListings(),
            this.listingsStoreService.resentlyViewedListings$,
            this.userStoreService.getUser(),
            this.userStoreService.getAgent()
        ]),
        tap(([{ models }]) => {
            if (models.some(({ action }) => action === FolderManagementAction.Attach)) {
                this.googleAnalyticsStoreService.addFolderManagementAttachDetachEvent('attach');
            }

            if (models.some(({ action }) => action === FolderManagementAction.Detach)) {
                this.googleAnalyticsStoreService.addFolderManagementAttachDetachEvent('detach');
            }
        }),
        switchMap(([{ models, shouldUnselectListings }, allListings, resentlyViewedListings, { customerId }, agent]) => {
            const listingCandidates = ListingActivityHelper.getActivityListingsCandidates(
                FoldersService.getListingsPickCandidatesHashCodes(models), allListings, resentlyViewedListings);

            return [
                listingActivityActions.addListingsToPickedList({ listingCandidates, customerId, agentId: agent.id }),
                ...(shouldUnselectListings ? [listingsActions.unselectAllListings()] : [])
            ];
        })
    ));

    public readonly manageListingsFolders$ = createEffect(() => this.actions$.pipe(
        ofType(foldersActions.manageListingsFolders),
        switchMap(({ models }) => this.foldersApiService.manageListingsFolders(models))
    ));

    public readonly manageListingsFoldersSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(foldersActions.manageListingsFoldersSuccess),
        concatLatestFrom(() => [
            this.listingsStoreService.getListings(),
            this.listingsStoreService.resentlyViewedListings$]),
        map(([{ models }, allListings, recentlyViewed]) => {
            const pickCandidatesHashCodes = FoldersService.getListingsPickCandidatesHashCodes(models);
            const listingsIds = ListingActivityHelper.getActivityListingsCandidates(pickCandidatesHashCodes, allListings, recentlyViewed).map(({ id }) => id);
            const listingCategory = Object.values(allListings)
                .filter(({ id }) => listingsIds.includes(id))
                .map(({ id, category }) => ({ listingId: id, category }));

            return listingActivityActions.addListingSessionActivity({ listingActivityType: ListingActivityType.PickListed, listingCategory });
        })
    ));

    public readonly removeListingsFromPortfolio$ = createEffect(() => this.actions$.pipe(
        ofType(foldersActions.manageListingsFoldersFailed),
        concatLatestFrom(() => [
            this.listingsStoreService.getListings(),
            this.listingsStoreService.resentlyViewedListings$,
            this.userStoreService.getUser(),
            this.userStoreService.getAgent()
        ]),
        map(([{ models }, allListings, resentlyViewedListings, { customerId }, agent]) => {
            const listingCandidates = ListingActivityHelper.getActivityListingsCandidates(
                FoldersService.getListingsPickCandidatesHashCodes(models), allListings, resentlyViewedListings, true);

            return listingActivityActions.removeListingsFromPickedList({ listingCandidates, customerId, agentId: agent.id });
        })
    ));

    public readonly restoreListingsSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(listingsActions.restoreSuccess),
        map(() => foldersActions.loadFolders({ shouldSetLoading: true }))
    ));

    public readonly updateActivitiesChangeFolderMappings$ = createEffect(() => this.actions$.pipe(
        ofType(listingActivityActions.setListingsActivity),
        concatLatestFrom(() => [this.foldersStoreReadService.getListingsFolderIdsMapping$, this.foldersStoreReadService.folderNewMatchIds$]),
        map(([{ request }, listingsFolderIdsMapping, folderNewMatchIds]) => this.onFolderMappingsUpdate(request.listingCandidates, listingsFolderIdsMapping, folderNewMatchIds))
    ));

    public readonly updatePickListAddedFolderMappings$ = createEffect(() => this.actions$.pipe(
        ofType(listingActivityActions.addListingsToPickedList),
        concatLatestFrom(() => [this.foldersStoreReadService.getListingsFolderIdsMapping$, this.foldersStoreReadService.folderNewMatchIds$]),
        map(([{ listingCandidates }, listingsFolderIdsMapping, folderNewMatchIds]) => this.onFolderMappingsUpdate(listingCandidates, listingsFolderIdsMapping, folderNewMatchIds))
    ));

    public readonly showFolderError$ = createEffect(
        () => this.actions$.pipe(
            ofType(foldersActions.manageListingsFoldersFailed, foldersActions.removeFailed),
            tap(({ error }) => {
                const localizationKey = FOLDER_ERRORS_MAP.get(error.errorKey);

                if (localizationKey != null) {
                    this.toaster.showClientError(localizationKey);
                }
            })
        ),
        { dispatch: false }
    );

    public readonly updateFolderMappings$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(foldersActions.updateFolderMappings),
            concatLatestFrom(() => [this.foldersStoreReadService.getListingsFolderIdsMapping$, this.foldersStoreReadService.folderNewMatchIds$]),
            map(([{ newMatchIds }, listingsFolderIdsMapping, folderNewMatchIds]) => {
                const getFolderNewMatchRecordWithoutRemoved = (folderNewMatches: FolderNewMatch[], { id, name, newMatchRecordIds }: FolderNewMatch) => {
                    const updatedNewMatchRecordIds = newMatchRecordIds.filter(newMatchId => !newMatchIds.includes(newMatchId));

                    return newMatchRecordIds.length > 0 ? [...folderNewMatches, new FolderNewMatch(id, name, updatedNewMatchRecordIds)] : folderNewMatches;
                };
                const updatedListingsFolderIdsMapping = listingsFolderIdsMapping.filter(x => !newMatchIds.includes(x.newMatchRecordId));
                const updatedFolderNewMatchIds = folderNewMatchIds.reduce(getFolderNewMatchRecordWithoutRemoved, new Array<FolderNewMatch>());

                return foldersActions.setFolderMappings({ updatedListingsFolderIdsMapping, updatedFolderNewMatchIds });
            })
        );
    });

    public readonly showAddEditFolderDialog$ = createEffect(() => this.actions$.pipe(
        ofType(foldersActions.showAddEditFolderDialog),
        switchMap(({ params: { id, name, eventSource } }) => {
            const config = { ...DEFAULT_MANAGE_FOLDER_DIALOG_CONFIG, data: { name: name ?? null, id: id ?? null } };

            return this.matDialog.open(AddEditFolderDialogComponent, config).afterClosed().pipe(
                filter(model => model != null),
                switchMap((folderData: IManageFolderModel) => {
                    const action = id != null
                        ? foldersActions.update({ folderData })
                        : foldersActions.create({ name: folderData.name });

                    return [
                        action,
                        ...(id != null ? [] : [addPageEvent({ name: GoogleAnalyticsEventName.Folder_Create, payload: { source: eventSource } })])
                    ];
                })
            );
        })));

    private onFolderMappingsUpdate(candidates: ActivityListingCandidate[], listingsFolderIdsMapping: ListingFolderIdsMap[], folderNewMatchIds: FolderNewMatch[]): Action {
        const updatedListingsFolderIdsMapping: ListingFolderIdsMap[] = ListingFolderIdsMapHelper.updateNewMatchesMapping(listingsFolderIdsMapping, candidates);
        const updatedFolderNewMatchIds: FolderNewMatch[] = FoldersNewMatchUpdateService.updateFolderNewMatches(folderNewMatchIds, updatedListingsFolderIdsMapping);

        return foldersActions.setFolderMappings({ updatedListingsFolderIdsMapping, updatedFolderNewMatchIds });
    }
}
