import * as moment from 'moment';

import { ListingsAppointments } from '@appointments/models/appointments/listings-appointments';
import { ListingsComments } from '@comments/models/comments/listings-comments';
import { IExtendedFilterOptions } from '@core-models/filter/extended-filter-options-interface';
import { FilterOptionsBase } from '@core-models/filter/filter-options-base';
import { ListingActivities } from '@listings/enums/listing-activities.enum';
import { ListingCategoryTexts } from '@listings/enums/listing-category-texts.enum';
import { MyListingsFilters } from '@my-listings/models/filter/my-listings-filters.enum';
import { LayoutFilterSettingsConstants } from '@settings/store/constants.ts/layout-filter-settings.constants';
import { AppointmentStatusService } from '@appointments/store/services/appointment-status.service';
import { AppointmentStatus } from '@appointments/enums/appointment-status.enum';
import { ListingInfo } from '@listings/models/listing/listing-info';

export class MyListingsFilterOptions extends FilterOptionsBase implements IExtendedFilterOptions<ListingInfo, ListingsAppointments, ListingsComments, number> {
    [MyListingsFilters.category] = ListingCategoryTexts.Sales;
    [MyListingsFilters.piked] = false;
    [MyListingsFilters.liked] = false;
    [MyListingsFilters.disliked] = false;
    [MyListingsFilters.shown] = false;
    [MyListingsFilters.newMatch] = false;
    [MyListingsFilters.newlyAdded] = false;
    [MyListingsFilters.upcomingOpenHouseStartDate]: Date = null;
    [MyListingsFilters.upcomingOpenHouseEndDate]: Date = null;
    [MyListingsFilters.upcomingAppointmentStartDate]: Date = null;
    [MyListingsFilters.upcomingAppointmentEndDate]: Date = null;
    [MyListingsFilters.usersFilter]: string = LayoutFilterSettingsConstants.allUsers;
    [MyListingsFilters.hasUnreadComments] = false;
    [MyListingsFilters.hasReadComments] = false;
    [MyListingsFilters.hasUpcomingAppointments] = false;
    [MyListingsFilters.hasPastOrDeclinedAppointments] = false;

    public static getDefaultFilter(): MyListingsFilterOptions {
        return new MyListingsFilterOptions();
    }

    public static getNewMatchFilter(): MyListingsFilterOptions {
        const filter = new MyListingsFilterOptions();

        filter.newMatch = true;

        return filter;
    }

    public static getCommentsFilter(): MyListingsFilterOptions {
        const filter = new MyListingsFilterOptions();

        filter.hasUnreadComments = true;
        filter.hasReadComments = true;

        return filter;
    }

    public static getUnreadCommentsFilter(): MyListingsFilterOptions {
        const filter = new MyListingsFilterOptions();

        filter.hasUnreadComments = true;

        return filter;
    }

    public static getAppointmentsFilter(): MyListingsFilterOptions {
        const filter = new MyListingsFilterOptions();

        filter.hasUpcomingAppointments = true;

        return filter;
    }

    public static getLikedFilter(): MyListingsFilterOptions {
        const filter = new MyListingsFilterOptions();

        filter.liked = true;

        return filter;
    }

    // eslint-disable-next-line complexity
    public filter(collection: ListingInfo[], appointments: ListingsAppointments = null, comments: ListingsComments = null, currentCustomerId: number = null): ListingInfo[] {
        if (collection == null) {
            return [];
        }

        const filterFunctions: ((listing: ListingInfo, appointments: ListingsAppointments, comments: ListingsComments, currentCustomerId: number) => boolean)[] = [];

        // category filter
        filterFunctions.push(listing => listing.category === this.category.toLowerCase());

        // from filter
        if (this.usersFilter !== null && this.usersFilter !== LayoutFilterSettingsConstants.allUsers) {
            const selectedFrom = this.usersFilter.split(',');

            if (selectedFrom.length > 0) {
                filterFunctions.push(listing => selectedFrom.includes(listing.listingOwnerId.toString()) && !listing.isNewMatch);
            }
        }

        // activities & newMatch filter
        const selectedActivities = [
            { activity: ListingActivities.Picked, selected: this.piked },
            { activity: ListingActivities.Liked, selected: this.liked },
            { activity: ListingActivities.Disliked, selected: this.disliked },
            { activity: ListingActivities.Shown, selected: this.shown }
        ].filter(activity => activity.selected).map(selectedActivity => selectedActivity.activity);

        if (selectedActivities.length > 0 || this.newMatch) {
            filterFunctions.push(listing =>
                listing.activities.some(activity => selectedActivities.some(a => activity.id === a)) || (this.newMatch && listing.isNewMatch)
            );
        }

        if (this.newlyAdded) {
            filterFunctions.push(listing => !listing.isNewViewed);
        }

        if (this.hasUpcomingAppointments && this.hasPastOrDeclinedAppointments) {
            filterFunctions.push((listing, listingAppointments) => {
                return listingAppointments != null && listingAppointments[listing.hashCode]?.length > 0;
            });
        } else {
            if (this.hasUpcomingAppointments) {
                filterFunctions.push((listing, listingAppointments) => {
                    return listingAppointments?.[listing.hashCode]?.some(x => {
                        const status = AppointmentStatusService.calculateTimeDependentStatus(x);

                        return status !== AppointmentStatus.Declined && status !== AppointmentStatus.Shown;
                    });
                });
            }

            if (this.hasPastOrDeclinedAppointments) {
                filterFunctions.push((listing, listingAppointments) => {
                    return listingAppointments?.[listing.hashCode]?.some(x => {
                        const status = AppointmentStatusService.calculateTimeDependentStatus(x);

                        return status === AppointmentStatus.Declined || status === AppointmentStatus.Shown;
                    });
                });
            }
        }

        // comments filter
        if (this.hasUnreadComments && this.hasReadComments) {
            filterFunctions.push((listing, listingAppointments, listingComments) =>
                listingComments?.[listing.hashCode]?.length > 0);
        } else {
            if (this.hasUnreadComments) {
                filterFunctions.push((listing, listingAppointments, listingComments) =>
                    listingComments?.[listing.hashCode]?.some(comment => !comment.viewed && comment.createId !== currentCustomerId));
            }

            if (this.hasReadComments) {
                filterFunctions.push((listing, listingAppointments, listingComments, currentCustomerId: number) =>
                    listingComments?.[listing.hashCode]?.some(comment => comment.viewed && comment.createId !== currentCustomerId));
            }
        }

        // open house filters
        if (this.upcomingOpenHouseStartDate != null && this.upcomingOpenHouseEndDate != null) {
            filterFunctions.push(listing =>
                listing.openHouses.some(openHouse =>
                    moment(openHouse.startDate).isSameOrAfter(this.upcomingOpenHouseStartDate, 'day') &&
                    moment(openHouse.endDate).isSameOrBefore(this.upcomingOpenHouseEndDate, 'day'))
            );
        } else {
            if (this.upcomingOpenHouseStartDate != null) {
                filterFunctions.push(listing =>
                    listing.openHouses.some(openHouse => moment(openHouse.startDate).isSameOrAfter(this.upcomingOpenHouseStartDate, 'day'))
                );
            }

            if (this.upcomingOpenHouseEndDate != null) {
                filterFunctions.push(listing =>
                    listing.openHouses.some(openHouse => moment(openHouse.endDate).isSameOrBefore(this.upcomingOpenHouseEndDate, 'day'))
                );
            }
        }

        // upcoming appointmetns filter
        if (this.upcomingAppointmentStartDate != null && this.upcomingAppointmentEndDate != null) {
            filterFunctions.push((listing, listingAppointments) =>
                listingAppointments != null && listingAppointments[listing.hashCode] != null &&
                listingAppointments[listing.hashCode].some(appointment =>
                    moment(appointment.startDateTime).isSameOrAfter(this.upcomingAppointmentStartDate, 'day') &&
                    moment(appointment.endDateTime).isSameOrBefore(this.upcomingAppointmentEndDate, 'day'))
            );
        } else {
            if (this.upcomingAppointmentStartDate != null) {
                filterFunctions.push((listing, listingAppointments) =>
                    listingAppointments != null && listingAppointments[listing.hashCode] != null &&
                    listingAppointments[listing.hashCode].some(appointment =>
                        moment(appointment.startDateTime).isSameOrAfter(this.upcomingAppointmentStartDate, 'day'))
                );
            }

            if (this.upcomingAppointmentEndDate != null) {
                filterFunctions.push((listing, listingAppointments) =>
                    listingAppointments != null && listingAppointments[listing.hashCode] != null &&
                    listingAppointments[listing.hashCode].some(appointment =>
                        moment(appointment.endDateTime).isSameOrBefore(this.upcomingAppointmentEndDate, 'day'))
                );
            }
        }

        return collection.filter(listing => filterFunctions.every(filter => filter(listing, appointments, comments, currentCustomerId)));
    }
}