import { OnboardingData } from '../../models/onboarding-data';

export interface IOnboardingState {
    data: OnboardingData[];
    isLoading: boolean;
    itemsCount: number;
    selectedItemIndex: number;
}

export function getOnboardingInitState(): IOnboardingState {
    return {
        data: [],
        isLoading: true,
        itemsCount: 0,
        selectedItemIndex: 0
    };
}