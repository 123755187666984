export const locale = {
    lang: 'en',
    data: {
        NOTIFICATION_LIST: {
            TITLES: {
                NOTIFICATIONS_MESSAGE: 'You have no additional notifications from the last 30 days.',
                NO_NOTIFICATIONS_MESSAGE: 'You have no notifications from the last 30 days.',
            }
        }
    }
};