import { ActivityListingCandidate, SetListingActivityRequest } from '@listings/models/api/set-listing-activity-request';
import { ListingActivity } from '@listings/models/listing/listing-activity';
import { ListingCardInfo } from '@listings/models/listing/listing-card-info';
import { Listings } from '@listings/models/listing/listings';
import { ListingsActivityInfo } from '@listings/models/listing/listings-activity-info';
import { NewMatch } from '@listings/models/listing/new-match';
import { ListingHelperService } from '@listings/services/listing-helper.service';
import { ListingsState } from '@listings/store/states/listings.state';

export class ListingsReducerHelpers {

    public static setListingsActivities(
        state: ListingsState,
        request: SetListingActivityRequest,
        customerId: number,
        isRestore: boolean,
        agentId: number,
        changeActivityAction: (activityListingCandidate: ActivityListingCandidate, activityId: number, customerId: number) => ListingActivity[],
    ): ListingsState {
        const modifiedListings = JSON.parse(JSON.stringify(state.listings)) as Listings;
        const modifiedListingsCollection = Object.values(modifiedListings);
        const modifiedListingsActivities = JSON.parse(JSON.stringify(state.listingsActivities)) as Record<number, ListingsActivityInfo>;
        const modifiedListingsNewMatches = JSON.parse(JSON.stringify(state.listingsNewMatches)) as Record<number, NewMatch[]>;

        request.listingCandidates.forEach(listingActivityCandidate => {
            const activities = changeActivityAction(listingActivityCandidate, request.activity.id, customerId);

            if (activities.length > 0) {

                if (modifiedListingsActivities[listingActivityCandidate.hashCode] == null) {
                    modifiedListingsActivities[listingActivityCandidate.hashCode] = {
                        activities,
                        createId: customerId,
                        pinOwnerAgentId: agentId,
                        isNewViewed: true,
                        createDate: new Date(),
                        isDeleted: false,
                        isAddedByAgent: false
                    };

                    const listing = modifiedListingsCollection.find(x => x.hashCode === listingActivityCandidate.hashCode);

                    if (listing == null) {
                        modifiedListings[listingActivityCandidate.id] = state.recentlyViewedListings.find(x => x.hashCode === listingActivityCandidate.hashCode);
                    }

                } else {
                    modifiedListingsActivities[listingActivityCandidate.hashCode] = { ...modifiedListingsActivities[listingActivityCandidate.hashCode], activities };
                }

                if (isRestore) {
                    modifiedListingsNewMatches[listingActivityCandidate.hashCode] = listingActivityCandidate.newMatches;
                } else {
                    delete modifiedListingsNewMatches[listingActivityCandidate.hashCode];
                }
            } else {
                modifiedListingsCollection
                    .filter(modifiedListing => modifiedListing.hashCode === listingActivityCandidate.hashCode)
                    .forEach(modifiedListing => delete modifiedListings[modifiedListing.id]);

                delete modifiedListingsActivities[listingActivityCandidate.hashCode];

                delete modifiedListingsNewMatches[listingActivityCandidate.hashCode];
            }
        });

        return {
            ...state,
            listings: modifiedListings,
            removalCandidateIds: state.removalCandidateIds,
            selectedListingIds: state.selectedListingIds.filter(selectedListingId => modifiedListings[selectedListingId] != null),
            listingsActivities: modifiedListingsActivities,
            listingsNewMatches: modifiedListingsNewMatches
        };
    }

    public static updateListings(
        currentListings: Listings,
        newListings: ListingCardInfo[],
        listingsActivities: Record<number, ListingsActivityInfo>,
        listingsNewMatches: Record<number, NewMatch[]>,
        isCustomerListings: boolean
    ): Listings {

        const updatedListings: ListingCardInfo[] = [];

        // On each update keep loaded customer listings and remove market marker (it will be set in case of is it specified in new listing source)
        Object.values(currentListings).forEach(listing => {

            const isNewMatch = listingsNewMatches[listing.hashCode]?.length > 0;
            const activities = listingsActivities[listing.hashCode]?.activities;

            if ((!isCustomerListings && (isNewMatch || activities?.length > 0)) ||
                (isCustomerListings && newListings.some(newListing => newListing.hashCode === listing.hashCode))) {

                updatedListings.push({ ...listing, isMarketListing: false });
            }
        });

        newListings.forEach(listing => {
            const matchingListingIndex = updatedListings.findIndex(exsistingListing => exsistingListing.hashCode === listing.hashCode);

            if (matchingListingIndex > -1) {
                updatedListings[matchingListingIndex] = { ...listing, id: updatedListings[matchingListingIndex].id };
            } else {
                updatedListings.push(listing);
            }
        });

        return ListingHelperService.mapToListings(updatedListings);
    }
}