import { NewMatch } from "@listings/models/listing/new-match";

export class NewMatchService {
    public static removeNewMatches(listingsNewMatches: Record<number, NewMatch[]>, hashCodes: number[]): Record<number, NewMatch[]> {
        return Object.entries(listingsNewMatches).reduce(
            (acc, [hashcode, newMatches]) => hashCodes.includes(+hashcode) ? acc : { ...acc, [hashcode]: newMatches },
            {} as Record<number, NewMatch[]>
        );
    }

    public static removeNewMatchesByIds(listingsNewMatches: Record<number, NewMatch[]>, newMatchIds: Set<number>): Record<number, NewMatch[]> {
        return Object.entries(listingsNewMatches).reduce(
            (acc, [hashcode, newMatches]) => ({ ...acc, [hashcode]: newMatches.filter(nm => !newMatchIds.has(nm.id)) }),
            {} as Record<number, NewMatch[]>
        );
    }
}
