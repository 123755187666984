import { Image } from '@media/models/image';
import { Thumbnail } from '@media/models/thumbnail';
import { Tour } from '@media/models/tour';
import { Video } from '@media/models/video';
import * as imageService from '@media/services/image-preferences.service';

export class FormattedMedia {

    public static notLoaded = new FormattedMedia([], [], []);

    public originalImages: Image[] = this.storedMedia?.map(imageService.formatOriginalImage);
    public largeImages: Image[] = this.storedMedia?.map(imageService.formatLargeImage);
    public mediumImages: Image[] = this.storedMedia?.map(imageService.formatMediumImage);
    public smallImages: Image[] = this.storedMedia?.map(imageService.formatSmallImage);
    public extraSmallImages: Image[] = this.storedMedia?.map(imageService.formatExtraSmallImage);
    public thumbnails: Thumbnail[] = this.storedMedia?.map(imageService.formatThumbnail);

    private readonly imagesMap: Map<string, Image[]> = new Map();

    constructor(
        private readonly storedMedia: imageService.StoredMedia[],
        public videos: Video[],
        public tours: Tour[]
    ) { }

    public hasAny = () => this.originalImages?.length > 0 || this.videos?.length > 0 || this.tours?.length > 0;

    public getImages(resolution: { width: number, height: number }): Image[] {
        const key = resolution.width + '/' + resolution.height;

        if (!this.imagesMap.has(key)) {
            const images = this.storedMedia?.map(o => imageService.formatWithResolution(o, resolution.width, resolution.height));

            this.imagesMap.set(key, images);
        }

        return this.imagesMap.get(key) ?? [];
    }

}