import { Injectable } from '@angular/core';
import { FolderManagementAction } from '@folders/enums/folder-management-action.enum';

import { FolderManagerModel } from '@folders/models/folder-management/folder-manager-model';

@Injectable({ providedIn: 'root' })
export class FoldersService {
    public static getListingsPickCandidatesIds(models: FolderManagerModel[]): string[] {
        return models.reduce(
            (candidates, { action, listings }) => {
                if (action !== FolderManagementAction.Attach) {
                    return candidates;
                }

                const listingsIds = listings.reduce(
                    (ids, { listingId, isPickCandidate }) => isPickCandidate ? [...ids, listingId] : ids,
                    new Array<string>()
                );

                return candidates.concat(listingsIds);
            },
            new Array<string>());
    }

    public static getListingsPickCandidatesHashCodes(models: FolderManagerModel[]): number[] {
        return models.reduce(
            (candidates, { action, listings }) => {
                if (action !== FolderManagementAction.Attach) {
                    return candidates;
                }

                const listingsHashCodes = listings.reduce(
                    (hashCodes, { hashCode, isPickCandidate }) => isPickCandidate ? [...hashCodes, hashCode] : hashCodes,
                    new Array<number>()
                );

                return candidates.concat(listingsHashCodes);
            },
            new Array<number>());
    }
}
