import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { NgLetModule } from 'ng-let';

import { RpcControlsModule } from '@core-controls/controls.module';
import { RpcDirectivesModule } from '@core-directives/directives.module';
import { TranslationLoaderService } from '@core-services/translation-loader.service';
import { RpcIconModule } from '@core-utils/rpc-icon/rpc-icon.module';
import { RpcTooltipModule } from '@core-utils/rpc-tooltip/rpc-tooltip.module';
import { MultiActivityControlsComponent } from '@listings/components/activity-controls/multi-activity-controls/multi-activity-controls.component';
import { SingleActivityControlsComponent } from '@listings/components/activity-controls/single-activity-controls/single-activity-controls.component';
import { LastPriceChangeComponent } from '@listings/components/last-price-change/last-price-change.component';
import { ListingLabelsComponent } from '@listings/components/listing-labels/listing-labels.component';
import { MultiSelectionMenuComponent } from '@listings/components/multi-selection-menu/multi-selection-menu.component';
import { locale as english } from '@listings/i18n/en';
import { ListingsEffects } from '@listings/store/effects/listings.effects';
import { listingsReducer } from '@listings/store/reducers/listings.reducer';
import { MediaModule } from '@media/media.module';
import { UsersModule } from '@users/users.module';
import { ActivitiesTooltipComponent } from './components/activities-tooltip/activities-tooltip.component';
import { ActivityButtonComponent } from './components/activity-controls/activity-button/activity-button.component';
import { RemovedListingActionControlsComponent } from './components/activity-controls/removed-listing-activity-controls/removed-listing-activity-controls.component';
import { RemovedListingMultiActivityControlsComponent } from './components/activity-controls/removed-listing-multi-activity-controls/removed-listing-multi-activity-controls.component';
import { ListingsListComponent } from './components/listings-list/listings-list.component';
import { MultiActivityCompactControlsComponent } from './components/multi-activity-compact-controls/multi-activity-compact-controls.component';
import { NewMatchTooltipComponent } from './components/new-match-tooltip/new-match-tooltip.component';
import { RemoveListingDialogComponent } from './components/remove-listing-dialog/remove-listing-dialog.component';
import { ListingActivityEffects } from './store/effects/listing-activity.effects';
import { ListingDirectionsEffects } from './store/effects/listing-directions.effects';
import { RemoveListingEffects } from './store/effects/remove-listing.effects';
import { RestoreListingEffects } from './store/effects/restore-listing.effects';
import { ListingEventsModule } from '../listing-events/listing-events.module';
import { RecentlyViewedListingsEffects } from './store/effects/recently-viewed-listings.effects';
import { OpenHouseTooltipComponent } from './components/open-house-tooltip/open-house-tooltip.component';
import { ListingPriceChangesEffects } from './store/effects/listing-price-changes.effects';
import { NewMatchesEffects } from './store/effects/new-matches.effects';

const components = [
    LastPriceChangeComponent,
    ListingLabelsComponent,
    SingleActivityControlsComponent,
    ActivityButtonComponent,
    MultiSelectionMenuComponent,
    MultiActivityControlsComponent,
    MultiActivityCompactControlsComponent,
    ListingsListComponent,
    RemoveListingDialogComponent,
    RemovedListingActionControlsComponent,
    RemovedListingMultiActivityControlsComponent,
    OpenHouseTooltipComponent
];

@NgModule({
    declarations: [
        components,
        ActivitiesTooltipComponent,
        NewMatchTooltipComponent,
    ],
    imports: [
        // Library Modules
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        MatMenuModule,
        MatCheckboxModule,
        MatDividerModule,
        FlexLayoutModule,
        MatDialogModule,
        MatBottomSheetModule,
        MatFormFieldModule,
        MatInputModule,
        NgLetModule,

        // Application Modules
        RpcIconModule,
        RpcControlsModule,
        UsersModule,
        MediaModule,
        RpcTooltipModule,
        RpcDirectivesModule,
        MediaModule,
        ListingEventsModule,

        // Module Configuration
        StoreModule.forFeature('listings', listingsReducer),
        EffectsModule.forFeature([
            ListingsEffects,
            ListingActivityEffects,
            RemoveListingEffects,
            RestoreListingEffects,
            ListingDirectionsEffects,
            RecentlyViewedListingsEffects,
            ListingPriceChangesEffects,
            NewMatchesEffects
        ])
    ],
    exports: [components],
    providers: []
})
export class ListingsModule {
    constructor(private readonly translationLoaderService: TranslationLoaderService) {
        this.translationLoaderService.loadTranslations(english);
    }
}