import { ScrollingModule } from '@angular/cdk/scrolling';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule, Provider } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { BrowserModule, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatNativeDateModule } from '@matheo/datepicker/core';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { ToastrModule } from 'ngx-toastr';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { ChunksErrorHandler } from '@app-common/error-handlers/chunks.error.handler';
import { ElmahIoErrorHandler } from '@app-common/error-handlers/elmah.io.error.handler';
import { RootErrorHandler } from '@app-common/error-handlers/root.error.handler';
import { appConfigurationDefault } from '@app-config/models/app-configuration-default';
import { APP_CONFIG } from '@app-config/services/app-configuration.service';
import { AppointmentsModule } from '@appointments/appointments.module';
import { AuthModule } from '@auth/auth.module';
import { HeadersInterceptor } from '@auth/interceptors/headers.interceptor';
import { JwtInterceptor } from '@auth/interceptors/jwt.interceptor';
import { TokenInitializerService } from '@auth/services/token-initializer.service';
import { logoutMetaReducer } from '@auth/store/reducers/auth.reducer';
import { CommentsModule } from '@comments/comments.module';
import { RpcDirectivesModule } from '@core-directives/directives.module';
import { AppRoutingModule } from '@core-layout/app/app-routing.module';
import { AppComponent } from '@core-layout/app/app.component';
import { locale as english } from '@core-layout/app/i18n/en';
import { serverMessagesMapping } from '@core-layout/app/server-message-mapping/server-message-mapping';
import { initializeApplication } from '@core-layout/app/services/app.initializer';
import { AppService } from '@core-layout/app/services/app.service';
import { AppEffects } from '@core-layout/app/store/effects/app.effects';
import { CustomSpinnerModule } from '@core-layout/custom-spinner/custom-spinner.module';
import { FooterModule } from '@core-layout/footer/footer.module';
import { ScrollToTopModule } from '@core-layout/scroll-to-top/scroll-to-top.module';
import { SideMenuModule } from '@core-layout/side-menu/side-menu.module';
import { SidebarModule } from '@core-layout/sidebar/sidebar.module';
import { ToolbarModule } from '@core-layout/toolbar/toolbar.module';
import { ServerMessageService } from '@core-services/server-message.service';
import { TranslationLoaderService } from '@core-services/translation-loader.service';
import { ImportantDialogModule } from '@core-utils/important-dialog/important-dialog.module';
import { ProgressBarModule } from '@core-utils/progress-bar/progress-bar.module';
import { RpcIconModule } from '@core-utils/rpc-icon/rpc-icon.module';
import { SimpleDialogModule } from '@core-utils/simple-dialog/simple-dialog.module';
import { ListingsModule } from '@listings/listings.module';
import { NotificationsModule } from '@notifications/notifications.module';
import { PwaModule } from '@rpc-pwa/pwa.module';
import { PwaService } from '@rpc-pwa/services/pwa.service';
import { SettingsModule } from '@settings/settings.module';
import { environment } from 'environments/environment';
import { RpcHammerConfig } from '../../../../../../hammer-config';
import { GoogleAnalyticsEffects } from './store/effects/google-analytics.effects';
import { GraphQLModule } from './graphql.module';
import { ToastModule } from '@toast/toast.module';
import { SearchModule } from '@search/search.module';
import { OnboardingModule } from 'app/modules/onboarding/onboarding.module';

const providers: Provider[] = [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: initializeApplication, deps: [TokenInitializerService, AppService, PwaService], multi: true },
    { provide: 'BASE_API_URL', useValue: environment.baseApiUrl },
    { provide: 'NOTIFICATIONS_API_URL', useValue: environment.notificationsApiUrl },
    { provide: 'IS_PRODUCTION', useValue: environment.production },
    { provide: 'IS_STAGE', useValue: environment.stage },
    {
        provide: 'virtual-scroller-default-options', useValue: {
            scrollThrottlingTime: 0,
            scrollDebounceTime: 0,
            scrollAnimationTime: 750,
            checkResizeInterval: 1000,
            resizeBypassRefreshThreshold: 5,
            modifyOverflowStyleOfParentScroll: true,
            stripedTable: false
        }
    },
    { provide: APP_CONFIG, useValue: appConfigurationDefault },
    { provide: HAMMER_GESTURE_CONFIG, useClass: RpcHammerConfig },
    ElmahIoErrorHandler,
    ChunksErrorHandler
];

if (environment.production || environment.stage) {
    providers.push({ provide: ErrorHandler, useClass: RootErrorHandler });
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        // Library Modules
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        NgxPageScrollCoreModule,
        MatButtonModule,
        MatNativeDateModule,
        ScrollingModule,

        // Required in main module since not working in lazy module as it is
        MatAutocompleteModule,
        MatChipsModule,

        TranslateModule.forRoot(),

        // Application Modules
        PwaModule,
        AuthModule,
        NotificationsModule,
        ToolbarModule,
        FooterModule,
        ProgressBarModule,
        SidebarModule,
        RpcDirectivesModule,
        CustomSpinnerModule,
        RpcIconModule,
        SideMenuModule,
        ScrollToTopModule,
        CommentsModule,
        AppointmentsModule,
        ListingsModule,
        SettingsModule,
        ToastModule,
        SearchModule,
        OnboardingModule,

        // Modals entry components have to be imported directly to AppModule because they can't be created inside lazy-loaded modules.
        SimpleDialogModule,
        ImportantDialogModule,

        // Module Configuration
        AppRoutingModule,

        // PWA
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production || environment.stage }),

        // NgRx
        // TODO : Investigate wrong stores usage to enable all strict runtime checks
        StoreModule.forRoot({ router: routerReducer }, {
            metaReducers: [logoutMetaReducer],
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
                strictStateSerializability: false,
                strictActionSerializability: false,
                strictActionWithinNgZone: false,
                strictActionTypeUniqueness: false
            }
        }),
        EffectsModule.forRoot([AppEffects, GoogleAnalyticsEffects]),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production || environment.stage,
            name: 'myPlaceNYC'
        }),

        StoreRouterConnectingModule.forRoot(),

        // Toaster
        ToastrModule.forRoot({
            preventDuplicates: true,
            countDuplicates: true,
            closeButton: true,
            maxOpened: 5,
            progressBar: true,
            progressAnimation: 'decreasing',
            resetTimeoutOnDuplicate: true
        }),

        HammerModule,

        GraphQLModule
    ],
    bootstrap: [
        AppComponent
    ],
    providers
})
export class AppModule {
    constructor(
        private readonly translationLoaderService: TranslationLoaderService,
        private readonly serverMessageService: ServerMessageService
    ) {
        this.translationLoaderService.loadTranslations(english);
        this.serverMessageService.loadServerMessagesMapping(serverMessagesMapping);
    }
}