import { createReducer, on } from '@ngrx/store';

import { ListingsMedia } from '@listings/models/listing/listings-media';
import * as listingActions from '@listings/store/actions/listings.actions';
import { ListingsState } from '@listings/store/states/listings.state';
import { FormattedMedia } from '@media/models/formatted-media';
import { ListingViewHelper } from './helpers/listing-view.helper';
import { ListingsReducerHelpers } from './helpers/listings.reducer.helper';
import { RemoveRestoreListingHelper } from './helpers/remove-restore-listing.helper';
import { listingActivityReducerTypes } from './listing-activity.reducer-types';
import { listingSelectionReducerTypes } from './listing-selection.reducer-types';
import { marketListingReducerTypes } from './market-listing.reducer-types';
import { Listings } from '@listings/models/listing/listings';
import { recentlyViewedListingsReducerTypes } from './recently-viewed-listings.reducer';
import { ListingHelperService } from '@listings/services/listing-helper.service';
import * as listingPriceChangesActions from '../actions/listing-price-changes.actions';
import { ListingActivityHelper } from '@listings/services/listing-activity.helper';
import { newMatchesReducerTypes } from './new-matches.reducer-types';
import { NewMatchService } from '@listings/services/new-match.service';

const initialState = new ListingsState();

export const listingsReducer = createReducer(
    initialState,
    on(listingActions.loadListing, (state): ListingsState => ({ ...state, isListingLoading: true })),
    on(listingActions.loadListingSuccess, (state, { listing, listingId }): ListingsState => {
        const exsistingListings = { ...state.listings };

        const matchingListing = Object.values(exsistingListings).find(exsistingListing => exsistingListing.hashCode === listing.hashCode);

        exsistingListings[listingId] = matchingListing != null ? { ...listing, id: matchingListing.id } : listing;

        return { ...state, listings: exsistingListings, isListingLoading: false };
    }),
    on(listingActions.loadListingFailed, (state): ListingsState => ({ ...state, isListingLoading: false })),
    on(listingActions.loadListingsDetailsSuccess, (state, { listingDetails }) => {
        return ({ ...state, listingsDetails: { ...state.listingsDetails, ...listingDetails } });
    }),
    on(listingActions.loadCustomerListings, (state, { shouldSetLoading }): ListingsState => {
        return { ...state, customerListingsLoaded: false, isListingsLoading: shouldSetLoading };
    }),
    on(listingActions.loadCustomerListingsSuccess, (state, { listings }) => {
        return {
            ...state,
            listings: ListingsReducerHelpers.updateListings(state.listings, Object.values(listings), state.listingsActivities, state.listingsNewMatches, true),
            customerListingsLoaded: true,
            isListingsLoading: false
        };
    }),
    on(listingActions.loadCustomerListingsFailed, (state, action): ListingsState => {
        return { ...state, customerListingsLoaded: false, isListingsLoading: false };
    }),
    on(listingActions.loadListingsMedia, (state, action): ListingsState => {
        return { ...state, mediaLoaded: false };
    }),
    on(listingActions.cleanupListingsMedia, (state): ListingsState => {
        if (state.media == null) {
            return state;
        }

        const media = Object.entries(state.media).reduce(
            (acc, [key, media]) => (state.listings[key] == null ? acc : { ...acc, [key]: media }),
            {} as ListingsMedia
        );

        return { ...state, media };
    }),
    on(listingActions.loadListingsMediaSuccess, (state, action): ListingsState => {
        const newMedia = { ...state.media };

        Object.keys(action.media).forEach(key => {
            const media = action.media[key];

            if (media == null) {
                newMedia[key] = new FormattedMedia(null, null, null);

                return;
            }

            newMedia[key] = new FormattedMedia(media.originalImages, media.videos, media.tours);
        });

        return {
            ...state,
            media: newMedia,
            mediaLoaded: true
        };
    }),
    on(listingActions.markAsViewed, (state, { listingHashCode }) => {
        return { ...state, listingsActivities: ListingViewHelper.setIsViewed(state.listingsActivities, listingHashCode, true) };
    }),
    on(listingActions.markAsViewedFailed, (state, { listingHashCode }) => {
        return { ...state, listingsActivities: ListingViewHelper.setIsViewed(state.listingsActivities, listingHashCode, false) };
    }),
    on(listingActions.resetState, () => ({ ...initialState })),
    on(listingActions.loadListinsgByHashCodesSuccess, (state, { loadedListings, listingsToDeleteIds }) => {
        let listingsUpdated = new Set<number>();

        const existingListings = Object.entries(state.listings).reduce(
            (acc, [key, listing]) => {
                const updatedListing = loadedListings.find(x => x.hashCode === listing.hashCode);

                if (updatedListing != null) {
                    listingsUpdated = listingsUpdated.add(updatedListing.hashCode);
                }

                // keep previous id to match listing on details page and prevent infinity requests
                return { ...acc, [key]: updatedListing == null ? listing : { ...updatedListing, id: key, isMarketListing: listing.isMarketListing } };
            },
            {} as Listings
        );

        for (const listingId of listingsToDeleteIds) {
            delete existingListings[listingId];
        }

        for (const listing of loadedListings) {
            if (!listingsUpdated.has(listing.hashCode)) {
                existingListings[listing.id] = listing;
            }
        }

        return {
            ...state,
            listings: existingListings,
            isListingsLoading: false
        };
    }),
    on(listingActions.loadListinsgByHashCodesFailed, (state) => ({ ...state, isListingsLoading: false })),
    on(listingActions.softDelete, (state, { listingIds }) => {
        const {
            listings,
            listingsActivities,
            listingsNewMatches
        } = RemoveRestoreListingHelper.softDelete(state.listings, listingIds, state.listingsActivities, state.listingsNewMatches);

        return { ...state, selectedListingIds: [], listings, listingsActivities, listingsNewMatches };
    }
    ),
    on(
        listingActions.softDeleteFailed,
        listingActions.restoreFailed,
        listingActions.hardDeleteFailed,
        (state, { listings, listingsActivities, listingsNewMatches }) => ({ ...state, listings, listingsActivities, listingsNewMatches })
    ),
    on(listingActions.restore, (state, { listingIds }) => {
        const hashCodes = listingIds.map(x => state.listings[x].hashCode);
        const listingsActivities = RemoveRestoreListingHelper.removeRestoreListings(state.listingsActivities, hashCodes, false);

        return {
            ...state,
            selectedListingIds: [],
            listingsActivities
        };
    }),
    on(listingActions.hardDelete, (state, { hashCodes }) => {
        const listingsActivities = ListingActivityHelper.removeActivities(state.listingsActivities, hashCodes);
        const listingsNewMatches = NewMatchService.removeNewMatches(state.listingsNewMatches, hashCodes);

        return ({
            ...state,
            selectedListingIds: [],
            listings: ListingHelperService.mapToListings(RemoveRestoreListingHelper.hardDelete(Object.values(state.listings), hashCodes)),
            recentlyViewedListings: RemoveRestoreListingHelper.hardDelete(state.recentlyViewedListings, hashCodes),
            listingsActivities,
            listingsNewMatches
        });
    }),
    on(listingPriceChangesActions.setListingsPriceChanges, (state, { priceChanges }) => ({ ...state, priceChanges })),
    ...marketListingReducerTypes,
    ...listingActivityReducerTypes,
    ...listingSelectionReducerTypes,
    ...recentlyViewedListingsReducerTypes,
    ...newMatchesReducerTypes
);