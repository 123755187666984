import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { from } from 'rxjs';
import { concatMap, delay, exhaustMap, filter, switchMap, take, tap } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { ChangeAgentDialogComponent } from '@agents/components/change-agent-dialog/change-agent-dialog.component';
import { AppConfigurationService } from '@app-config/services/app-configuration.service';
import * as appointmentsActions from '@appointments/store/actions/appointments.actions';
import { TokenInitializerService } from '@auth/services/token-initializer.service';
import * as loginActions from '@auth/store/actions/login.actions';
import { AuthApiService } from '@auth/store/services/auth-api.service';
import { UserStoreService } from '@auth/store/services/user-store.service';
import * as commentsActions from '@comments/store/actions/comments.actions';
import { RpcRoute } from '@core-layout/app/models/rpc-route';
import { RouteService } from '@core-layout/app/services/route.service';
import { ToastService } from '@core-services/toast.service';
import * as notificationsActions from '@notifications/store/actions/notifications.actions';
import * as settingsActions from '@settings/store/actions/settings.actions';
import * as switchCollaborationSpaceActions from '../actions/switch-collaboration-space.actions';
import * as recentlyViewedListingsActions from '@listings/store/actions/recently-viewed-listings.actions';
import { CollaborationSpaceStoreService } from '../services/collaboration-space-store.service';
import { GoogleAnalyticsStoreService } from '@core-layout/app/store/services/google-analytics-store.service';
import { GoogleAnalyticsEventName } from 'app/modules/core-modules/enums/google-analytics-event-name';
import { SECTION_CARDS_TO_SHOW } from '@landing/constants/landing.constants';
import * as listingsActions from '@listings/store/actions/listings.actions';
import * as externalListingsActions from '@external-listings/store/actions/external-listings.actions';
import * as foldersActions from '@folders/store/actions/folders.actions';
import * as onboardingActions from '@onboarding/store/actions/onboarding.actions';
import * as newMatchesActions from '@listings/store/actions/new-matches.actions';
import * as listingActivityActions from '@listings/store/actions/listing-activity.actions';

@Injectable()
export class SwitchCollaborationSpaceEffects {

    public readonly initializeNotificationDelay = 1000;

    constructor(
        private readonly actions$: Actions,
        private readonly authApiService: AuthApiService,
        private readonly configurationService: AppConfigurationService,
        private readonly routeService: RouteService,
        private readonly userStoreService: UserStoreService,
        private readonly toaster: ToastService,
        private readonly tokenInitializerService: TokenInitializerService,
        private readonly collaborationSpaceStoreService: CollaborationSpaceStoreService,
        private readonly matDialog: MatDialog,
        private readonly googleAnalyticsStoreService: GoogleAnalyticsStoreService,
    ) { }

    public readonly loadCollaborationSpaces$ = createEffect(() =>
        this.actions$.pipe(
            ofType(switchCollaborationSpaceActions.loadCollaborationSpaces),
            switchMap(() => this.authApiService.loadCollaborationSpaces()),
        )
    );

    public readonly loadCollaborationSpacesSuccessful$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(switchCollaborationSpaceActions.loadCollaborationSpacesSuccessful),
                concatLatestFrom(() => this.collaborationSpaceStoreService.collaborationSpaces$),
                filter(([, collaborationSpaces]) => collaborationSpaces.length > 1),
                tap(([, collaborationSpaces]) => {
                    const dialogConfig: MatDialogConfig = { data: collaborationSpaces, autoFocus: false, restoreFocus: false, panelClass: 'ca-dialog' };

                    this.matDialog.open(ChangeAgentDialogComponent, dialogConfig);
                })
            ),
        { dispatch: false }
    );

    public readonly loadHasMultipleCollaborationSpaces$ = createEffect(() =>
        this.actions$.pipe(
            ofType(switchCollaborationSpaceActions.loadHasMultipleCollaborationSpaces),
            switchMap(() => this.authApiService.loadHasMultipleCollaborationSpaces()),
        )
    );

    public readonly switchCollaborationSpaceSuccessful$ = createEffect(() => this.actions$.pipe(
        ofType(switchCollaborationSpaceActions.switchCollaborationSpaceSuccessful),
        switchMap(({ loginResponse, selectedCustomerId }) =>
            from(this.routeService.navigate(RpcRoute.FindHome)).pipe(
                tap(() => this.configurationService.configuration = { layout: { spinner: { hidden: false } } }),
                exhaustMap(() => [
                    loginActions.logout(false, false, false),
                    loginActions.collaborationSpaceLogin({ response: loginResponse }),
                    switchCollaborationSpaceActions.resetState({ selectedCustomerId }),
                    switchCollaborationSpaceActions.initializeNotifications(),
                    newMatchesActions.loadListingsNewMatchesRequested({ hashCodes: [] }),
                    listingActivityActions.loadListingsActivitiesRequested({ hashCodes: [] }),
                    recentlyViewedListingsActions.loadRecentlyViewedListing({ countToLoad: SECTION_CARDS_TO_SHOW }),
                ]),
                tap(() => this.configurationService.configuration = { layout: { spinner: { hidden: true } } })
            )
        ))
    );

    public readonly switchCollaborationSpaceFailed$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(switchCollaborationSpaceActions.switchCollaborationSpaceFailed),
                tap((error) => {
                    this.configurationService.configuration = { layout: { spinner: { hidden: true } } };
                    this.toaster.showServerError(error);
                })
            ),
        { dispatch: false }
    );

    public readonly switchCollaborationSpace$ = createEffect(() =>
        this.actions$.pipe(
            ofType(switchCollaborationSpaceActions.switchCollaborationSpace),
            tap(() => {
                this.configurationService.configuration = { layout: { spinner: { hidden: false } } };
                this.googleAnalyticsStoreService.addEvent(GoogleAnalyticsEventName.ChangeAgent);
            }),
            switchMap(({ selectedCustomerId }) => this.authApiService.getCollaborationSpaceToken(selectedCustomerId))
        )
    );

    public readonly initializeNotifications$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(switchCollaborationSpaceActions.initializeNotifications),
                delay(this.initializeNotificationDelay),
                tap(() => this.tokenInitializerService.initializeNotifications())
            ),
        { dispatch: false }
    );

    public readonly resetStates$ = createEffect(
        () => this.actions$.pipe(
            ofType(switchCollaborationSpaceActions.resetState),
            switchMap(({ selectedCustomerId }) => this.userStoreService.customerId$.pipe(
                filter((newCustomerId) => newCustomerId != null && newCustomerId === selectedCustomerId),
                take(1)
            )),
            concatMap(() => [
                notificationsActions.resetState(),
                appointmentsActions.resetState(),
                commentsActions.resetState(),
                listingsActions.resetState(),
                externalListingsActions.resetState(),
                foldersActions.resetState(),
                onboardingActions.resetState(),
                settingsActions.resetState(),
            ])
        )
    );
}