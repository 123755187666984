import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { AdditionalFooterComponent } from './additional-footer.component';
import { RpcIconModule } from '@core-utils/rpc-icon/rpc-icon.module';
import { TranslationLoaderService } from '@core-services/translation-loader.service';
import { locale as english } from '@core-layout/additional-footer/i18n/en';
import { NgLetModule } from 'ng-let';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        NgLetModule,

        RpcIconModule
    ],
    declarations: [AdditionalFooterComponent],
    exports: [AdditionalFooterComponent],
})
export class AdditionalFooterModule {
    constructor(private readonly translationLoaderService: TranslationLoaderService) {
        this.translationLoaderService.loadTranslations(english);
    }
}
