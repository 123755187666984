export const locale = {
    lang: 'en',
    data: {
        SEARCH_FILTER_TITLES: {
            TITLES: {
                ANY: 'Any'
            },
            TEMPLATES: {
                RANGE_FILTER_TITLE: '{{min}} - {{max}}',
                CHECKBOX_FILTER_EXACT_MATCH: '{{title}} {{defaultLocalizationKey}}',
                CHECKBOX_FILTER_EXACT_MATCH_SECOND_ANY: '{{firstValueLocalizationKey}} - {{anyLocalizationKey}} {{defaultLocalizationKey}}'
            }
        }
    }
};