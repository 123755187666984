import { EmailAlertsSettings } from './email-alerts-settings';
import { LayoutSettings } from './layout-settings';
import { PermissionSettings } from './permission-settings';

export class CustomerSettings {

    public readonly emailAlerts: EmailAlertsSettings;
    public readonly layoutSettings: LayoutSettings;
    public readonly permissionSettings: PermissionSettings;

    constructor(emailAlerts: EmailAlertsSettings, layoutSettings: LayoutSettings, permissionSettings: PermissionSettings) {
        this.emailAlerts = emailAlerts;
        this.layoutSettings = layoutSettings;
        this.permissionSettings = permissionSettings;
    }
}