/* eslint-disable @typescript-eslint/no-magic-numbers */
import { Directive, ElementRef, EventEmitter, HostListener, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, QueryList, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SimpleChanges } from '@core-models/utilities/generic-simple-changes';
import { CalculatedTransformationData } from './calculated-transformation-data';
import { ScrollableTransformCalculateOptions } from './scrollable-transform-calculate-options';

// TODO : Refactor - check responsibilities, new values adjustment, names, settings, simplify usage.

@Directive({ selector: '[scrollable]' })
export class ScrollableDirective implements OnInit, OnChanges, OnDestroy {

    /**
     * Shows whether reset of transformation to default is required or not on scrollable elements change.
     */
    @Input('scrollable') public scrollableEnabled: boolean;

    /**
     * Shows whether mouse wheel scroll by X acis is supported.
     */
    @Input('scrollableMouseWheelX') public scrollableMouseWheelX = true;

    /**
     * Shows whether mouse wheel scroll by Y acis is supported.
     */
    @Input('scrollableMouseWheelY') public scrollableMouseWheelY = false;

    /**
     * Scrollable elements container by X acis. Required to calculate scrollable boundaries.
     */
    @Input('scrollableXContainer') public scrollableXContainerElement: ElementRef<HTMLElement>;

    /**
     * Scrollable elements container by Y acis. Required to calculate scrollable boundaries.
     */
    @Input('scrollableYContainer') public scrollableYContainerElement: ElementRef<HTMLElement>;

    /**
     * List of all scrollable (or fixed elements) inside scrollable by acis X container.
     */
    @Input('scrollableXElements') public scrollableXElements: QueryList<ElementRef<HTMLElement>>;

    /**
     * Scrollable element container inside scrollable by acis Y container. Required to calculate scrollable boundaries.
     */
    @Input('scrollableYElement') public scrollableYElement: ElementRef<HTMLElement>;

    /**
     * Transformation target elements for X acis. Transform value is applied to these elements.
     */
    @Input('scrollableXTransformTargets') public scrollableXTransformTargetElements: QueryList<ElementRef<HTMLElement>>;

    /**
     * Transformation target elements for Y acis. Transform value is applied to these elements.
     */
    @Input('scrollableYTransformTargets') public scrollableYTransformTargetElements: QueryList<ElementRef<HTMLElement>>;

    /**
     * Used for calculation of whole scrollable containers width and its limits when gaps are placed one by one between scrollable elements.
     * Works only if 'scrollableElementsGapSum' is not provided or equals to 0.
     */
    @Input('scrollableXElementsGap') public scrollableXElementsGap? = 0;

    /**
     * Used for calculation of whole scrollable containers width and its limits when there are several gaps or extra separators placed in a row.
     */
    @Input('scrollableXElementsGapSum') public scrollableXElementsGapSum? = 0;

    /**
     * Used to simplify scrolling near border limits.
     * When scrollable area from any border is less than the value, auto scroll to border will be performed.
     * Note: Will not work if 'scrollableElementsGapSum' is passed.
     */
    @Input('scrollableXElementsBorderAdjustmentGap') public scrollableXElementsBorderAdjustmentGap? = 0;

    /**
     * Used to simplify scrolling near border limits.
     * When scrollable area from any border is less than the value, auto scroll to border will be performed.
     */
    @Input('scrollableYElementsBorderAdjustmentGap') public scrollableYElementsBorderAdjustmentGap? = 0;

    /**
     * Used to calculate specific transform position by X acis.
     * Do not pass options to trigger recalculation using last meaningful mouse/touch event.
     * Otherwise, options to transform to specific position must be provided.
     */
    @Input('scrollableXTransformCalculateTrigger') public scrollableXTransformCalculateTrigger: EventEmitter<ScrollableTransformCalculateOptions>;

    /**
     * Used to calculate specific transform position by Y acis.
     * Provide new scroll position.
     */
    @Input('scrollableYTransformCalculateTrigger') public scrollableYTransformCalculateTrigger: EventEmitter<number>;

    /**
     * Set to true if only manual transform change required.
     * Transform change will be performed only when 'scrollableTransformCalculateTrigger' emits value.
     * Default: false;
     */
    @Input('scrollableXTransformCalculateTriggerEnabledOnly') public scrollableXTransformCalculateTriggerEnabledOnly? = false;

    /**
     * Indicates what animation strategy should be used when scrolling programmatically.
     * Default: 'default'
     */
    @Input('autoScrollAnimationStrategy') public autoScrollAnimationStrategy: 'smooth' | 'default' = 'default';

    /**
     * Sets smooth scroll duration in ms.
     * Used when 'autoScrollAnimationStrategy' is set to 'smooth'.
     * Default: 600
     */
    @Input('smoothScrollDuration') public smoothScrollDuration = 600;

    /**
     * Emits new transform X and Y values each time they are changed.
     */
    @Output() public readonly scrollableTransformChanged = new EventEmitter<CalculatedTransformationData>();
    @Output() public readonly scrollableXTransformTriggerChanged = new EventEmitter<CalculatedTransformationData>();
    @Output() public readonly scrollableTransformStarted = new EventEmitter<void>();
    @Output() public readonly scrollableTransformFinished = new EventEmitter<void>();

    @Output() public readonly navigationOptionsChanged = new EventEmitter<{ isPreviousAwailable: boolean, isNextAwailable: boolean }>();

    private readonly unsubscribe$ = new Subject<void>();
    private defaultTransformUnsubscribe$ = new Subject<void>();

    private enableEventCalculation = false;
    private startClickPostition: MouseEvent | TouchEvent;
    private lastTransformationClickPostition: MouseEvent | TouchEvent;
    private startTransformPositionX: number;
    private startTransformPositionY: number;

    constructor(
        private readonly ngZone: NgZone,
        private readonly renderer: Renderer2
    ) { }

    public ngOnInit(): void {
        if (this.scrollableXTransformCalculateTrigger != null) {
            this.scrollableXTransformCalculateTrigger
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(scrollableTransformCalculateOptions => this.calculateXTransformTo(scrollableTransformCalculateOptions));
        }

        if (this.scrollableYTransformCalculateTrigger != null) {
            this.scrollableYTransformCalculateTrigger
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(scrollableTransformCalculateOptions => this.calculateYTransformTo(scrollableTransformCalculateOptions));
        }
    }

    @HostListener('wheel', ['$event'])
    public onMouseWheel(event: WheelEvent): void {
        if (!event.shiftKey && this.scrollableMouseWheelY) {
            const transformationData = this.calculateWheelYTransformationValues(event);

            this.trySetYTransform(transformationData);
        } else if ((event.shiftKey || event.deltaX !== 0) && this.scrollableMouseWheelX) {
            const transformationData = this.calculateWheelXTransformationValues(event);

            this.trySetXTransform(transformationData);
        }
    }

    public ngOnChanges(changes: SimpleChanges<ScrollableDirective>): void {
        const horizontalScrollableEnabled = changes.scrollableEnabled != null && changes.scrollableEnabled
            || this.scrollableEnabled;

        const scrollableElements = changes.scrollableXElements != null && changes.scrollableXElements.currentValue
            ? changes.scrollableXElements.currentValue
            : this.scrollableXElements;

        if (horizontalScrollableEnabled && scrollableElements != null) {
            this.defaultTransformUnsubscribe$.next();
            this.defaultTransformUnsubscribe$.complete();
            this.defaultTransformUnsubscribe$ = new Subject<void>();
            scrollableElements.changes
                .pipe(takeUntil(this.defaultTransformUnsubscribe$))
                .subscribe(() => {
                    this.setXTransform(0, null);
                    this.setYTransform(0, null);
                });
        }
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.defaultTransformUnsubscribe$.next();
        this.defaultTransformUnsubscribe$.complete();
    }

    @HostListener('touchstart', ['$event'])
    @HostListener('mousedown', ['$event'])
    public onMousedown(event: MouseEvent | TouchEvent): void {
        if (this.scrollableXTransformCalculateTriggerEnabledOnly) {
            return;
        }

        this.enableEventCalculation = true;
        this.startClickPostition = event;

        if (this.scrollableXTransformTargetElements != null && this.scrollableXTransformTargetElements.length > 0) {
            const current = this.getTransform(this.scrollableXTransformTargetElements.first.nativeElement);

            this.startTransformPositionX = Number(current[0]);
        } else {
            this.startTransformPositionX = 0;
        }

        if (this.scrollableYTransformTargetElements != null && this.scrollableYTransformTargetElements.length > 0) {
            const current = this.getTransform(this.scrollableYTransformTargetElements.first.nativeElement);

            this.startTransformPositionY = Number(current[1]);
        } else {
            this.startTransformPositionY = 0;
        }
    }

    @HostListener('touchend', ['$event'])
    @HostListener('touchcancel', ['$event'])
    @HostListener('mouseup', ['$event'])
    @HostListener('mouseleave', ['$event'])
    public onMouseup(event: MouseEvent | TouchEvent): void {
        if (this.scrollableXTransformCalculateTriggerEnabledOnly) {
            return;
        }

        this.enableEventCalculation = false;
        setTimeout(() => this.scrollableTransformFinished.emit());
    }

    @HostListener('touchmove', ['$event'])
    @HostListener('mousemove', ['$event'])
    public onMousemove(event: MouseEvent | TouchEvent): void {
        if (this.scrollableXTransformCalculateTriggerEnabledOnly) {
            return;
        }

        this.ngZone.runOutsideAngular(() => {
            if (this.enableEventCalculation) {
                if (this.scrollableXElements != null && this.scrollableXElements.length > 0) {
                    this.lastTransformationClickPostition = event;
                    const transformationData = this.calculateXTransformationValues(event);

                    if (this.startTransformPositionX !== transformationData.newTransformPositionX) {
                        this.trySetXTransform(transformationData);
                        this.scrollableTransformStarted.emit();
                    }
                }

                if (this.scrollableYContainerElement != null &&
                    this.scrollableYElement != null &&
                    this.scrollableYTransformTargetElements != null &&
                    this.scrollableYTransformTargetElements.length > 0
                ) {
                    this.lastTransformationClickPostition = event;
                    const transformationData = this.calculateYTransformationValues(event);

                    if (this.startTransformPositionY !== transformationData.newTransformPositionY) {
                        this.trySetYTransform(transformationData);
                        this.scrollableTransformStarted.emit();
                    }
                }
            }
        });
    }

    private calculateXTransformTo(scrollableTransformCalculateOptions: ScrollableTransformCalculateOptions): void {
        this.ngZone.runOutsideAngular(() => {
            if (scrollableTransformCalculateOptions == null) {
                // Calculate transformation using last click/touch position that performed transformation
                this.enableEventCalculation = true;
                this.onMousemove(this.lastTransformationClickPostition);
                this.enableEventCalculation = false;

                return;
            }

            if (scrollableTransformCalculateOptions.scrollableElementIndex != null) {
                // Calculate transformation to specific item
                if (this.scrollableXElements != null && scrollableTransformCalculateOptions.scrollableElementIndex < this.scrollableXElements.length) {
                    const [newTransformPositionX, adjustmentWidth] = this.calculateIndexNewTransformPositionX(scrollableTransformCalculateOptions);
                    const transformMaxPositionX = this.calculateTransformMaxPositionX();
                    const transformationData = {
                        newTransformPositionX,
                        transformMaxPositionX,
                        isBorderAlignmentRequired: !scrollableTransformCalculateOptions.screenCenterAlignRequired,
                        screenCenterAlignWidth: adjustmentWidth,
                        newTransformPositionY: 0,
                        transformMaxPositionY: 0
                    };

                    this.trySetXTransform(transformationData, true, true);
                }
            }
            else if (scrollableTransformCalculateOptions.scrollableTransform) {
                const current = this.getTransform(this.scrollableXTransformTargetElements.first.nativeElement);
                const transformMaxPositionX = this.calculateTransformMaxPositionX();
                let transformPositionX = scrollableTransformCalculateOptions.scrollableTransform + Number(current[0]);

                if (transformPositionX > 0) {
                    transformPositionX = 0;
                } else if (transformPositionX < 0 && Math.abs(transformPositionX) > transformMaxPositionX) {
                    transformPositionX = -transformMaxPositionX;
                }

                const transformationData = {
                    newTransformPositionX: transformPositionX,
                    transformMaxPositionX,
                    isBorderAlignmentRequired: !scrollableTransformCalculateOptions.screenCenterAlignRequired,
                    newTransformPositionY: 0,
                    transformMaxPositionY: 0
                };

                this.trySetXTransform(transformationData, true, true);

            }
        });
    }

    private calculateYTransformTo(newTransformPositionY: number): void {
        this.ngZone.runOutsideAngular(() => {
            const transformMaxPositionY = this.calculateTransformMaxPositionY();

            if (newTransformPositionY > 0 || newTransformPositionY == null) {
                newTransformPositionY = 0;
            } else if (newTransformPositionY < 0 &&
                Math.abs(newTransformPositionY) > transformMaxPositionY
            ) {
                newTransformPositionY = -transformMaxPositionY;
            }

            const transformationData = {
                newTransformPositionX: 0,
                transformMaxPositionX: 0,
                isBorderAlignmentRequired: true,
                screenCenterAlignWidth: 0,
                newTransformPositionY,
                transformMaxPositionY
            };

            this.trySetYTransform(transformationData, true);
        });
    }

    private trySetXTransform(transformationData: CalculatedTransformationData, emitTriggerEvent = false, isAutomatic = false): void {
        if (this.hasNotReachedLeftRightBorder(transformationData) || !transformationData.isBorderAlignmentRequired) {
            // Move elements only if there is some space until the border

            if (this.shouldAdjustNearLeftBorder(transformationData) && transformationData.isBorderAlignmentRequired) {
                // Transform to start to avoid wrong calculation when several px left
                this.setXTransform(0, transformationData, emitTriggerEvent, isAutomatic);

                return;
            }

            if (this.shouldAdjustNearRightBorder(transformationData) && transformationData.isBorderAlignmentRequired) {
                // Transform to end to avoid wrong calculation when several px left
                this.setXTransform(-transformationData.transformMaxPositionX, transformationData, emitTriggerEvent, isAutomatic);

                return;
            }

            this.setXTransform(transformationData.newTransformPositionX, transformationData, emitTriggerEvent, isAutomatic);

        } else if (transformationData.newTransformPositionX < 0 && Math.abs(transformationData.newTransformPositionX) > transformationData.transformMaxPositionX) {
            // Move elements to the right border in case elements disappeared or became thinner
            this.setXTransform(-transformationData.transformMaxPositionX, transformationData, emitTriggerEvent, isAutomatic);
        }
    }

    private trySetYTransform(transformationData: CalculatedTransformationData, isAutomatic = false): void {
        if (this.hasNotReachedTopBottomBorder(transformationData) || !transformationData.isBorderAlignmentRequired) {

            if (this.shouldAdjustNearTopBorder(transformationData) && transformationData.isBorderAlignmentRequired) {
                this.setYTransform(0, transformationData, isAutomatic);

                return;
            }

            if (this.shouldAdjustNearBottomBorder(transformationData) && transformationData.isBorderAlignmentRequired) {
                this.setYTransform(-transformationData.transformMaxPositionY, transformationData, isAutomatic);

                return;
            }

            this.setYTransform(transformationData.newTransformPositionY, transformationData, isAutomatic);

        } else if (transformationData.newTransformPositionY <= 0 && Math.abs(transformationData.newTransformPositionY) <= transformationData.transformMaxPositionY) {
            this.setYTransform(-transformationData.transformMaxPositionY, transformationData, isAutomatic);
        } else if (transformationData.newTransformPositionY > 0) {
            this.setYTransform(0, transformationData, isAutomatic);
        }
    }

    private calculateXTransformationValues(event: MouseEvent | TouchEvent): CalculatedTransformationData {
        const newTransformPositionX = this.calculateEventNewTransformPositionX(event);
        const transformMaxPositionX = this.calculateTransformMaxPositionX();

        return {
            newTransformPositionX,
            transformMaxPositionX,
            isBorderAlignmentRequired: true,
            screenCenterAlignWidth: 0,
            newTransformPositionY: 0,
            transformMaxPositionY: 0
        };
    }

    // eslint-disable-next-line complexity
    private calculateWheelXTransformationValues(event: WheelEvent): CalculatedTransformationData {
        const transformMaxPositionX = this.calculateTransformMaxPositionX();

        if (this.scrollableXTransformTargetElements != null) {
            const current = this.getTransform(this.scrollableXTransformTargetElements.first.nativeElement);

            this.startTransformPositionX = Number(current[0]);
        } else {
            this.startTransformPositionX = 0;
        }

        let newTransformPositionX = 0;

        let isDeltaNegative = false;

        if (event.deltaX !== 0) {
            newTransformPositionX = Number(this.startTransformPositionX) - event.deltaX;
            isDeltaNegative = event.deltaX < 0;
        } else {
            const correctedDelta = -(event.deltaY >= -10 && event.deltaY <= 10 ? event.deltaY * 10 : event.deltaY);

            newTransformPositionX = Number(this.startTransformPositionX) + correctedDelta;
            isDeltaNegative = event.deltaY < 0;
        }

        if (isDeltaNegative && this.startTransformPositionX <= 0 && this.startTransformPositionX >= -100) {
            newTransformPositionX = 0;
        } else if (!isDeltaNegative &&
            Math.abs(transformMaxPositionX) - Math.abs(this.startTransformPositionX) <= 100 &&
            Math.abs(transformMaxPositionX) - Math.abs(this.startTransformPositionX) >= 0
        ) {
            newTransformPositionX = -transformMaxPositionX;
        }

        return {
            newTransformPositionX,
            transformMaxPositionX,
            isBorderAlignmentRequired: true,
            screenCenterAlignWidth: 0,
            newTransformPositionY: 0,
            transformMaxPositionY: 0
        };
    }

    private calculateYTransformationValues(event: MouseEvent | TouchEvent): CalculatedTransformationData {
        const newTransformPositionY = this.calculateEventNewTransformPositionY(event);
        const transformMaxPositionY = this.calculateTransformMaxPositionY();

        return {
            newTransformPositionX: 0,
            transformMaxPositionX: 0,
            isBorderAlignmentRequired: true,
            screenCenterAlignWidth: 0,
            newTransformPositionY,
            transformMaxPositionY
        };
    }

    // eslint-disable-next-line complexity
    private calculateWheelYTransformationValues(event: WheelEvent): CalculatedTransformationData {
        const transformMaxPositionY = this.calculateTransformMaxPositionY();

        if (this.scrollableYTransformTargetElements != null) {
            const current = this.getTransform(this.scrollableYTransformTargetElements.first.nativeElement);

            this.startTransformPositionY = Number(current[1]);
        } else {
            this.startTransformPositionY = 0;
        }

        const correctedDelta = -(event.deltaY >= -10 && event.deltaY <= 10 ? event.deltaY * 10 : event.deltaY);

        let newTransformPositionY = Number(this.startTransformPositionY) + correctedDelta;

        if (event.deltaY < 0 && this.startTransformPositionY <= 0 && this.startTransformPositionY >= -100) {
            newTransformPositionY = 0;
        } else if (event.deltaY > 0 &&
            Math.abs(transformMaxPositionY) - Math.abs(this.startTransformPositionY) <= 100 &&
            Math.abs(transformMaxPositionY) - Math.abs(this.startTransformPositionY) >= 0
        ) {
            newTransformPositionY = -transformMaxPositionY;
        }

        return {
            newTransformPositionX: 0,
            transformMaxPositionX: 0,
            isBorderAlignmentRequired: true,
            screenCenterAlignWidth: 0,
            newTransformPositionY: newTransformPositionY > 0 ? -newTransformPositionY : newTransformPositionY,
            transformMaxPositionY
        };
    }

    /**
     * Calculates new transform position according to mouse/touch event X position.
     * @param event Mouse or Touch event.
     */
    private calculateEventNewTransformPositionX(event: MouseEvent | TouchEvent): number {
        if (event == null) {
            return 0;
        }

        const eventPostionX = event instanceof MouseEvent
            ? event.clientX
            : event.touches[0].clientX;

        const startEventPostionX = this.startClickPostition instanceof MouseEvent
            ? this.startClickPostition.clientX
            : this.startClickPostition.touches[0].clientX;

        return Number(this.startTransformPositionX) + (eventPostionX - startEventPostionX);
    }

    /**
     * Calculates new transform position according to mouse/touch event Y position.
     * @param event Mouse or Touch event.
     */
    private calculateEventNewTransformPositionY(event: MouseEvent | TouchEvent): number {
        if (event == null) {
            return 0;
        }

        const eventPostionY = event instanceof MouseEvent
            ? event.clientY
            : event.touches[0].clientY;

        const startEventPostionY = this.startClickPostition instanceof MouseEvent
            ? this.startClickPostition.clientY
            : this.startClickPostition.touches[0].clientY;

        return Number(this.startTransformPositionY) + (eventPostionY - startEventPostionY);
    }

    /**
     * Calculates new transform position according to required scrollable element index and
     * provided gaps sum or directive's 'scrollableElementsGap' field value.
     * @param scrollableTransformCalculateOptions TransformTo options.
     */
    private calculateIndexNewTransformPositionX(scrollableTransformCalculateOptions: ScrollableTransformCalculateOptions): [number, number] {
        const gapsWidth = scrollableTransformCalculateOptions.scrollableElementGapsSum != null
            ? scrollableTransformCalculateOptions.scrollableElementGapsSum
            : scrollableTransformCalculateOptions.scrollableElementIndex * this.scrollableXElementsGap;

        const scrollableElementsWidthCalculationAction = (sum: number, element: ElementRef<HTMLElement>, index: number) => {
            return index < scrollableTransformCalculateOptions.scrollableElementIndex
                ? sum + element.nativeElement.offsetWidth
                : sum;
        };

        const scrollableElementsWithGapsWidth = this.scrollableXElements.reduce(scrollableElementsWidthCalculationAction, 0) + gapsWidth;

        if (scrollableTransformCalculateOptions.screenCenterAlignRequired &&
            scrollableTransformCalculateOptions.scrollableElementIndex < this.scrollableXElements.length &&
            this.scrollableXElements.get(scrollableTransformCalculateOptions.scrollableElementIndex) != null
        ) {
            const screenWithoutCardWidth = window.innerWidth - this.scrollableXElements.get(scrollableTransformCalculateOptions.scrollableElementIndex).nativeElement.offsetWidth;
            const adjustmentWidth = screenWithoutCardWidth / 2;

            return [-(scrollableElementsWithGapsWidth) + adjustmentWidth, adjustmentWidth];
        }

        return [-(scrollableElementsWithGapsWidth), 0];
    }

    /**
     * Calculates max X transform position value (positive).
     */
    private calculateTransformMaxPositionX(): number {
        if (this.scrollableXElements == null || this.scrollableXContainerElement == null) {
            return 0;
        }

        const gapsWidth = this.scrollableXElementsGapSum > 0
            ? this.scrollableXElementsGapSum
            : (this.scrollableXElements.length - 1) * this.scrollableXElementsGap;

        const scrollableElementsWidth =
            this.scrollableXElements.reduce((sum, element) => sum + element.nativeElement.offsetWidth, 0) + gapsWidth;

        // Subtract width of elements container so last slide would be on right side
        let transformMaxPositionX =
            scrollableElementsWidth - this.scrollableXContainerElement.nativeElement.offsetWidth;

        if (transformMaxPositionX <= 0) {
            transformMaxPositionX = 0;
        }

        return transformMaxPositionX;
    }

    /**
     * Calculates max Y transform position value (positive).
     */
    private calculateTransformMaxPositionY(): number {
        if (this.scrollableYContainerElement == null || this.scrollableYElement == null) {
            return 0;
        }

        let transformMaxPositionY = this.scrollableYElement.nativeElement.offsetHeight - this.scrollableYContainerElement.nativeElement.offsetHeight;

        if (transformMaxPositionY <= 0) {
            transformMaxPositionY = 0;
        }

        return transformMaxPositionY;
    }

    private hasNotReachedLeftRightBorder(transformationData: CalculatedTransformationData): boolean {
        return transformationData.newTransformPositionX <= 0 && transformationData.transformMaxPositionX >= 0
            && Math.abs(transformationData.newTransformPositionX) <= transformationData.transformMaxPositionX;
    }

    private hasNotReachedTopBottomBorder(transformationData: CalculatedTransformationData): boolean {
        return transformationData.newTransformPositionY <= 0 && transformationData.transformMaxPositionY >= 0
            && Math.abs(transformationData.newTransformPositionY) <= transformationData.transformMaxPositionY;
    }

    private shouldAdjustNearLeftBorder(transformationData: CalculatedTransformationData): boolean {
        return transformationData.newTransformPositionX >= -this.scrollableXElementsBorderAdjustmentGap && this.startTransformPositionX !== 0;
    }

    private shouldAdjustNearRightBorder(transformationData: CalculatedTransformationData): boolean {
        return (Math.abs(transformationData.transformMaxPositionX) - Math.abs(transformationData.newTransformPositionX))
            <= this.scrollableXElementsBorderAdjustmentGap
            && this.startTransformPositionX !== 0;
    }

    private shouldAdjustNearTopBorder(transformationData: CalculatedTransformationData): boolean {
        return transformationData.newTransformPositionY >= -this.scrollableYElementsBorderAdjustmentGap && this.startTransformPositionY !== 0;
    }

    private shouldAdjustNearBottomBorder(transformationData: CalculatedTransformationData): boolean {
        return (Math.abs(transformationData.transformMaxPositionY) - Math.abs(transformationData.newTransformPositionY))
            <= this.scrollableYElementsBorderAdjustmentGap
            && this.startTransformPositionY !== 0;
    }

    private getTransform(element: HTMLElement): string[] {
        const transform = window.getComputedStyle(element, null).getPropertyValue('-webkit-transform');
        // eslint-disable-next-line max-len
        const results = transform.match(/matrix(?:(3d)\(-{0,1}\d+(?:, -{0,1}\d+)*(?:, (-{0,1}\d+))(?:, (-{0,1}\d+))(?:, (-{0,1}\d+)), -{0,1}\d+\)|\(-{0,1}\d+(?:, -{0,1}\d+)*(?:, (-{0,1}\d+\.?\d*))(?:, (-{0,1}\d+\.?\d*))\))/);

        if (!results) {
            return ['0', '0', '0'];
        }

        if (results[1] === '3d') {
            return results.slice(2, 5);
        }

        results.push('0');

        return results.slice(5, 8); // returns the [X,Y,Z,1] values
    }

    private setXTransform(transformX: number, transformationData: CalculatedTransformationData = null, emitTriggerEvent = false, isAutomatic = false): void {
        if (this.scrollableXTransformTargetElements != null && this.scrollableXTransformTargetElements.length > 0) {
            this.ngZone.run(() => {

                const newTransform = Math.round(transformX);

                this.scrollableXTransformTargetElements.forEach(element => {
                    if (isAutomatic && this.autoScrollAnimationStrategy === 'smooth') {
                        this.smoothScrollToElement(element.nativeElement);
                    }

                    const current = this.getTransform(element.nativeElement);

                    this.renderer.setStyle(element.nativeElement, 'transform', `translate3d(${newTransform}px, ${current[1]}px, 0px)`);
                });

                if (transformationData != null) {
                    transformationData.newTransformPositionX = newTransform;

                    const navigationOptions = this.getNavigationOptions(transformationData);

                    this.navigationOptionsChanged.emit(navigationOptions);
                }

                this.scrollableTransformChanged.emit(transformationData);

                if (emitTriggerEvent) {
                    this.scrollableXTransformTriggerChanged.emit(transformationData);
                }
            });
        }
    }

    private setYTransform(transformY: number, transformationData: CalculatedTransformationData = null, isAutomatic = false): void {
        if (this.scrollableYTransformTargetElements != null && this.scrollableYTransformTargetElements.length > 0) {
            this.ngZone.run(() => {

                const newTransform = Math.round(transformY);

                this.scrollableYTransformTargetElements.forEach(element => {
                    if (isAutomatic && this.autoScrollAnimationStrategy === 'smooth') {
                        this.smoothScrollToElement(element.nativeElement);
                    }

                    const current = this.getTransform(element.nativeElement);

                    this.renderer.setStyle(element.nativeElement, 'transform', `translate3d(${current[0]}px, ${newTransform}px, 0px)`);
                });

                if (transformationData != null) {
                    transformationData.newTransformPositionY = newTransform;
                }

                this.scrollableTransformChanged.emit(transformationData);
            });
        }
    }

    private smoothScrollToElement(element: HTMLElement): void {
        this.renderer.setStyle(element, 'transition', `${this.smoothScrollDuration}ms ease-in-out`);
        setTimeout(() => {
            this.renderer.setStyle(element, 'transition', 'none');
        }, this.smoothScrollDuration);
    }

    private getNavigationOptions(transformationData: CalculatedTransformationData): { isPreviousAwailable: boolean; isNextAwailable: boolean; } {
        const { newTransformPositionX, transformMaxPositionX } = transformationData;

        const newTransformPositionXAbs = Math.abs(newTransformPositionX);
        // padding and divider on the feft side
        const leftMarkupOffset = 15;
        // when swipe manually, transformMaxPositionX on the left side can never reach zero value and left arrow always awailable always
        const totalOffset = this.scrollableXElementsGap + leftMarkupOffset;
        const isPreviousAwailable = (transformMaxPositionX !== 0 && newTransformPositionXAbs - totalOffset > 0);
        const isNextAwailable = (transformMaxPositionX !== 0 && newTransformPositionXAbs < transformMaxPositionX);

        return { isPreviousAwailable, isNextAwailable };
    }
}