import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { SortOptionBase } from '@core-models/sort/sort-option';
import * as onMarketActions from '@on-market/store/actions/on-market.actions';
import * as onMarketSelectors from '@on-market/store/selectors/on-market.selector';
import { SettingsStoreService } from '@settings/store/services/settings-store.service';
import { ViewModes } from '@view-base/models/view-modes.enum';

@Injectable({ providedIn: 'root' })
export class OnMarketStoreService {

    constructor(
        private readonly store: Store,
        private readonly settingsStoreService: SettingsStoreService
    ) { }

    public getSort(): Observable<SortOptionBase> {
        return this.store.select((onMarketSelectors.selectSort));
    }

    public getViewMode(): Observable<ViewModes> {
        return this.store.select((onMarketSelectors.selectSelectedViewMode));
    }

    public getMapSizePercent(): Observable<number> {
        return this.store.select((onMarketSelectors.selectMapSizePercent));
    }

    public setSorting(sort: SortOptionBase): void {
        this.store.dispatch(onMarketActions.setSort({ sort }));
    }

    public setViewMode(mode: ViewModes): void {
        this.store.dispatch(onMarketActions.setViewMode({ viewMode: mode }));
    }

    public setMapSizePercent(size: number): void {
        this.store.dispatch(onMarketActions.setMapSizePercent({ mapSizePercent: size }));
    }

    public resetSavedView(): void {
        this.settingsStoreService.getSettings()
            .pipe(take(1))
            .subscribe(settings => this.store.dispatch(onMarketActions.resetSavedView(settings)));
    }

    public setNeighborhoodLayerVisibility(isVisible: boolean): void {
        this.store.dispatch(onMarketActions.setNeighborhoodLayerVisibility({ isVisible }));
    }

    public resetState(): void {
        this.store.dispatch(onMarketActions.resetState());
    }
}