import { createAction, props } from '@ngrx/store';

import { NeighborhoodProperty } from '@on-market/models/neighborhood-property';

export const loadNeighborhoodProperties = createAction('[Neighborhood] Load Neighborhood Properties');

export const loadNeighborhoodPropertiesSuccess = createAction(
    '[Neighborhood] Load Neighborhood Properties Success',
    props<{ neighborhoodProperties: NeighborhoodProperty[] }>()
);

export const loadNeighborhoodPropertiesFailed = createAction('[Neighborhood] Load Neighborhood Properties Failed');