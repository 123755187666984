import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, Observable } from 'rxjs';

import { ListingListItem } from '@listings/models/listing/listing-list-item';
import { RemoveListingData } from '@listings/models/listing/remove-listing-data';
import { ListingsStoreService } from '@listings/store/services/listings-store.service';
import { ListingIdentifier } from '@listings/models/listing/listing-identifier';

@Component({
    templateUrl: './remove-listing-dialog.component.html',
    styleUrls: ['./remove-listing-dialog.component.scss'],
})
export class RemoveListingDialogComponent implements OnInit {

    public listings$: Observable<ListingListItem[]>;
    public removedListingsCount$: Observable<number>;

    constructor(
        private readonly dialogRef: MatDialogRef<RemoveListingDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: RemoveListingData,
        private readonly listingsStoreService: ListingsStoreService,
    ) { }

    public ngOnInit(): void {
        this.listings$ = this.data?.listingId != null
            ? this.listingsStoreService.getListingInfoById(this.data.listingId).pipe(map(listing => [listing]))
            : this.listingsStoreService.selectedPortfolioListingsInfo$;

        this.removedListingsCount$ = this.listings$.pipe(map(listings => listings.filter(({ isDeleted }) => isDeleted).length));
    }

    public onUnselectListingClicked(listingId: string): void {
        this.listingsStoreService.unselectListings([listingId]);
    }

    public removeListings(listings: ListingListItem[]): void {
        this.closeDialogWithData(listings);
    }

    public close(): void {
        this.dialogRef.close();
    }

    private closeDialogWithData(listings: ListingListItem[]): void {
        const listingIdsToRemove = this.data?.isHardDelete
            ? listings.map(listing => {
                return new ListingIdentifier(listing.listingId, listing.hashCode);
            })
            : listings.reduce(
                (result, { isDeleted, listingId, hashCode }) => isDeleted ? result : result.concat({ listingId, hashCode }),
                new Array<ListingIdentifier>()
            );

        this.dialogRef.close(listingIdsToRemove);
    }
}
