import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { combineLatest, debounceTime, filter, take } from 'rxjs';

import { ScrollConstants } from '@core-layout/scroll-to-top/scroll.constants';
import { ScrollService } from '@core-layout/scroll-to-top/scroll.service';
import { FolderCover } from '@folders/models/folder-cover';
import { FolderSelectionModel } from '@folders/models/folder-selection-model';
import { FoldersStoreReadService } from '@folders/store/services/folders-store-read.service';
import { FoldersStoreWriteService } from '@folders/store/services/folders-store-write.service';
import { GalleryViewTypes } from '@gallery-view/enums/gallery-view-types';
import { MyListingsStoreService } from '@my-listings/store/services/my-listings-store.service';
import { SettingsStoreService } from '@settings/store/services/settings-store.service';
import { GoogleAnalyticsStoreService } from '@core-layout/app/store/services/google-analytics-store.service';
import { InitialFolderStateMode } from '@folders/enums/initial-folder-state-mode.enum';

@Component({
    selector: 'folders-view',
    templateUrl: './folders-view.component.html',
    styleUrls: ['./folders-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FoldersViewComponent implements OnInit {
    public readonly systemFoldersCover$ = this.foldersStoreReadService.systemFoldersCover$;

    public readonly isLoading$ = this.foldersStoreReadService.isLoading$;

    public readonly defaultFolderId$ = this.settingsStoreService.defaultFolderId$;
    public readonly customFoldersCover$ = this.foldersStoreReadService.sortedCustomFoldersCover$;

    constructor(
        private readonly foldersStoreReadService: FoldersStoreReadService,
        private readonly settingsStoreService: SettingsStoreService,
        private readonly scrollService: ScrollService,
        private readonly foldersStoreWriteService: FoldersStoreWriteService,
        private readonly myListingsStoreService: MyListingsStoreService,
        private readonly googleAnalyticsStoreService: GoogleAnalyticsStoreService,
    ) { }

    public ngOnInit(): void {
        this.myListingsStoreService.setViewType(GalleryViewTypes.Folders);
        this.myListingsStoreService.load();
        this.myListingsStoreService.setInitialFolderStateMode(InitialFolderStateMode.Default);

        combineLatest([
            this.foldersStoreReadService.sortedCustomFoldersCover$,
            this.foldersStoreReadService.selectedFolderId$
        ]).pipe(
            filter(([, selectedFolderId]) => selectedFolderId != null),
            take(1),
            debounceTime(ScrollConstants.ScrollTime.MinScrollTime),
        ).subscribe(([sortedCustomFoldersCover, selectedFolderId]) => {
            this.scrollService.scroll(`#${selectedFolderId}`, null, ScrollConstants.ScrollTime.MinScrollTime);
        });
    }

    public onFolderClick(model: FolderSelectionModel): void {
        this.googleAnalyticsStoreService.addOpenFolderEvent(model.initialFolderStateMode);
        this.myListingsStoreService.navigateToFolder(model.id, model.initialFolderStateMode);
    }

    public trackByFolder(index: number, folder: FolderCover): number {
        return folder.id;
    }

    public createFolder(): void {
        this.foldersStoreWriteService.showAddEditFolderDialog({ eventSource: 'fab' });
    }
}