import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { User } from '@auth/models/user';
import { UserStoreService } from '@auth/store/services/user-store.service';
import { RpcRoute } from '@core-layout/app/models/rpc-route';
import { RouteService } from '@core-layout/app/services/route.service';

@Injectable({ providedIn: 'root' })
export class NotAuthorizedOnlyGuard implements CanActivateChild, CanActivate {

    constructor(
        private readonly routeService: RouteService,
        private readonly userStoreService: UserStoreService
    ) { }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.canActivateRoute();
    }

    public canActivate(): Observable<boolean> {
        return this.canActivateRoute();
    }

    private canActivateRoute(): Observable<boolean> {
        return this.userStoreService.getUser()
            .pipe(
                take(1),
                switchMap((user: User) => {
                    if (user != null && user.loginData != null) {
                        this.routeService.navigate(RpcRoute.FindHome).catch(() => { });

                        return of(false);
                    }

                    return of(true);

                }));
    }
}