<ng-container *ngIf="folder != null">
    <div class="folder-menu" [ngClass]="{ 'rpc-dropdown': !folder.isSystemFolder }">
        <div class="folder-creator" *ngIf="!folder.isSystemFolder && folder.creatorInfo != null">
            <avatar-initials class="folder-creator-avatar" [userFullName]="folder.creatorInfo?.fullName"
                [userProfileImageUrl]="folder.creatorInfo?.profileImage">
            </avatar-initials>
            <div class="folder-creator-name text-truncate">{{ folder.creatorInfo?.fullName }}</div>
            <div class="folder-creator-note">{{ 'FOLDER_DETAILS.TITLES.FOLDER_CREATOR' | translate }}</div>
            <div class="folder-creator-date">
                {{ folder?.createDateTime | date: DateConstants.Formats.AngularPipeShortDateSlash }}
            </div>
        </div>
        <ng-container *ngIf="!folder.isSystemFolder && savedSearchesNames.length !== 0">
            <div class="folder-saved-search" (click)="onSavedSearchesCliked()" [ngClass]="{
                    'folder-saved-search--multiple': hasMultipleSavedSearches,
                    'folder-saved-search--opened': isSavedSearchesAreaExpanded
                }">
                <div class="folder-saved-search-title">
                    {{ 'FOLDER_DETAILS.TITLES.ATTACHED_SAVED_SEARCH' | translate }}
                </div>
                <ng-container *ngIf="!hasMultipleSavedSearches; else multipleSavedSearches">
                    <div class="folder-saved-search-name text-truncate">
                        {{ savedSearchesNames[0] }}
                    </div>
                    <rpc-icon [iconName]="'saved-search'" class="folder-saved-search-icon"></rpc-icon>
                </ng-container>
                <ng-template #multipleSavedSearches>
                    <div class="folder-saved-search-name">
                        <div *ngFor="let savedSearchName of savedSearchesNames"
                            class="folder-saved-search-name-item text-truncate">
                            {{ savedSearchName }}
                        </div>
                    </div>
                    <rpc-icon [iconName]="'saved-search'" class="folder-saved-search-icon"></rpc-icon>

                    <div class="folder-saved-search-trigger">
                        <div class="folder-saved-search-trigger-text">
                            {{ (isSavedSearchesAreaExpanded ? 'FOLDER_DETAILS.TITLES.HIDE' :
                            'FOLDER_DETAILS.TITLES.SEE_ALL') | translate }}
                        </div>
                        <rpc-icon class="folder-saved-search-trigger-icon" [iconName]="'arrow-down'"></rpc-icon>
                    </div>
                </ng-template>
            </div>
            <mat-divider class="folder-menu-divider"></mat-divider>
        </ng-container>
        <mat-slide-toggle class="folder-default-toggle" [labelPosition]="'before'"
            (change)="onDefaultFolderChange($event.checked)" [checked]="isDefault">
            <span class="folder-default-toggle-box">
                <span class="folder-default-toggle-text">{{ 'FOLDER_DETAILS.TITLES.SET_DEFAULT' | translate }}</span>
                <span class="folder-default-toggle-note">
                    {{ 'FOLDER_DETAILS.TITLES.DEFAULT_FOLDER_MESSAGE' | translate}}
                </span>
            </span>
        </mat-slide-toggle>
        <ng-container *ngIf="!folder.isSystemFolder">
            <mat-divider class="folder-menu-divider"></mat-divider>
            <rpc-button class="rpc-dropdown-btn rpc-dropdown-btn--first" [appearanceType]="'normal'" [iconName]="'edit'"
                title="FOLDER_DETAILS.TITLES.EDIT" (clicked)="edit()"></rpc-button>
            <rpc-button class="rpc-dropdown-btn" [appearanceType]="'normal'" [iconName]="'delete-bin'"
                title="FOLDER_DETAILS.TITLES.DELETE_FOLDER" (clicked)="onDeleteFolder()"></rpc-button>
        </ng-container>
    </div>
</ng-container>