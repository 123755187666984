import { MediaSliderMode } from '@media/components/media-slider/enums/media-slider-mode';
import { MediaGroup } from '@media/enums/media-group.enum';
import { Image } from '@media/models/image';
import { Thumbnail } from '@media/models/thumbnail';

export class MediaConstants {

    public static UnavailableImageUrl = 'assets/images/decoration/ld-no-media.svg';

    public static UnavailableImage: Image = {
        desktopUrl: MediaConstants.UnavailableImageUrl,
        mobileUrl: MediaConstants.UnavailableImageUrl,
        mediaId: 999,
        isPortrait: false,
        assetType: null,
        description: '',
        recordType: null,
        isDummy: true
    };

    public static UnavailableThumbnail: Thumbnail = {
        url: MediaConstants.UnavailableImageUrl,
        mediaId: 999,
        isPortrait: false,
        assetType: null,
        description: '',
        recordType: null,
        isDummy: true
    };
}

export const MEDIA_VIEWER_MODES = new Map<MediaSliderMode, MediaGroup>([
    [MediaSliderMode.Floorplan, MediaGroup.ListingFloorplans],
    [MediaSliderMode.Image, MediaGroup.All],
    [MediaSliderMode.Tour, MediaGroup.All],
    [MediaSliderMode.Video, MediaGroup.Videos],
]);

export const VIMEO_NOT_PRIVATE_VIDEO_REGEX = /^(?:https?:\/\/)?(?:www\.)?(?:player\.)?vimeo\.com\/(?:video\/)?(\d+)(?:\?.*)?$/;