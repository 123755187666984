import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FolderManagementAction } from '@folders/enums/folder-management-action.enum';
import { FoldersStoreWriteService } from '@folders/store/services/folders-store-write.service';
import { IFolderAttachmentDialogData } from './models/folder-attachment-dialog-data';

@Component({
    templateUrl: './folder-attachment-dialog.component.html',
    styleUrls: ['./folder-attachment-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FolderAttachmentDialogComponent {
    constructor(
        private readonly dialogRef: MatDialogRef<FolderAttachmentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: IFolderAttachmentDialogData,
        private readonly foldersStoreWriteService: FoldersStoreWriteService
    ) { }

    public onFolderClicked(): void {
        this.foldersStoreWriteService.manageListingsFolders([{
            action: FolderManagementAction.Attach,
            folderId: this.data.folder.id,
            listings: this.data.listings,
        }],
            this.data.shouldUnselectListings
        );

        this.dialogRef.close();
    }

    public onOtherFoldersClicked(): void {
        this.foldersStoreWriteService.openFolderManagementDialog(this.data.shouldUnselectListings, this.data.listingId);
        this.dialogRef.close();
    }

    public onClose(): void {
        this.dialogRef.close();
    }
}