import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { catchError, map, Observable, of } from 'rxjs';

import { ApiDataResult, ApiResult } from '@core-models/api/api-result';
import { ApiHttpClient } from '@core-services/api-http-client.service';
import { ApiError } from '@error/models/api-error';
import { Folder } from '@folders/models/folder';
import { FolderManagerModel } from '@folders/models/folder-management/folder-manager-model';
import { FolderSavedSearch } from '@folders/models/folder-saved-search';
import { ListingFolderIdsMap } from '@folders/models/listing-folder-ids-map';
import { IManageFolderModel } from '@folders/models/manage-folder-model';
import { RemoveFolderListingsModel } from '@folders/models/remove-folder-listings-model';
import * as foldersActions from '../actions/folders.actions';
import { FolderNewMatch } from '@folders/models/folder-new-match';
import { ListingActivityId } from '@listings/models/listing/listing-activity-id';

@Injectable({ providedIn: 'root' })
export class FoldersApiService {

    constructor(private readonly http: ApiHttpClient) { }

    public getCustomFolders(): Observable<Action> {
        return this.http.get('folder/custom').pipe(
            map((response: ApiDataResult<Folder[]>) => {
                return response.error != null
                    ? foldersActions.loadFoldersFailed(response.error)
                    : foldersActions.loadFoldersSuccess({ folders: response.result });
            }),
            catchError((errorResponse: HttpErrorResponse) =>
                of(foldersActions.loadFoldersFailed(errorResponse.error as ApiError))
            )
        );
    }

    public update(folderData: IManageFolderModel): Observable<Action> {
        return this.http.put('folder', folderData).pipe(
            map((response: ApiResult) => {
                return response.error != null
                    ? foldersActions.updateFailed(response.error)
                    : foldersActions.updateSuccess({ folderData });
            }),
            catchError((errorResponse: HttpErrorResponse) =>
                of(foldersActions.updateFailed(errorResponse.error as ApiError))
            )
        );
    }

    public create(name: string, customerId: number): Observable<Action> {
        return this.http.post('folder', { name }).pipe(
            map((response: ApiDataResult<number>) => {
                return response.error != null
                    ? foldersActions.createFailed(response.error)
                    : foldersActions.createSuccess({ id: response.result, name, customerId });
            }),
            catchError((errorResponse: HttpErrorResponse) =>
                of(foldersActions.createFailed(errorResponse.error as ApiError))
            )
        );
    }

    public getListingFolderIdsMappings(): Observable<Action> {
        return this.http.get('folder/listing-folder-ids-mapping').pipe(
            map((response: ApiDataResult<ListingFolderIdsMap[]>) => {
                return response.error != null
                    ? foldersActions.loadListingFolderIdsMappingsFailed(response.error)
                    : foldersActions.loadListingFolderIdsMappingsSuccess({ listingFolderIdsMap: response.result });
            }),
            catchError((errorResponse: HttpErrorResponse) =>
                of(foldersActions.loadListingFolderIdsMappingsFailed(errorResponse.error as ApiError))

            )
        );
    }

    public manageListingsFolders(models: FolderManagerModel[]): Observable<Action> {
        const data = models.map(({ listings, folderId, action }) => {
            const listingIdIsPickCandidateMap = {};
            listings.forEach(
                listing => listingIdIsPickCandidateMap[listing.listingId] = listing.isPickCandidate
            );

            return {
                folderId: folderId,
                action,
                listingIdIsPickCandidateMap
            };
        });

        return this.http.post('folder/manage-listings', { models: data }).pipe(
            map((response: ApiDataResult<ListingActivityId[]>) => {
                return response.error != null
                    ? foldersActions.manageListingsFoldersFailed(response.error, models)
                    : foldersActions.manageListingsFoldersSuccess({ models, activitiesSet: response.result });
            }),
            catchError((errorResponse: HttpErrorResponse) =>
                of(foldersActions.manageListingsFoldersFailed(errorResponse.error as ApiError, models))
            )
        );
    }

    public removeFolder(model: RemoveFolderListingsModel, folders: Folder[]): Observable<Action> {
        return this.http
            .post('folder/remove', model)
            .pipe(
                map((response: ApiDataResult<number>) => {
                    return response.error != null
                        ? foldersActions.removeFailed({ error: response.error, folders })
                        : foldersActions.removeSuccess({ model });
                }),
                catchError((errorResponse: HttpErrorResponse) => {
                    return of(foldersActions.removeFailed({ error: errorResponse.error as ApiError, folders }));
                })
            );
    }

    public getFolderSavedSearches(): Observable<Action> {
        return this.http.get('folder/saved-searches').pipe(
            map((response: ApiDataResult<FolderSavedSearch[]>) => {
                return response.error != null
                    ? foldersActions.loadFolderSavedSearchesFailed({ error: response.error as ApiError })
                    : foldersActions.loadFolderSavedSearchesSuccess({ folderSavedSearches: response.result });
            }),
            catchError((errorResponse: HttpErrorResponse) =>
                of(foldersActions.loadFolderSavedSearchesFailed({ error: errorResponse.error as ApiError }))
            )
        );
    }

    public getFolderNewMatches(): Observable<Action> {
        return this.http.get('folder/new-match').pipe(
            map((response: ApiDataResult<FolderNewMatch[]>) => {
                return response.error != null
                    ? foldersActions.loadFolderNewMatchesFailed({ error: response.error as ApiError })
                    : foldersActions.loadFolderNewMatchesSuccess({ folderNewMatches: response.result });
            }),
            catchError((errorResponse: HttpErrorResponse) =>
                of(foldersActions.loadFolderNewMatchesFailed({ error: errorResponse.error as ApiError }))
            )
        );
    }
}