import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { Customer } from '@auth/models/customer';
import { CustomerRegistationFormData } from '@auth/models/customer-registration-data';
import { RpcInputOptions } from '@core-controls/components/rpc-input/models/rpc-input-options';
import { PhoneInfo } from '@core-controls/models/phone-info';
import { ValidationService } from '@core-validation/validation.service';
import { RpcRoute } from '@core-layout/app/models/rpc-route';
import { PRIVACY_POLICY_LINK, TERMS_AND_CONDITIONS_LINK } from '@auth/constants/terms-and-conditions';

@Component({
    selector: 'registration-form',
    templateUrl: './registration-form.component.html',
    styleUrls: ['./registration-form.component.scss'],
})
export class RegistrationFormComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() public customerData: Customer;
    @Input() public isWorkspaceAddition: boolean;
    @Output() public formSubmited = new EventEmitter<CustomerRegistationFormData>();

    public readonly rpcRoute = RpcRoute;

    public registrationForm: FormGroup;
    public firstNameControlOptions: RpcInputOptions;
    public lastNameControlOptions: RpcInputOptions;
    public emailControlOptions: RpcInputOptions;
    public passwordControlOptions: RpcInputOptions;
    public passwordConfirmControlOptions: RpcInputOptions;
    public agreeToTermsAndConditions: RpcCheckboxState = 'unchecked';

    public termsAndConditionsLink = TERMS_AND_CONDITIONS_LINK;
    public privacyPolicyLink = PRIVACY_POLICY_LINK;

    private readonly subscription = new Subscription();

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly validationService: ValidationService
    ) { }

    public ngOnInit(): void {
        this.buildForm();
    }

    public ngAfterViewInit(): void {
        setTimeout(() => {
            if (!this.isWorkspaceAddition) {
                this.addSubscriptions();
            }

            this.registrationForm.patchValue(this.customerData);
        });
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onPhoneNumberChanged(data: PhoneInfo): void {
        this.registrationForm.get('phoneNumber').setValue(data);
    }

    public onSubmit(): void {
        const customer = new CustomerRegistationFormData();

        customer.id = this.customerData.id;
        customer.firstName = this.registrationForm.controls.firstName.value as string;
        customer.lastName = this.registrationForm.controls.lastName.value as string;
        customer.email = this.registrationForm.controls.email.value as string;
        customer.phoneNumberInfo = this.registrationForm.controls.phoneNumber.value as PhoneInfo;

        if (!this.isWorkspaceAddition) {
            customer.password = this.registrationForm.controls.password.value as string;
            customer.confirmPassword = this.registrationForm.controls.passwordConfirm.value as string;
        }

        this.formSubmited.emit(customer);
    }

    private buildForm(): void {
        this.registrationForm = this.formBuilder.group({
            phoneNumber: [null, this.validationService.getExternalDependencyValidator(false)]
        });

        this.firstNameControlOptions = {
            formGroup: this.registrationForm,
            controlName: 'firstName',
            validatorsMap: [
                {
                    message: 'REGISTRATION_FORM.VALIDATION_ERRORS.FIRST_NAME_REQUIRED',
                    showError: (control: FormControl) => control.hasError('required'),
                    validator: Validators.required
                }
            ]
        };

        this.lastNameControlOptions = {
            formGroup: this.registrationForm,
            controlName: 'lastName',
            validatorsMap: [
                {
                    message: 'REGISTRATION_FORM.VALIDATION_ERRORS.LAST_NAME_REQUIRED',
                    showError: (control: FormControl) => control.hasError('required'),
                    validator: Validators.required
                }
            ]
        };

        this.lastNameControlOptions = {
            formGroup: this.registrationForm,
            controlName: 'lastName',
            validatorsMap: [
                {
                    message: 'REGISTRATION_FORM.VALIDATION_ERRORS.LAST_NAME_REQUIRED',
                    showError: (control: FormControl) => control.hasError('required'),
                    validator: Validators.required
                }
            ]
        };

        this.emailControlOptions = {
            formGroup: this.registrationForm,
            controlName: 'email',
            validatorsMap: [
                {
                    message: 'REGISTRATION_FORM.VALIDATION_ERRORS.EMAIL_REQUIRED',
                    showError: (control: FormControl) => control.hasError('required'),
                    validator: Validators.required
                },
                {
                    message: 'REGISTRATION_FORM.VALIDATION_ERRORS.WRONG_EMAIL',
                    showError: (control: FormControl) => control.hasError('email'),
                    validator: Validators.email
                }
            ]
        };

        this.passwordControlOptions = {
            formGroup: this.registrationForm,
            controlName: 'password',
            validatorsMap: [
                {
                    message: 'REGISTRATION_FORM.VALIDATION_ERRORS.PASSWORD_REQUIRED',
                    showError: (control: FormControl) => control.hasError('required'),
                    validator: Validators.required
                },
                {
                    message: 'REGISTRATION_FORM.VALIDATION_ERRORS.NUMBER_REQIRED',
                    showError: (control: FormControl) => control.hasError('noNumber'),
                    validator: this.validationService.getPasswordValidator()
                },
                {
                    message: 'REGISTRATION_FORM.VALIDATION_ERRORS.CAPITAL_REQUIRED',
                    showError: (control: FormControl) => control.hasError('noCapitalCase'),
                    validator: this.validationService.getPasswordValidator()
                },
                {
                    message: 'REGISTRATION_FORM.VALIDATION_ERRORS.SMALL_REQUIRED',
                    showError: (control: FormControl) => control.hasError('noSmallCase'),
                    validator: this.validationService.getPasswordValidator()
                },
                {
                    message: 'REGISTRATION_FORM.VALIDATION_ERRORS.SPECIAL_REQIRED',
                    showError: (control: FormControl) => control.hasError('noSpecialCharacter'),
                    validator: this.validationService.getPasswordValidator()
                },
                {
                    message: 'REGISTRATION_FORM.VALIDATION_ERRORS.MINIMUM_LENGTH',
                    showError: (control: FormControl) => control.hasError('minlength'),
                    validator: this.validationService.getPasswordValidator()
                },
                {
                    message: 'REGISTRATION_FORM.VALIDATION_ERRORS.MAXIMUM_LENGTH',
                    showError: (control: FormControl) => control.hasError('maxlength'),
                    validator: this.validationService.getPasswordValidator()
                }
            ]
        };

        this.passwordConfirmControlOptions = {
            formGroup: this.registrationForm,
            controlName: 'passwordConfirm',
            validatorsMap: [
                {
                    message: 'REGISTRATION_FORM.VALIDATION_ERRORS.PASSWORD_CONFIRMATION_REQUIRED',
                    showError: (control: FormControl) => control.hasError('required'),
                    validator: Validators.required
                },
                {
                    message: 'REGISTRATION_FORM.VALIDATION_ERRORS.PASSWORDS_NOT_MATCH',
                    showError: (control: FormControl) => control.hasError('notMatching'),
                    validator: this.validationService.getMatchValidator('password')
                }
            ]
        };

        this.registrationForm.addControl(
            'phoneNumber',
            new FormControl(null, this.validationService.getExternalDependencyValidator(false)));
    }

    private addSubscriptions(): void {
        this.subscription.add(this.registrationForm.get('password').valueChanges
            .subscribe(() => {
                this.registrationForm.get('passwordConfirm').updateValueAndValidity();
            }));
    }
}