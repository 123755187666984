import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

import { ListingApiService } from '@listings/store/services/listing-api.service';
import * as listingPriceChangesActions from '../actions/listing-price-changes.actions';
import { ListingsStoreService } from '../services/listings-store.service';
import { ListingPriceChange } from '@listings/models/listing/listing-price-change';

@Injectable()
export class ListingPriceChangesEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly listingApiService: ListingApiService,
        private readonly listingsStoreService: ListingsStoreService,
    ) { }

    public readonly loadLastListingsPriceChanges$ = createEffect(() => this.actions$.pipe(
        ofType(listingPriceChangesActions.loadListingsPriceChanges),
        concatLatestFrom(() => this.listingsStoreService.listingsPriceChanges$),
        mergeMap(([{ hashCodes }, listingsPriceChanges]) => {
            const loaded = new Set(Object.keys(listingsPriceChanges));
            const hashCodesToLoad = hashCodes.filter(x => !loaded.has(x.toString()));

            if (hashCodesToLoad.length === 0) {
                return EMPTY;
            }

            return this.listingApiService.getLastListingPriceChanges(hashCodesToLoad);
        })
    ));

    public readonly loadLastListingsPriceChangesSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(listingPriceChangesActions.loadListingsPriceChangesSuccess),
        concatLatestFrom(() => this.listingsStoreService.listingsPriceChanges$),
        map(([{ priceChanges }, allPriceChanges]) => {
            return listingPriceChangesActions.setListingsPriceChanges({ priceChanges: { ...allPriceChanges, ...priceChanges } });
        })
    ));

    public readonly cleanListingsPriceChanges$ = createEffect(() => this.actions$.pipe(
        ofType(listingPriceChangesActions.cleanListingsPriceChanges),
        concatLatestFrom(() => [this.listingsStoreService.listingsPriceChanges$, this.listingsStoreService.getCustomerListings()]),
        map(([, allPriceChanges, customerListings]) => {
            const hashCodesToKeep = new Set(customerListings.reduce(
                (acc, x) => x.isMarketListing ? acc : [...acc, x.hashCode],
                new Array<number>()
            ));

            const priceChanges = Object.entries(allPriceChanges).reduce(
                (acc, [hashCode, priceChange]) => hashCodesToKeep.has(+hashCode) ? { ...acc, [hashCode]: priceChange } : acc,
                {} as Record<number, ListingPriceChange>
            );

            return listingPriceChangesActions.setListingsPriceChanges({ priceChanges });
        })
    ));
}
