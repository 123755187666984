import { ActionCreator, on, ReducerTypes } from '@ngrx/store';

import { Listings } from '@listings/models/listing/listings';
import * as listingActions from '@listings/store/actions/listings.actions';
import { ListingsState } from '../states/listings.state';
import { ListingsReducerHelpers } from './helpers/listings.reducer.helper';

export const marketListingReducerTypes: ReducerTypes<ListingsState, readonly ActionCreator[]>[] = [
    on(listingActions.loadMarketListings, (state) => ({
        ...state,
        marketListingsLoaded: false,
        marketListingsLoading: true
    })),
    on(listingActions.loadMarketListingsSuccess, (state, { listings }) => {
        return {
            ...state,
            listings: ListingsReducerHelpers.updateListings(state.listings, Object.values(listings), state.listingsActivities, state.listingsNewMatches, false),
            marketListingsLoaded: true,
            marketListingsLoading: false
        };
    }),
    on(listingActions.loadMarketListingsFailed, (state) => ({
        ...state,
        marketListingsLoaded: false,
        marketListingsLoading: false
    })),
    on(listingActions.setMarketListingsLoaded, (state, { isLoaded }) => ({ ...state, marketListingsLoaded: isLoaded })),
    on(listingActions.clearMarketListings, (state) => {
        const filteredListings = Object.keys(state.listings)
            .reduce(
                (listings, key) => {
                    const stateListing = state.listings[key];

                    if (!stateListing.isMarketListing ||
                        state.listingsActivities[stateListing.hashCode]?.activities?.length > 0 ||
                        state.listingsNewMatches[stateListing.hashCode]?.length > 0) {

                        listings[key] = { ...stateListing, isMarketListing: false };
                    }

                    return listings;
                },
                {} as Listings);

        return {
            ...state,
            listings: filteredListings
        };
    }),
];