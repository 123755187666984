import { createFeatureSelector, createSelector } from '@ngrx/store';

import { OnMarketState } from '../states/on-market.state';

const selectOnMarketState = createFeatureSelector<OnMarketState>('on-market');

export const selectSort = createSelector(selectOnMarketState, onMarketState => onMarketState?.sort ?? null);

export const selectSelectedViewMode = createSelector(selectOnMarketState, onMarketState => onMarketState?.viewMode ?? null);

export const selectMapSizePercent = createSelector(selectOnMarketState, onMarketState => onMarketState?.mapSizePercent ?? null);
