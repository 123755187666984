
import { ActivityListingCandidate } from '@listings/models/api/set-listing-activity-request';
import { ListingInfo } from '@listings/models/listing/listing-info';
import { ListingsActivityInfo } from '@listings/models/listing/listings-activity-info';

export class ListingActivityHelper {
    public static getActivityListingsCandidates(
        hashCodes: number[],
        allListings: Record<number, ListingInfo>,
        recentlyViewedListings: ListingInfo[],
        clearActivities = false
    ): ActivityListingCandidate[] {

        const customerListings = Object.values(allListings);

        const activityListingsCandidatesHashCodes = [...new Set(hashCodes)];
        const activityListingsCandidates = activityListingsCandidatesHashCodes
            .map(hashCode => ({ ...(customerListings.find(l => l.hashCode === hashCode) ?? recentlyViewedListings.find(l => l.hashCode === hashCode)) }))
            .filter(listing => listing?.id != null);

        if (clearActivities) {
            activityListingsCandidates.forEach(candidate => candidate.activities = []);
        }

        return activityListingsCandidates;
    }

    public static removeActivities(listingsActivities: Record<number, ListingsActivityInfo>, hashCodes: number[]): Record<number, ListingsActivityInfo> {
        return Object.entries(listingsActivities).reduce(
            (acc, [hashcode, activitiesInfo]) => hashCodes.includes(+hashcode) ? acc : { ...acc, [hashcode]: activitiesInfo },
            {} as Record<number, ListingsActivityInfo>
        );
    }
}