import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, QueryList, ViewChildren } from '@angular/core';
import { map, Observable } from 'rxjs';
import isEqual from 'lodash-es/isEqual';

import { DateConstants } from '@core-constants/dates/date.constants';
import { SimpleChanges } from '@core-models/utilities/generic-simple-changes';
import { RpcTooltipDirective } from '@core-utils/rpc-tooltip/directive/rpc-tooltip.directive';
import { ListingCategoryTexts } from '@listings/enums/listing-category-texts.enum';
import { MatchMediaService } from '@media/services/match-media.service';
import { NewMatchTooltipComponent } from '../new-match-tooltip/new-match-tooltip.component';
import { ListingActivityContribution } from '@portfolio/models/listing-activity-contribution';
import { OpenHouseTooltipComponent } from '../open-house-tooltip/open-house-tooltip.component';
import { OpenHouse } from '@listings/models/listing/openhouse';
import { DECREASE_PRICE_CHANGE_TYPE } from '@listings/constants/listings.constants';
import { ListingPriceChange } from '@listings/models/listing/listing-price-change';

@Component({
    selector: 'listing-labels',
    templateUrl: './listing-labels.component.html',
    styleUrls: ['./listing-labels.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListingLabelsComponent implements OnChanges, OnInit {

    @Input() openHouses: OpenHouse[];
    @Input() isLongLabels?: boolean;
    @Input() showNewlyAddedTag?: boolean;
    @Input() isNewViewed: boolean;
    @Input() isNewMatch: boolean;
    @Input() priceChange: ListingPriceChange;
    @Input() listingActivityContribution?: ListingActivityContribution;

    @ViewChildren(RpcTooltipDirective) public tooltipDirectives: QueryList<RpcTooltipDirective>;

    public DateConstants = DateConstants;
    public ListingCategory = ListingCategoryTexts;
    public readonly NewMatchTooltipComponent = NewMatchTooltipComponent;
    public readonly OpenHouseTooltipComponent = OpenHouseTooltipComponent;
    public newMatchTooltipData: { listingActivityContribution: ListingActivityContribution };
    public openHouseTooltipData: { openHouse: OpenHouse };
    public isMobile$: Observable<boolean>;
    public readonly customTriggerName = 'singleClicked';
    public readonly decreasePriceChangeType = DECREASE_PRICE_CHANGE_TYPE;

    constructor(
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly matchMediaService: MatchMediaService,
    ) { }

    public ngOnInit(): void {
        this.isMobile$ = this.matchMediaService.onMediaChange.pipe(
            map(mediaQuery => ['xs', 'sm', 'md'].includes(mediaQuery))
        );
    }

    public ngOnChanges(changes: SimpleChanges<ListingLabelsComponent>): void {
        if (changes != null &&
            (changes.openHouses != null && !isEqual(changes.openHouses.currentValue, changes.openHouses.previousValue)) ||
            (changes.isLongLabels != null && changes.isLongLabels.currentValue !== changes.isLongLabels.previousValue) ||
            changes.listingActivityContribution?.currentValue != null
        ) {
            this.newMatchTooltipData = { listingActivityContribution: changes.listingActivityContribution?.currentValue };

            if (changes.openHouses?.currentValue) {
                this.openHouseTooltipData = { openHouse: changes.openHouses?.currentValue?.[0] };
            }

            this.changeDetectorRef.detectChanges();
        }
    }

    public menuOpened(): void {
        this.changeDetectorRef.detectChanges();
    }

    public onMobileClick(): void {
        this.tooltipDirectives.filter(({ trigger }) => trigger === this.customTriggerName).forEach(t => t.show());
    }

    public hideTooltips(): void {
        this.tooltipDirectives.forEach(tooltip => tooltip.hide(true));
    }
}