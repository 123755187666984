<div class="rpc-additional-footer">

    <div class="rpc-additional-footer-content">

        <ng-container
            *ngLet="{company: companyConfiguration$ | async, companyLogo: companyLogo$ | async} as companyConfiguration">

            <img class="rpc-additional-footer-company"
                *ngIf="companyConfiguration.company.internalId != null || (internalCompanyConfigurationLoaded$ | async)"
                [src]="companyConfiguration.companyLogo" [alt]="companyConfiguration.company.companyName">

        </ng-container>

        <div class="rpc-additional-footer-powered">

            <rpc-icon class="rpc-additional-footer-powered-icon" [iconName]="'rp-icon'">
            </rpc-icon>

            <div class="rpc-additional-footer-powered-text">{{'ADDITIONAL_FOOTER.TITLES.POWERED_BY' | translate}}</div>

        </div>

    </div>

</div>