import { createAction, props } from '@ngrx/store';

import { NewMatch } from '@listings/models/listing/new-match';

export const loadListingsNewMatchesRequested = createAction('[Listings] Load Listings New Matches Requested', props<{ hashCodes: number[], shouldSetLoading?: boolean, shouldSetLoaded?: boolean, isForce?: boolean }>());
export const loadListingsNewMatches = createAction('[Listings] Load Listings New Matches', props<{ hashCodes: number[], shouldSetLoading: boolean, shouldSetLoaded?: boolean }>());
export const loadListingsNewMatchesSuccess = createAction('[Listings] Load Listings New Matches Success', props<{ hashCodes: number[], newMatches: Record<number, NewMatch[]>, shouldSetLoaded?: boolean }>());
export const loadListingsNewMatchesFailed = createAction('[Listings] Load Listings New Matches Failed');

export const setListingsNewMatches = createAction('[Listings] Set Listings New Matches', props<{ newMatches: Record<number, NewMatch[]> }>());
export const removeNewMatches = createAction('[Listings] Remove New Matches', props<{ hashCodes: number[], newMatchIds?: Set<number> }>());
