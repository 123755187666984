export class PageRefresherService {

    public static rewriteMobilePageRefresh(mainContentQuerySelector: string, toolbarQuerySelector: string, bannedElementsQuerySelectors: string[] = []): void {
        if (!CSS.supports('overscroll-behavior-y', 'contain')) {
            console.warn('\'overscroll-behavior\' is not supported by your browser');
        }

        const mainScrollingElement = document.querySelector(mainContentQuerySelector);

        PageRefresherService.initializeMobileRefresh(mainScrollingElement, mainScrollingElement, bannedElementsQuerySelectors);

        const toolbarScrollingElement = document.querySelector(toolbarQuerySelector);

        PageRefresherService.initializeMobileRefresh(mainScrollingElement, toolbarScrollingElement, bannedElementsQuerySelectors);
    }

    private static initializeMobileRefresh(scrollingElement: Element, eventListenerElement: Element, bannedElementsQuerySelectors: string[]): void {
        if (scrollingElement == null || eventListenerElement == null) {
            return;
        }

        const refreshMaximumStartPosition = 150;
        const refreshTouchRequiredLengthPosition = 160;

        let touchStartPosition = 0;
        let touchEndPosition = 0;

        const isPageTop = () => scrollingElement.scrollTop <= 0;
        const isCorrectStartPosition = () => touchStartPosition < refreshMaximumStartPosition;
        const isScrollingDown = () => touchEndPosition > touchStartPosition;
        const isRequiredScrollLenght = () => (touchEndPosition - touchStartPosition) > refreshTouchRequiredLengthPosition;
        const isRefreshing = () => document.body.classList.contains('refreshing');

        eventListenerElement.addEventListener(
            'touchstart',
            (event: TouchEvent) => {
                if (PageRefresherService.hasBannedElement(event, bannedElementsQuerySelectors)) {
                    return;
                }

                touchStartPosition = event.touches[0].pageY;

                if (event.touches.length > 1) {
                    touchStartPosition = 0;
                    touchEndPosition = 0;
                }
            },
            { passive: true, capture: true });

        eventListenerElement.addEventListener(
            'touchmove',
            (event: TouchEvent) => {
                if (PageRefresherService.hasBannedElement(event, bannedElementsQuerySelectors)) {
                    return;
                }

                touchEndPosition = event.touches[0].pageY;

                if (event.touches.length > 1) {
                    touchStartPosition = 0;
                    touchEndPosition = 0;
                } else if (isPageTop() && isCorrectStartPosition() && isScrollingDown() && isRequiredScrollLenght() && !isRefreshing()) {
                    document.body.classList.add('refreshing');
                }
            },
            { passive: true, capture: true });

        eventListenerElement.addEventListener(
            'touchend',
            (event: TouchEvent) => {
                if (PageRefresherService.hasBannedElement(event, bannedElementsQuerySelectors)) {
                    return;
                }

                const refresher = document.querySelector('.refresher');

                refresher.classList.add('shrink');

                if (isPageTop() && isCorrectStartPosition() && isScrollingDown() && isRequiredScrollLenght() && isRefreshing() && event.touches.length === 0) {
                    refresher.classList.add('done');
                    window.location.reload();
                }

                document.body.classList.remove('refreshing');
                refresher.classList.remove('shrink');
                refresher.classList.remove('done');

                touchStartPosition = 0;
                touchEndPosition = 0;
            },
            { passive: true, capture: true });
    }

    private static hasBannedElement(event: TouchEvent, bannedElementsQuerySelectors: string[]): boolean {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return bannedElementsQuerySelectors.some(bannedQuerySelector => (event.target as any).closest(bannedQuerySelector) != null);
    }

}