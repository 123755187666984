<div *ngIf="openHouse != null" class="rpc-oh-tooltip">

    <div class="rpc-oh-tooltip-title">
        {{ 'OPEN_HOUSE_TOOLTIP.NEXT_OPEN_HOUSE' | translate }}:
    </div>

    <div>
        {{ openHouse.startDate | date : dateConstants.Formats.AngularPipeShortDateSlash }}
    </div>

    <div>

        <span>
            {{ openHouse.startDate | date: dateConstants.Formats.AngularPipeShortTimeWithoutPeriod }} -
            {{ openHouse.endDate | date: dateConstants.Formats.AngularPipeShortTimeWithoutPeriod }}
        </span>

        <span>
            {{openHouse.comment}}
        </span>

    </div>

</div>