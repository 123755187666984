import { locale as savedSearchForm } from '../components/saved-search-form/i18n/en';
import { locale as addEditSavedSearchDialog } from '../components/add-edit-saved-search-dialog/i18n/en';
import { locale as savedSearchInfo } from '../components/saved-search-info/i18n/en';

const common = {
    DIALOGS: {
        SAVED_SEARCH_DELETION: 'Are you sure you want to delete saved search?'
    },
    ERRORS: {
        SAVED_SEARCH_LOAD_FORBIDDEN: 'Forbidden to load saved searches',
        SAVED_SEARCH_CREATION_FORBIDDEN: 'Forbidden to create saved search',
        SAVED_SEARCH_CREATION_FAILED: 'Failed to create saved search',
        SAVED_SEARCH_UPDATE_FORBIDDEN: 'Forbidden to update saved search',
        SAVED_SEARCH_UPDATE_FAILED: 'Failed to update saved search',
        SAVED_SEARCH_DELETE_FORBIDDEN: 'Forbidden to delete saved search',
        SAVED_SEARCH_DELETE_FAILED: 'Failed to delete saved search'
    }
};

export const locale = {
    lang: 'en',
    data: {
        ...common,
        ...savedSearchForm.data,
        ...addEditSavedSearchDialog.data,
        ...savedSearchInfo.data,
    }
};