import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { SortOptionBase } from '@core-models/sort/sort-option';
import { InitialFolderStateMode } from '@folders/enums/initial-folder-state-mode.enum';
import { GalleryViewTypes } from '@gallery-view/enums/gallery-view-types';
import { MyListingsFilterOptions } from '@my-listings/models/filter/my-listings-filter-options';
import * as myListingsActions from '@my-listings/store/actions/my-listings.actions';
import * as myListingsSelectors from '@my-listings/store/selectors/my-listings.selector';
import { ViewModes } from '@view-base/models/view-modes.enum';
import { HiddenChangedEventParameters } from '@core-directives/hideable/hidden-changed-event-parameters';
import { SYSTEM_FOLDERS_ID_TO_URL_NAME_MAP } from '@folders/constants/folder.constants';
import { RouteService } from '@core-layout/app/services/route.service';
import { RpcRoute } from '@core-layout/app/models/rpc-route';

@Injectable({ providedIn: 'root' })
export class MyListingsStoreService {

    constructor(
        private readonly store: Store,
        private readonly routeService: RouteService
    ) { }

    public readonly checkboxFilterUsersCountForTitle$ = this.store.select(myListingsSelectors.selectSelectedUsersCountForTitle);
    public readonly checkboxUsersAvatarsInfo$ = this.store.select(myListingsSelectors.selectUsersAvatarsInfo);
    public readonly usersFilterOptionsCount$ = this.store.select(myListingsSelectors.selectUsersFilterOptionsCount);
    public readonly initialFolderStateMode$ = this.store.select(myListingsSelectors.selectInitialFolderStateMode);
    public readonly appliedHiddenFiltersCount$ = this.store.select(myListingsSelectors.selectAppliedHiddenFiltersCount);

    public getSort(): Observable<SortOptionBase> {
        return this.store.select((myListingsSelectors.selectSort));
    }

    public getFilter(): Observable<MyListingsFilterOptions> {
        return this.store.select((myListingsSelectors.selectFilter));
    }

    public getViewMode(): Observable<ViewModes> {
        return this.store.select((myListingsSelectors.selectSelectedViewMode));
    }

    public getMapSizePercent(): Observable<number> {
        return this.store.select((myListingsSelectors.selectMapSizePercent));
    }

    public getViewType(): Observable<GalleryViewTypes> {
        return this.store.select((myListingsSelectors.selectedViewType));
    }

    public setSorting(sort: SortOptionBase): void {
        this.store.dispatch(myListingsActions.setSort({ sort }));
    }

    public setFilter(filter: MyListingsFilterOptions): void {
        this.store.dispatch(myListingsActions.setFilter({ filter }));
    }

    public setSelectedUsersFilter(selectedUsersFilter: number[]): void {
        this.store.dispatch(myListingsActions.setSelectedUsersFilter({ selectedUsersFilter }));
    }

    public setViewMode(mode: ViewModes): void {
        this.store.dispatch(myListingsActions.setViewMode({ viewMode: mode }));
    }

    public setMapSizePercent(size: number): void {
        this.store.dispatch(myListingsActions.setMapSizePercent({ mapSizePercent: size }));
    }

    public setViewType(viewType: GalleryViewTypes): void {
        this.store.dispatch(myListingsActions.setViewType({ viewType }));
    }

    public load(): void {
        this.store.dispatch(myListingsActions.load());
    }

    public applyInitialFolderStateMode(): void {
        this.store.dispatch(myListingsActions.applyInitialFolderStateMode());
    }

    public initializeInsideFolderView(folderId?: number): void {
        this.store.dispatch(myListingsActions.initializeInsideFolderView({ folderId }));
    }

    public changeFilterParamsVisibility(visibleFilterParams: HiddenChangedEventParameters[]): void {
        this.store.dispatch(myListingsActions.changeFilterParamsVisibility({ visibleFilterParams }));
    }

    public navigateToFolder(folderId: number, initialFolderStateMode?: InitialFolderStateMode): void {
        const folder = SYSTEM_FOLDERS_ID_TO_URL_NAME_MAP.get(folderId) ?? folderId.toString();

        this.routeService.navigate(RpcRoute.FolderFullPath, null, null, folder)
            .then(() => {
                if (initialFolderStateMode != null) {
                    this.setInitialFolderStateMode(initialFolderStateMode);
                }
            })
            .catch(() => { });
    }

    public setInitialFolderStateMode(initialFolderStateMode: InitialFolderStateMode): void {
        this.store.dispatch(myListingsActions.setInitialFolderStateMode({ initialFolderStateMode }));
    }
}