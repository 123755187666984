import { createAction, props } from '@ngrx/store';

import { OnboardingData } from '../../models/onboarding-data';
import { ApiError } from '@error/models/api-error';

export const loadOnboardingData = createAction(
    '[Onboarding] Load Onboarding Data'
);
export const loadOnboardingDataSuccess = createAction(
    '[Onboarding] Load Onboarding Data Success',
    props<{ data: OnboardingData[] }>()
);

export const loadOnboardingDataFailed = createAction(
    '[Onboarding] Load Onboarding Data Failed',
    props<{ error: ApiError }>()
);

export const markAsViewed = createAction(
    '[Onboarding] Mark As Viewed'
);

export const markAsViewedSuccess = createAction(
    '[Onboarding] Mark As Viewed Success'
);

export const markAsViewedFailed = createAction(
    '[Onboarding] Mark As Viewed Failed',
    props<{ error: ApiError }>()
);

export const setNextSelectedIndex = createAction(
    '[Onboarding] Set Next Selected Item Index'
);

export const setPreviousSelectedIndex = createAction(
    '[Onboarding] Set Previous Selected Item Index'
);

export const resetState = createAction('[Onboarding] Reset State');
