export const locale = {
    lang: 'en',
    data: {
        FOLDER_MANAGEMENT_DIALOG: {
            TITLES: {
                FOLDER_MANAGEMENT: 'Folder Management',
                CANCEL: 'Cancel',
                ADD_TO: 'Add to...',
                NEW_FOLDER: 'New Folder',
                CREATE_NEW_FOLDER: 'Create new folder',
                CREATE_FOLDER: 'Create Folder',
                SAVE: 'Save',
            }
        }
    }
};