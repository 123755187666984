import { NeighborhoodProperty } from '@on-market/models/neighborhood-property';
import { PatternSearchResult } from '@search/models/pattern-search-result';

export class OmniSearchService {

    public static getPatternByOptions(
        neighborhoodProperties: NeighborhoodProperty[],
        selectedNeighborhoodsIds: number[],
        patternSearchResult: PatternSearchResult[]): PatternSearchResult[] {
        const patternSearchResultForNeighborhoods = OmniSearchService.getPatternsByNeighborhoods(neighborhoodProperties, selectedNeighborhoodsIds);
        const patternSearchResultForNotNeighborhoods = OmniSearchService.getPatternsByNotNeighborhoods(patternSearchResult);

        return patternSearchResultForNeighborhoods.concat(patternSearchResultForNotNeighborhoods);
    }

    private static getPatternsByNeighborhoods(neighborhoodProperties: NeighborhoodProperty[], selectedNeighborhoodsIds: number[]): PatternSearchResult[] {
        const properties = [...neighborhoodProperties];
        const combined: PatternSearchResult[] = [];

        const createPatternSearchResult = (keys: number[], text: string) => {
            // since we set and read it type property in code, it should be on of the options ('borough', 'section', 'neighborhood'), does not matter which one
            const patternResult: PatternSearchResult = { keys, text, type: 'neighborhood' };

            return patternResult;
        };

        const checkIsFullMatch = (nodes: NeighborhoodProperty[]): boolean => {
            return nodes.every(({ id, children }) => children.length > 0 ? checkIsFullMatch(children) : selectedNeighborhoodsIds.includes(id));
        };

        while (properties.length > 0) {
            const currentNode = properties.shift();

            const isChild = currentNode.children.length === 0;

            if (selectedNeighborhoodsIds.includes(currentNode.id) && isChild) {
                combined.push(createPatternSearchResult([currentNode.id], currentNode.name));
            }

            if (isChild) {
                continue;
            }

            const isFullMatch = checkIsFullMatch(currentNode.children);

            if (isFullMatch) {
                const nestedNeighborhoodsIds = OmniSearchService.getAllNestedNeighborhoods(currentNode, false);

                combined.push(createPatternSearchResult(nestedNeighborhoodsIds, currentNode.name));
            } else {
                properties.push(...currentNode.children);
            }
        }

        return combined;
    }

    private static getPatternsByNotNeighborhoods(patternSearchResult: PatternSearchResult[]): PatternSearchResult[] {
        const createPatternSearchResult = (pattern: PatternSearchResult) => {
            const patternResult: PatternSearchResult = { keys: pattern.keys, text: pattern.text, type: pattern.type.toLowerCase() };

            return patternResult;
        };

        const searchResults = patternSearchResult.filter(p =>
            p.type.toLowerCase() !== 'borough' &&
            p.type.toLowerCase() !== 'section' &&
            p.type.toLowerCase() !== 'neighborhood');

        return searchResults.map(p => createPatternSearchResult(p));
    }

    private static getAllNestedNeighborhoods(node: NeighborhoodProperty, includeParentNode: boolean): number[] {
        return node.children.length > 0
            ? node.children.flatMap(child => OmniSearchService.getAllNestedNeighborhoods(child, true))
                .concat(includeParentNode ? node.id : [])
            : [node.id];
    }
}