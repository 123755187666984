import { ApiError } from "@error/models/api-error";
import { ListingCardInfo } from "@listings/models/listing/listing-card-info";
import { createAction, props } from "@ngrx/store";

export const loadRecentlyViewedListing = createAction(
    '[Listings] Load Recently Viewed Listing',
    props<{ countToLoad: number }>()
);

export const loadRecentlyViewedListingSuccess = createAction(
    '[Listings] Load Recently Viewed Listing Success',
    props<{ recentlyViewedlistings: ListingCardInfo[] }>()
);

export const loadRecentlyViewedListingFailed = createAction(
    '[Listings] Load Recently Viewed Listing Failed',
    props<{ error: ApiError }>()
);