import { createFeatureSelector, createSelector } from "@ngrx/store";
import isEqual from "lodash-es/isEqual";

import { SearchState } from "../states/search.state";
import { OnMarketStateHelper } from "@on-market/store/states/on-market.state.helper";

const selectSearhcState = createFeatureSelector<SearchState>('search');

export const selectSearchOptions = createSelector(selectSearhcState, ({ searchOptions }) => searchOptions);
export const selectPreviousSearchOptions = createSelector(selectSearhcState, ({ previousSearchOptions }) => previousSearchOptions);

export const selectNeighborhoodProperties = createSelector(selectSearhcState, ({ neighborhoodProperties }) => neighborhoodProperties);
export const selectSearchRunMode = createSelector(selectSearhcState, ({ searchRunMode }) => searchRunMode);

export const selectMapShapeOptions = createSelector(selectSearchOptions, ({ mapShapesSearchOptions }) => mapShapesSearchOptions);

export const selectMapShapesCount = createSelector(
    selectMapShapeOptions,
    selectSearchOptions,
    ({ shapes, circles }, { neighborhoodIds }) => shapes.length + circles.length + neighborhoodIds.length
);

export const selectIsCriteriaEmpty = createSelector(selectSearchOptions, searchOptions => {
    return isEqual(searchOptions, OnMarketStateHelper.createDefaultSearchOptions(searchOptions.categoryId));
});

export const selectSelectedNeighborhoodIds = createSelector(selectSearchOptions, searchOptions => searchOptions.neighborhoodIds);
