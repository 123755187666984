<rpc-icon-button *ngIf="!isMobile" class="rpc-activity-button" [class]="{
        'liked': calculatedActivities?.isLiked,
        'disliked': calculatedActivities?.isDisliked,
        'shown': calculatedActivities?.isShown && !calculatedActivities?.isLiked && !calculatedActivities?.isDisliked
        }" [iconName]="iconName" [actionClass]="'active ' + activityClass"
    [actionTrigger]="calculatedActivities?.isLiked || calculatedActivities?.isDisliked || calculatedActivities?.isShown"
    [badgeIcon]="badgeIcon" [badgeIconName]="'eye'" [enableCustomBackground]="true" [disabled]="disabled" rpcTooltip
    [customClass]="'tooltip-list--inline-padding-empty'" [component]="tooltipComponent"
    [componentScopeData]="tooltipData" [showToolTip]="!disabled" (tooltipInitialized)="tooltipInitialized($event)"
    (clicked)="onClick()">
</rpc-icon-button>

<rpc-icon-button *ngIf="isMobile" class="rpc-activity-button" [ngClass]="{
        'liked': calculatedActivities?.isLiked,
        'disliked': calculatedActivities?.isDisliked,
        'shown': calculatedActivities?.isShown && !calculatedActivities?.isLiked && !calculatedActivities?.isDisliked
        }" [iconName]="iconName" [actionClass]="'active ' + activityClass"
    [actionTrigger]="calculatedActivities?.isLiked || calculatedActivities?.isDisliked || calculatedActivities?.isShown"
    [badgeIcon]="badgeIcon" [badgeIconName]="'eye'" [enableCustomBackground]="true" [disabled]="disabled" rpcTooltip
    [trigger]="'custom'" [customClass]="'tooltip-list--inline-padding-empty'" [component]="tooltipComponent"
    [componentScopeData]="tooltipData" [showToolTip]="!disabled" (tooltipInitialized)="tooltipInitialized($event)"
    rpcClickHandler (singleClicked)="onClick()" (doubleClicked)="onDoubleClick()">
</rpc-icon-button>