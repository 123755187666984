<div class="rpc-onboarding" (swipeleft)="next()" (swiperight)="previous()"
    *ngIf="(onboardingDataItem$ | async) as dataItem">

    <rpc-button class="rpc-onboarding-close" [iconName]="'cross'" [appearanceType]="'stroke'"
        [alt]="'ONBOARDING_DIALOG.BUTTONS.CLOSE' | translate" (clicked)="close()">
    </rpc-button>

    <ng-container
        *ngLet="{selectedItemIndex: selectedItemIndex$ | async, itemsImages:itemsImages$ | async} as onboardingData">

        <div class="rpc-onboarding-image-wrap">

            <ng-container *ngFor="let item of onboardingData.itemsImages; let i = index">

                <default-image class="rpc-onboarding-image" [image]="item" [useBackgroundImage]="true"
                    [class.rpc-onboarding-image--active]="i === onboardingData.selectedItemIndex"
                    [title]="dataItem.title">
                </default-image>

            </ng-container>

        </div>

        <div class="rpc-onboarding-body">

            <div class="rpc-onboarding-system-text">{{'ONBOARDING_DIALOG.TEXT.WHATS_NEW' | translate}}</div>

            <div class="rpc-onboarding-title">{{dataItem.title}}</div>

            <div class="rpc-onboarding-title" *ngIf="dataItem.titleSecond">{{dataItem.titleSecond}}</div>

            <div class="rpc-onboarding-text" *ngIf="dataItem.description" [innerHTML]="dataItem.description">
            </div>

            <div class="rpc-onboarding-text" *ngIf="dataItem.descriptionSecond">{{dataItem.descriptionSecond}}
            </div>

            <ul *ngIf="dataItem.descriptionList && dataItem.descriptionList.length > 0" class="rpc-onboarding-list">

                <li *ngFor="let item of dataItem.descriptionList" [innerHTML]="item.description">
                </li>

            </ul>

            <div class="rpc-onboarding-button-wrap" *ngIf="dataItem.redirectButton">

                <rpc-button class="rpc-onboarding-button" [title]="dataItem.redirectButton.title"
                    [appearanceType]="'flat'" [color]="'primary'" (clicked)="redirect(dataItem.redirectButton.url)">
                </rpc-button>

            </div>

        </div>

        <div class="rpc-onboarding-footer" *ngIf="onboardingData.itemsImages && onboardingData.itemsImages.length > 1">

            <rpc-icon-button class="rpc-onboarding-nav rpc-onboarding-nav--prev" [iconName]="'arrow-left'"
                [title]="'ONBOARDING_DIALOG.BUTTONS.PREVIOUS' | translate" (clicked)="previous()">
            </rpc-icon-button>

            <div class="rpc-onboarding-counter" *ngIf="onboardingData.itemsImages">
                {{onboardingData.selectedItemIndex! + 1}} / {{onboardingData.itemsImages.length}}
            </div>

            <rpc-icon-button class="rpc-onboarding-nav rpc-onboarding-nav--next" [iconName]="'arrow-right'"
                [title]="'ONBOARDING_DIALOG.BUTTONS.NEXT' | translate" (clicked)="next()">
            </rpc-icon-button>

        </div>

    </ng-container>

</div>