export const locale = {
    lang: 'en',
    data: {
        SAVED_SEARCH_INFO: {
            TITLES: {
                BUY: 'BUY',
                RENT: 'RENT',
            }
        }
    }
};