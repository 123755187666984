export const locale = {
    lang: 'en',
    data: {
        SIDE_MENU: {
            TITLES: {
                MY_LISTINGS: 'Portfolio',
                MY_APPOINTMENTS: 'Appointments',
                FIND_HOME: 'Find Your Home',
                PROFILE: 'My Profile',
                SETTINGS: 'Settings',
                PROFILES: 'Profiles',
                LOGOUT: 'Logout',
                INSTALLATION: 'Add to Home screen',
                CHANGE_AGENT: 'Change Agent',
                KNOWLEDGE_BASE: 'Knowledge Base'
            }
        }
    }
};