import { ActionError } from '@core-models/errors/action-error';
import { FeatureCollection } from '@settings/models/map/feature-collection';
import { CustomerSettings } from '@settings/models/settings/customer-settings';
import { SettingsActionErrors } from '../enums/settings-action-errors';

export class SettingsState {

    settings: CustomerSettings;
    newMatchSettings: number[];
    neighborhoodsMapping: FeatureCollection;

    error: ActionError<SettingsActionErrors>;
    public readonly isLoading: boolean = false;

    constructor() {
        this.settings = null;
        this.neighborhoodsMapping = null;

        this.error = null;
    }
}