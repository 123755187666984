<div class="folders-list"
    *ngLet="{defaultFolderId: defaultFolderId$ | async, customFoldersCover: customFoldersCover$ | async} as folders">

    <div class="folders-list-panel">

        <div class="folders-list-container">

            <div class="folders-list-container-title">

                <div class="folders-list-container-title-text">{{ 'FOLDERS_VIEW.TITLES.OVERVIEW' | translate }}</div>

            </div>

            <div class="folders-list-container-box">

                <folder class="folders-list-container-item"
                    *ngFor="let folder of systemFoldersCover$ | async; trackBy: trackByFolder" [folder]="folder"
                    [isDefault]="folder.id === folders.defaultFolderId" (folderClicked)="onFolderClick($event)">
                </folder>

            </div>

        </div>

    </div>

    <div class="folders-list-container">

        <div class="folders-list-container-title"
            [class.folders-list-container-title--action]="folders.customFoldersCover.length > 0">

            <div class="folders-list-container-title-text">{{ 'FOLDERS_VIEW.TITLES.PERSONAL_FOLDERS' | translate }}
            </div>

            <rpc-button class="folders-list-container-title-action" *ngIf="folders.customFoldersCover.length > 0"
                [color]="'accent'" [iconName]="'add-new'" [alt]="'FOLDERS_VIEW.TITLES.NEW_FOLDER'"
                (clicked)="createFolder()">
            </rpc-button>

        </div>

        <div class="folders-list-container-box">

            <folder class="folders-list-container-item"
                *ngFor="let folder of folders.customFoldersCover; trackBy: trackByFolder" [folder]="folder"
                [isDefault]="folder.id === folders.defaultFolderId" (folderClicked)="onFolderClick($event)">
            </folder>

            <div class="folders-list-container-add" [title]="'FOLDERS_VIEW.TITLES.NEW_FOLDER' | translate" matRipple
                (click)="createFolder()">

                <rpc-icon class="folders-list-container-add-icon" [iconName]="'add-new'">
                </rpc-icon>

                <div class="folders-list-container-add-text">{{'FOLDERS_VIEW.TITLES.NEW_FOLDER' | translate}}</div>

            </div>

        </div>

    </div>

</div>