import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListingInfo } from '@listings/models/listing/listing-info';
import { ListingsMedia } from '@listings/models/listing/listings-media';
import * as listingActions from '@listings/store/actions/listings.actions';
import * as recentlyViewedListingsActions from '@listings/store/actions/recently-viewed-listings.actions';
import * as listingSelectors from '@listings/store/selectors/listings.selector';
import { FormattedMedia } from '@media/models/formatted-media';
import { RemoveListingData } from '@listings/models/listing/remove-listing-data';
import * as listingActivityActions from '../actions/listing-activity.actions';
import * as newMatchesActions from '../actions/new-matches.actions';
import { ListingListItem } from '@listings/models/listing/listing-list-item';
import { ListingDetails } from '@listings/models/listing/listing-details';
import { NewMatch } from '@listings/models/listing/new-match';

@Injectable({ providedIn: 'root' })
export class ListingsStoreService {

    constructor(
        private readonly store: Store
    ) { }

    public readonly isListingsLoading$ = this.store.select(listingSelectors.selectIsListingsLoading);
    public readonly isListingLoading$ = this.store.select(listingSelectors.selectIsListingLoading);
    public readonly selectedPortfolioListingsInfo$ = this.store.select(listingSelectors.selectPortfolioSelectedListingsInfo);
    public readonly isAllSelectedListingsAreRemoved$ = this.store.select(listingSelectors.selectIsAllSelectedListingsAreRemoved);
    public readonly isAnySelectedListingRemoved$ = this.store.select(listingSelectors.selectIsAnySelectedListingRemoved);
    public readonly hasAnySelectedPortfolioListings$ = this.store.select(listingSelectors.hasAnySelectedPortfolioListings);
    public readonly hasNotRemovedSelectedPortfolioListings$ = this.store.select(listingSelectors.hasNotRemovedSelectedPortfolioListings);
    public readonly selectedListingsWithoutOpenRentals$ = this.store.select(listingSelectors.selectSelectedListingsWithoutOpenRentals);
    public readonly isActiveListingIsInPortfolio$ = this.store.select(listingSelectors.selectIsActiveListingIsInPortfolio);
    public readonly unviewedListingsCount$ = this.store.select(listingSelectors.selectUnviewedListingsCount);
    public readonly resentlyViewedListings$ = this.store.select(listingSelectors.selectRecentlyViewedListings);
    public readonly areRecentlyViewedListingsLoaded$ = this.store.select(listingSelectors.selectRecentlyViewedListingsLoaded);
    public readonly isRecentlyViewedListingsLoading$ = this.store.select(listingSelectors.selectIsRecentlyViewedListingsLoading);
    public readonly upcomingListingsOpenHousesWithListings$ = this.store.select(listingSelectors.selectUpcomingOpenHousesWithListings);
    public readonly listingsPriceChanges$ = this.store.select(listingSelectors.selectListingsPriceChanges);
    public readonly areNewMatchesLoaded$ = this.store.select(listingSelectors.selectNewMatchesLoaded);
    public readonly areListingsActivitiesLoaded$ = this.store.select(listingSelectors.selectListingsActivitiesLoaded);
    public readonly listingsActivities$ = this.store.select(listingSelectors.selectListingsActivities);
    public readonly listingsNewMatches$ = this.store.select(listingSelectors.selectListingsNewMatches);
    public readonly activitiesOrNewMatchesInLoading$ = this.store.select(listingSelectors.selectActivitiesOrNewMatchesInLoading);
    public readonly baseListings$ = this.store.select(listingSelectors.selectBaseListings);
    public readonly selectedListingsHashcodes$ = this.store.select(listingSelectors.selectSelectedListingsHashcodes);
    public readonly listingsDetails$ = this.store.select(listingSelectors.selectListingsDetails);

    public getListings(): Observable<Record<string, ListingInfo>> {
        return this.store.select((listingSelectors.selectListings));
    }

    public getCustomerListings(): Observable<ListingInfo[]> {
        return this.store.select(listingSelectors.selectCustomerListings);
    }

    public getAllListingsMedia(): Observable<ListingsMedia> {
        return this.store
            .select((listingSelectors.selectAllListingsMedia));
    }

    public getListingMedia(listingId: string): Observable<FormattedMedia> {
        return this.store.select(listingSelectors.selectListingMedia(listingId));
    }

    public isMediaLoadedForListing(listingId: string): Observable<boolean> {
        return this.store.select(listingSelectors.selectIsMediaLoadedForListing(listingId));
    }

    public getSelectedCustomerListings(): Observable<ListingInfo[]> {
        return combineLatest([this.getCustomerListings(), this.getSelectedListingIds()])
            .pipe(
                map(([listings, selectedLstingids]) => listings.filter(listing => selectedLstingids.includes(listing.id)))
            );
    }

    public getMarketListings(): Observable<ListingInfo[]> {
        return this.store.select(listingSelectors.selectMarketListings);
    }

    public getSelectedMarketListingsAvailableForActivity(activityId: number): Observable<ListingInfo[]> {
        return this.store.select(listingSelectors.selectSelectedMarketListingsAvailableForActivity(activityId));
    }

    public getListing(listingId: string): Observable<ListingInfo> {
        return this.store.select(listingSelectors.selectListing(listingId));
    }

    public getListingDetails(hashCode: number): Observable<ListingDetails> {
        return this.store.select(listingSelectors.selectListingDetails(hashCode));
    }

    public getSelectedListingIds(): Observable<string[]> {
        return this.store.select((listingSelectors.selectSelectedListingIds));
    }

    public getSelectedListingIdsWithoutOpenRentals(): Observable<string[]> {
        return this.store.select(listingSelectors.selectSelectedListingIdsWithoutOpenRentals);
    }

    public areCustomerListingsLoaded(): Observable<boolean> {
        return this.store.select((listingSelectors.selectCustomerListingsLodaded));
    }

    public areMarketListingsLoading(): Observable<boolean> {
        return this.store.select((listingSelectors.selectMarketListingsLoading));
    }

    public getListingInfoById(listingId: string): Observable<ListingListItem> {
        return this.store.select(listingSelectors.selectListingFolderManagerInfoById(listingId));
    }

    public getsSavedSearchNewMatches(savedSearchId: number): Observable<NewMatch[]> {
        return this.store.select(listingSelectors.selectSavedSearchNewMatches(savedSearchId));
    }

    public loadListing(listingId: string): void {
        this.store.dispatch(listingActions.loadListing({ id: listingId }));
    }

    public loadListingsDetails(hashCodes: number[]): void {
        this.store.dispatch(listingActions.loadListingsDetails({ hashCodes }));
    }

    public loadListingsMedia(listingIds: string[]): void {
        this.store.dispatch(listingActions.loadListingsMedia({ listingIds }));
    }

    public loadCustomerListings(shouldSetLoading = true): void {
        this.store.dispatch(listingActions.loadCustomerListings({ shouldSetLoading }));
    }

    public loadRecentlyViewedListings(countToLoad: number): void {
        this.store.dispatch(recentlyViewedListingsActions.loadRecentlyViewedListing({ countToLoad }));
    }

    public markAsViewed(listingId: string, listingHashCode: number): void {
        this.store.dispatch(listingActions.markAsViewed({ listingId, listingHashCode }));
    }

    public selectListing(listingId: string): void {
        this.store.dispatch(listingActions.selectListing({ id: listingId }));
    }

    public unselectListings(listingIds: string[]): void {
        this.store.dispatch(listingActions.unselectListings({ listingIds }));
    }

    public selectListings(listingIds: string[]): void {
        this.store.dispatch(listingActions.selectListings({ listingIds }));
    }

    public unselectAllListings(): void {
        this.store.dispatch(listingActions.unselectAllListings());
    }

    public setMarketListingsLoaded(loaded: boolean): void {
        this.store.dispatch(listingActions.setMarketListingsLoaded({ isLoaded: loaded }));
    }

    public clearMarketListings(): void {
        this.store.dispatch(listingActions.clearMarketListings());
    }

    public hardDelete(listingIds: string[], hashCodes: number[], isFromStateOnly = false): void {
        this.store.dispatch(listingActions.hardDelete({ listingIds, hashCodes, isFromStateOnly }));
    }

    public softDelete(listingIds: string[], isFromStateOnly = false): void {
        this.store.dispatch(listingActions.softDeleteRequested({ listingIds, isFromStateOnly }));
    }

    public openListingDeleteManagementDialog(removeListingData: RemoveListingData): void {
        this.store.dispatch(listingActions.openListingDeleteManagementDialog({ removeListingData }));
    }

    public restore(listingsIds: string[]): void {
        this.store.dispatch(listingActions.restoreRequeted({ listingsIds }));
    }

    public showDirections(): void {
        this.store.dispatch(listingActions.showDirections());
    }

    public loadListingsNewMatches(hashCodes?: number[], shouldSetLoading = true, shouldSetLoaded = true, isForce = false): void {
        this.store.dispatch(newMatchesActions.loadListingsNewMatchesRequested({ hashCodes, shouldSetLoading, shouldSetLoaded, isForce }));
    }

    public loadListingsActivities(hashCodes?: number[], shouldSetLoading = true, shouldSetLoaded = true, isForce = false): void {
        this.store.dispatch(listingActivityActions.loadListingsActivitiesRequested({ hashCodes, shouldSetLoading, shouldSetLoaded, isForce }));
    }
}
