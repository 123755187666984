<div class="rpc-notification-appointment"
    [class.rpc-notification-appointment--comment]="appointmentNotification.comment"
    [class.rpc-notification-appointment--footer]="statusDetails.isPendingCreatedByAgent || 
    (notificationAction !== NotificationEventAction.AppointmentCreated && !statusDetails.isPending)">

    <div class="rpc-notification-appointment-address" [title]="listingAddress" (click)="onAppointmentClick(false)">
        {{ listingAddress }}
    </div>

    <div class="rpc-notification-appointment-date">

        <div>
            {{ appointmentNotification.startDateTime | timePeriodLabel : appointmentNotification.endDateTime }}
        </div>

        <mat-divider class="rpc-notification-appointment-date-divider" [vertical]="true"></mat-divider>

        <div>
            {{ appointmentNotification.startDateTime | date: DateConstants.Formats.AngularPipeShortDateSlash}}
        </div>

    </div>

    <div *ngIf="appointmentNotification.comment" class="rpc-notification-appointment-comment">
        {{appointmentNotification.comment}}</div>

    <div class="rpc-notification-appointment-footer" *ngIf="statusDetails.isPendingCreatedByAgent || 
        (notificationAction !== NotificationEventAction.AppointmentCreated && !statusDetails.isPending)">

        <ng-container *ngIf="statusDetails.isPendingCreatedByAgent; else statusTemplate">

            <rpc-button class="rpc-notification-appointment-btn rpc-notification-appointment-btn--accept"
                *ngIf="actionOptions.canAccept" [title]="'APPOINTMENT_NOTIFICATION.TITLES.ACCEPT'" [iconName]="'accept'"
                (clicked)="onAccept($event)">
            </rpc-button>

            <rpc-button class="rpc-notification-appointment-btn rpc-notification-appointment-btn--decline"
                *ngIf="actionOptions.canDecline" [title]="'APPOINTMENT_NOTIFICATION.TITLES.DECLINE'"
                [iconName]="'decline'" (clicked)="onDecline($event)">
            </rpc-button>

        </ng-container>

    </div>

    <ng-template #statusTemplate>

        <ng-container
            *ngIf="notificationAction !== NotificationEventAction.AppointmentCreated && (statusDetails.isConfirmed || statusDetails.isDeclined || statusDetails.isShown)">

            <div *ngLet="appointmentStatusAttributes.get(status) as statusAttributes"
                class="rpc-notification-appointment-status" [class]="statusAttributes.class">

                <rpc-icon class="rpc-notification-appointment-status-icon" [iconName]="statusAttributes.icon">
                </rpc-icon>

                <div class="rpc-notification-appointment-status-text">
                    {{statusAttributes.title | translate}}
                </div>

            </div>

        </ng-container>

    </ng-template>

    <rpc-button class="rpc-notification-appointment-link" [title]="'APPOINTMENT_NOTIFICATION.TITLES.ALL_APPOINTMENTS'"
        [color]="'accent'" (clicked)="onAppointmentClick(true)">
    </rpc-button>

</div>