export const locale = {
    lang: 'en',
    data: {
        FOLDER_ATTACHMENT_DIALOG: {
            TITLES: {
                ADD_TO: 'Add to',
                OTHER_FOLDERS: 'Other Folder(s)'
            },
            BUTTONS: {
                CLOSE: 'Close'
            }
        }
    }
};
