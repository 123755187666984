export class Folder {
    public id: number;
    public name: string;
    public createId?: number;
    public createDateTime: Date;
    public updateDateTime?: Date;

    constructor(
        id: number,
        name: string,
        createId?: number,
        createDateTime?: Date,
        updateDateTime?: Date
    ) {
        this.id = id;
        this.name = name;
        this.createId = createId;
        this.createDateTime = new Date(createDateTime);
        this.updateDateTime = updateDateTime === null ? null : new Date(updateDateTime);
    }
}