export const locale = {
    lang: 'en',
    data: {
        FOLDERS: {
            TITLES: {
                ALL_HOMES_IN_PORTFOLIO: 'All Homes in Portfolio',
                ADDED_BY_AGENT: 'Added By My Agent',
                NEW_FROM_SAVED_SEARCHES: 'New Matches From My Searches',
                OPEN_HOUSES: 'Open Houses',
                TOTAL_LISTINGS: 'Total Listings',
                DETAILS: 'Folder Details',
                LISTINGS: 'Listings',
                NEW_MATCHES: 'New Matches',
                MESSAGES: 'Listings with Messages',
                APPOINTMENTS: 'Listings with Upcoming Appointments',
                LIKED_LISTINGS: 'Liked Listings',
                EMPTY_FOLDER: 'You have not added any homes to this folder.',
                HOMES: '{{count}} homes',
                HOME: '{{count}} home',
            }
        }
    }
};
