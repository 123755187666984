import { ListingSearchOptions } from '@listings/models/search/listing-search-options';
import { NeighborhoodProperty } from '@on-market/models/neighborhood-property';
import { OnMarketStateHelper } from '@on-market/store/states/on-market.state.helper';
import { SearchRunMode } from '@search/enums/search-run-mode.enum';
import { PatternSearchResult } from '@search/models/pattern-search-result';

export class SearchState {
    public readonly patternSearchResults: PatternSearchResult[] = [];
    public readonly searchOptions: ListingSearchOptions = OnMarketStateHelper.createDefaultSearchOptions();
    public readonly previousSearchOptions: ListingSearchOptions = OnMarketStateHelper.createDefaultSearchOptions();
    public readonly neighborhoodProperties: NeighborhoodProperty[] = [];
    public readonly searchRunMode: SearchRunMode = SearchRunMode.OnCriteriaChanged;
}