export const locale = {
    lang: 'en',
    data: {
        FOLDERS_VIEW: {
            TITLES: {
                OVERVIEW: 'Overview',
                PERSONAL_FOLDERS: 'Personal Folders',
                NEW_FOLDER: 'Create Folder',
            }
        }
    }
};
