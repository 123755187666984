import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { RpcRoute } from '@core-layout/app/models/rpc-route';
import { RouteService } from '@core-layout/app/services/route.service';
import { FolderManagerModel } from '@folders/models/folder-management/folder-manager-model';
import { IManageFolderModel } from '@folders/models/manage-folder-model';
import { IRemoveFolderModel } from '@folders/models/remove-folder-model';
import * as foldersActions from '../actions/folders.actions';
import { AddEditFolderParams } from '@folders/models/add-edit-folder-params';

@Injectable({ providedIn: 'root' })
export class FoldersStoreWriteService {
    constructor(
        private readonly store: Store,
        private readonly routeService: RouteService
    ) { }

    public setDefaultFolder(folderId: number | null): void {
        this.store.dispatch(foldersActions.setDefaultFolder({ folderId }));
    }

    public update(folderData: IManageFolderModel): void {
        this.store.dispatch(foldersActions.update({ folderData }));
    }

    public create(name: string): void {
        this.store.dispatch(foldersActions.create({ name }));
    }

    public loadFolders(): void {
        this.store.dispatch(foldersActions.loadFolders({ shouldSetLoading: true }));
        this.store.dispatch(foldersActions.loadListingFolderIdsMappings());
        this.store.dispatch(foldersActions.loadFolderSavedSearches());
        this.store.dispatch(foldersActions.loadFolderNewMatches());
    }

    public navigateToFolderView(): void {
        this.store.dispatch(foldersActions.unselectFolder());
        this.routeService.navigate(RpcRoute.Portfolio).catch(() => { });
    }

    public unselectFolder(): void {
        this.store.dispatch(foldersActions.unselectFolder());
    }

    public load(): void {
        this.store.dispatch(foldersActions.load());
    }

    public openFolderManagementDialog(shouldUnselectListings: boolean, listingId?: string): void {
        this.store.dispatch(foldersActions.openFolderManagementDialog({ listingId, shouldUnselectListings }));
    }

    public remove(model: IRemoveFolderModel, isFromStateOnly = false): void {
        this.store.dispatch(foldersActions.remove({ model, isFromStateOnly }));
    }

    public manageListingsFolders(models: FolderManagerModel[], shouldUnselectListings: boolean): void {
        this.store.dispatch(foldersActions.manageListingsFolders({ models, shouldUnselectListings }));
    }

    public attachListingsToSavedSearchFolder(shouldUnselectListings: boolean, listingId?: string): void {
        this.store.dispatch(foldersActions.attachListingsToSavedSearchFolder({ shouldUnselectListings, listingId }));
    }

    public showAddEditFolderDialog(params: AddEditFolderParams): void {
        this.store.dispatch(foldersActions.showAddEditFolderDialog({ params }));
    }
}