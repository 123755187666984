import { createAction, props } from '@ngrx/store';

import { ListingActivityType } from '@customer-activity/enums/listing-activity-type.enum';
import { ApiError } from '@error/models/api-error';
import { ActivityListingCandidate, SetListingActivityRequest } from '@listings/models/api/set-listing-activity-request';
import { ListingActivityId } from '@listings/models/listing/listing-activity-id';
import { ListingsActivityInfo } from '@listings/models/listing/listings-activity-info';

export const setListingsActivity = createAction(
    '[Listings] Set Listings Activity',
    props<{ request: SetListingActivityRequest, customerId: number, agentId: number }>()
);

export const setListingsActivitySuccess = createAction(
    '[Listings] Set Listings Activity Success',
    props<{ request: SetListingActivityRequest, activitiesSet: ListingActivityId[] }>()
);

export const setListingsActivityFailed = createAction(
    '[Listings] Set Listings Activity Failed',
    props<{ error: ApiError, request: SetListingActivityRequest, customerId: number, agentId: number }>()
);

export const addListingsToPickedList = createAction(
    '[Listings] Add Listings To Picked List',
    props<{ listingCandidates: ActivityListingCandidate[], customerId: number, agentId: number }>()
);

export const removeListingsFromPickedList = createAction(
    '[Listings] Remove Listings From Picked List',
    props<{ listingCandidates: ActivityListingCandidate[], customerId: number, agentId: number }>()
);

export const addListingSessionActivity = createAction(
    '[Listings] Add Listing Session Activity',
    props<{ listingActivityType: ListingActivityType, listingCategory: { listingId: string, category: string }[] }>()
);

export const loadListingsActivitiesRequested = createAction('[Listings] Load Listings Activities Requested', props<{ hashCodes: number[], shouldSetLoading?: boolean, shouldSetLoaded?: boolean, isForce?: boolean }>());
export const loadListingsActivities = createAction('[Listings] Load Listings Activities', props<{ hashCodes: number[], shouldSetLoading: boolean, shouldSetLoaded?: boolean }>());
export const loadListingsActivitiesSuccess = createAction('[Listings] Load Listings Activities Success', props<{ activities: Record<number, ListingsActivityInfo>, shouldSetLoaded?: boolean }>());
export const loadListingsActivitiesFailed = createAction('[Listings] Load Listings Activities Failed');

export const setListingsActivities = createAction('[Listings] Set Listings Activities', props<{ activities: Record<number, ListingsActivityInfo> }>());
