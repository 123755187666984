<div class="saved-search-edit-form">

    <rpc-input class="saved-search-preset-input-wrap" [options]="nameControlOptions" [type]="'text'"
        [appearance]="'outline'" [label]="'SAVED_SEARCH_FORM.TITLES.SAVED_SEARCH_NAME'">
    </rpc-input>

    <rpc-select class="saved-search-preset-select-wrap" [appearance]="'outline'"
        [label]="'SAVED_SEARCH_FORM.TITLES.FOLDER'" [controlOptions]="folderControlOptions" [shouldTranslate]="false"
        (closed)="onCloseCreateFolderControl()">

        <ng-container *ngIf="isCreateFolderFormVisible; else createFolderForm">

            <add-edit-folder-form class="saved-search-preset-folder-form" [name]="getNewFolderName()"
                (manageFolderClicked)="onCreateFolderClicked($event)" (canceled)="onCloseCreateFolderControl()"
                [submitButtonText]="'MANAGE_FOLDER_FORM.BUTTONS.CREATE'">
            </add-edit-folder-form>

        </ng-container>

        <ng-template #createFolderForm>

            <rpc-button class="saved-search-preset-folder" [title]="'SAVED_SEARCH_FORM.TITLES.NEW_FOLDER'"
                [iconName]="'add-new'" (clicked)="onShowCreateFolderControl()">
            </rpc-button>

        </ng-template>

        <mat-divider class="saved-search-preset-folder-divider">
        </mat-divider>

    </rpc-select>

    <div *ngIf="isShowIncludePreviousMatchesCheckbox" class="saved-search-preset-checkbox-wrap">

        <rpc-checkbox class="saved-search-preset-checkbox" [label]="'SAVED_SEARCH_FORM.TITLES.PREVIOUSLY_MATCHED'"
            [(state)]="includePreviousMatches">
        </rpc-checkbox>

        <div class="saved-search-preset-checkbox-note">
            {{ 'SAVED_SEARCH_FORM.TITLES.NOTE' | translate }}.
        </div>

    </div>

    <mat-slide-toggle class="saved-search-preset-toggle" [labelPosition]="'after'" [checked]="shouldSearchNewMatches"
        (change)="onSearchNewMatchesChange($event.checked)">

        <span>{{ 'SAVED_SEARCH_FORM.TITLES.INCLUDE_IN_NEW_MATCHES' | translate }}</span>

    </mat-slide-toggle>

</div>