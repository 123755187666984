import { ChangeDetectionStrategy, Component, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, Observable } from 'rxjs';

import { ScrollService } from '@core-layout/scroll-to-top/scroll.service';
import { FolderManagementAction } from '@folders/enums/folder-management-action.enum';
import { Folder } from '@folders/models/folder';
import { FolderManagerModel } from '@folders/models/folder-management/folder-manager-model';
import { ListingFolderManagerInfo } from '@folders/models/folder-management/listing-folder-manager-info';
import { IManageFolderModel } from '@folders/models/manage-folder-model';
import { FoldersStoreReadService } from '@folders/store/services/folders-store-read.service';
import { FoldersStoreWriteService } from '@folders/store/services/folders-store-write.service';
import { ListingsStoreService } from '@listings/store/services/listings-store.service';
import { AddEditFolderFormComponent } from '../add-edit-folder-form/add-edit-folder-form.component';
import { FolderSelectionComponent } from '../folder-selection/folder-selection.component';
import { IFolderManagementDialogData } from './models/folder-management-dialog-data';
import { GoogleAnalyticsStoreService } from '@core-layout/app/store/services/google-analytics-store.service';

@Component({
    templateUrl: './folder-management-dialog.component.html',
    styleUrls: ['./folder-management-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FolderManagementDialogComponent implements OnInit {
    @ViewChildren(FolderSelectionComponent) public folderSelections: QueryList<FolderSelectionComponent>;
    @ViewChild('createFolderForm') public createFolderForm: AddEditFolderFormComponent;

    public listings$: Observable<ListingFolderManagerInfo[]>;
    public readonly folders$ = this.foldersStoreReadService.customFoldersSortedByDate$;

    public editedFolderId?: number;

    constructor(
        private readonly dialogRef: MatDialogRef<FolderManagementDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private readonly data: IFolderManagementDialogData,
        private readonly listingsStoreService: ListingsStoreService,
        private readonly foldersStoreReadService: FoldersStoreReadService,
        private readonly foldersStoreWriteService: FoldersStoreWriteService,
        private readonly scrollService: ScrollService,
        private readonly googleAnalyticsStoreService: GoogleAnalyticsStoreService,
    ) { }

    public ngOnInit(): void {
        this.listings$ = this.data?.listingId == null
            ? this.foldersStoreReadService.selectedListingsFolderManagerInfo$
            : this.foldersStoreReadService.getListingFolderManagerInfoById(this.data.listingId).pipe(map(listing => listing == null ? [] : [listing]));
    }

    public onUnselectListingClicked(listingId: string): void {
        this.listingsStoreService.unselectListings([listingId]);
    }

    public onSaveClicked(): void {
        const models = this.getManageListingsFoldersModels();

        if (models.length === 0) {
            this.close();

            return;
        }

        this.foldersStoreWriteService.manageListingsFolders(models, this.data.shouldUnselectListings);
        this.close();
    }

    public onEditClicked(folderId: number): void {
        this.editedFolderId = folderId;
        this.scrollService.scrollToElementById(folderId.toString(), 'smooth');
    }

    public onCreateFolderClicked(model: IManageFolderModel): void {
        this.foldersStoreWriteService.create(model.name);
        this.clearCreateFolderForm();
        this.scrollToContainerTop();

        this.googleAnalyticsStoreService.addCreateFolderEvent('folder_manager');
    }

    public onCancelCreationClicked(): void {
        this.clearCreateFolderForm();
    }

    public onRenameClicked(model: IManageFolderModel): void {
        this.foldersStoreWriteService.update(model);
        this.editedFolderId = null;
        this.scrollToContainerTop();
    }

    public onCancelRenameClicked(): void {
        this.editedFolderId = null;
    }

    public close(): void {
        this.dialogRef.close();
    }

    public trackByFolderId(_: number, folder: Folder): number {
        return folder.id;
    }

    public isSaveButtonDisabled(listings: ListingFolderManagerInfo[]): boolean {
        if (this.editedFolderId != null) {
            return true;
        }

        if (listings.some(listing => !listing.isMarketListing)) {
            return false;
        }

        return this.folderSelections != null
            ? this.folderSelections.toArray().every(({ action }) => action === FolderManagementAction.Keep)
            : true;
    }

    private clearCreateFolderForm(): void {
        this.createFolderForm.manageFolderFormGroup.reset();
        Object.keys(this.createFolderForm.manageFolderFormGroup.controls).forEach(
            key => this.createFolderForm.manageFolderFormGroup.get(key).setErrors(null)
        );
    }

    private getManageListingsFoldersModels(): FolderManagerModel[] {
        return this.folderSelections
            .filter(({ action }) => action !== FolderManagementAction.Keep)
            .map(({ listings, folder, action }): FolderManagerModel => {
                const listingsToManage = listings.filter(listing => action === FolderManagementAction.Attach
                    ? listing.foldersIds.every(folderId => folderId !== folder.id)
                    : listing.foldersIds.some(folderId => folderId === folder.id));

                return {
                    action,
                    folderId: folder.id,
                    listings: listingsToManage
                };
            });
    }

    private scrollToContainerTop(): void {
        if (this.scrollService.isElementScrollable('#folderSelections')) {
            this.scrollService.scrollInContainer('#folderSelections', { top: 0, behavior: 'smooth' });
        } else if (this.scrollService.isElementScrollable('.mat-dialog-container')) {
            this.scrollService.scrollInContainer('.mat-dialog-container', { top: 0, behavior: 'smooth' });
        }
    }
}
