import { Component, Input } from '@angular/core';

import { AgentBaseInfo } from '@agents/models/agent-base-info';

@Component({
    selector: 'portfolio-agents-list',
    templateUrl: './portfolio-agents-list.component.html',
    styleUrls: ['./portfolio-agents-list.component.scss']
})
export class PortfolioAgentsListComponent {
    @Input() agents: AgentBaseInfo[];
}
