import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NON_DIGITS_PATTERN } from '@core-constants/regular-expressions/regular-expressions.constants';
import { RpcInputOptions } from '@core-controls/components/rpc-input/models/rpc-input-options';
import { RangeType } from '@core-controls/components/rpc-range-slider/rpc-range-slider.component';
import { SimpleChanges } from '@core-models/utilities/generic-simple-changes';
import { ShortNumberPipe } from '@core-pipes/short-number/short-number.pipe';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

// TODO : Refactor to simplify
@Component({
    selector: 'range-filter',
    templateUrl: './range-filter.component.html',
    styleUrls: ['./range-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RangeFilterComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

    @Input() public readonly min: number;
    @Input() public readonly max: number;
    @Input() public readonly selectedValue: { min?: number, max?: number };
    @Input() public readonly step: number;
    @Input() public readonly minFormControlName: string;
    @Input() public readonly maxFormControlName: string;
    @Input() public readonly anyMinAllowed: boolean = true;
    @Input() public readonly anyMaxAllowed: boolean = true;
    @Input() public readonly isCurrency: boolean = false;

    @Output() public valueChanged = new EventEmitter<{ min: number; max: number; }>();

    private readonly unsubscribe$ = new Subject<void>();

    public readonly anyDefaultText = new TranslatePipe(this.translateService, this.changeDetectorRef).transform('RANGE_FILTER.TITLES.ANY') as string;

    private isInitialized = false;

    public form: FormGroup;
    public minControlOptions: RpcInputOptions;
    public maxControlOptions: RpcInputOptions;

    public rangeSliderValue: { min: number; max: number; };

    public get minValue(): number {
        return this.anyMinAllowed ? (this.min - this.step) : this.min;
    }

    public get maxValue(): number {
        return this.anyMaxAllowed ? (this.max + this.step) : this.max;
    }

    constructor(
        private readonly translateService: TranslateService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly formBuilder: FormBuilder
    ) { }

    public ngOnInit(): void {
        this.form = this.formBuilder.group({});
        this.initializeForm();
        this.form.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(formValue => {

            // eslint-disable-next-line 
            let newMinValue: string | number = formValue[this.minFormControlName] as string;
            // eslint-disable-next-line 
            let newMaxValue: string | number = formValue[this.maxFormControlName] as string;

            newMinValue = newMinValue?.toString().replace(NON_DIGITS_PATTERN, '');
            newMaxValue = newMaxValue?.toString().replace(NON_DIGITS_PATTERN, '');

            this.onFormChanged(newMinValue, newMaxValue, false, false);
        });
    }

    public ngOnChanges(changes: SimpleChanges<RangeFilterComponent>): void {
        if (changes.selectedValue != null && !changes.selectedValue?.firstChange) {
            const updatedMinValue: number = changes.selectedValue?.currentValue?.min;
            const updatedMaxValue: number = changes.selectedValue?.currentValue?.max;

            if (this.rangeSliderValue?.min !== updatedMinValue || this.rangeSliderValue?.max !== updatedMaxValue) {
                this.onFormChanged(updatedMinValue, updatedMaxValue, false, true);
            }
        }
    }

    public ngAfterViewInit(): void {
        this.isInitialized = true;
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public resetForm(): void {
        this.form.reset({
            [this.minFormControlName]: this.anyMinAllowed ? this.anyDefaultText : this.minValue,
            [this.maxFormControlName]: this.anyMaxAllowed ? this.anyDefaultText : this.maxValue
        });
        this.rangeSliderValue = { min: this.minValue, max: this.maxValue };
        this.notifyValueChanged(this.minValue, this.maxValue);
    }

    public onRangeSliderChanged(value: RangeType): void {
        this.onFormChanged(value.min, value.max, false, true);
    }

    public formatBorderValues(): void {
        this.onFormChanged(this.rangeSliderValue?.min, this.rangeSliderValue?.max, true, true);
    }

    public formatLabel = (value: number): string => {
        if (this.minValue === value && this.anyMinAllowed || this.maxValue === value && this.anyMaxAllowed) {
            return this.anyDefaultText;
        }

        return new ShortNumberPipe().transform(value, this.isCurrency);
    };

    private initializeForm(): void {
        this.minControlOptions = new RpcInputOptions(
            this.form,
            this.minFormControlName,
            this.selectedValue?.min == null ? this.anyMinAllowed ? this.anyDefaultText : this.minValue : this.selectedValue.min);
        this.maxControlOptions = new RpcInputOptions(
            this.form,
            this.maxFormControlName,
            this.selectedValue?.max == null ? this.anyMaxAllowed ? this.anyDefaultText : this.maxValue : this.selectedValue.max);
        this.rangeSliderValue = {
            min: this.selectedValue?.min == null ? this.minValue : this.selectedValue.min,
            max: this.selectedValue?.max == null ? this.maxValue : this.selectedValue.max
        };
    }

    // eslint-disable-next-line complexity
    private onFormChanged(sourceMin: string | number, sourceMax: string | number, adjustBorderValues = false, emitEvent = true): void {
        let [min, max] = this.adjustFormValue(sourceMin, sourceMax);

        if (adjustBorderValues) {
            [min, max] = this.adjustFormBorderValues(min, max);
        }

        const currentFormValue = this.form.value;
        // eslint-disable-next-line 
        const currentFormMinTextValue: string = currentFormValue[this.minFormControlName];
        // eslint-disable-next-line
        const currentFormMaxTextValue: string = currentFormValue[this.maxFormControlName];

        const [currentFormMin, currentFormMax] = this.adjustFormValue(currentFormMinTextValue, currentFormMaxTextValue);

        const sliderChanged = this.rangeSliderValue?.min !== min || this.rangeSliderValue?.max !== max;
        const minFormChanged =
            (currentFormMinTextValue == null && currentFormMin === 0) ||
            (currentFormMinTextValue !== this.anyDefaultText && min === this.minValue) ||
            currentFormMin !== min ||
            !this.isCurrency && currentFormMinTextValue != null && typeof currentFormMinTextValue === 'string' &&
            (currentFormMinTextValue.includes('.') || currentFormMinTextValue.includes(','));

        const maxFormChanged =
            (currentFormMaxTextValue == null && currentFormMax === 0) ||
            (currentFormMaxTextValue !== this.anyDefaultText && max === this.maxValue) ||
            currentFormMax !== max ||
            !this.isCurrency && currentFormMaxTextValue != null && typeof currentFormMaxTextValue === 'string' &&
            (currentFormMaxTextValue.includes('.') || currentFormMaxTextValue.includes(','));

        if (sliderChanged) {
            this.rangeSliderValue = { min, max };
        }

        if (sliderChanged || minFormChanged || maxFormChanged) {
            this.notifyValueChanged(min, max);
        }

        if (minFormChanged || maxFormChanged) {
            this.patchForm(min, max, emitEvent, minFormChanged, maxFormChanged);
        }
    }

    private notifyValueChanged(min: number, max: number): void {
        if (this.isInitialized) {
            this.valueChanged.next({
                min: this.minValue === min && this.anyMinAllowed ? null : min,
                max: this.maxValue === max && this.anyMaxAllowed ? null : max
            });
        }
    }

    private patchForm(min: number, max: number, emitEvent: boolean, minFormChanged: boolean, maxFormChanged: boolean): void {
        let resultMin: number | string = min;
        let resultMax: number | string = max;

        if (this.minValue === min && this.anyMinAllowed) {
            resultMin = this.anyDefaultText;
        }

        if (this.maxValue === max && this.anyMaxAllowed) {
            resultMax = this.anyDefaultText;
        }

        const patchValue = {};

        if (minFormChanged) {
            patchValue[this.minFormControlName] = resultMin;
        }

        if (maxFormChanged) {
            patchValue[this.maxFormControlName] = resultMax;
        }

        this.form.patchValue(patchValue, { emitEvent });
    }

    // eslint-disable-next-line complexity
    private adjustFormValue(sourceMin: string | number, sourceMax: string | number): number[] {

        if (sourceMin == null || sourceMin === this.anyDefaultText || sourceMin === '') {
            sourceMin = this.minValue;
        } else if (sourceMin != null) {
            sourceMin = typeof sourceMin === 'number'
                ? sourceMin
                : Number((sourceMin as string).replace(/\$|,|(\.\d*\D*)/g, ''));
        }

        if (sourceMax == null || sourceMax === this.anyDefaultText || sourceMax === '') {
            sourceMax = this.maxValue;
        } else if (sourceMax != null) {
            sourceMax = typeof sourceMax === 'number'
                ? sourceMax
                : Number((sourceMax as string).replace(/\$|,|(\.\d*\D*)/g, ''));
        }

        return [sourceMin as number, sourceMax as number];
    }

    private adjustFormBorderValues(min: number, max: number): number[] {
        const minMaxValue = this.anyMinAllowed ? this.maxValue - this.step : this.minValue;

        if (min > minMaxValue) {
            min = minMaxValue;
            max = this.maxValue;
        } else if (min > max) {
            max = min;
        } else if (max > this.maxValue) {
            max = this.maxValue;
        }

        return [min, max];
    }

}