/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';

import { gql } from 'app/graphql/gql';
import { ListingEventShareInput, ListingIdentifierInput, ListingSearchEventInput } from 'app/graphql/graphql';

export interface ListingIdentifier {
    listingId: number;
    listingNumber?: string;
}

@Injectable({ providedIn: 'root' })
export class ListingEventsApiService {

    constructor(private readonly apollo: Apollo) { }

    public trackViewed(listing: ListingIdentifierInput) {
        return this.apollo.mutate({
            mutation: gql(`
                mutation TrackViewedListing($listing: ListingIdentifierInput!) {
                    listingEvents {
                        viewed(identifier: $listing)
                    }
                }
            `),
            variables: {
                listing
            }
        });
    }

    public trackPicked(appliedForUserId: number, listings: ListingIdentifierInput[]) {
        return this.apollo.mutate({
            mutation: gql(`
                mutation TrackPickedListings($appliedForUserId: Int!, $listings: [ListingIdentifierInput!]!)  {
                    listingEvents {
                        picked(appliedForUserId: $appliedForUserId, listingIdentifiers: $listings)
                    }
                }
            `),
            variables: {
                appliedForUserId,
                listings
            }
        });
    }

    public trackLiked(appliedForUserId: number, listings: ListingIdentifierInput[]) {
        return this.apollo.mutate({
            mutation: gql(`
                mutation TrackLikedListings($appliedForUserId: Int!, $listings: [ListingIdentifierInput!]!) {
                    listingEvents {
                        liked(appliedForUserId: $appliedForUserId, listingIdentifiers: $listings)
                    }
                }
            `),
            variables: {
                appliedForUserId,
                listings
            }
        });
    }

    public trackListingShared(input: ListingEventShareInput) {
        return this.apollo.mutate({
            mutation: gql(`
                mutation TrackSharedListings($input: ListingEventShareInput!) {
                    listingEvents {
                        shareDetails(input: $input)
                    }
                }
            `),
            variables: { input }
        });
    }

    public trackSearchResults(input: ListingSearchEventInput) {
        return this.apollo.mutate({
            mutation: gql(`
                mutation TrackListingSearchDetails($input: ListingSearchEventInput!) {
                    listingEvents {
                        searchDetails(input:$input)
                    }
                }
            `),
            variables: {
                input
            }
        });
    }
}