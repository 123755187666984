import { ActionError } from '@core-models/errors/action-error';
import { SortOptionBase } from '@core-models/sort/sort-option';
import { FolderActionErrors } from '@folders/enums/folder-action-errors';
import { FolderNewMatch } from '@folders/models/folder-new-match';
import { FolderSavedSearch } from '@folders/models/folder-saved-search';
import { Folder } from '../../models/folder';
import { ListingFolderIdsMap } from '../../models/listing-folder-ids-map';
import { FoldersStateHelper } from './folders.state.helper';

export class FoldersState {
    folders: Folder[] = [];
    listingFolderIdsMap: ListingFolderIdsMap[] = [];
    selectedFolderId?: number;
    areFoldersLoaded = false;
    isLoading = false;
    sort: SortOptionBase;
    error: ActionError<FolderActionErrors>;
    foldersSavedSearches: FolderSavedSearch[] = [];
    folderNewMatches: FolderNewMatch[] = [];
    constructor() {
        this.sort = FoldersStateHelper.defaultSort;
    }
}