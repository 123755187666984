import { AfterViewInit, Directive, EventEmitter, HostListener, Input, NgZone, OnDestroy, Output } from '@angular/core';
import { SplitAreaDirective, SplitComponent } from 'angular-split';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Debounce } from '@core-decorators/debounce.decorator';

@Directive({ selector: '[splitAreaContentCorrector]' })
export class SplitAreaContentCorrectorDirective implements AfterViewInit, OnDestroy {

    @Input('splitAreaContentCorrector') public splitComponent: SplitComponent;
    @Input('correctionTrigger') public correctionTrigger: EventEmitter<void>;
    @Input('gutterOffset') public gutterOffset = 10;
    @Input('leftAreaWidthTrigger') public leftAreaWidthTrigger = 0;
    @Input('rightAreaWidthTrigger') public rightAreaWidthTrigger = 0;

    @Output() public splitAreaWidthTriggered = new EventEmitter<SplitAreaWidthTriggerEventParameters>();

    private readonly unsubscribe$ = new Subject<void>();

    private leftTriggerSatisfy = false;
    private rightTriggerSatisfy = false;

    constructor(
        private readonly ngZone: NgZone
    ) { }

    public ngAfterViewInit(): void {
        this.correctContent();

        this.splitComponent.dragProgress$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(data => {
                this.correctContent();
            });

        this.correctionTrigger
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.correctContent();
            });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    @HostListener('window:resize')
    @Debounce(0)
    public onResize(): void {
        this.correctContent();
    }

    private correctContent(): void {
        this.ngZone.runOutsideAngular(() => {

            const [leftArea, rightArea] = this.getDisplayedAreas();

            if (leftArea != null && rightArea != null) {
                const splitAreaWidth = rightArea.clientWidth;
                const splitAreaOffsetLeft = rightArea.offsetLeft;

                const child = rightArea.children[0] as HTMLElement;

                if (child != null) {
                    child.style.width = `${splitAreaWidth}px`;
                }

                const gutter = rightArea.nextElementSibling as HTMLElement;

                if (gutter != null) {
                    gutter.style.left = `${splitAreaOffsetLeft + this.gutterOffset}px`;
                    gutter.style.opacity = '1';
                }

                if (leftArea.clientWidth <= this.leftAreaWidthTrigger) {
                    if (!this.leftTriggerSatisfy) {
                        this.leftTriggerSatisfy = true;
                        const [leftAreaDirective, rightAreaDirective] = this.getDisplayedAreaDirectives();

                        this.splitAreaWidthTriggered.emit({
                            splitComponent: this.splitComponent,
                            leftAreaDirective,
                            rightAreaDirective,
                            area: 'left'
                        });
                    }
                } else {
                    this.leftTriggerSatisfy = false;
                }

                if (rightArea.parentElement.clientWidth - (leftArea.clientWidth + this.splitComponent.gutterSize) <= this.rightAreaWidthTrigger) {
                    if (!this.rightTriggerSatisfy) {
                        this.rightTriggerSatisfy = true;
                        const [leftAreaDirective, rightAreaDirective] = this.getDisplayedAreaDirectives();

                        this.splitAreaWidthTriggered.emit({
                            splitComponent: this.splitComponent,
                            leftAreaDirective,
                            rightAreaDirective,
                            area: 'right'
                        });
                    }
                } else {
                    this.rightTriggerSatisfy = false;
                }
            }

        });
    }

    private getDisplayedAreas(): HTMLElement[] {
        const [leftAreaDirective, rightAreaDirective] = this.getDisplayedAreaDirectives();

        if (leftAreaDirective != null && rightAreaDirective != null) {
            const leftArea = leftAreaDirective.elRef?.nativeElement as HTMLElement;
            const rightArea = rightAreaDirective.elRef?.nativeElement as HTMLElement;

            return [leftArea, rightArea];
        }

        return [null, null];
    }

    private getDisplayedAreaDirectives(): SplitAreaDirective[] {
        if (this.splitComponent != null && this.splitComponent.displayedAreas != null && this.splitComponent.displayedAreas.length > 1) {
            const leftAreaDirective = this.splitComponent.displayedAreas[0].component;
            const rightAreaDirective = this.splitComponent.displayedAreas[1].component;

            return [leftAreaDirective, rightAreaDirective];
        }

        return [null, null];
    }
}

export class SplitAreaWidthTriggerEventParameters {
    public readonly splitComponent: SplitComponent;
    public readonly leftAreaDirective: SplitAreaDirective;
    public readonly rightAreaDirective: SplitAreaDirective;
    public readonly area: 'right' | 'left';
}