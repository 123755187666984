import { ChangeDetectionStrategy, Component } from '@angular/core';
import { map } from 'rxjs';

import { AppConfigurationService } from '@app-config/services/app-configuration.service';
import { RpcRoute } from '@core-layout/app/models/rpc-route';
import { RouteService } from '@core-layout/app/services/route.service';
import { SettingsStoreService } from '@settings/store/services/settings-store.service';
import { MatchMediaService } from '@media/services/match-media.service';

@Component({
    selector: 'logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoComponent {
    public readonly companyConfiguration$ = this.configurationService.configuration$.pipe(map(configuration => configuration.company));
    public readonly isDarkMode$ = this.settingsStoreService.isDarkMode$;
    public readonly isMobileScreen$ = this.matchMediaService.onMediaChange.pipe(map(mediaQuery => mediaQuery === 'xs'));
    public readonly internalCompanyConfigurationLoaded$ = this.configurationService.getInternalCompanyConfigurationLoaded();

    constructor(
        private readonly routeService: RouteService,
        private readonly configurationService: AppConfigurationService,
        private readonly settingsStoreService: SettingsStoreService,
        private readonly matchMediaService: MatchMediaService,
    ) { }

    public onLogoClicked(): void {
        this.routeService.navigate(RpcRoute.FindHome).catch(() => { });
    }
}
