import { Injectable, OnDestroy } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';

import { User } from '@auth/models/user';
import { collaborationSpaceLoginSuccessful, loginSuccessful, logout, refreshSuccessful } from '@auth/store/actions/login.actions';
import { UserStoreService } from '@auth/store/services/user-store.service';
import { DateConstants } from '@core-constants/dates/date.constants';
import { ApiHttpClient } from '@core-services/api-http-client.service';
import { ActivityType } from '@customer-activity/models/activity-type';
import { AddUserActivityRequest, AddUserActivityWithActivityRequest } from '@customer-activity/models/add-user-activity-request';
import { TelemetryService } from '@customer-activity/services/telemetry.service';
import { AppointmentActivityType } from './enums/appointment-activity-type.enum';
import { ListingActivityType } from './enums/listing-activity-type.enum';
import { AddAppointmentActivityRequest } from './models/add-appointment-activity-request';
import { AddListingActivityRequest } from './models/add-listing-activity-request';
import { AddSavedSearchActivityRequest } from './models/add-saved-search-activity-request';
import { AddSearchSessionActivityRequest } from './models/add-search-session-activity-request';
import { SearchFieldInfo } from './models/search-field-info';

@Injectable({ providedIn: 'root' })
export class CustomerActivityService implements OnDestroy {
    private readonly unsubscribe$ = new Subject<void>();
    private readonly analyticsActivitiesRoute = 'analytics/activities';
    private readonly customerActivitiesRoute = 'customer/activities';
    private lastActivation: string | null = null;
    private user: User | null = null;

    constructor(
        private readonly actions$: Actions,
        private readonly http: ApiHttpClient,
        private readonly telemetryService: TelemetryService,
        private readonly router: Router,
        private readonly userStoreService: UserStoreService
    ) { }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    // create action to call in appropriate places without subscriptions to external actions
    // activity module should no know about auth module
    public initialize(): Promise<void> {
        return new Promise<void>(resolve => {
            this.actions$
                .pipe(
                    ofType(loginSuccessful, collaborationSpaceLoginSuccessful),
                    takeUntil(this.unsubscribe$)
                )
                .pipe(filter(o => !o.duringLoginSync))
                .subscribe(() => {
                    this.addActivity(ActivityType.Login)
                        .pipe(take(1))
                        .subscribe(() => this.tryAddDailyActivity());
                });

            this.actions$
                .pipe(
                    ofType(refreshSuccessful),
                    takeUntil(this.unsubscribe$),
                    switchMap(() => this.addActivity(ActivityType.Refresh).pipe(take(1)))
                )
                .subscribe();

            this.actions$
                .pipe(ofType(logout), takeUntil(this.unsubscribe$))
                .pipe(filter(logoutData => !logoutData.duringLoginSync))
                .subscribe(logoutData => {
                    const request = logoutData.forced ?
                        this.addForcedLogoutActivity() :
                        this.addActivity(ActivityType.Logout);

                    this.lastActivation = null;
                    request.pipe(take(1)).subscribe();
                });

            this.userStoreService.getUser()
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(user => this.user = user);

            this.router.events
                .pipe(filter(o => o instanceof ActivationEnd && this.user != null), takeUntil(this.unsubscribe$))
                .subscribe(() => this.tryAddDailyActivity());

            resolve();
        });
    }

    public addListingSessionActivity(
        listingActivityType: ListingActivityType,
        listingCategory: { listingId: string, category: string }[],
        savedSearchId?: number
    ): Observable<void> {
        return this.http.post(`${this.analyticsActivitiesRoute}/listing`, new AddListingActivityRequest(listingActivityType, listingCategory, savedSearchId)).pipe(take(1));
    }

    public addAppointmentSessionActivity(
        listingId: string,
        listingCategory: string,
        appointmentId: number,
        activityType: AppointmentActivityType,
        savedSearchId?: number
    ): Observable<void> {
        return this.http.post(
            `${this.analyticsActivitiesRoute}/appointment`,
            new AddAppointmentActivityRequest(listingId, listingCategory, appointmentId, activityType, savedSearchId)
        ).pipe(take(1));
    }

    public addSavedSearchActivity(savedSearchId: number, listingsReturned: number, isNew: boolean, listingCategory: string, createdId: number, runById: number): Observable<void> {
        return this.http.post(
            `${this.analyticsActivitiesRoute}/savedSearch`,
            new AddSavedSearchActivityRequest(savedSearchId, listingsReturned, isNew, listingCategory, createdId, runById)
        ).pipe(take(1));
    }

    public addSearchActivity(category: string, fields: { fieldName: string, fieldValue: string }[]): Observable<void> {

        const searchFields = fields.map(({ fieldName, fieldValue }) => new SearchFieldInfo(fieldName, fieldValue));

        return this.http.post(`${this.analyticsActivitiesRoute}/search`, new AddSearchSessionActivityRequest(category, searchFields)).pipe(take(1));
    }

    public addActivity(activityType: ActivityType): Observable<void> {
        return this.http.post(`${this.customerActivitiesRoute}`, new AddUserActivityWithActivityRequest(activityType, this.telemetryService.getTelemetry()));
    }

    private tryAddDailyActivity(): void {
        const currentActivation = moment().format(DateConstants.Formats.ShortDateMinus);

        if (this.lastActivation == null || this.lastActivation !== currentActivation) {
            this.lastActivation = currentActivation;
            this.addDailyActivity()
                .pipe(take(1))
                .subscribe();
        }
    }

    private addDailyActivity(): Observable<void> {
        return this.http.post(`${this.customerActivitiesRoute}/daily`, new AddUserActivityRequest(this.telemetryService.getTelemetry()));
    }

    private addForcedLogoutActivity(): Observable<void> {
        return this.http.post(`${this.customerActivitiesRoute}/forced-logout`, new AddUserActivityRequest(this.telemetryService.getTelemetry()));
    }
}