import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';

import { SimpleChanges } from '@core-models/utilities/generic-simple-changes';

import { RpcInputOptions } from './models/rpc-input-options';
import { MatOption } from '@angular/material/core/option';

@Component({
    selector: 'rpc-input',
    templateUrl: './rpc-input.component.html',
    styleUrls: ['./rpc-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RpcInputComponent implements OnInit, OnChanges, AfterViewInit {

    @Input() options: RpcInputOptions;
    @Input() type?: string;
    @Input() appearance: MatFormFieldAppearance;
    @Input() autocomplete?: string;
    @Input() label?: string;
    @Input() iconName?: string;
    @Input() iconPosition: 'prefix' | 'suffix' = 'suffix';
    @Input() iconTooltipText?: string;
    @Input() iconTooltipPosition: 'below' | 'above' | 'left' | 'right' | 'before' | 'after' = 'below';
    @Input() disabled?: boolean;
    @Input() readonly?: boolean;

    @Input() autocompleteOptions: { id: string, disabled?: boolean }[];
    @Input() noOptionHint?: string;

    @Output() blurChange = new EventEmitter<void>();
    @Output() changed = new EventEmitter<string>();
    @Output() optionSelected = new EventEmitter<MatOption>();
    @Output() optionMenuClosed = new EventEmitter<void>();

    public formControl: FormControl;

    public ngOnInit(): void {
        this.initialize(this.options);
    }

    public ngOnChanges(changes: SimpleChanges<RpcInputComponent>): void {
        if (changes.options?.currentValue != null && !changes.options.isFirstChange()) {
            if (changes.options?.previousValue != null) {
                changes.options.currentValue.formGroup.removeControl(changes.options.previousValue.controlName);
            }

            this.initialize(changes.options.currentValue);
        }

        if (changes.disabled != null && changes.disabled.currentValue != null) {
            this.disableControl(changes.disabled.currentValue);
        }

        // if autocompleteOptions is empty array, show noOptionHint
        if (this.noOptionHint && changes.autocompleteOptions) {
            this.autocompleteOptions = changes.autocompleteOptions.currentValue && changes.autocompleteOptions.currentValue.length === 0
                ? [{ id: this.noOptionHint, disabled: true }]
                : changes.autocompleteOptions.currentValue;
        }
    }

    public ngAfterViewInit(): void {
        setTimeout(() => {
            this.disableControl(this.disabled);
        });
    }

    public onInput(): void {
        this.changed.emit(this.formControl.value);
    }

    public onValueSelected(item: MatOption): void {
        this.formControl.setValue(item.id);
        this.optionSelected.emit(item);
    }

    private initialize(controlOptions: RpcInputOptions): void {
        if (controlOptions == null ||
            controlOptions.formGroup == null ||
            controlOptions.controlName == null ||
            controlOptions.controlName === ''
        ) {
            throw new Error('Wrong form control configuration. Please, provide proper options configuration.');
        }

        const validators = controlOptions.validatorsMap
            ?.filter(validationItem => validationItem.validator != null)
            .map(validationItem => validationItem.validator);

        this.formControl = new FormControl(controlOptions.defaultValue, validators);
        controlOptions.formGroup.setControl(controlOptions.controlName, this.formControl);
        controlOptions.formGroup.updateValueAndValidity();
    }

    private disableControl(disabled: boolean): void {
        if (this.formControl != null) {
            if (disabled) {
                this.formControl.disable();
            } else {
                this.formControl.enable();
            }
        }
    }

}