<mat-form-field [appearance]="appearance" class="rpc-input field-{{appearance}}">

    <mat-label *ngIf="label != null && label !== ''">
        {{ label | translate }}
    </mat-label>

    <rpc-icon class="rpc-input-icon"
        *ngIf="iconName != null && iconName !== '' && (iconTooltipText === '' || iconTooltipText == null) && iconPosition === 'prefix'"
        matPrefix iconName="{{iconName}}">
    </rpc-icon>

    <rpc-icon class="rpc-input-icon"
        *ngIf="iconName != null && iconName !== '' && iconTooltipText != null && iconTooltipText !== '' && iconPosition === 'prefix'"
        matPrefix iconName="{{iconName}}" matTooltip="{{iconTooltipText | translate}}"
        [matTooltipPosition]="iconTooltipPosition">
    </rpc-icon>

    <input matInput [matAutocomplete]="auto" name="{{options.controlName}}" [type]="type" [formControl]="formControl"
        [autocomplete]="autocomplete" [readonly]="readonly" (blur)="blurChange.emit()" (input)="onInput()"
        (keydown)="$event.stopPropagation();">

    <rpc-icon class="rpc-input-icon"
        *ngIf="iconName != null && iconName !== '' && (iconTooltipText === '' || iconTooltipText == null) && iconPosition === 'suffix'"
        matSuffix iconName="{{iconName}}">
    </rpc-icon>

    <rpc-icon class="rpc-input-icon"
        *ngIf="iconName != null && iconName !== '' && iconTooltipText != null && iconTooltipText !== '' && iconPosition === 'suffix'"
        matSuffix iconName="{{iconName}}" matTooltip="{{iconTooltipText | translate}}"
        [matTooltipPosition]="iconTooltipPosition">
    </rpc-icon>

    <mat-error *ngFor="let validationItem of options.validatorsMap">
        <ng-container *ngTemplateOutlet="showErrorsTemplate, context: {
            $implicit: {
                showError: validationItem.showError,
                message: validationItem.message
            }
        }">
        </ng-container>
    </mat-error>

    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onValueSelected($event.option)"
        (closed)="optionMenuClosed.emit()">
        <mat-option *ngFor="let item of autocompleteOptions" [value]="item" [title]="item.disabled ? '' : item.id"
            [disabled]="item.disabled">{{item.id}}</mat-option>
    </mat-autocomplete>

</mat-form-field>

<ng-template #showErrorsTemplate let-validationItem>
    <div *ngIf="validationItem.showError(formControl)">
        {{ validationItem.message | translate }}
    </div>
</ng-template>