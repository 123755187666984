/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n    mutation UpsertCommuteTimePlaces($upsert: CommuteTimePlaceUpsertInput!) {\n        upsertCommuteTimePlace(upsert: $upsert) {\n            address\n            placeId\n        }\n    }\n": types.UpsertCommuteTimePlacesDocument,
    "\n    query GetCommuteTimePlaces {\n        commuteTimePlaces {\n            address\n            placeId\n        }\n    }\n": types.GetCommuteTimePlacesDocument,
    "\n    mutation CreateUpdateNote($note: UpsertCustomerListingNoteInput!) {\n        customerNotes {\n            upsert(note: $note) {\n                id\n            }\n        }\n    }\n": types.CreateUpdateNoteDocument,
    "\n    mutation DeleteNote($listingIds: [Int!]!) {\n        customerNotes {\n            delete(listingsIds: $listingIds) {\n                success\n            }\n        }\n    }\n": types.DeleteNoteDocument,
    "\n    query GetListingsNotes {\n        customerListingNotes {\n            customerListingsNotes {\n                id\n                listingId\n                note\n            }\n        }\n    }\n": types.GetListingsNotesDocument,
    "\n    query GetListingNote($listingId: Int!) {\n        customerListingNotes {\n            customerListingNote(listingId: $listingId) {\n                id\n                listingId\n                note\n            }\n        }\n    }\n": types.GetListingNoteDocument,
    "\n                mutation TrackViewedListing($listing: ListingIdentifierInput!) {\n                    listingEvents {\n                        viewed(identifier: $listing)\n                    }\n                }\n            ": types.TrackViewedListingDocument,
    "\n                mutation TrackPickedListings($appliedForUserId: Int!, $listings: [ListingIdentifierInput!]!)  {\n                    listingEvents {\n                        picked(appliedForUserId: $appliedForUserId, listingIdentifiers: $listings)\n                    }\n                }\n            ": types.TrackPickedListingsDocument,
    "\n                mutation TrackLikedListings($appliedForUserId: Int!, $listings: [ListingIdentifierInput!]!) {\n                    listingEvents {\n                        liked(appliedForUserId: $appliedForUserId, listingIdentifiers: $listings)\n                    }\n                }\n            ": types.TrackLikedListingsDocument,
    "\n                mutation TrackSharedListings($input: ListingEventShareInput!) {\n                    listingEvents {\n                        shareDetails(input: $input)\n                    }\n                }\n            ": types.TrackSharedListingsDocument,
    "\n                mutation TrackListingSearchDetails($input: ListingSearchEventInput!) {\n                    listingEvents {\n                        searchDetails(input:$input)\n                    }\n                }\n            ": types.TrackListingSearchDetailsDocument,
    "\n    mutation CreateAppointmentCreatedNotification($input: AppointmentEventInput!) {\n        notifications {\n            events {\n                appointmentCreated (input: $input) {\n                    id\n                }\n            }\n        }\n    }\n": types.CreateAppointmentCreatedNotificationDocument,
    "\n    mutation CreateAppointmentConfirmedNotification($input: AppointmentEventInput!) {\n        notifications {\n            events {\n                appointmentAccepted (input: $input) {\n                    id\n                }\n            }\n        }\n    }\n": types.CreateAppointmentConfirmedNotificationDocument,
    "\n    mutation CreateAppointmentDeclinedNotification($input: AppointmentEventInput!) {\n        notifications {\n            events {\n                appointmentDeclined (input: $input) {\n                    id\n                }\n            }\n        }\n    }\n": types.CreateAppointmentDeclinedNotificationDocument,
    "\n    mutation CreateAppointmentUpdatedNotification($input: AppointmentUpdatedEventInput!) {\n        notifications {\n            events {\n                appointmentUpdated (input: $input) {\n                    id\n                }\n            }\n        }\n    }\n": types.CreateAppointmentUpdatedNotificationDocument,
    "\n    mutation CreateAppointmentDeletedNotification($input: AppointmentDeletedEventInput!) {\n        notifications {\n            events {\n                appointmentDeleted(input: $input) {\n                    id\n                }\n            }\n        }\n    }\n": types.CreateAppointmentDeletedNotificationDocument,
    "\n    mutation CommentCreatedNotification($input: CommentCreatedInput!) {\n        notifications {\n            events {\n                commentCreated (input: $input) {\n                    id\n                }\n            }\n        }\n    }\n": types.CommentCreatedNotificationDocument,
    "\n    mutation CommentUpdatedNotification($input: CommentUpdatedInput!) {\n        notifications {\n            events {\n                commentUpdated (input: $input) {\n                    id\n                }\n            }\n        }\n    }\n": types.CommentUpdatedNotificationDocument,
    "\n    mutation FolderAddedNotification($input: FolderEventInput!){\n        notifications {\n            events {\n                folderAdded(input: $input) {\n                    id\n                }\n            }\n        }\n    }\n": types.FolderAddedNotificationDocument,
    "\n    mutation FolderUpdateNotification($input: FolderEventInput!){\n        notifications {\n            events {\n                folderUpdated(input: $input) {\n                    id\n                }\n            }\n        }\n    }\n": types.FolderUpdateNotificationDocument,
    "\n    mutation FolderRemovedNotification($input: FolderEventInput!){\n        notifications {\n            events {\n                folderRemoved(input: $input) {\n                    id\n                }\n            }\n        }\n    }\n": types.FolderRemovedNotificationDocument,
    "\n    mutation FolderListingsChangedNotification($input: FolderEventInput!) {\n        notifications {\n            events {\n                folderListingsChanged(input: $input) {\n                    id\n                }\n            }\n        }\n    }\n": types.FolderListingsChangedNotificationDocument,
    "\n    mutation CreateNewListingActivityNotification($input: ListingActivityInput!){\n        notifications {\n            events {\n                newListingActivity(input: $input) {\n                    id\n                }\n            }\n        }\n    }\n": types.CreateNewListingActivityNotificationDocument,
    "\n    mutation CreateListingActivityChangedNotification($input: ListingActivityInput!){\n        notifications {\n            events {\n                changedListingActivity(input: $input) {\n                    id\n                }\n            }\n        }\n    }\n": types.CreateListingActivityChangedNotificationDocument,
    "\n    mutation CreateListingActivityRemovedNotification($input: NotificationRemoveListingActivityInput!) {\n        notifications {\n            events {\n                removeListingActivity(input: $input) {\n                    id\n                }\n            }\n        }\n    }\n": types.CreateListingActivityRemovedNotificationDocument,
    "\n    mutation CreateExternalListingAddedNotification($input: ExternalListingEventInput!){\n        notifications {\n            events {\n                externalListingAdded(input: $input) {\n                    id\n                }\n            }\n        }\n    }\n": types.CreateExternalListingAddedNotificationDocument,
    "\n    mutation CreateListingSoftDeletedNotification($input: ListingsEventInput!){\n        notifications {\n            events {\n                listingSoftDeleted(input: $input) {\n                    id\n                }\n            }\n        }\n    }\n": types.CreateListingSoftDeletedNotificationDocument,
    "\n    mutation CreateListingHardDeletedNotification($input: ListingsEventInput!){\n        notifications {\n            events {\n                listingHardDeleted(input: $input) {\n                    id\n                }\n            }\n        }\n    }\n": types.CreateListingHardDeletedNotificationDocument,
    "\n    mutation CreateListingRestoredNotification($input: ListingsEventInput!){\n        notifications {\n            events {\n                listingRestored(input: $input) {\n                    id\n                }\n            }\n        }\n    }\n": types.CreateListingRestoredNotificationDocument,
    "\n    query GetAllNotifiactions{\n        notifications {\n            isViewed\n            entityDate\n            comment {\n                text\n            }\n            appointment {\n                createId\n                updateId\n                agentStatus\n                customerStatus\n                startDateTime\n                endDateTime\n                comment\n                isCreatedByAgent\n            }\n            listing {\n                address\n                imageUrl\n            }\n            notificationId\n            listingId\n            entityId\n            entityType\n            collaborationId\n            createId\n            createDate\n            action\n        }\n    }\n": types.GetAllNotifiactionsDocument,
    "\n    mutation ViewUnviewNotifications($notificationIds: [Int!]){\n        notifications {\n            viewUnviewNotifications(notificationIds: $notificationIds) {\n                success\n            }\n        }\n    }\n": types.ViewUnviewNotificationsDocument,
    "\n    mutation RemoveNotificationsByListings($input: RemoveNotificationsByListingsInput!){\n        notifications {\n            removeByListings(input: $input) {\n                success\n            }\n        }\n    }\n": types.RemoveNotificationsByListingsDocument,
    "\n    mutation RemoveNotifications($ids: [Int!]!){\n        notifications {\n            remove(notificationIds: $ids) {\n            success\n            }\n        }\n    }\n": types.RemoveNotificationsDocument,
    "\n    mutation RemoveEntitiesNotifications($input: RemoveByEntitiesInput!){\n        notifications {\n            removeByEntities(input: $input) {\n              success\n            }\n        }\n    }\n": types.RemoveEntitiesNotificationsDocument,
    "\n    mutation CreateUserDeactivatedNotification($userId: Int!){\n        notifications {\n            events {\n                userDeactivated(userId: $userId) {\n                    success\n                }\n            }\n        }\n    }\n": types.CreateUserDeactivatedNotificationDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation UpsertCommuteTimePlaces($upsert: CommuteTimePlaceUpsertInput!) {\n        upsertCommuteTimePlace(upsert: $upsert) {\n            address\n            placeId\n        }\n    }\n"): (typeof documents)["\n    mutation UpsertCommuteTimePlaces($upsert: CommuteTimePlaceUpsertInput!) {\n        upsertCommuteTimePlace(upsert: $upsert) {\n            address\n            placeId\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query GetCommuteTimePlaces {\n        commuteTimePlaces {\n            address\n            placeId\n        }\n    }\n"): (typeof documents)["\n    query GetCommuteTimePlaces {\n        commuteTimePlaces {\n            address\n            placeId\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation CreateUpdateNote($note: UpsertCustomerListingNoteInput!) {\n        customerNotes {\n            upsert(note: $note) {\n                id\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation CreateUpdateNote($note: UpsertCustomerListingNoteInput!) {\n        customerNotes {\n            upsert(note: $note) {\n                id\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation DeleteNote($listingIds: [Int!]!) {\n        customerNotes {\n            delete(listingsIds: $listingIds) {\n                success\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation DeleteNote($listingIds: [Int!]!) {\n        customerNotes {\n            delete(listingsIds: $listingIds) {\n                success\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query GetListingsNotes {\n        customerListingNotes {\n            customerListingsNotes {\n                id\n                listingId\n                note\n            }\n        }\n    }\n"): (typeof documents)["\n    query GetListingsNotes {\n        customerListingNotes {\n            customerListingsNotes {\n                id\n                listingId\n                note\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query GetListingNote($listingId: Int!) {\n        customerListingNotes {\n            customerListingNote(listingId: $listingId) {\n                id\n                listingId\n                note\n            }\n        }\n    }\n"): (typeof documents)["\n    query GetListingNote($listingId: Int!) {\n        customerListingNotes {\n            customerListingNote(listingId: $listingId) {\n                id\n                listingId\n                note\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n                mutation TrackViewedListing($listing: ListingIdentifierInput!) {\n                    listingEvents {\n                        viewed(identifier: $listing)\n                    }\n                }\n            "): (typeof documents)["\n                mutation TrackViewedListing($listing: ListingIdentifierInput!) {\n                    listingEvents {\n                        viewed(identifier: $listing)\n                    }\n                }\n            "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n                mutation TrackPickedListings($appliedForUserId: Int!, $listings: [ListingIdentifierInput!]!)  {\n                    listingEvents {\n                        picked(appliedForUserId: $appliedForUserId, listingIdentifiers: $listings)\n                    }\n                }\n            "): (typeof documents)["\n                mutation TrackPickedListings($appliedForUserId: Int!, $listings: [ListingIdentifierInput!]!)  {\n                    listingEvents {\n                        picked(appliedForUserId: $appliedForUserId, listingIdentifiers: $listings)\n                    }\n                }\n            "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n                mutation TrackLikedListings($appliedForUserId: Int!, $listings: [ListingIdentifierInput!]!) {\n                    listingEvents {\n                        liked(appliedForUserId: $appliedForUserId, listingIdentifiers: $listings)\n                    }\n                }\n            "): (typeof documents)["\n                mutation TrackLikedListings($appliedForUserId: Int!, $listings: [ListingIdentifierInput!]!) {\n                    listingEvents {\n                        liked(appliedForUserId: $appliedForUserId, listingIdentifiers: $listings)\n                    }\n                }\n            "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n                mutation TrackSharedListings($input: ListingEventShareInput!) {\n                    listingEvents {\n                        shareDetails(input: $input)\n                    }\n                }\n            "): (typeof documents)["\n                mutation TrackSharedListings($input: ListingEventShareInput!) {\n                    listingEvents {\n                        shareDetails(input: $input)\n                    }\n                }\n            "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n                mutation TrackListingSearchDetails($input: ListingSearchEventInput!) {\n                    listingEvents {\n                        searchDetails(input:$input)\n                    }\n                }\n            "): (typeof documents)["\n                mutation TrackListingSearchDetails($input: ListingSearchEventInput!) {\n                    listingEvents {\n                        searchDetails(input:$input)\n                    }\n                }\n            "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation CreateAppointmentCreatedNotification($input: AppointmentEventInput!) {\n        notifications {\n            events {\n                appointmentCreated (input: $input) {\n                    id\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation CreateAppointmentCreatedNotification($input: AppointmentEventInput!) {\n        notifications {\n            events {\n                appointmentCreated (input: $input) {\n                    id\n                }\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation CreateAppointmentConfirmedNotification($input: AppointmentEventInput!) {\n        notifications {\n            events {\n                appointmentAccepted (input: $input) {\n                    id\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation CreateAppointmentConfirmedNotification($input: AppointmentEventInput!) {\n        notifications {\n            events {\n                appointmentAccepted (input: $input) {\n                    id\n                }\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation CreateAppointmentDeclinedNotification($input: AppointmentEventInput!) {\n        notifications {\n            events {\n                appointmentDeclined (input: $input) {\n                    id\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation CreateAppointmentDeclinedNotification($input: AppointmentEventInput!) {\n        notifications {\n            events {\n                appointmentDeclined (input: $input) {\n                    id\n                }\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation CreateAppointmentUpdatedNotification($input: AppointmentUpdatedEventInput!) {\n        notifications {\n            events {\n                appointmentUpdated (input: $input) {\n                    id\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation CreateAppointmentUpdatedNotification($input: AppointmentUpdatedEventInput!) {\n        notifications {\n            events {\n                appointmentUpdated (input: $input) {\n                    id\n                }\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation CreateAppointmentDeletedNotification($input: AppointmentDeletedEventInput!) {\n        notifications {\n            events {\n                appointmentDeleted(input: $input) {\n                    id\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation CreateAppointmentDeletedNotification($input: AppointmentDeletedEventInput!) {\n        notifications {\n            events {\n                appointmentDeleted(input: $input) {\n                    id\n                }\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation CommentCreatedNotification($input: CommentCreatedInput!) {\n        notifications {\n            events {\n                commentCreated (input: $input) {\n                    id\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation CommentCreatedNotification($input: CommentCreatedInput!) {\n        notifications {\n            events {\n                commentCreated (input: $input) {\n                    id\n                }\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation CommentUpdatedNotification($input: CommentUpdatedInput!) {\n        notifications {\n            events {\n                commentUpdated (input: $input) {\n                    id\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation CommentUpdatedNotification($input: CommentUpdatedInput!) {\n        notifications {\n            events {\n                commentUpdated (input: $input) {\n                    id\n                }\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation FolderAddedNotification($input: FolderEventInput!){\n        notifications {\n            events {\n                folderAdded(input: $input) {\n                    id\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation FolderAddedNotification($input: FolderEventInput!){\n        notifications {\n            events {\n                folderAdded(input: $input) {\n                    id\n                }\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation FolderUpdateNotification($input: FolderEventInput!){\n        notifications {\n            events {\n                folderUpdated(input: $input) {\n                    id\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation FolderUpdateNotification($input: FolderEventInput!){\n        notifications {\n            events {\n                folderUpdated(input: $input) {\n                    id\n                }\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation FolderRemovedNotification($input: FolderEventInput!){\n        notifications {\n            events {\n                folderRemoved(input: $input) {\n                    id\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation FolderRemovedNotification($input: FolderEventInput!){\n        notifications {\n            events {\n                folderRemoved(input: $input) {\n                    id\n                }\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation FolderListingsChangedNotification($input: FolderEventInput!) {\n        notifications {\n            events {\n                folderListingsChanged(input: $input) {\n                    id\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation FolderListingsChangedNotification($input: FolderEventInput!) {\n        notifications {\n            events {\n                folderListingsChanged(input: $input) {\n                    id\n                }\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation CreateNewListingActivityNotification($input: ListingActivityInput!){\n        notifications {\n            events {\n                newListingActivity(input: $input) {\n                    id\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation CreateNewListingActivityNotification($input: ListingActivityInput!){\n        notifications {\n            events {\n                newListingActivity(input: $input) {\n                    id\n                }\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation CreateListingActivityChangedNotification($input: ListingActivityInput!){\n        notifications {\n            events {\n                changedListingActivity(input: $input) {\n                    id\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation CreateListingActivityChangedNotification($input: ListingActivityInput!){\n        notifications {\n            events {\n                changedListingActivity(input: $input) {\n                    id\n                }\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation CreateListingActivityRemovedNotification($input: NotificationRemoveListingActivityInput!) {\n        notifications {\n            events {\n                removeListingActivity(input: $input) {\n                    id\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation CreateListingActivityRemovedNotification($input: NotificationRemoveListingActivityInput!) {\n        notifications {\n            events {\n                removeListingActivity(input: $input) {\n                    id\n                }\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation CreateExternalListingAddedNotification($input: ExternalListingEventInput!){\n        notifications {\n            events {\n                externalListingAdded(input: $input) {\n                    id\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation CreateExternalListingAddedNotification($input: ExternalListingEventInput!){\n        notifications {\n            events {\n                externalListingAdded(input: $input) {\n                    id\n                }\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation CreateListingSoftDeletedNotification($input: ListingsEventInput!){\n        notifications {\n            events {\n                listingSoftDeleted(input: $input) {\n                    id\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation CreateListingSoftDeletedNotification($input: ListingsEventInput!){\n        notifications {\n            events {\n                listingSoftDeleted(input: $input) {\n                    id\n                }\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation CreateListingHardDeletedNotification($input: ListingsEventInput!){\n        notifications {\n            events {\n                listingHardDeleted(input: $input) {\n                    id\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation CreateListingHardDeletedNotification($input: ListingsEventInput!){\n        notifications {\n            events {\n                listingHardDeleted(input: $input) {\n                    id\n                }\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation CreateListingRestoredNotification($input: ListingsEventInput!){\n        notifications {\n            events {\n                listingRestored(input: $input) {\n                    id\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation CreateListingRestoredNotification($input: ListingsEventInput!){\n        notifications {\n            events {\n                listingRestored(input: $input) {\n                    id\n                }\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query GetAllNotifiactions{\n        notifications {\n            isViewed\n            entityDate\n            comment {\n                text\n            }\n            appointment {\n                createId\n                updateId\n                agentStatus\n                customerStatus\n                startDateTime\n                endDateTime\n                comment\n                isCreatedByAgent\n            }\n            listing {\n                address\n                imageUrl\n            }\n            notificationId\n            listingId\n            entityId\n            entityType\n            collaborationId\n            createId\n            createDate\n            action\n        }\n    }\n"): (typeof documents)["\n    query GetAllNotifiactions{\n        notifications {\n            isViewed\n            entityDate\n            comment {\n                text\n            }\n            appointment {\n                createId\n                updateId\n                agentStatus\n                customerStatus\n                startDateTime\n                endDateTime\n                comment\n                isCreatedByAgent\n            }\n            listing {\n                address\n                imageUrl\n            }\n            notificationId\n            listingId\n            entityId\n            entityType\n            collaborationId\n            createId\n            createDate\n            action\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation ViewUnviewNotifications($notificationIds: [Int!]){\n        notifications {\n            viewUnviewNotifications(notificationIds: $notificationIds) {\n                success\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation ViewUnviewNotifications($notificationIds: [Int!]){\n        notifications {\n            viewUnviewNotifications(notificationIds: $notificationIds) {\n                success\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation RemoveNotificationsByListings($input: RemoveNotificationsByListingsInput!){\n        notifications {\n            removeByListings(input: $input) {\n                success\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation RemoveNotificationsByListings($input: RemoveNotificationsByListingsInput!){\n        notifications {\n            removeByListings(input: $input) {\n                success\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation RemoveNotifications($ids: [Int!]!){\n        notifications {\n            remove(notificationIds: $ids) {\n            success\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation RemoveNotifications($ids: [Int!]!){\n        notifications {\n            remove(notificationIds: $ids) {\n            success\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation RemoveEntitiesNotifications($input: RemoveByEntitiesInput!){\n        notifications {\n            removeByEntities(input: $input) {\n              success\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation RemoveEntitiesNotifications($input: RemoveByEntitiesInput!){\n        notifications {\n            removeByEntities(input: $input) {\n              success\n            }\n        }\n    }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation CreateUserDeactivatedNotification($userId: Int!){\n        notifications {\n            events {\n                userDeactivated(userId: $userId) {\n                    success\n                }\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation CreateUserDeactivatedNotification($userId: Int!){\n        notifications {\n            events {\n                userDeactivated(userId: $userId) {\n                    success\n                }\n            }\n        }\n    }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;