import { Component, Input, } from '@angular/core';

import { DateConstants } from '@core-constants/dates/date.constants';
import { OpenHouse } from '@listings/models/listing/openhouse';

@Component({
    selector: 'open-house-tooltip',
    templateUrl: './open-house-tooltip.component.html',
    styleUrls: ['./open-house-tooltip.component.scss']
})
export class OpenHouseTooltipComponent {
    @Input() public openHouse: OpenHouse;

    public readonly dateConstants = DateConstants;
}
