import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RpcControlsModule } from '@core-controls/controls.module';
import { PaginatorComponent } from './paginator.component';
import { PaginatorDirective } from './paginator.directive';
import { PaginatorPipe } from './paginator.pipe';
import { PaginatorService } from './paginator.service';

@NgModule({
    declarations: [
        PaginatorComponent,
        PaginatorDirective,
        PaginatorPipe
    ],
    imports: [
        // Library Modules
        CommonModule,

        // Application Modules
        RpcControlsModule
    ],
    exports: [
        PaginatorComponent,
        PaginatorDirective,
        PaginatorPipe
    ],
    providers: [
        PaginatorService
    ]
})
export class PaginatorModule { }
