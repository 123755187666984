import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';

import { SearchApiService } from '../services/search-api.service';
import * as patternSearchActions from '../actions/pattern-search.actions';
import { SearchStoreService } from '../services/search-store.service';
import { OmniSearchService } from '../services/omni-search.service';
import * as searchActions from '../actions/search-criteria.actions';
import { ListingSearchOptions } from '@listings/models/search/listing-search-options';
import { GoogleAnalyticsStoreService } from '@core-layout/app/store/services/google-analytics-store.service';

@Injectable()
export class PatternSearchEffects {

    constructor(
        private readonly actions$: Actions,
        private readonly patternSearchApiService: SearchApiService,
        private readonly searchStoreService: SearchStoreService,
        private readonly googleAnalyticsStoreService: GoogleAnalyticsStoreService,
    ) { }

    public readonly searchByPattern$ = createEffect(() => this.actions$.pipe(
        ofType(patternSearchActions.searchByPattern),
        concatLatestFrom(() => this.searchStoreService.searchOptions$),
        switchMap(([{ searchPattern }, searchOptions]) => {
            return this.patternSearchApiService.searchByPattern(searchPattern, searchOptions.categoryId);
        })
    ));

    public readonly patternResultChanged$ = createEffect(() => this.actions$.pipe(
        ofType(patternSearchActions.patternResultChanged),
        concatLatestFrom(() => [this.searchStoreService.searchOptions$, this.searchStoreService.neighborhoodProperties$]),
        map(([{ patternResults }, currentSearchOptions, neighborhoodProperties]) => {

            const neighborhoodIds: number[] = [];
            const rpBins: number[] = [];
            const webNumbers: number[] = [];
            const listingNumbers: number[] = [];

            patternResults.forEach(patternResult => {
                switch (patternResult.type.toLowerCase()) {
                    case 'borough':
                    case 'section':
                    case 'neighborhood':
                        neighborhoodIds.push(...patternResult.keys);
                        break;
                    case 'street':
                    case 'building name':
                    case 'address':
                        rpBins.push(...patternResult.keys);
                        break;
                    case 'listing':
                        listingNumbers.push(...patternResult.keys);
                        break;
                    case 'web':
                        webNumbers.push(...patternResult.keys);
                        break;
                    default:
                        break;
                }
            });

            const patternSearchResult = OmniSearchService.getPatternByOptions(neighborhoodProperties, neighborhoodIds, patternResults);

            const searchOptions: ListingSearchOptions = {
                ...currentSearchOptions,
                patternSearchResult,
                rpBins,
                neighborhoodIds,
                webNumbers,
                listingNumbers
            };

            const addedPatternResult = patternResults.find(patternResult => !currentSearchOptions.patternSearchResult.includes(patternResult));

            if (addedPatternResult != null) {
                this.googleAnalyticsStoreService.addSearchOmniBoxEvent(addedPatternResult.type);
            }

            return searchActions.changeSearchOptions({ searchOptions });
        }),
    ));
}