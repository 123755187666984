import { Injectable } from '@angular/core';

import { FolderNewMatch } from '@folders/models/folder-new-match';
import { ListingFolderIdsMap } from '@folders/models/listing-folder-ids-map';

@Injectable({ providedIn: 'root' })
export class FoldersNewMatchUpdateService {

    public static updateFolderNewMatches(folderNewMatches: FolderNewMatch[], updatedListingFolderIdsMap: ListingFolderIdsMap[]) {
        const folderNewMatchRecordIdsMapping: { [folderId: number]: number[]; } = {};
        updatedListingFolderIdsMap.forEach(listingFolderIdmapItem => {
            if (listingFolderIdmapItem.newMatchRecordId == null) {
                return;
            }

            if (folderNewMatchRecordIdsMapping[listingFolderIdmapItem.folderId] != null) {
                folderNewMatchRecordIdsMapping[listingFolderIdmapItem.folderId].push(listingFolderIdmapItem.newMatchRecordId);
            } else {
                folderNewMatchRecordIdsMapping[listingFolderIdmapItem.folderId] = [listingFolderIdmapItem.newMatchRecordId];
            }
        });

        const updatedFolderNewMatches: FolderNewMatch[] = folderNewMatches
            .filter(folderNewMatchesMapitem => folderNewMatchRecordIdsMapping[folderNewMatchesMapitem.id] != null)
            .map(folderNewMatchesMapitem => ({
                ...folderNewMatchesMapitem,
                newMatchRecordIds: folderNewMatchRecordIdsMapping[folderNewMatchesMapitem.id]
            }));

        return updatedFolderNewMatches;
    }

}