export const locale = {
    lang: 'en',
    data: {
        NOTIFICATIONS: {
            TITLES: {
                NOTIFICATIONS: 'Notifications',
                MARK_ALL_AS_READ: 'Mark All as Read',
                ONLY_SHOW_UNREAD: 'Show Unread Only',
                ALL: 'All',
                MESSAGES: 'Messages',
                APPOINTMENTS: 'Appointments',
                LISTINGS: 'Listings',
                TODAY: 'Today',
                YESTERDAY: 'Yesterday',
                OLDER: 'Older',
            }
        }
    }
};