export const locale = {
    lang: 'en',
    data: {
        LISTING_LABELS: {
            NEW_MATCH: {
                LONG_TITLE: 'Matches Your Criteria'
            },
            NEWLY_ADDED_TO_PORTFOLIO: {
                LONG_TITLE: 'Newly Added to Portfolio'
            },
            OPEN_HOUSE: {
                LONG_TITLE_WITH_DATE: 'Open House: {{date}}',
                LONG_TITLE: 'Open House',
                NEXT: 'Next',
                PUBLIC: 'Public',
                BROKER_ONLY: 'Broker Only'
            },
            PRICE_DROP: {
                LONG_TITLE: 'Price Drop'
            },
        }
    }
};