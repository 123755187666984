export const locale = {
    lang: 'en',
    data: {
        OMNI_SEARCH: {
            TITLES: {
                CANCEL: 'Cancel',
                PLACEHOLDER: 'Enter Address, Neighborhood, Listing ID…'
            }
        }
    }
};