import { ADDED_BY_AGENT_DEFAULT_FOLDER_SETTINGS, DEFAULT_FOLDER_SETTINGS } from './constants/settings.constants';
import { CustomerSettings } from './models/settings/customer-settings';
import { FoldersSettings } from './models/settings/folders-settings';
import { SYSTEM_FOLDERS_INFO } from '@folders/constants/folder.constants';

export class SettingsHelper {

    public static createDefaultFolderSettings(folderId: number): FoldersSettings {
        const settings = folderId === SYSTEM_FOLDERS_INFO.ADDED_BY_AGENT.id ? ADDED_BY_AGENT_DEFAULT_FOLDER_SETTINGS : DEFAULT_FOLDER_SETTINGS;

        return { ...settings, folderId };
    }

    public static updateWithFoldersSettings(settings: CustomerSettings, foldersSettings: FoldersSettings[], isUnselectDefaultFolder = false): CustomerSettings {
        return {
            ...settings,
            layoutSettings: {
                ...settings.layoutSettings,
                foldersSettings,
                defaultFolderId: isUnselectDefaultFolder ? null : settings.layoutSettings.defaultFolderId
            }
        };
    }

    public static getDefaultFolderSettings(settings: CustomerSettings): FoldersSettings {
        const { defaultFolderId, foldersSettings } = settings.layoutSettings;

        return defaultFolderId != null
            ? foldersSettings.find(({ folderId }) => folderId === defaultFolderId)
            : null;
    }

    public static updateWithDefaultFolderSettings(settings: CustomerSettings): CustomerSettings {
        const { defaultFolderId, foldersSettings } = settings.layoutSettings;

        return defaultFolderId != null && SettingsHelper.getDefaultFolderSettings(settings) == null
            ? SettingsHelper.updateWithFoldersSettings(settings, [...(foldersSettings ?? []), SettingsHelper.createDefaultFolderSettings(defaultFolderId)])
            : settings;
    }
}