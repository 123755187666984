export const locale = {
    lang: 'en',
    data: {
        CREATE_EDIT_FOLDER_DIALOG: {
            TITLES: {
                CREATE: 'Create Folder',
                EDIT: 'Edit Folder',
            },
            BUTTONS: {
                CLOSE: 'Close'
            }
        }
    }
};
