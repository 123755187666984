import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { UserStoreService } from '@auth/store/services/user-store.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    private readonly notAuthorizedCode = 401;

    constructor(private readonly userStoreService: UserStoreService) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return this.userStoreService.getUser()
            .pipe(
                take(1),
                switchMap(user => {
                    const token = user?.loginData?.accessToken;
                    const expiration = user?.expiration;

                    if (token != null && expiration < new Date() && !request.url.includes('refreshToken')) {
                        return this.tryRestoreAccessToken(request, next);
                    }

                    if (token != null && expiration > new Date()) {
                        request = this.getAuthorizationRequest(request, token);
                    }

                    return next.handle(request).pipe(catchError(error => {
                        if (error instanceof HttpErrorResponse && error.status === this.notAuthorizedCode) {
                            return this.tryRestoreAccessToken(request, next);
                        }

                        return throwError(() => error);
                    }));
                }));
    }

    private getAuthorizationRequest(request: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
        return request.clone({
            setHeaders: {
                ['Authorization']: `Bearer ${token}`
            }
        });
    }

    private tryRestoreAccessToken(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return this.userStoreService.getUser().pipe(
            filter(user => user?.loginData?.accessToken != null && user?.expiration > new Date()),
            take(1),
            switchMap(user => next.handle(this.getAuthorizationRequest(request, user.loginData.accessToken)))
        );
    }
}