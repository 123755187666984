<div class="rpc-notification-group" [class.rpc-notification-group--opened]="isGroupNotificationVisible"
    [class.rpc-notification-group--read]="notificationItem.isViewed">

    <div class="rpc-notification-group-header" (click)="changeNotificationGroupVisibility()">

        <rpc-icon class="rpc-notification-group-trigger" [iconName]="'arrow-right'">
        </rpc-icon>

        <div class="rpc-notification-group-icon-wrap">

            <rpc-icon [iconName]="notificationItem.icon"
                [class]="['rpc-notification-group-icon', notificationItem.iconClass != null ? notificationItem.iconClass : '']">
            </rpc-icon>

        </div>
        <div class="rpc-notification-group-title" wrapWords
            [innerHTML]="notificationItem.translationInfo.key | translate: notificationItem.translationInfo.params">
        </div>

        <div class="rpc-notification-group-date" *ngIf="!isGroupNotificationVisible">
            {{notificationItem.date | date: dateFormat}}
        </div>

        <notification-checkbox class="rpc-notification-group-btn" [checked]="!notificationItem.isViewed"
            (cliked)="onViewUnviewNotificationGroup()">
        </notification-checkbox>

    </div>

    <ng-container *ngIf="isGroupNotificationVisible">

        <div class="rpc-notification-group-block"
            *ngFor="let group of notificationItem.groups; trackBy: trackByCreatorNotifications"
            [class.rpc-notification-group-block--read]="group.isViewed">

            <avatar-initials class="rpc-notification-group-block-avatar" [userFullName]="group.creator.fullName"
                [userProfileImageUrl]="group.creator.profileImage">
            </avatar-initials>

            <div class="rpc-notification-group-block-name" [title]="group.creator.fullName">
                {{group.creator.fullName}}
            </div>

            <mat-divider class="rpc-notification-group-block-divider" [vertical]="true"></mat-divider>

            <div class="rpc-notification-group-block-activity">

                <rpc-icon [iconName]="notificationItem.icon"
                    [class]="['rpc-notification-group-block-activity-icon', notificationItem.iconClass != null ? notificationItem.iconClass : '']">
                </rpc-icon>

                <div class="rpc-notification-group-block-activity-text">{{group.notifications.length}}</div>

            </div>

            <notification-checkbox class="rpc-notification-group-btn" [checked]="!group.isViewed"
                (cliked)="onViewUnviewCreatorNotifications(group)">
            </notification-checkbox>

            <div class="rpc-notification-group-inner"
                [class.rpc-notification-group-inner--many]="group.notifications.length > collapsedGroupNotificationsLength">

                <mat-divider class="rpc-notification-group-inner-divider" [vertical]="true"></mat-divider>

                <div class="rpc-notification-group-list">

                    <div class="rpc-notification-group-list-item" (click)="onNotificationClick(notification)"
                        *ngFor="let notification of group.notifications | slice :0 :getCreatorNotificationCountToShow(group); trackBy: trackByNotification"
                        [class.rpc-notification-group-list-item--read]="notification.isViewed">

                        <div class="rpc-notification-group-listing"
                            *ngIf="notification.type === NotificationEventEntity.ListingActivity || notification.type === NotificationEventEntity.ExternalListing">

                            <default-image class="rpc-notification-group-listing-img"
                                [image]="(notification.data | cast: ListingNotification).listingUrl"
                                [useBackgroundImage]="true">
                            </default-image>

                            <div class="rpc-notification-group-address" [title]="notification.listingAddress">
                                {{ notification.listingAddress }}
                            </div>

                        </div>

                        <div class="rpc-notification-group-date">{{notification.dateCreated | date: dateFormat}}</div>

                        <notification-checkbox class="rpc-notification-group-btn" [checked]="!notification.isViewed"
                            (cliked)="onViewUnviewNotification(notification)">
                        </notification-checkbox>
                    </div>

                </div>

                <rpc-button class="rpc-notification-group-inner-btn"
                    *ngIf="group.notifications.length > collapsedGroupNotificationsLength" [color]="'accent'"
                    [title]="isCreatorsGroupCollapsed(group) ? 'NOTIFICATION_GROUP.TITLES.SHOW_MORE' : 'NOTIFICATION_GROUP.TITLES.SHOW_LESS'"
                    (clicked)="onShowHideMore(group)">
                </rpc-button>

            </div>

        </div>

    </ng-container>

</div>