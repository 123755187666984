import { Injectable } from '@angular/core';
import { switchMap, EMPTY } from 'rxjs';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

import { CreateNotificationApiService } from '../services/create-notification-api.service';
import { UserStoreService } from '@auth/store/services/user-store.service';
import * as foldersActions from '@folders/store/actions/folders.actions';
import * as listingActivityActions from '@listings/store/actions/listing-activity.actions';
import { ACTIVITY_NOTIFICATION_ACTION_MAP } from '@notifications/constants/graphql-types-map.constants';
import { ListingHelperService } from '@listings/services/listing-helper.service';
import { ListingActivityConstants } from '@listings/constants/listing-activity-constants';
import { ListingActivityHelper } from '@listings/services/listing-activity.helper';
import { ListingsStoreService } from '@listings/store/services/listings-store.service';
import { FoldersService } from '@folders/store/services/folders.service';

@Injectable()
export class CreateListingActivityNotificationEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly createNotificationApiService: CreateNotificationApiService,
        private readonly userStoreService: UserStoreService,
        private readonly listingsStoreService: ListingsStoreService,
    ) { }

    public readonly createListingAddedToPortfolioViaFolderManagerNotification$ = createEffect(() => this.actions$.pipe(
        ofType(foldersActions.manageListingsFoldersSuccess),
        concatLatestFrom(() => [
            this.listingsStoreService.getListings(),
            this.listingsStoreService.resentlyViewedListings$,
            this.userStoreService.customerCollaborationId$]),
        switchMap(([{ models, activitiesSet }, allListings, resentlyViewedListings, collaborationId]) => {
            const listingsToAdd = ListingActivityHelper.getActivityListingsCandidates(
                FoldersService.getListingsPickCandidatesHashCodes(models), allListings, resentlyViewedListings);

            if (listingsToAdd.length === 0) {
                return EMPTY;
            }

            const newListingActivityNotification = {
                agentListingPinIds: activitiesSet.map(x => x.agentListingPinId),
                collaborationId,
                action: ACTIVITY_NOTIFICATION_ACTION_MAP[ListingActivityConstants.PickListed.id],
                date: new Date()
            };

            return this.createNotificationApiService.createNewListingActivityNotification(newListingActivityNotification);
        })
    ));

    public readonly createChangeReactionNotification$ = createEffect(() => this.actions$.pipe(
        ofType(listingActivityActions.setListingsActivitySuccess),
        concatLatestFrom(() => this.userStoreService.customerCollaborationId$),
        switchMap(([{ request, activitiesSet }, collaborationId]) => {
            const activity = ListingHelperService.mapActivityToListingActivityType(request.activity.id);

            if (activity == null) {
                return EMPTY;
            }

            const agentListingPinIds = activitiesSet.map(x => x.agentListingPinId);
            const commonInput = { agentListingPinIds, collaborationId, date: new Date() };
            const action = ACTIVITY_NOTIFICATION_ACTION_MAP[request.activity.id];

            return request.isReactionRemoved
                ? this.createNotificationApiService.createListingActivityRemovedNotification(commonInput)
                : request.activity.id === ListingActivityConstants.PickListed.id
                    ? this.createNotificationApiService.createNewListingActivityNotification({ ...commonInput, action })
                    : this.createNotificationApiService.createListingActivityChangedNotification({ ...commonInput, action });
        })
    ));
}