import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { filter, Observable, of, switchMap, take } from 'rxjs';

import { RpcRoute } from '@core-layout/app/models/rpc-route';
import { RouteService } from '@core-layout/app/services/route.service';
import { INITIAL_DEFAULT_FOLDER_ID } from '@folders/constants/folder.constants';
import { SettingsStoreService } from '@settings/store/services/settings-store.service';
import { MyListingsStoreService } from '@my-listings/store/services/my-listings-store.service';
import { InitialFolderStateMode } from '@folders/enums/initial-folder-state-mode.enum';

@Injectable({ providedIn: 'root' })
export class MyListingsGuard implements CanActivate, CanActivateChild {
    constructor(
        private readonly routeService: RouteService,
        private readonly settingsStoreService: SettingsStoreService,
        private readonly myListingsStoreService: MyListingsStoreService,
    ) { }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivateRoute(childRoute, state);
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivateRoute(route, state);
    }

    private canActivateRoute(route: ActivatedRouteSnapshot, _: RouterStateSnapshot): Observable<boolean> {
        const params = route.queryParams;
        const state = this.routeService.getNavigationState<{ processDefaultFolder: boolean | null }>();

        if (params?.tab != null) {
            this.routeService.navigate(RpcRoute.Portfolio).catch(() => { });

            return of(false);
        } else if (route.firstChild?.routeConfig?.path !== '') {
            return of(true);
        }

        return state?.processDefaultFolder === false ? of(true) : this.handlePortfolioRoute();
    }

    private handlePortfolioRoute(): Observable<boolean> {
        return this.settingsStoreService.defaultFolderId$.pipe(
            filter(defaultFolderId => defaultFolderId !== INITIAL_DEFAULT_FOLDER_ID),
            take(1),
            switchMap(defaultFolderId => {
                if (defaultFolderId != null) {
                    this.myListingsStoreService.navigateToFolder(defaultFolderId, InitialFolderStateMode.Default);

                    return of(false);
                }

                return of(true);
            })
        );
    }
}