import { createAction, props } from '@ngrx/store';
import { ApiNote } from '@notes/models/api-note';

import { Note } from '@notes/models/note';

export const loadNotes = createAction('[Notes] Load Notes');
export const loadNotesSuccess = createAction('[Notes] Load Notes Success', props<{ notes: ApiNote[] }>());
export const loadNotesFailed = createAction('[Notes] Load Notes Failed');

export const loadNote = createAction('[Notes] Load Note', props<{ listingHashCode: number }>());
export const loadNoteSuccess = createAction('[Notes] Load Note Success', props<{ note: ApiNote }>());
export const loadNoteFailed = createAction('[Notes] Load Note Failed');

export const createNote = createAction('[Notes] Create Note', props<{ listingHashCode: number, note: string }>());
export const createNoteSuccess = createAction('[Notes] Create Note Success', props<{ note: Note }>());
export const createNoteFailed = createAction('[Notes] Create Note Failed', props<{ notes: Record<number, Note> }>());

export const updateNote = createAction('[Notes] Update Note', props<{ note: Note }>());
export const updateNoteSuccess = createAction('[Notes] Update Note Success');
export const updateNoteFailed = createAction('[Notes] Update Note Failed', props<{ notes: Record<number, Note> }>());

export const deleteNote = createAction('[Notes] Delete Note', props<{ listingHashCodes: number[] }>());
export const deleteNoteSuccess = createAction('[Notes] Delete Note Success');
export const deleteNoteFailed = createAction('[Notes] Delete Note Failed', props<{ notes: Record<number, Note> }>());

export const openAddEditNoteDialog = createAction('[Notes] Open Add Edit Note Dialog', props<{ listingHashCode: number, listingAddress: string }>());

export const setNotes = createAction('[Notes] Set Notes', props<{ notes: Record<number, Note> }>());