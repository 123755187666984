export const locale = {
    lang: 'en',
    data: {
        ONBOARDING_DIALOG: {
            BUTTONS: {
                CLOSE: 'Close',
                EXPLORE: 'Explore',
                PREVIOUS: 'Previous',
                NEXT: 'Next'
            },
            TEXT: {
                WHATS_NEW: 'What\'s new?',
            }
        }
    }
};
