import { Injectable } from '@angular/core';

import { ErrorHandlerBase } from './error.handler.base';

@Injectable({ providedIn: 'root' })
export class ChunksErrorHandler implements ErrorHandlerBase {

    public isApplicable(error: Error): boolean {
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;

        if (error != null && error.message != null && chunkFailedMessage.test(error.message)) {
            return true;
        }

        return false;
    }

    public handleError(error: Error): void {
        if (this.isApplicable(error)) {
            window.location.reload();
        }
    }
}