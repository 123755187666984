import { ListingsActivityInfo } from '@listings/models/listing/listings-activity-info';

export class ListingViewHelper {
    public static setIsViewed(listingsActivities: Record<number, ListingsActivityInfo>, listingHashCode: number, isViewed: boolean): Record<number, ListingsActivityInfo> {
        return Object.entries(listingsActivities).reduce(
            (acc, [hashcode, activitiesInfo]) => {
                return { ...acc, [hashcode]: { ...activitiesInfo, isNewViewed: listingHashCode === +hashcode ? isViewed : activitiesInfo.isNewViewed } };
            },
            {} as Record<number, ListingsActivityInfo>
        );
    }
}