import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { AppointmentStatus } from '@appointments/enums/appointment-status.enum';
import { AppointmentActionOptions } from '@appointments/store/models/appointment-action-options';
import { AppointmentStatusDetails } from '@appointments/store/models/appointment-status-details';
import { AppointmentActionService } from '@appointments/store/services/appointment-action.service';
import { AppointmentStatusService } from '@appointments/store/services/appointment-status.service';
import { DateConstants } from '@core-constants/dates/date.constants';
import { NotificationEventAction } from '@notifications/enums/notification-event-action';
import { AppointmentNotification } from '@notifications/models/notification-data';
import { NotificationNavigateParams } from '@notifications/models/notification-navigate-params';

@Component({
    selector: 'appointment-notification',
    templateUrl: './appointment-notification.component.html',
    styleUrls: ['./appointment-notification.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppointmentNotificationComponent {

    @Input() public set appointment(value: AppointmentNotification) {
        const { customerStatus, agentStatus, creator, createId, updateId, customerId, agentId } = value;
        const appointmentActionOptionsParams = { customerStatus, agentStatus, creator, createId, updateId, viewed: false };

        this.appointmentNotification = value;
        this.status = AppointmentStatusService.calculateTimeDependentStatus(value);
        this.statusDetails = AppointmentStatusService.getStatusDetails(value, this.status);
        this.actionOptions = AppointmentActionService.getAppointmentActionOptions(appointmentActionOptionsParams, customerId, agentId, false, this.status);
    }

    @Input() public listingAddress: string;
    @Input() public notificationId: number;
    @Input() public notificationAction: NotificationEventAction;

    @Output() public navigateToNotificationEntity = new EventEmitter<NotificationNavigateParams>();
    @Output() public statusChanged = new EventEmitter<AppointmentStatus>();

    public appointmentNotification: AppointmentNotification;
    public status: AppointmentStatus;
    public statusDetails: AppointmentStatusDetails;
    public actionOptions: AppointmentActionOptions;

    public readonly DateConstants = DateConstants;
    public readonly NotificationEventAction = NotificationEventAction;

    public readonly appointmentStatusAttributes = new Map<AppointmentStatus, { title: string, icon: string, class: string }>([
        [AppointmentStatus.Confirmed, { title: 'APPOINTMENT_NOTIFICATION.TITLES.CONFIRMED', icon: 'check', class: 'rpc-notification-appointment-status--confirmed' }],
        [AppointmentStatus.Declined, { title: 'APPOINTMENT_NOTIFICATION.TITLES.DECLINED', icon: 'decline', class: 'rpc-notification-appointment-status--declined' }],
        [AppointmentStatus.Shown, { title: 'APPOINTMENT_NOTIFICATION.TITLES.SHOWN', icon: 'eye', class: 'rpc-notification-appointment-status--shown' }],
    ]);

    public onDecline(event: MouseEvent): void {
        event.stopPropagation();
        this.statusChanged.emit(AppointmentStatus.Declined);
    }

    public onAccept(event: MouseEvent): void {
        event.stopPropagation();
        this.statusChanged.emit(AppointmentStatus.Confirmed);
    }

    public onAppointmentClick(goToAll: boolean): void {
        this.navigateToNotificationEntity.emit({ notificationId: this.notificationId, goToAll });
    }
}