import { createAction, props } from '@ngrx/store';

import { SortOptionBase } from '@core-models/sort/sort-option';
import { InitialFolderStateMode } from '@folders/enums/initial-folder-state-mode.enum';
import { GalleryViewTypes } from '@gallery-view/enums/gallery-view-types';
import { MyListingsFilterOptions } from '@my-listings/models/filter/my-listings-filter-options';
import { FoldersSettings } from '@settings/models/settings/folders-settings';
import { ViewModes } from '@view-base/models/view-modes.enum';
import { HiddenChangedEventParameters } from '@core-directives/hideable/hidden-changed-event-parameters';

export const setSort = createAction(
    '[My Listings Page] Set Sort',
    props<{ sort: SortOptionBase }>()
);

export const setExternalListingsSort = createAction(
    '[My Listings Page] Set External Listings Sort',
    props<{ sort: SortOptionBase }>()
);

export const setFilter = createAction(
    '[My Listings Page] Set Filter',
    props<{ filter: MyListingsFilterOptions }>()
);

export const setSelectedUsersFilter = createAction(
    '[My Listings Page] Set Selected Users Filter',
    props<{ selectedUsersFilter: number[] }>()
);

export const setViewMode = createAction(
    '[My Listings Page] Set View Mode',
    props<{ viewMode: ViewModes }>()
);

export const setMapSizePercent = createAction(
    '[My Listings Page] Set Map Size Percent',
    props<{ mapSizePercent: number }>()
);

export const resetSavedView = createAction(
    '[My Listings Page] Reset Saved View',
    props<{ folderSettings: FoldersSettings }>()
);

export const setViewType = createAction(
    '[My Listings Page] Set View Type',
    props<{ viewType: GalleryViewTypes }>()
);

export const load = createAction('[My Listings Page] Load');

export const setInitialFolderStateMode = createAction(
    '[My Listings Page] Set Initial Folder State Mode',
    props<{ initialFolderStateMode?: InitialFolderStateMode }>()
);

export const applyInitialFolderStateMode = createAction('[My Listings Page] Apply Initial Folder State Mode');

export const resetState = createAction('[My Listings Page] Reset State');

export const initializeInsideFolderView = createAction('[My Listings Page] Initialize Inside Folder View', props<{ folderId: number | null }>());

export const changeFilterParamsVisibility = createAction(
    '[My Listings Page] Change Filter Parameters Visibility',
    props<{ visibleFilterParams: HiddenChangedEventParameters[] }>()
); 