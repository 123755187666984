<div class="fad-wrap">
    <div class="fad-title-wrap">
        <div class="fad-title">{{ 'FOLDER_ATTACHMENT_DIALOG.TITLES.ADD_TO' | translate }}...</div>
        <rpc-icon-button class="fad-title-close" [iconName]="'cross'" [title]="'FOLDER_ATTACHMENT_DIALOG.BUTTONS.CLOSE'" [mat-dialog-close]="false"
            (click)='onClose()'>
        </rpc-icon-button>
    </div>
    <div class="fad-body">
        <rpc-button class="fad-item" [iconName]="'folder'" [title]="data.folder.name"
            (clicked)="onFolderClicked()" [shouldTranslate]="false"></rpc-button>

        <rpc-button class="fad-item" [iconName]="'folder'" [title]="'FOLDER_ATTACHMENT_DIALOG.TITLES.OTHER_FOLDERS'"
            (clicked)="onOtherFoldersClicked()"></rpc-button>

    </div>
</div>