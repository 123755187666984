import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import isEqual from 'lodash-es/isEqual';
import { Action } from '@ngrx/store';

import { CustomerActivityService } from '@customer-activity/customer-activity.service';
import { ListingCategories } from '@listings/enums/listing-categories.enum';
import { ListingCategoryTexts } from '@listings/enums/listing-category-texts.enum';
import { ListingSearchOptions } from '@listings/models/search/listing-search-options';
import * as listingActions from '@listings/store/actions/listings.actions';
import { GoogleAnalyticsStoreService } from '@core-layout/app/store/services/google-analytics-store.service';
import { GoogleAnalyticsEventName } from 'app/modules/core-modules/enums/google-analytics-event-name';
import { OnMarketStateHelper } from '@on-market/store/states/on-market.state.helper';
import { OmniSearchService } from '../services/omni-search.service';
import { SearchStoreService } from '../services/search-store.service';
import * as searchCriteriaActions from '../actions/search-criteria.actions';
import { SearchApiService } from '../services/search-api.service';
import * as neighborhoodActions from '../actions/neighborhood.actions';
import { SearchRunMode } from '@search/enums/search-run-mode.enum';

@Injectable()
export class SearchCriteriaEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly customerActivityService: CustomerActivityService,
        private readonly googleAnalyticsStoreService: GoogleAnalyticsStoreService,
        private readonly searchStoreService: SearchStoreService,
        private readonly searchApiService: SearchApiService,
    ) { }

    public readonly loadNeighborhoodProperties$ = createEffect(() => this.actions$.pipe(
        ofType(neighborhoodActions.loadNeighborhoodProperties),
        concatLatestFrom(() => this.searchStoreService.neighborhoodProperties$),
        filter(([, neighborhoodProperties]) => neighborhoodProperties.length === 0),
        switchMap(() => this.searchApiService.loadNeighborhoodProperties())
    ));

    public readonly changeSearchOption$ = createEffect(() => this.actions$.pipe(
        ofType(searchCriteriaActions.changeSearchOptions),
        concatLatestFrom(() => [this.searchStoreService.searchOptions$, this.searchStoreService.searchRunMode$]),
        switchMap(([{ searchOptions }, oldSearchoptions, searchRunMode]) => {
            const newSearchOptions = { ...oldSearchoptions, ...searchOptions };
            const { categoryId } = newSearchOptions;

            const isDefault = categoryId != null
                ? isEqual(newSearchOptions, OnMarketStateHelper.createDefaultSearchOptions(categoryId))
                : false;

            if (!isDefault && (categoryId == null || categoryId === oldSearchoptions.categoryId)) {
                return [
                    searchCriteriaActions.setSearchOptions({ searchOptions: newSearchOptions }),
                    ...(searchRunMode === SearchRunMode.OnCriteriaChanged ? [searchCriteriaActions.search({ searchOptions: newSearchOptions, shouldSetLoading: true })] : []),
                ];
            }

            return [
                searchCriteriaActions.clearSearchCriteria({ categoryId }),
                searchCriteriaActions.setPreviousSearchOptionsValue({ searchOptions: OnMarketStateHelper.createDefaultSearchOptions(categoryId) })
            ];
        })
    ));

    public readonly search$ = createEffect(() => this.actions$.pipe(
        ofType(searchCriteriaActions.search),
        concatLatestFrom(() => this.searchStoreService.searchOptions$),
        map(([{ searchOptions, type, ...other }, currentSearchOptions]) => ({ ...other, searchOptions: searchOptions ?? currentSearchOptions })),
        filter(({ searchOptions, canRunWithEmptyCriteria }) => {
            return (canRunWithEmptyCriteria ?? false) || !isEqual(searchOptions, OnMarketStateHelper.createDefaultSearchOptions(searchOptions.categoryId));
        }),
        switchMap(({ searchOptions, shouldSetLoading }) => {

            const actions: Action[] = [];

            if (shouldSetLoading) {
                actions.push(listingActions.setMarketListingsLoaded({ isLoaded: false }));
            }

            actions.push(listingActions.loadMarketListings({ searchOptions }), listingActions.cleanupListingsMedia());

            return actions;
        })
    ));

    public readonly setMapShapesSearchOptions$ = createEffect(() => this.actions$.pipe(
        ofType(searchCriteriaActions.setMapShapesSearchOptions),
        concatLatestFrom(() => this.searchStoreService.searchOptions$),
        map(([{ mapOptions }, currentSearchOptions]) => {
            const searchOptions: ListingSearchOptions = { ...currentSearchOptions, mapShapesSearchOptions: mapOptions };
            const { circles, shapes } = currentSearchOptions.mapShapesSearchOptions;

            if (mapOptions.circles.length + mapOptions.shapes.length > (circles.length + shapes.length)) {
                this.googleAnalyticsStoreService.addEvent(GoogleAnalyticsEventName.Map_Draw);
            }

            return searchCriteriaActions.changeSearchOptions({ searchOptions });
        }),
    ));

    public readonly setSelectedMapNeighborhoods$ = createEffect(() => this.actions$.pipe(
        ofType(searchCriteriaActions.setSelectedMapNeighborhoods),
        concatLatestFrom(() => [this.searchStoreService.searchOptions$, this.searchStoreService.neighborhoodProperties$]),
        map(([{ selectedNeighborhoodIds }, currentSearchOptions, neighborhoodProperties]) => {

            const patternSearchResult = OmniSearchService.getPatternByOptions(neighborhoodProperties, selectedNeighborhoodIds, currentSearchOptions.patternSearchResult);

            const searchOptions: ListingSearchOptions = {
                ...currentSearchOptions,
                neighborhoodIds: selectedNeighborhoodIds,
                patternSearchResult
            };

            if (selectedNeighborhoodIds.length > currentSearchOptions.neighborhoodIds.length) {
                this.googleAnalyticsStoreService.addEvent(GoogleAnalyticsEventName.Map_SelectNeighborhood);
            }

            return searchCriteriaActions.changeSearchOptions({ searchOptions });
        }),
    ));

    public readonly addCustomerSearchActivities$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(searchCriteriaActions.addCustomerSearchActivities),
                switchMap(({ categoryId, fields }) => {
                    if (fields == null || fields.length === 0) {
                        return EMPTY;
                    }

                    const category = categoryId === ListingCategories.Sales ? ListingCategoryTexts.Sales : ListingCategoryTexts.Rental;

                    return this.customerActivityService.addSearchActivity(category, fields);
                })
            ),
        { dispatch: false }
    );

    public readonly clearSearchCriteria$ = createEffect(() => this.actions$.pipe(
        ofType(searchCriteriaActions.clearSearchCriteria),
        concatLatestFrom(() => this.searchStoreService.searchOptions$),
        switchMap(([{ categoryId }, currentSearchOptions]) => {
            const searchOptions = OnMarketStateHelper.createDefaultSearchOptions(categoryId ?? currentSearchOptions.categoryId);

            return isEqual(searchOptions, currentSearchOptions) ? EMPTY : [searchCriteriaActions.setSearchOptions({ searchOptions })];
        }),
    ));
}