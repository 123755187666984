
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import partition from 'lodash-es/partition';

import { ListingActivityType } from '@customer-activity/enums/listing-activity-type.enum';
import { ListingActivityConstants } from '@listings/constants/listing-activity-constants';
import { SetListingActivitiesRequest, SetListingActivityRequest } from '@listings/models/api/set-listing-activity-request';
import * as listingActivityActions from '../actions/listing-activity.actions';
import { GoogleAnalyticsStoreService } from '@core-layout/app/store/services/google-analytics-store.service';

@Injectable({ providedIn: 'root' })
export class ListingActivityStoreService {

    constructor(
        private readonly store: Store,
        private readonly googleAnalyticsStoreService: GoogleAnalyticsStoreService,
    ) { }

    public setListingsActivity(activityRequest: SetListingActivityRequest, customerId: number, agentId: number): void {
        const [newMatchListings, commonListings] = partition(activityRequest.listingCandidates, x => x.isNewMatch);

        const correctedActivityRequest: SetListingActivityRequest = {
            listingCandidates: [],
            activity: activityRequest.activity
        };

        const activityReturnToPickListRequest: SetListingActivityRequest = {
            listingCandidates: [],
            activity: ListingActivityConstants.PickListed,
            isReactionRemoved: true
        };

        if (newMatchListings.length > 0) {
            this.store.dispatch(listingActivityActions.setListingsActivity({
                request: { listingCandidates: newMatchListings, activity: activityRequest.activity },
                customerId,
                agentId
            }));
        }

        commonListings.forEach(candidate => {
            if (candidate.activities.some(activity => activity.id === activityRequest.activity.id &&
                activityRequest.activity.id !== ListingActivityConstants.PickListed.id &&
                activityRequest.activity.id !== ListingActivityConstants.Shown.id)) {
                activityReturnToPickListRequest.listingCandidates.push(candidate);
            }
        });

        commonListings.forEach(candidate => {
            if (candidate.activities.length > 1 && candidate.activities.every(activity => activity.id !== activityRequest.activity.id
                || activity.id === ListingActivityConstants.Shown.id)) {
                correctedActivityRequest.listingCandidates.push(candidate);
            } else if (candidate.activities.every(activity => activity.id !== activityRequest.activity.id)) {
                correctedActivityRequest.listingCandidates.push(candidate);
            }
        });

        if (correctedActivityRequest.listingCandidates.length > 0) {
            this.store.dispatch(listingActivityActions.setListingsActivity({
                request: correctedActivityRequest,
                customerId,
                agentId
            }));

            this.googleAnalyticsStoreService.addListingReactionEvent(correctedActivityRequest.activity.id);
        }

        if (activityReturnToPickListRequest.listingCandidates.length > 0) {
            this.store.dispatch(listingActivityActions.setListingsActivity({
                request: activityReturnToPickListRequest,
                customerId,
                agentId
            }));
        }
    }

    public setListingsActivities(activityRequest: SetListingActivitiesRequest, customerId: number, agentId: number): void {
        const correctedActivityRequest: SetListingActivitiesRequest = {
            listingCandidates: [],
            activities: activityRequest.activities
        };

        const activityReturnToPickListRequest: SetListingActivityRequest = {
            listingCandidates: [],
            activity: ListingActivityConstants.PickListed,
            isReactionRemoved: true
        };

        const shownActivityRequest: SetListingActivityRequest = {
            listingCandidates: [],
            activity: ListingActivityConstants.Shown,
            isReactionRemoved: true
        };

        activityRequest.listingCandidates.forEach(candidate => {
            const isAllActivitiesMatches = candidate.activities.length > 0 && candidate.activities
                .every(candidateActivity => activityRequest.activities.some(requestActivity => candidateActivity.id === requestActivity.id));

            const isRequestShownActivity = activityRequest.activities.some(candidateActivity => candidateActivity.id === ListingActivityConstants.Shown.id);
            const isCandidatePickListedActivity = candidate.activities.some(candidateActivity => candidateActivity.id === ListingActivityConstants.PickListed.id);

            if (isRequestShownActivity && isCandidatePickListedActivity) {
                shownActivityRequest.listingCandidates.push(candidate);
            } else if (isAllActivitiesMatches && !isCandidatePickListedActivity) {
                activityReturnToPickListRequest.listingCandidates.push(candidate);
            }

            activityRequest.activities.forEach(a => {
                if (candidate.activities.length > 1 && candidate.activities.every(activity => activity.id !== a.id)) {
                    correctedActivityRequest.listingCandidates.push(candidate);
                }
                else if (candidate.activities.every(activity => activity.id !== a.id)) {
                    correctedActivityRequest.listingCandidates.push(candidate);
                }
            });
        });

        if (correctedActivityRequest.listingCandidates.length > 0 && correctedActivityRequest.activities.length === 1) {
            this.store.dispatch(listingActivityActions.setListingsActivity({
                request: { listingCandidates: activityRequest.listingCandidates, activity: activityRequest.activities[0] },
                customerId,
                agentId
            }));

            this.googleAnalyticsStoreService.addListingReactionEvent(activityRequest.activities[0].id);
        }

        if (activityReturnToPickListRequest.listingCandidates.length > 0) {
            this.store.dispatch(listingActivityActions.setListingsActivity({
                request: activityReturnToPickListRequest,
                customerId,
                agentId
            }));
        }

        if (shownActivityRequest.listingCandidates.length > 0) {
            this.store.dispatch(listingActivityActions.setListingsActivity({
                request: shownActivityRequest,
                customerId,
                agentId
            }));
        }
    }

    public addListingSessionActivity(listingActivityType: ListingActivityType, listingCategory: { listingId: string, category: string }[]): void {
        this.store.dispatch(listingActivityActions.addListingSessionActivity({ listingActivityType, listingCategory }));
    }
}