import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { SimpleChanges } from '@core-models/utilities/generic-simple-changes';
import { FolderManagementAction } from '@folders/enums/folder-management-action.enum';
import { Folder } from '@folders/models/folder';
import { ListingFolderManagerInfo } from '@folders/models/folder-management/listing-folder-manager-info';
import { IManageFolderModel } from '@folders/models/manage-folder-model';

@Component({
    selector: 'folder-selection',
    templateUrl: './folder-selection.component.html',
    styleUrls: ['./folder-selection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FolderSelectionComponent implements OnInit, OnChanges {
    @Input() public listings: ListingFolderManagerInfo[];
    @Input() public folder: Folder;
    @Input() public isEditModeEnabled: boolean;
    @Input() public isEditButtonHidden: boolean;

    @Output() public editClicked = new EventEmitter<number>();
    @Output() public cancelClicked = new EventEmitter<number>();
    @Output() public renameClicked = new EventEmitter<IManageFolderModel>();

    public areAllListingsInFolder?: boolean;
    public areSomeListingsInFolder?: boolean;
    public action: FolderManagementAction = FolderManagementAction.Keep;
    public isFolderInEditMode: boolean;
    public checkboxState: RpcCheckboxState;

    public ngOnInit(): void {
        this.checkboxState = this.areAllListingsInFolder ? 'checked' : this.areSomeListingsInFolder ? 'indeterminate' : 'unchecked';
    }

    public ngOnChanges(changes: SimpleChanges<FolderSelectionComponent>): void {
        if (changes.listings?.currentValue == null) {
            return;
        }

        const folder = changes.folder?.currentValue ?? this.folder;

        const allListingsInFolder = changes.listings.currentValue.every(({ foldersIds }) => foldersIds.includes(folder.id));
        const someListingsInFolder = !allListingsInFolder
            && changes.listings.currentValue.some(({ foldersIds }) => foldersIds.includes(folder.id));

        this.updateCheckboxStateOnChanges(allListingsInFolder, someListingsInFolder);

        this.areAllListingsInFolder = allListingsInFolder;
        this.areSomeListingsInFolder = someListingsInFolder;
    }

    public onCheckboxChange(checkboxState: RpcCheckboxState): void {
        this.checkboxState = checkboxState;
        this.updateAction();
    }

    public onEditClicked(): void {
        this.editClicked.emit(this.folder.id);
    }

    public onCancelClicked(): void {
        this.cancelClicked.emit(this.folder.id);
    }

    public onRenameClicked(model: IManageFolderModel): void {
        this.renameClicked.emit(model);
    }

    private updateCheckboxStateOnChanges(allListingsInFolder: boolean, someListingsInFolder: boolean): void {
        if (this.action !== FolderManagementAction.Keep) {
            return;
        }

        if (this.areAllListingsInFolder != null && this.areAllListingsInFolder !== allListingsInFolder) {
            this.checkboxState = 'checked';
            return;
        }

        if (this.areSomeListingsInFolder != null && this.areSomeListingsInFolder !== someListingsInFolder) {
            this.checkboxState = someListingsInFolder ? 'indeterminate' : 'unchecked';
        }
    }

    private updateAction(): void {
        if (this.checkboxState === 'indeterminate') {
            this.action = this.areSomeListingsInFolder
                ? FolderManagementAction.Keep
                : this.areAllListingsInFolder
                    ? FolderManagementAction.Detach
                    : FolderManagementAction.Attach;
            return;
        }

        if (this.checkboxState === 'checked') {
            this.action = !this.areAllListingsInFolder ? FolderManagementAction.Attach : FolderManagementAction.Keep;
            return;
        }

        this.action = this.areAllListingsInFolder || this.areSomeListingsInFolder
            ? FolderManagementAction.Detach
            : FolderManagementAction.Keep;
    }
}
