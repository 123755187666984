import { CurrencyPipe } from '@angular/common';
import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { CurrencyConstants } from '@core-constants/currency/currency.constants';
import { NON_DIGITS_PATTERN, NON_DIGITS_WITH_DOT_PATTERN } from '@core-constants/regular-expressions/regular-expressions.constants';
import { RpcInputComponent } from '@core-controls/components/rpc-input/rpc-input.component';

@Directive({
    selector: '[formatCurrency]'
})
export class FormatCurrencyDirective implements OnInit, OnDestroy {

    @Input() public readonly isTransform: boolean = true;
    @Input() public readonly exceptionValues: string[] = [];
    @Input() public readonly useFraction: boolean = true;

    private readonly element: HTMLElement;
    private previousValue: string;
    private subscription$: Subscription;
    private readonly commaPattern = /,/g;

    constructor(
        private readonly elementRef: ElementRef<HTMLElement>,
        private readonly currencyPipe: CurrencyPipe,
        private readonly rpcInput: RpcInputComponent
    ) {
        this.element = this.elementRef.nativeElement;
    }

    public ngOnInit(): void {
        this.subscription$ = this.rpcInput.formControl.valueChanges.subscribe((value: string | number) => this.transform(value));
    }

    public ngOnDestroy(): void {
        this.subscription$.unsubscribe();
    }

    private transform(value: string | number): void {
        if (!this.isTransform || value == null || value === '' || value != null && this.exceptionValues.includes(value.toString())) {
            this.previousValue = null;

            return;
        }

        const inputElement = (this.element.getElementsByTagName('input')[0]) as HTMLInputElement;

        const cursorPosition = inputElement?.selectionStart;

        let amount: string = null;

        if (this.useFraction) {
            const amountWithCorrectDot = this.correctDotPosition(value.toString(), cursorPosition);

            amount = amountWithCorrectDot.replace(NON_DIGITS_WITH_DOT_PATTERN, '');
        } else {
            amount = value.toString().replace(NON_DIGITS_PATTERN, '');
        }

        const currentValueFormatted = this.currencyPipe.transform(
            amount,
            CurrencyConstants.Codes.USD,
            CurrencyConstants.Symbols.USD,
            this.useFraction ? CurrencyConstants.Formats.TwoDigitsFraction : CurrencyConstants.Formats.WithoutFraction,
            'en');

        this.rpcInput.formControl.setValue(currentValueFormatted, { emitEvent: false });

        const newCursorPosition = this.getNewCursorPosition(cursorPosition, currentValueFormatted);

        setTimeout(() => inputElement.setSelectionRange(newCursorPosition, newCursorPosition));

        this.previousValue = currentValueFormatted;
    }

    // eslint-disable-next-line complexity
    private getNewCursorPosition(currentPosition: number, currentValue: string): number {
        const minimumFractionValueLength = 5;
        const minimumNonFractionValueLength = 2;
        const commasCountPrevious = this.previousValue?.match(this.commaPattern)?.length ?? 0;
        const commasCountCurrent = currentValue?.match(this.commaPattern)?.length ?? 0;

        if (commasCountPrevious > commasCountCurrent) {
            // leave cursor on first position when first digit removed (e.g. $7|,324,536.00 -> $|324,536.00, where | - cursor)
            // leave cursor on first position when valuew with commas replaced (e.g. $7|,324,536.00 -> $2|.00)
            return currentPosition === 1
                ? (this.useFraction && currentValue.length === minimumFractionValueLength) || (!this.useFraction && currentValue.length === minimumNonFractionValueLength)
                    ? currentPosition + 1
                    : currentPosition
                : currentPosition - (commasCountPrevious - commasCountCurrent);
        } else if (commasCountPrevious < commasCountCurrent) {
            return currentPosition + commasCountCurrent - commasCountPrevious;
        }

        return this.previousValue == null
            || (this.useFraction && currentValue.length === minimumFractionValueLength)
            || (!this.useFraction && currentValue.length === minimumNonFractionValueLength)
            ? currentPosition + 1
            : currentPosition;

    }

    private correctDotPosition(value: string, currentPosition: number): string {
        const charachters = [...value];
        const currentDotCount = charachters.filter(x => x === '.').length;
        const isValueFormatted = value.startsWith('$');

        if (!isValueFormatted || currentDotCount === 1) {
            return value;
        }

        const previousDotIndex = this.previousValue.indexOf('.');

        // user drop dot, restore it from previous
        if (currentDotCount === 0) {
            charachters.splice(previousDotIndex, 0, '.');
        } else {
            const isNewDotPositionGreaterThanCorrect = (currentPosition - 1) > previousDotIndex;

            const index = isNewDotPositionGreaterThanCorrect ? value.lastIndexOf('.') : value.indexOf('.');

            charachters.splice(index, 1);
        }

        return charachters.join('');
    }
}