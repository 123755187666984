import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IOnboardingState } from '../states/onboarding.state';

const onboardingStateSelector = createFeatureSelector<IOnboardingState>('onboarding');

export const selectItemToDisplay = createSelector(
    onboardingStateSelector,
    (state) => {
        return state?.data[state?.selectedItemIndex] ?? null;
    }
);

export const selectIsDataLoading = createSelector(
    onboardingStateSelector,
    state => state?.isLoading
);

export const selectSelectedItemIndex = createSelector(
    onboardingStateSelector,
    state => state?.selectedItemIndex
);

export const selectOnboardingData = createSelector(
    onboardingStateSelector,
    state => state?.data
);

export const selectItemsImages = createSelector(
    selectOnboardingData,
    data => data.map(item => item.imageUrl)
);

export const selectViewedSlides = createSelector(
    selectOnboardingData,
    data => data.filter(item => item.viewed).map(item => item.slideId)
);
