<div class="pai-wrap">

    <avatar-initials class="pai-avatar" [userFullName]="agent.fullName" [userProfileImageUrl]="agent.profilePictureUrl">
    </avatar-initials>

    <div class="pai-box">
        <div class="pai-name text-truncate" [title]="agent.fullName">{{agent.fullName}}</div>
        <div class="pai-company text-truncate" [title]="agent.companyName">{{agent.companyName}}</div>
        <div class="pai-title">
            <div class="pai-title-text text-truncate" *ngIf="!agent.dosTitle && agent.executiveTitle"
                [title]="agent.executiveTitle">
                {{agent.executiveTitle}}
            </div>
            <div class="pai-title-text text-truncate" *ngIf="agent.dosTitle && !agent.executiveTitle"
                [title]="agent.dosTitle">
                {{agent.dosTitle}}
            </div>
            <div class="pai-title-text text-truncate" *ngIf="agent.dosTitle && agent.executiveTitle"
                [title]="agent.dosTitle + ', ' + agent.executiveTitle">
                {{agent.dosTitle}}, {{agent.executiveTitle}}
            </div>
        </div>
    </div>

    <div class="pai-contacts">
        <div class="pai-email">
            <a class="pai-info" href="mailto:{{agent.email}}" matRipple [title]="agent.email">
                <rpc-icon class="pai-info-icon" [iconName]="'email'"></rpc-icon>
                <div class="pai-info-text text-truncate">{{ agent.email }}</div>
            </a>
        </div>
        <div class="pai-tel" *ngIf="agent.phone">
            <a class="pai-info" href="tel:{{agent.phone}}" matRipple>
                <rpc-icon class="pai-info-icon" [iconName]="'phone'"></rpc-icon>
                <div class="pai-info-text">{{ agent.phone }}</div>
            </a>
        </div>
    </div>
</div>