import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from "rxjs";
import { OnboardingData } from '../../models/onboarding-data';
import { OnboardingStoreService } from '../../store/services/onboarding-store.service';
import { RouteService } from '@core-layout/app/services/route.service';
import { RpcRoute } from '@core-layout/app/models/rpc-route';

@Component({
    selector: 'onboarding-dialog',
    templateUrl: './onboarding-dialog.component.html',
    styleUrls: ['./onboarding-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OnboardingDialogComponent implements OnDestroy {

    public onboardingDataItem$: Observable<OnboardingData | null> = this.onboardingStoreService.itemToDisplay$;
    public selectedItemIndex$: Observable<number> = this.onboardingStoreService.selectedItemIndex$;
    public itemsImages$: Observable<string[]> = this.onboardingStoreService.itemsImages$;
    private readonly unsubscribe$ = new Subject<void>();
    constructor(
        private readonly dialogRef: MatDialogRef<OnboardingDialogComponent>,
        private readonly onboardingStoreService: OnboardingStoreService,
        private readonly routeService: RouteService,
    ) { }

    public previous(): void {
        this.onboardingStoreService.setPreviousSelectedIndex();
    }

    public next(): void {
        this.onboardingStoreService.setNextSelectedIndex();
    }

    public redirect(link: RpcRoute): void {
        this.routeService.navigate(link ?? RpcRoute.Portfolio).catch(() => { });
        this.close();
    }

    public close(): void {
        this.dialogRef.close();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();

        this.onboardingStoreService.markAsViewed();
    }
}