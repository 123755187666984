import { ActionCreator, on, ReducerTypes } from '@ngrx/store';

import * as listingActions from '@listings/store/actions/listings.actions';
import { ListingsState } from '../states/listings.state';

export const listingSelectionReducerTypes: ReducerTypes<ListingsState, readonly ActionCreator[]>[] = [
    on(listingActions.selectListing, (state, action): ListingsState => {
        const updatedSelectedListingIds = !state.selectedListingIds.includes(action.id)
            ? [...state.selectedListingIds, action.id]
            : [...state.selectedListingIds];

        return {
            ...state,
            selectedListingIds: updatedSelectedListingIds
        };
    }),
    on(listingActions.unselectListings, (state, action): ListingsState => {
        return {
            ...state,
            selectedListingIds: state.selectedListingIds.filter(id => !action.listingIds.includes(id))
        };
    }),
    on(listingActions.unselectAllListings, (state): ListingsState => {
        return {
            ...state,
            selectedListingIds: []
        };
    }),
    on(listingActions.selectListings, (state, action): ListingsState => {
        const updatedSelectedListingIds = [...state.selectedListingIds];

        action.listingIds
            .filter((listingId) => !state.selectedListingIds.includes(listingId))
            .forEach((listingId) => updatedSelectedListingIds.push(listingId));

        return {
            ...state,
            selectedListingIds: updatedSelectedListingIds
        };
    }),
];