import { SortOptionFirstLevel } from '@core-models/sort/sort-option-first-level';
import { SortOptionSecondLevel } from '@core-models/sort/sort-option-second-level';
import { ListingInfo } from '@listings/models/listing/listing-info';
import { ListingStatusDates } from '@listings/models/listing/listing-status-dates';
import { MyListingsFilterOptions } from '@my-listings/models/filter/my-listings-filter-options';
import { FoldersSettings } from '@settings/models/settings/folders-settings';
import { ViewModes } from '@view-base/models/view-modes.enum';

const folderIdKey: keyof FoldersSettings = 'folderId';

export const DEFAULT_FOLDER_SETTINGS: Omit<FoldersSettings, typeof folderIdKey> = {
    filterSettings: MyListingsFilterOptions.getDefaultFilter(),
    sortSettings: new SortOptionSecondLevel<ListingInfo, ListingStatusDates>('statusDates', 'listedDate'),
    viewMode: ViewModes.gallery,
    mapSizePercent: 25
};

export const ADDED_BY_AGENT_DEFAULT_FOLDER_SETTINGS: Omit<FoldersSettings, typeof folderIdKey> = {
    ...DEFAULT_FOLDER_SETTINGS,
    sortSettings: new SortOptionFirstLevel<ListingInfo>('customerAssignedDate'),
};