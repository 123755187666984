<div id="{{notificationTargetScrollTo}}" class="rpc-notifications"
    *ngLet="isShowOnlyUnviewedNotifications$ | async as isShowOnlyUnviewedNotifications">

    <div class="rpc-notifications-title">{{ 'NOTIFICATIONS.TITLES.NOTIFICATIONS' | translate}}</div>

    <div class="rpc-notifications-clear" matRipple (click)="onMarkAllViewed()"
        *ngIf="(flatNotifications$ | async).length > 0">
        {{ 'NOTIFICATIONS.TITLES.MARK_ALL_AS_READ' | translate}}
    </div>

    <mat-slide-toggle class="rpc-notifications-toogle" [labelPosition]="'after'"
        [checked]="isShowOnlyUnviewedNotifications"
        (change)="onShowOnlyUnviewedFilterChanged(!isShowOnlyUnviewedNotifications)">
        <span class="rpc-notifications-toogle-text">{{ 'NOTIFICATIONS.TITLES.ONLY_SHOW_UNREAD' | translate }}</span>
    </mat-slide-toggle>

    <mat-tab-group class="rpc-notifications-tabs" [selectedIndex]="activeTabIndex$ | async"
        (selectedTabChange)="onSelectedTabChanged($event)" *ngLet="unreadNotificationsCounters$ | async as counters"
        [color]="'accent'" rpcScrollFix>
        <ng-container *ngFor="let tabData of tabsData;">
            <mat-tab>
                <ng-template mat-tab-label>
                    <div [rpcBadge] [rpcBadgeAlert]="counters[tabData.counterKey]">
                        {{ tabData.title | translate }}
                    </div>
                </ng-template>
                <notification-list [notifications]="tabData.notifications$ | async"
                    (navigateToNotificationEntity)="onNavigateToNotificationEntity($event)">
                </notification-list>
            </mat-tab>
        </ng-container>
    </mat-tab-group>
</div>