export const locale = {
    lang: 'en',
    data: {
        MANAGE_FOLDER_FORM: {
            FIELDS: {
                NAME: 'Folder Name',
            },
            ERRORS: {
                NAME_REQUIRED: 'Name is required',
                MAX_LENGTH: 'There is a restriction on the length of the name 255 symbols',
                UNIQUE_NAME: 'A folder by that name is already in use'
            },
            BUTTONS: {
                CREATE: 'Create',
                CANCEL: 'Cancel',
                EDIT: 'Edit',
            },
        }
    }
};
