<div class="rpc-notification-item" (click)="onNotificationClick()"
    [class.rpc-notification-item--read]="notification.isViewed"
    [class.rpc-notification-item--icon]="notification.type !== NotificationEventEntity.Comment"
    [class.rpc-notification-item--appointment]="notification.type === NotificationEventEntity.Appointment">

    <ng-container *ngIf="notification.type === NotificationEventEntity.Comment;else iconTemplate">

        <avatar-initials class="rpc-notification-item-avatar" [userFullName]="notification.creator.fullName"
            [userProfileImageUrl]="notification.creator.profileImage">
        </avatar-initials>

    </ng-container>

    <ng-template #iconTemplate>

        <div class="rpc-notification-item-icon-wrap">

            <rpc-icon class="rpc-notification-item-icon" [iconName]="notification.icon"
                [class]="notification.iconClass ?? ''">
            </rpc-icon>

        </div>

    </ng-template>


    <div class="rpc-notification-item-title" wrapWords>{{ notification.translationInfo.key | translate:
        notification.translationInfo.params}}</div>

    <div class="rpc-notification-item-date">{{ notification.dateCreated | date: dateFormat}}</div>

    <notification-checkbox class="rpc-notification-group-btn" [checked]="!notification.isViewed"
        (cliked)="onToggleNotification()">
    </notification-checkbox>

    <div class="rpc-notification-item-inner"
        *ngIf="notification.type === NotificationEventEntity.ListingActivity || notification.type === NotificationEventEntity.ExternalListing">

        <div class="rpc-notification-item-listing" [title]="notification.listingAddress">

            <default-image class="rpc-notification-item-listing-img"
                [image]="(notification.data | cast: ListingNotification).listingUrl" [useBackgroundImage]="true">
            </default-image>

            <div class="rpc-notification-item-address">{{
                notification.listingAddress }}</div>

        </div>

    </div>

    <div class="rpc-notification-item-inner" *ngIf="notification.type === NotificationEventEntity.Comment">

        <div class="rpc-notification-item-address" [title]="notification.listingAddress">
            {{ notification.listingAddress }}
        </div>

        <div class="rpc-notification-item-comment">{{(notification.data | cast: CommentNotification).text}}</div>

    </div>

    <appointment-notification class="rpc-notification-item-inner"
        *ngIf="notification.type === NotificationEventEntity.Appointment" [listingAddress]="notification.listingAddress"
        [notificationId]="notification.notificationId" [notificationAction]="notification.action"
        [appointment]="(notification.data| cast: AppointmentNotification)"
        (navigateToNotificationEntity)="onNavigateToNotificationEntity($event)"
        (statusChanged)="onAppointmentStatusChanged($event)">
    </appointment-notification>

</div>