import { Injectable } from '@angular/core';

import { ActionError } from '@core-models/errors/action-error';
import { ToastService } from '@core-services/toast.service';
import { FolderActionErrors } from '@folders/enums/folder-action-errors';

@Injectable({ providedIn: 'root' })
export class FolderErrorsService {
    private static readonly FolderActionErrorsLocalizaion = new Map<FolderActionErrors, string>([
        [FolderActionErrors.FolderNameAlreadyExists, 'FOLDERS_COMMON.ERRORS.FOLDER_NAME_EXISTS'],
        [FolderActionErrors.FolderDeletionFailed, 'FOLDERS_COMMON.ERRORS.FOLDER_DELETION_FAILED'],
    ]);

    constructor(
        private readonly toaster: ToastService
    ) { }

    public showError(actionError: ActionError<FolderActionErrors>): void {
        const localizationKey = FolderErrorsService.FolderActionErrorsLocalizaion.get(actionError.error);
        if (localizationKey != null) {
            this.toaster.showClientError(localizationKey);
        }
    }
}