import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

import { RpcInputOptions } from '@core-controls/components/rpc-input/models/rpc-input-options';
import { SimpleChanges } from '@core-models/utilities/generic-simple-changes';
import { ValidationService } from '@core-validation/validation.service';
import { IManageFolderModel } from '@folders/models/manage-folder-model';
import { FoldersStoreReadService } from '@folders/store/services/folders-store-read.service';
import { MAXIMUM_FOLDER_NAME_LENGTH } from './constants/add-edit-folder-form.constants';

@Component({
    selector: 'add-edit-folder-form',
    templateUrl: './add-edit-folder-form.component.html',
    styleUrls: ['./add-edit-folder-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddEditFolderFormComponent implements OnInit, OnChanges, OnDestroy {

    @Input() public name?: string;
    @Input() public id?: number;
    @Input() public submitButtonText: string;
    @Input() public additionalFormClass?: string;

    @Output() public readonly manageFolderClicked = new EventEmitter<IManageFolderModel>();
    @Output() public readonly canceled = new EventEmitter<void>();

    public readonly maximumFolderNameLength = MAXIMUM_FOLDER_NAME_LENGTH;
    public manageFolderFormGroup: FormGroup = new FormGroup({});
    public nameOptions: RpcInputOptions;

    private readonly unsubscribe$ = new Subject<void>();
    private foldersName: string[];

    private get title(): string {
        return this.manageFolderFormGroup.get('name')?.value as string;
    }

    constructor(
        private readonly validationService: ValidationService,
        private readonly foldersStoreReadService: FoldersStoreReadService
    ) { }

    public ngOnInit(): void {
        this.foldersStoreReadService.getAllFoldersNames()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((foldersNames) => {
                this.foldersName = foldersNames.map(name => name.toLowerCase());
            });

        this.initializeForm(this.name);
    }

    public ngOnChanges(changes: SimpleChanges<AddEditFolderFormComponent>): void {
        const { name } = changes;

        if (name?.currentValue != null && name?.currentValue !== this.name) {
            this.initializeForm(name.currentValue);
        }
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public onManageFolderClicked(): void {
        if (this.manageFolderFormGroup.invalid) {
            return;
        }

        this.manageFolderClicked.emit({
            name: this.title,
            id: this.id
        });
    }

    public cancel(): void {
        this.canceled.emit();
    }

    private initializeForm(name: string): void {
        this.nameOptions = {
            controlName: 'name',
            formGroup: this.manageFolderFormGroup,
            defaultValue: name,
            validatorsMap: [
                {
                    message: 'MANAGE_FOLDER_FORM.ERRORS.NAME_REQUIRED',
                    showError: (control: FormControl) => control.hasError('required'),
                    validator: Validators.required
                },
                {
                    message: 'MANAGE_FOLDER_FORM.ERRORS.MAX_LENGTH',
                    showError: (control: FormControl) => control.hasError('maxlength'),
                    validator: Validators.maxLength(this.maximumFolderNameLength)
                },
                {
                    message: 'MANAGE_FOLDER_FORM.ERRORS.UNIQUE_NAME',
                    showError: (control: FormControl) => control.hasError('uniqueValue'),
                    validator: this.validationService.uniqueValuesValidatior(this.foldersName, true)
                }
            ]
        };
    }
}
