import { createReducer, on } from '@ngrx/store';

import * as savedSearchActions from '../actions/saved-search.actions';
import { SavedSearchState } from '../state/saved-search.state';

export const savedSearchReducer = createReducer(
    new SavedSearchState(),
    on(savedSearchActions.loadSavedSearchesSuccess, (state, { savedSearches }) => ({ ...state, savedSearches, activeSavedSearchId: null })),
    on(savedSearchActions.createSavedSearch, savedSearchActions.deleteSavedSearchFailed, (state, { savedSearch }) => {
        return { ...state, savedSearches: [...state.savedSearches, savedSearch] };
    }),
    on(savedSearchActions.createSavedSearchSuccess, (state, { tempId, savedSearchId }) => {
        const savedSearches = state.savedSearches.map(search => search.id === tempId ? { ...search, id: savedSearchId } : search);

        return { ...state, savedSearches, activeSavedSearchId: savedSearchId };
    }),
    on(savedSearchActions.createSavedSearchFailed, savedSearchActions.deleteSavedSearch, (state, { savedSearch }) => ({
        ...state,
        savedSearches: state.savedSearches.filter(search => search.id !== savedSearch.id)
    })),
    on(savedSearchActions.setActiveSavedSearchId, (state, { activeSavedSearchId }) => ({ ...state, activeSavedSearchId })),
    on(savedSearchActions.updateSavedSearch, (state, { currentSavedSearch, updateCriteria }) => ({
        ...state,
        savedSearches: state.savedSearches.map(search => search.id === currentSavedSearch.id ? currentSavedSearch : search),
        activeSavedSearchId: updateCriteria ? currentSavedSearch.id : state.activeSavedSearchId
    })),
    on(savedSearchActions.updateSavedSearchFailed, (state, { savedSearch }) => ({
        ...state,
        savedSearches: state.savedSearches.map(search => search.id === savedSearch.id ? savedSearch : search)
    })),
);