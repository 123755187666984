import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { IRemoveFolderModel } from '@folders/models/remove-folder-model';

@Component({
    templateUrl: './remove-folder-dialog.component.html',
    styleUrls: ['./remove-folder-dialog.component.scss'],
})
export class RemoveFolderDialogComponent {

    public allListingsSelected = false;

    constructor(
        private readonly dialogRef: MatDialogRef<IRemoveFolderModel>,
        @Inject(MAT_DIALOG_DATA) public readonly data: { name: string, id: number, hasListings: boolean },
    ) { }

    public onSelectionChanged(checked: boolean): void {
        this.allListingsSelected = checked;
    }

    public onRemoveFolder(): void {
        this.dialogRef.close({ id: this.data.id, isRemoveListings: this.allListingsSelected });
    }

    public onClose(): void {
        this.dialogRef.close();
    }
}
