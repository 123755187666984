<ng-container *ngFor="let item of timePeriodNotifications; trackBy: trackByTimePeriodNotifications">

    <div class="rpc-notifications-list" *ngIf="item.notifications.length > 0">

        <div class="rpc-notifications-list-title">{{item.title | translate}}</div>

        <ng-container *ngFor="let notification of item.notifications; trackBy: trackByNotificationItemType">

            <notification-group class="rpc-notifications-list-group"
                *ngIf="notification.isGroup; else singleNotification"
                [notificationItem]="notification | cast: NotificationGroupItem" [dateFormat]="item.dateFormat"
                (navigateToNotificationEntity)="onNavigateToNotificationEntity($event)">
            </notification-group>

            <ng-template #singleNotification>
                <notification-item class="rpc-notifications-list-item"
                    [notification]="notification | cast: NotificationPlainItem" [dateFormat]="item.dateFormat"
                    (navigateToNotificationEntity)="onNavigateToNotificationEntity($event)">
                </notification-item>
            </ng-template>

        </ng-container>

    </div>

</ng-container>

<div class="rpc-notifications-list">

    <div *ngIf="hasAnyNotifications; else noNotifications" class="rpc-notifications-list-note">
        {{'NOTIFICATION_LIST.TITLES.NOTIFICATIONS_MESSAGE' | translate}}
    </div>

    <ng-template #noNotifications>
        <div class="rpc-notifications-list-empty">

            <img class="rpc-notifications-list-empty-img" src="assets/images/decoration/notifications/empty.webp"
                alt="" />

            <div class="rpc-notifications-list-empty-text">
                {{'NOTIFICATION_LIST.TITLES.NO_NOTIFICATIONS_MESSAGE' | translate}}
            </div>

        </div>
    </ng-template>
</div>