<div class="cf-wrap cf-wrap--btns" *ngIf="enableRangeLayout; else rangeLayoutNotAllowed">
    <div class="cf-row" [scrollable]="true" [scrollableXContainer]="scrollableContainerElement"
        [scrollableXElements]="scrollableElements" [scrollableXTransformTargets]="scrollableTransformTargets"
        [scrollableXElementsGap]="5" [scrollableXElementsBorderAdjustmentGap]="10">
        <div class="cf-row-inner" #scrollableContainer #scrollableTransformTarget>
            <div class="cf-btn-box" #scrollableElement *ngIf="enableAny">
                <rpc-button class="cf-btn" [title]="'CHECKBOX_FILTER.TITLES.ANY'"
                    [actionClass]="isAnySelected ? 'active' : ''" [appearanceType]="'stroke'" (clicked)="toogleAny()">
                </rpc-button>
            </div>

            <div class="cf-btn-box" #scrollableElement *ngFor="let value of availableValues; let i = index">
                <rpc-button class="cf-btn"
                    [title]="((value.valueLocalizationKey | translate) + (isExactMatchSelected ? '' : notExactMatchAdditionalText))"
                    [actionClass]="isAnySelected || currentSelectedValues.includes(value.value) ? 'active' :  ''"
                    [appearanceType]="'stroke'" [disabled]="disabledValues?.includes(value.value)"
                    (clicked)="toogleValue(i)">
                </rpc-button>
            </div>
        </div>
    </div>

    <div class="cf-toogler-wrap" *ngIf="enableExactMatch">
        <mat-slide-toggle class="cf-toogler" [labelPosition]="'before'" [checked]="isExactMatchSelected"
            [disabled]="isAnySelected" (change)="toggleExactMatch($event.checked)">
            <span>{{ 'CHECKBOX_FILTER.TITLES.EXACT_MATCH' | translate }}</span>
        </mat-slide-toggle>
    </div>

</div>

<ng-template #rangeLayoutNotAllowed>
    <div class="cf-wrap cf-wrap--chips">
        <div class="cf-btn-box" #scrollableElement *ngFor="let item of availableValues; let i = index">
            <rpc-button class="cf-btn" [title]="item.valueLocalizationKey"
                [actionClass]="currentSelectedValues.includes(item.value) ? 'active' :  ''" [appearanceType]="'stroke'"
                [disabled]="disabledValues?.includes(item.value)" (clicked)="toogleValue(i)">
            </rpc-button>
        </div>
    </div>
</ng-template>