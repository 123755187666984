import { ListingCardInfo } from '@listings/models/listing/listing-card-info';
import { ListingDetails } from '@listings/models/listing/listing-details';
import { ListingPriceChange } from '@listings/models/listing/listing-price-change';
import { Listings } from '@listings/models/listing/listings';
import { ListingsActivityInfo } from '@listings/models/listing/listings-activity-info';
import { ListingsMedia } from '@listings/models/listing/listings-media';
import { NewMatch } from '@listings/models/listing/new-match';

export class ListingsState {

    /**
     * Stores all loaded listings (including customer, market, etc.).
     */
    listings: Listings;
    listingsDetails: Record<number, ListingDetails> = {};
    media: ListingsMedia;
    recentlyViewedListings: ListingCardInfo[];
    /**
     * Stores selected listings.
     */
    selectedListingIds: string[];

    customerListingsLoaded: boolean;
    marketListingsLoaded: boolean;
    marketListingsLoading: boolean;
    mediaLoaded: boolean;
    recentlyViewedListingsLoaded: boolean;
    isRecentlyViewedListingsLoading: boolean;

    isListingsLoading = false;
    isListingLoading = false;

    /**
     * Listing ids that should be removed when leaving listing details page.
     * Expected to be NM duplicates that got an activity (duplicates are visible while they are NM).
     */
    removalCandidateIds: string[];
    readonly priceChanges: Record<number, ListingPriceChange> = {};
    readonly listingsActivities: Record<number, ListingsActivityInfo> = {};
    readonly listingsActivitiesLoading: boolean = false;
    readonly listingsActivitiesLoaded: boolean = false;

    readonly listingsNewMatches: Record<number, NewMatch[]> = {};
    public readonly newMatchesLoading: boolean = false;
    public readonly newMatchesLoaded: boolean = false;

    constructor() {
        this.listings = {};
        this.recentlyViewedListings = [];
        this.selectedListingIds = [];

        this.customerListingsLoaded = false;
        this.marketListingsLoaded = false;
        this.marketListingsLoading = false;
        this.mediaLoaded = false;
        this.recentlyViewedListingsLoaded = false;
        this.removalCandidateIds = [];
    }
}