import { MyListingsFilters } from '@my-listings/models/filter/my-listings-filters.enum';

export const removeChildFiltersWithoutParent = (allHiddenFiltersKeys: string[], nestedFiltersMap: Map<MyListingsFilters, MyListingsFilters[]>): string[] => {
    const nestedFilters = [...nestedFiltersMap.entries()].reduce(
        (filters, [rootFilter, nested]) => !allHiddenFiltersKeys.includes(rootFilter) ? [...filters, ...nested] : filters,
        new Array<MyListingsFilters>()
    );

    if (nestedFilters.length > 0) {
        return nestedFilters.reduce((allFilters, value) => {
            return allFilters.filter(filterValue => filterValue !== value);
        }, allHiddenFiltersKeys);
    }

    return allHiddenFiltersKeys;
};
