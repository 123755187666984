import { ActionCreator, on, ReducerTypes } from '@ngrx/store';

import { ListingActivityConstants } from '@listings/constants/listing-activity-constants';
import { ActivityHelpersService } from '@listings/store/services/activity-helpers.service';
import { ListingsState } from '@listings/store/states/listings.state';
import * as listingActivityActions from '../actions/listing-activity.actions';
import { ListingsReducerHelpers } from './helpers/listings.reducer.helper';

export const listingActivityReducerTypes: ReducerTypes<ListingsState, readonly ActionCreator[]>[] = [
    on(listingActivityActions.setListingsActivity, (state, { request, customerId, agentId }) => {

        const customerListings = Object.values(state.listings);

        const listingsWithoutRemoved = request.listingCandidates.filter(x => {
            const listing = customerListings.find(l => l.hashCode === x.hashCode) ?? state.recentlyViewedListings.find(l => l.hashCode === x.hashCode);

            return !state.listingsActivities[listing.hashCode]?.isDeleted;
        });

        return ListingsReducerHelpers.setListingsActivities(
            state,
            { ...request, listingCandidates: listingsWithoutRemoved, },
            customerId,
            false,
            agentId,
            ActivityHelpersService.setListingActivity,
        );
    }),
    on(listingActivityActions.setListingsActivityFailed, (state, { request, customerId, agentId }) =>
        ListingsReducerHelpers.setListingsActivities(
            state,
            request,
            customerId,
            true,
            agentId,
            ActivityHelpersService.restoreListingActivity
        )
    ),
    on(listingActivityActions.addListingsToPickedList, (state, { listingCandidates, customerId, agentId }) => {
        const updatedState = ListingsReducerHelpers.setListingsActivities(
            state,
            {
                listingCandidates,
                activity: ListingActivityConstants.PickListed
            },
            customerId,
            false,
            agentId,
            ActivityHelpersService.setListingActivity
        );

        return { ...updatedState };
    }
    ),
    on(listingActivityActions.removeListingsFromPickedList, (state, { listingCandidates, customerId, agentId }) =>
        ListingsReducerHelpers.setListingsActivities(
            state,
            {
                listingCandidates,
                activity: ListingActivityConstants.PickListed
            },
            customerId,
            true,
            agentId,
            ActivityHelpersService.restoreListingActivity
        )
    ),
    on(listingActivityActions.loadListingsActivities, (state, { shouldSetLoading }) => {
        return ({ ...state, listingsActivitiesLoading: shouldSetLoading ?? true, listingsActivitiesLoaded: false });
    }),
    on(listingActivityActions.loadListingsActivitiesSuccess, (state, { shouldSetLoaded }) => {
        return ({ ...state, listingsActivitiesLoading: false, listingsActivitiesLoaded: shouldSetLoaded ?? true });
    }),
    on(listingActivityActions.loadListingsActivitiesFailed, state => {
        return ({ ...state, listingsActivitiesLoading: false, listingsActivitiesLoaded: false });
    }),
    on(listingActivityActions.setListingsActivities, (state, { activities }) => ({ ...state, listingsActivities: activities })),
];