import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as onboardingActions from '../actions/onboarding.actions';
import * as onboardingSelectors from '../selectors/onboarding.selectors';
import { IOnboardingState } from '../states/onboarding.state';

@Injectable({ providedIn: 'root' })
export class OnboardingStoreService {
    constructor(private readonly store: Store<IOnboardingState>) { }

    public readonly itemToDisplay$ = this.store.select(onboardingSelectors.selectItemToDisplay);
    public readonly isLoading$ = this.store.select(onboardingSelectors.selectIsDataLoading);
    public readonly selectedItemIndex$ = this.store.select(onboardingSelectors.selectSelectedItemIndex);
    public readonly itemsImages$ = this.store.select(onboardingSelectors.selectItemsImages);
    public readonly viewedSlides$ = this.store.select(onboardingSelectors.selectViewedSlides);

    public loadOnboardingData(): void {
        this.store.dispatch(onboardingActions.loadOnboardingData());
    }

    public markAsViewed(): void {
        this.store.dispatch(onboardingActions.markAsViewed());
    }

    public setNextSelectedIndex(): void {
        this.store.dispatch(onboardingActions.setNextSelectedIndex());
    }

    public setPreviousSelectedIndex(): void {
        this.store.dispatch(onboardingActions.setPreviousSelectedIndex());
    }

    public resetState(): void {
        this.store.dispatch(onboardingActions.resetState());
    }
}