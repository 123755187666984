import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { ListingsEventsEffects } from './store/effects/listing-event.effects';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        EffectsModule.forFeature([ListingsEventsEffects]),
    ]
})
export class ListingEventsModule { }
