import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ApiHttpClient } from '@core-services/api-http-client.service';
import { PatternSearchResult } from '@search/models/pattern-search-result';
import * as patternSearchActions from '../actions/pattern-search.actions';
import * as neighborhoodActions from '../actions/neighborhood.actions';
import { NeighborhoodProperty } from '@on-market/models/neighborhood-property';

@Injectable({ providedIn: 'root' })
export class SearchApiService {

    constructor(private readonly http: ApiHttpClient) { }

    public searchByPattern(searchPattern: string, listingCategoryId: number): Observable<Action> {
        return this.http
            .post('listing/patternSearch', { searchPattern, listingCategoryId })
            .pipe(
                map((patternSearchResults: PatternSearchResult[]) => patternSearchActions.searchByPatternSuccess({ patternSearchResults })),
                catchError(() => of(patternSearchActions.searchByPatternFailed()))
            );
    }

    public loadNeighborhoodProperties(): Observable<Action> {
        return this.http.get('listing/neighborhoodProperties').pipe(
            map((neighborhoodProperties: NeighborhoodProperty[]) => neighborhoodActions.loadNeighborhoodPropertiesSuccess({ neighborhoodProperties })),
            catchError(() => of(neighborhoodActions.loadNeighborhoodPropertiesFailed()))
        );
    }
}