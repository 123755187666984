import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

import * as appointmentsActions from '@appointments/store/actions/appointments.actions';
import { UserStoreService } from '@auth/store/services/user-store.service';
import { AppointmentStatus } from '@appointments/enums/appointment-status.enum';
import { CreateNotificationApiService } from '../services/create-notification-api.service';
import { NotificationsStoreService } from '../services/notifications-store.service';
import { NotificationEventAction } from '@notifications/enums/notification-event-action';
import { AppointmentNotification } from '@notifications/models/notification-data';

@Injectable()
export class CreateAppointmentNotificationEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly createNotificationApiService: CreateNotificationApiService,
        private readonly userStoreService: UserStoreService,
        private readonly notificationsStoreService: NotificationsStoreService,
    ) { }

    public readonly createAppoinmentCreatedNotification$ = createEffect(() => this.actions$.pipe(
        ofType(appointmentsActions.createListingAppointmentSuccess),
        concatLatestFrom(() => this.userStoreService.customerCollaborationId$),
        switchMap(([{ appointment }, collaborationId]) => {
            const appointmentCreatedInput = {
                appointmentCreatorId: appointment.createId,
                appointmentId: appointment.id,
                listingId: appointment.listingIdHashCode,
                message: appointment.comment,
                range: {
                    startDate: appointment.startDateTime,
                    endDate: appointment.endDateTime
                },
                customerCollaborationId: collaborationId
            };

            return this.createNotificationApiService.createAppointmentCreatedNotification(appointmentCreatedInput);
        })
    ));

    public readonly createAppointmentStatusChangedNotification = createEffect(() => this.actions$.pipe(
        ofType(appointmentsActions.changeAppointmentStatusSuccess),
        concatLatestFrom(() => [
            this.userStoreService.customerCollaborationId$,
            this.notificationsStoreService.flatNotifications$,
        ]),
        switchMap(([{ request }, customerCollaborationId, notifications]) => {
            const appointmentCreatedNotifiaction = notifications.find(x => {
                return x.entityId === request.appointmentId
                    && (x.action === NotificationEventAction.AppointmentCreated || x.action === NotificationEventAction.AppointmentConfirmed);
            });
            const appointmentData = appointmentCreatedNotifiaction.data as AppointmentNotification;

            const input = {
                appointmentCreatorId: appointmentCreatedNotifiaction.createId,
                appointmentId: request.appointmentId,
                listingId: request.listingHashCode,
                message: appointmentData.comment,
                range: {
                    startDate: appointmentData.startDateTime,
                    endDate: appointmentData.endDateTime
                },
                customerCollaborationId
            };

            if (request.customerStatus === AppointmentStatus.Confirmed) {
                return this.createNotificationApiService.createAppointmentConfirmedNotification(input);
            }

            return this.createNotificationApiService.createAppointmentDeclinedNotification(input);

        })
    ));

    public readonly createAppoinmentUpdatedNotification$ = createEffect(() => this.actions$.pipe(
        ofType(appointmentsActions.updateListingAppointmentSuccess),
        concatLatestFrom(() => this.userStoreService.customerCollaborationId$),
        switchMap(([{ request }, collaborationId]) => {
            const appointmentUpdatedInput = {
                appointmentCreatorId: request.oldListingAppointment.createId,
                appointmentId: request.oldListingAppointment.id,
                listingId: request.oldListingAppointment.listingIdHashCode,
                message: request.oldListingAppointment.comment,
                range: {
                    startDate: request.oldListingAppointment.startDateTime,
                    endDate: request.oldListingAppointment.endDateTime
                },
                customerCollaborationId: collaborationId,
                newMessage: request.listingAppointment.comment,
                newRange: {
                    startDate: request.listingAppointment.startDateTime,
                    endDate: request.listingAppointment.endDateTime
                }
            };

            return this.createNotificationApiService.createAppointmentUpdatedNotification(appointmentUpdatedInput);
        })
    ));

    public readonly createAppointmentDeletedNotification$ = createEffect(() => this.actions$.pipe(
        ofType(appointmentsActions.deleteListingAppointmentSuccess),
        concatLatestFrom(() => [this.userStoreService.customerId$, this.userStoreService.customerCollaborationId$]),
        switchMap(([{ request: { appointmentId } }, customerId, customerCollaborationId]) => {
            const input = { appointmentId, customerCollaborationId, createId: customerId };

            return this.createNotificationApiService.createAppointmentDeletedNotification(input);
        })
    ));
}