import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ListingCategories } from '@listings/enums/listing-categories.enum';
import { SavedSearchInfo } from '@saved-search/models/saved-search-info';

@Component({
    selector: 'saved-search-info',
    templateUrl: './saved-search-info.component.html',
    styleUrls: ['./saved-search-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SavedSearchInfoComponent {

    @Input() public savedSearch: SavedSearchInfo;
    @Input() public isEditable = false;
    @Input() public isActive = false;

    @Output() public clicked = new EventEmitter<void>();
    @Output() public edit = new EventEmitter<void>();

    public readonly listingCategories = ListingCategories;

    public onSavedSearchClick(): void {
        this.clicked.emit();
    }

    public onEditClick(event: MouseEvent): void {
        event.stopPropagation();

        this.edit.emit();
    }
}
