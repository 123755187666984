
import { locale as checkboxFilter } from '../components/checkbox-filter/i18n/en';
import { locale as omniSearch } from '../components/omni-search/i18n/en';
import { locale as rangeFilter } from '../components/range-filter/i18n/en';
import { locale as searchFilterTitles } from '../services/i18n/en';

export const locale = {
    lang: 'en',
    data: {
        ...checkboxFilter.data,
        ...omniSearch.data,
        ...rangeFilter.data,
        ...searchFilterTitles.data
    }
};