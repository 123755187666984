import { createAction, props } from '@ngrx/store';

import { ApiError } from '@error/models/api-error';
import { Listings } from '@listings/models/listing/listings';
import { ListingsMediaFromApi } from '@listings/models/listing/listings-media-from-api';
import { ListingSearchOptions } from '@listings/models/search/listing-search-options';
import { RemoveListingData } from '@listings/models/listing/remove-listing-data';
import { ListingsActivityInfo } from '@listings/models/listing/listings-activity-info';
import { NewMatch } from '@listings/models/listing/new-match';
import { ListingCardInfo } from '@listings/models/listing/listing-card-info';
import { ListingDetails } from '@listings/models/listing/listing-details';

export const loadListing = createAction(
    '[Listings] Load Listing',
    props<{ id: string }>());
export const loadListingSuccess = createAction(
    '[Listings] Load Listing Success',
    props<{ listing: ListingCardInfo, listingId: string }>());
export const loadListingFailed = createAction(
    '[Listings] Load Listing Failed',
    (error: ApiError) => error);

export const loadListingsDetails = createAction('[Listings] Load Listings Details', props<{ hashCodes: number[] }>());
export const loadListingsDetailsSuccess = createAction('[Listings] Load Listings Details Success', props<{ listingDetails: Record<number, ListingDetails> }>());
export const loadListingsDetailsFailed = createAction('[Listings] Load Listings Details Failed');

export const loadListinsgByHashCodes = createAction('[Listings] Load Listings By Hash Codes', props<{ hashCodes: number[] }>());

export const loadListinsgByHashCodesSuccess = createAction(
    '[Listings] Reload Listings By Ids Success',
    props<{ loadedListings: ListingCardInfo[], listingsToDeleteIds: string[] }>()
);

export const loadListinsgByHashCodesFailed = createAction(
    '[Listings] Reload Listings By Ids Failed',
    (error: ApiError) => error
);

export const loadCustomerListings = createAction('[Listings] Load Customer Listings', props<{ shouldSetLoading: boolean }>());
export const loadCustomerListingsSuccess = createAction(
    '[Listings] Load Customer Listings Success',
    props<{ listings: Listings }>());
export const loadCustomerListingsFailed = createAction(
    '[Listings] Load Customer Listings Failed',
    (error: ApiError) => error);

export const loadListingsMedia = createAction(
    '[Listings] Load Listings Media',
    props<{ listingIds: string[] }>());
export const cleanupListingsMedia = createAction(
    '[Listings] Reset Listings Media');
export const loadListingsMediaSuccess = createAction(
    '[Listings] Load Listings Media Success',
    props<{ media: ListingsMediaFromApi }>());
export const loadListingsMediaFailed = createAction(
    '[Listings] Load Listings Media Failed',
    (error: ApiError) => error
);

export const loadMarketListings = createAction(
    '[Listings] Load Market Listings',
    props<{ searchOptions: ListingSearchOptions }>());
export const loadMarketListingsSuccess = createAction(
    '[Listings] Load Market Listings Success',
    props<{ listings: Listings }>());
export const loadMarketListingsFailed = createAction(
    '[Listings] Load Market Listings Failed',
    (error: ApiError) => error
);

export const markAsViewed = createAction(
    '[Listings] Mark Listing As Viewed',
    props<{ listingId: string, listingHashCode: number }>());
export const markAsViewedSuccess = createAction(
    '[Listings] Mark Listing As Viewed Success');
export const markAsViewedFailed = createAction(
    '[Listings] Mark Listing As Viewed Failed',
    (error: ApiError, listingHashCode: number) => {
        return { error, listingHashCode };
    });

export const selectListing = createAction(
    '[Listings] Select Listing',
    props<{ id: string }>());
export const selectListings = createAction(
    '[Listings] Select Listings',
    props<{ listingIds: string[] }>());
export const unselectListings = createAction(
    '[Listings] Unselect Listings',
    props<{ listingIds: string[] }>());
export const unselectAllListings = createAction(
    '[Listings] Unselect All Listings');

export const setMarketListingsLoaded = createAction(
    '[Listings] Set Market Listings Loaded',
    props<{ isLoaded: boolean }>());

export const clearMarketListings = createAction(
    '[Listings] Clear Market Liistings'
);

export const resetState = createAction('[Listings] Reset State');

export const softDeleteRequested = createAction('[Listings] Soft Delete Requested', props<{ listingIds: string[], isFromStateOnly?: boolean }>());
export const softDelete = createAction(
    '[Listings] Soft Delete',
    props<{ listingIds: string[], listings: Listings, listingsActivities: Record<number, ListingsActivityInfo>, listingsNewMatches: Record<number, NewMatch[]>, isFromStateOnly?: boolean }>()
);
export const softDeleteSuccess = createAction(
    '[Listings] Soft Delete Success',
    props<{ listingIds: string[], listings: Listings, listingsActivities: Record<number, ListingsActivityInfo>, listingsNewMatches: Record<number, NewMatch[]> }>()
);
export const softDeleteFailed = createAction(
    '[Listings] Soft Delete Failed',
    props<{ error: ApiError, listings: Listings, listingsActivities: Record<number, ListingsActivityInfo>, listingsNewMatches: Record<number, NewMatch[]> }>()
);

export const hardDelete = createAction('[Listings] Hard Delete', props<{ listingIds: string[], hashCodes: number[], isFromStateOnly?: boolean }>());
export const hardDeleteSuccess = createAction('[Listings] Hard Delete Success', props<{ listingsHashCodes: number[] }>());
export const hardDeleteFailed = createAction(
    '[Listings] Hard Delete Failed',
    props<{ error: ApiError, listings: Listings, listingsActivities: Record<number, ListingsActivityInfo>, listingsNewMatches: Record<number, NewMatch[]> }>()
);

export const openListingDeleteManagementDialog = createAction('[Listings] Open Listing Delete Management Dialog', props<{ removeListingData: RemoveListingData }>());

export const restoreRequeted = createAction('[Listings] Restore Requested', props<{ listingsIds: string[] }>());
export const restore = createAction(
    '[Listings] Restore',
    props<{ listingIds: string[], listings: Listings, listingsActivities: Record<number, ListingsActivityInfo>, listingsNewMatches: Record<number, NewMatch[]>, isFromStateOnly?: boolean }>()
);
export const restoreSuccess = createAction('[Listings] Restore Success', props<{ listingIds: string[] }>());
export const restoreFailed = createAction(
    '[Listings] Restore Failed',
    props<{ error: ApiError, listings: Listings, listingsActivities: Record<number, ListingsActivityInfo>, listingsNewMatches: Record<number, NewMatch[]> }>(),
);

export const showDirections = createAction('[Listings] Show Directions');

export const setListings = createAction('[Listings] Set Listings', props<{ listingIds: string[] }>());