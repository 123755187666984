import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

import * as appointmentsActions from '@appointments/store/actions/appointments.actions';
import * as commentsActions from '@comments/store/actions/comments.actions';
import * as foldersActions from '@folders/store/actions/folders.actions';
import * as listingActions from '@listings/store/actions/listings.actions';
import { ListingApiService } from '@listings/store/services/listing-api.service';
import { ListingsStoreService } from '../services/listings-store.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RemoveListingData } from '@listings/models/listing/remove-listing-data';
import { DEFAULT_REMOVE_LISTING_DIALOG_CONFIG } from '@listings/components/remove-listing-dialog/constants/remove-listing-dialog.constants';
import { RemoveListingDialogComponent } from '@listings/components/remove-listing-dialog/remove-listing-dialog.component';
import { ListingIdentifier } from '@listings/models/listing/listing-identifier';
import * as notesActions from '@notes/store/actions/notes.actions';
import { RemoveRestoreListingHelper } from '../reducers/helpers/remove-restore-listing.helper';

@Injectable()
export class RemoveListingEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly listingApiService: ListingApiService,
        private readonly listingsStoreService: ListingsStoreService,
        private readonly matDialog: MatDialog
    ) { }

    public readonly openListingDeleteManagementDialog$ = createEffect(() => this.actions$.pipe(
        ofType(listingActions.openListingDeleteManagementDialog),
        switchMap(({ removeListingData }) => {

            const dialogConfig: MatDialogConfig<RemoveListingData> = {
                ...DEFAULT_REMOVE_LISTING_DIALOG_CONFIG,
                data: removeListingData
            };

            return this.matDialog.open(RemoveListingDialogComponent, dialogConfig)
                .afterClosed()
                .pipe(
                    filter(identifier => identifier != null),
                    take(1),
                    map((listingsIdentifires: ListingIdentifier[]) => {
                        const listingIds = listingsIdentifires.map(i => i.listingId);
                        const hashCodes = listingsIdentifires.map(i => i.hashCode);

                        return removeListingData.isHardDelete
                            ? listingActions.hardDelete({ listingIds, hashCodes: hashCodes ?? [], isFromStateOnly: false })
                            : listingActions.softDeleteRequested({ listingIds, isFromStateOnly: false });
                    })
                );
        })
    ));

    public readonly softDeleteRequested$ = createEffect(() => this.actions$.pipe(
        ofType(listingActions.softDeleteRequested),
        concatLatestFrom(() => [this.listingsStoreService.baseListings$, this.listingsStoreService.listingsActivities$, this.listingsStoreService.listingsNewMatches$]),
        map(([{ listingIds, isFromStateOnly }, listings, listingsActivities, listingsNewMatches]) => {
            return listingActions.softDelete({ listingIds, listings, listingsActivities, listingsNewMatches, isFromStateOnly });
        })
    ));

    public readonly softDelete$ = createEffect(() => this.actions$.pipe(
        ofType(listingActions.softDelete),
        switchMap(({ listingIds, isFromStateOnly, listings, listingsActivities, listingsNewMatches }) => {
            return isFromStateOnly ? EMPTY : this.listingApiService.softDelete(listingIds, listings, listingsActivities, listingsNewMatches);
        })
    ));

    public readonly softDeleteSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(listingActions.softDeleteSuccess),
        switchMap(({ listingIds, listings, listingsActivities, listingsNewMatches }) => {
            const getNewMatchIdsWithoutActivities = (newMatchIds: number[], id: string) => {
                const { hashCode } = listings[id];
                const activities = listingsActivities[hashCode]?.activities ?? [];
                const newMatches = listingsNewMatches[hashCode] ?? [];

                return newMatches.length > 0 && activities.length === 0 ? [...newMatchIds, ...newMatches.map(({ id }) => id)] : newMatchIds;
            };
            const newMatchIdsWithoutActivities = listingIds.reduce(getNewMatchIdsWithoutActivities, new Array<number>());

            return [
                appointmentsActions.loadListingsAppointments(),
                ...(newMatchIdsWithoutActivities.length > 0 ? [foldersActions.updateFolderMappings({ newMatchIds: newMatchIdsWithoutActivities })] : [])
            ];
        })
    ));

    public readonly hardDeleteListings$ = createEffect(() => this.actions$.pipe(
        ofType(listingActions.hardDelete),
        concatLatestFrom(() => [this.listingsStoreService.baseListings$, this.listingsStoreService.listingsActivities$, this.listingsStoreService.listingsNewMatches$]),
        switchMap(([{ listingIds, isFromStateOnly }, listings, listingsActivities, listingsNewMatchhes]) => {
            return isFromStateOnly ? EMPTY : this.listingApiService.hardDelete(listingIds, listings, listingsActivities, listingsNewMatchhes);
        })
    ));

    public readonly hardDeleteSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(listingActions.hardDeleteSuccess),
        switchMap(({ listingsHashCodes }) => [
            commentsActions.loadListingsComments(),
            appointmentsActions.loadListingsAppointments(),
            foldersActions.loadListingFolderIdsMappings(),
            notesActions.deleteNote({ listingHashCodes: listingsHashCodes })
        ])
    ));
}
