import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';

import { SimpleChanges } from '@core-models/utilities/generic-simple-changes';
import { CheckboxFilterValue } from './models/checkbox-filter-value';
import { CheckboxFilterOption } from '@listings/models/search/checkbox-filter-option';

@Component({
    selector: 'checkbox-filter',
    templateUrl: './checkbox-filter.component.html',
    styleUrls: ['./checkbox-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxFilterComponent implements OnChanges {

    @ViewChild('scrollableContainer') public scrollableContainerElement: ElementRef<HTMLElement>;
    @ViewChildren('scrollableElement') public scrollableElements: QueryList<ElementRef<HTMLElement>>;
    @ViewChildren('scrollableTransformTarget') public scrollableTransformTargets: QueryList<ElementRef<HTMLElement>>;

    @Input() public readonly values: CheckboxFilterValue[];
    @Input() public readonly selectedValues: number[];
    @Input() public readonly disabledValues: number[] = [];
    @Input() public readonly isAny: boolean;
    @Input() public readonly isExactMatch: boolean;

    @Input() public readonly enableRangeLayout: boolean = true;
    /** If enableRangeLayout = false output for 'any' wiil be false, otherwise the selected value */
    @Input() public readonly enableAny: boolean = true;
    /** If enableRangeLayout = false output for 'exactMatch' always wiil be true, otherwise the selected value */
    @Input() public readonly enableExactMatch: boolean = true;
    /** Text that will be added to localized value text in case selected exactMatch = false */
    @Input() public readonly notExactMatchAdditionalText: string = '+';
    @Input() public readonly allowMultipleSelection: boolean = false;

    @Output() public valueChanged = new EventEmitter<CheckboxFilterOption>();

    public availableValues: CheckboxFilterValue[];
    public isAnySelected: boolean;
    public currentSelectedValues: number[];
    public isExactMatchSelected: boolean;

    public ngOnChanges(changes: SimpleChanges<CheckboxFilterComponent>): void {
        if (changes.values?.currentValue != null) {
            this.availableValues = changes.values.currentValue
                .sort((firstValue, secondValue) => firstValue.order - secondValue.order);
        }

        if (changes.isAny?.currentValue != null) {
            this.isAnySelected = changes.isAny.currentValue;
        }

        if (changes.selectedValues?.currentValue != null) {
            this.currentSelectedValues = changes.selectedValues.currentValue;
        }

        if (changes.isExactMatch?.currentValue != null) {
            this.isExactMatchSelected = changes.isExactMatch.currentValue;
        }

        if (changes.enableRangeLayout?.currentValue != null && !changes.enableRangeLayout.currentValue) {
            this.isAnySelected = false;
            this.isExactMatchSelected = true;
        }
    }

    public toogleAny(): void {
        if (this.enableRangeLayout && this.enableAny && !this.isAnySelected) {
            this.isAnySelected = true;
            this.currentSelectedValues = [];
            this.isExactMatchSelected = false;

            this.notifyValueChanged();
        }
    }

    // eslint-disable-next-line complexity
    public toogleValue(index: number): void {
        if (this.enableRangeLayout && this.enableAny && this.isAnySelected) {
            this.isAnySelected = false;
            this.currentSelectedValues = this.isExactMatchSelected
                ? [this.availableValues[index].value]
                : this.availableValues.slice(index).map(value => value.value);
        } else {
            if (this.enableExactMatch && this.isExactMatchSelected) {
                const selectedValueIndex = this.currentSelectedValues.indexOf(this.availableValues[index].value);

                if (selectedValueIndex >= 0) {
                    if (this.currentSelectedValues.length === 1 && !this.allowMultipleSelection) {
                        this.isAnySelected = true;
                        this.isExactMatchSelected = false;
                        this.currentSelectedValues = [];
                    } else {
                        if (this.allowMultipleSelection) {
                            this.currentSelectedValues = this.currentSelectedValues.filter(value => value !== this.availableValues[index].value);
                        } else {
                            this.currentSelectedValues = [this.availableValues[index].value];
                        }
                    }
                } else {
                    if (this.allowMultipleSelection) {
                        this.currentSelectedValues = [...this.currentSelectedValues, this.availableValues[index].value];
                    } else {
                        this.currentSelectedValues = [this.availableValues[index].value];
                    }
                }
            } else {
                const selectedValueIndex = this.currentSelectedValues.indexOf(this.availableValues[index].value);

                if (selectedValueIndex >= 0) {
                    if (selectedValueIndex === 0) {
                        this.isAnySelected = true;
                        this.currentSelectedValues = [];
                    }
                    else {
                        this.currentSelectedValues = this.availableValues.slice(index).map(value => value.value);
                    }
                } else {
                    this.currentSelectedValues = this.availableValues.slice(index).map(value => value.value);
                }
            }
        }

        this.notifyValueChanged();
    }

    public toggleExactMatch(value: boolean): void {
        this.isExactMatchSelected = value;
        const firstSelectedValue = this.currentSelectedValues.length > 0 ? this.currentSelectedValues[0] : null;

        if (!this.isExactMatchSelected && firstSelectedValue != null) {
            const firstAvailableValueindex = this.availableValues.findIndex(avaliableValue => avaliableValue.value === firstSelectedValue);

            if (firstAvailableValueindex >= 0) {
                this.currentSelectedValues = this.availableValues.slice(firstAvailableValueindex).map(avaliableValue => avaliableValue.value);
            }
        }

        if (this.isExactMatchSelected && firstSelectedValue != null) {
            this.currentSelectedValues = [firstSelectedValue];
        }

        this.notifyValueChanged();
    }

    private notifyValueChanged(): void {
        this.valueChanged.next({
            any: this.enableRangeLayout ? this.enableAny ? this.isAnySelected : false : false,
            values: this.currentSelectedValues,
            exactMatch: this.enableRangeLayout ? this.enableExactMatch ? this.isExactMatchSelected : true : true
        });
    }
}