import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { DateConstants } from '@core-constants/dates/date.constants';
import { NotificationGroupItem } from '@notifications/models/notification-group-item';
import { NotificationNavigateParams } from '@notifications/models/notification-navigate-params';
import { NotificationPlainItem } from '@notifications/models/notification-plain-item';
import { NotificationItem, Notifications } from '@notifications/models/notifications';

@Component({
    selector: 'notification-list',
    templateUrl: './notification-list.component.html',
    styleUrls: ['./notification-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationListComponent {
    @Input() public set notifications(notifications: Notifications) {
        this.timePeriodNotifications = [
            { title: 'NOTIFICATIONS.TITLES.TODAY', notifications: notifications.today, dateFormat: DateConstants.Formats.AngularPipeShortTime },
            { title: 'NOTIFICATIONS.TITLES.YESTERDAY', notifications: notifications.yesterday, dateFormat: DateConstants.Formats.AngularPipeShortTime },
            { title: 'NOTIFICATIONS.TITLES.OLDER', notifications: notifications.older, dateFormat: DateConstants.Formats.AngularPipeShortDateSlash },
        ];

        this.hasAnyNotifications = notifications.today.length + notifications.yesterday.length + notifications.older.length > 0;
    }

    @Output() public readonly navigateToNotificationEntity = new EventEmitter<NotificationNavigateParams>();

    public readonly NotificationPlainItem = NotificationPlainItem;
    public readonly NotificationGroupItem = NotificationGroupItem;

    public timePeriodNotifications: { title: string, notifications: NotificationItem[], dateFormat: string }[] = [];
    public hasAnyNotifications: boolean;

    public onNavigateToNotificationEntity(notificationNavigateParams: NotificationNavigateParams): void {
        this.navigateToNotificationEntity.emit(notificationNavigateParams);
    }

    public trackByTimePeriodNotifications(index: number, item: { title: string, notifications: NotificationItem[], dateFormat: string }): string {
        return item.notifications.map(x => x.isGroup ? (x as NotificationGroupItem).id : (x as NotificationPlainItem).notificationId).join(',');
    }

    public trackByNotificationItemType(index: number, item: NotificationPlainItem | NotificationGroupItem): string {
        return (item as NotificationPlainItem).notificationId?.toString() ?? (item as NotificationGroupItem).id;
    }
}
