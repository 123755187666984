import { ChangeDetectionStrategy, Component } from '@angular/core';

import { Folder } from '@folders/models/folder';

@Component({
    selector: 'folder-tooltip',
    templateUrl: './folder-tooltip.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FolderTooltipComponent {

    public folders: Folder[];
}