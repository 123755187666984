import { locale as addEditFolderDialog } from '../components/add-edit-folder-dialog/i18n/en';
import { locale as addEditFolderForm } from '../components/add-edit-folder-form/i18n/en';
import { locale as folderAttachmentDialog } from '../components/folder-attachment-dialog/i18n/en';
import { locale as folderDetails } from '../components/folder-details/i18n/en';
import { locale as folderManagementDialog } from '../components/folder-management-dialog/i18n/en';
import { locale as folderSelection } from '../components/folder-selection/i18n/en';
import { locale as folder } from '../components/folder/i18n/en';
import { locale as foldersView } from '../components/folders-view/i18n/en';
import { locale as removeFolderDialog } from '../components/remove-folder-dialog/i18n/en';
import { locale as folderError } from '../services/i18n/en';

export const locale = {
    lang: 'en',
    data: {
        ...folder.data,
        ...addEditFolderDialog.data,
        ...addEditFolderForm.data,
        ...folderDetails.data,
        ...foldersView.data,
        ...folderError.data,
        ...removeFolderDialog.data,
        ...folderManagementDialog.data,
        ...folderSelection.data,
        ...folderAttachmentDialog.data
    }
};