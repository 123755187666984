import { EventEmitter } from '@angular/core';

import { PaginatorInstance } from './paginator-instance';

export class PaginatorService {

    private static readonly DefaultId = 'DEFAULT_PAGINATOR_ID';

    private readonly paginatorInstances: { [id: string]: PaginatorInstance } = {};

    public change: EventEmitter<string> = new EventEmitter<string>();

    public defaultId(): string { return PaginatorService.DefaultId; }

    /**
     * Register a PaginationInstance with this service. Returns a
     * boolean value signifying whether the instance is new or
     * updated (true = new or updated, false = unchanged).
     */
    public register(instance: PaginatorInstance): boolean {
        if (instance.id == null) {
            instance.id = PaginatorService.DefaultId;
        }

        if (this.paginatorInstances[instance.id] == null) {
            this.paginatorInstances[instance.id] = instance;
            return true;
        } else {
            return this.updateInstance(instance);
        }
    }

    /**
     * Returns the current page number.
     */
    public getCurrentPage(id: string): number {
        if (this.paginatorInstances[id] != null) {
            return this.paginatorInstances[id].currentPage;
        }
    }

    /**
     * Sets the current page number.
     */
    public setCurrentPage(id: string, page: number): void {
        if (this.paginatorInstances[id] != null) {
            const instance = this.paginatorInstances[id];
            const maxPage = Math.ceil(instance.totalItems / instance.itemsPerPage);
            if (page <= maxPage && 1 <= page) {
                this.paginatorInstances[id].currentPage = page;
                this.change.emit(id);
            } else if (maxPage === 0) {
                this.paginatorInstances[id].currentPage = 1;
                this.change.emit(id);
            }
        }
    }

    /**
     * Sets the value of instance.totalItems
     */
    public setTotalItems(id: string, totalItems: number): void {
        if (this.paginatorInstances[id] != null && 0 <= totalItems) {
            this.paginatorInstances[id].totalItems = totalItems;
            this.change.emit(id);
        }
    }

    /**
     * Sets the value of instance.itemsPerPage.
     */
    public setItemsPerPage(id: string, itemsPerPage: number): void {
        if (this.paginatorInstances[id] != null) {
            this.paginatorInstances[id].itemsPerPage = itemsPerPage;
            this.change.emit(id);
        }
    }

    /**
     * Returns a clone of the pagination instance object matching the id. If no
     * id specified, returns the instance corresponding to the default id.
     */
    public getInstance(id: string = PaginatorService.DefaultId): PaginatorInstance {
        if (this.paginatorInstances[id] != null) {
            return this.clone(this.paginatorInstances[id]);
        }
        return new PaginatorInstance();
    }

    /**
     * Check each property of the instance and update any that have changed. Return
     * true if any changes were made, else return false.
     */
    private updateInstance(instance: PaginatorInstance): boolean {
        let changed = false;
        for (const paginatorId in this.paginatorInstances[instance.id]) {
            if (instance[paginatorId] !== this.paginatorInstances[instance.id][paginatorId]) {
                this.paginatorInstances[instance.id][paginatorId] = instance[paginatorId];
                changed = true;
            }
        }
        return changed;
    }

    /**
     * Perform a shallow clone of PaginatorInstance
     */
    private clone(paginatorInstance: PaginatorInstance): PaginatorInstance {
        const target = new PaginatorInstance();
        for (const propertyName in paginatorInstance) {
            if (paginatorInstance.hasOwnProperty(propertyName)) {
                target[propertyName] = paginatorInstance[propertyName];
            }
        }
        return target;
    }
}