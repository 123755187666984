import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as searchSelectors from '../selectors/search-criteria.selectors';
import { ListingSearchOptions } from '@listings/models/search/listing-search-options';
import { PatternSearchResult } from '@search/models/pattern-search-result';
import * as searchCriteriaActions from '../actions/search-criteria.actions';
import * as patternSearchSelector from '../selectors/pattern-search.selector';
import * as patternSearchActions from '../actions/pattern-search.actions';
import * as neighborhoodActions from '../actions/neighborhood.actions';
import { MapShapesSearchOptions } from '@listings/models/search/map-shapes-search-options';
import { SearchRunMode } from '@search/enums/search-run-mode.enum';

@Injectable({ providedIn: 'root' })
export class SearchStoreService {

    constructor(private readonly store: Store) { }

    public readonly searchOptions$ = this.store.select(searchSelectors.selectSearchOptions);
    public readonly previousSearchOptions$ = this.store.select(searchSelectors.selectPreviousSearchOptions);
    public readonly patternSearchResults$ = this.store.select(patternSearchSelector.selectPatternSearchResults);
    public readonly neighborhoodProperties$ = this.store.select((searchSelectors.selectNeighborhoodProperties));
    public readonly shapeOptions$ = this.store.select(searchSelectors.selectMapShapeOptions);
    public readonly mapShapesCount$ = this.store.select(searchSelectors.selectMapShapesCount);
    public readonly searchRunMode$ = this.store.select(searchSelectors.selectSearchRunMode);
    public readonly isCriteriaEmpty$ = this.store.select(searchSelectors.selectIsCriteriaEmpty);
    public readonly selectedNeighborhoodIds$ = this.store.select(searchSelectors.selectSelectedNeighborhoodIds);

    public loadNeighborhoodProperties(): void {
        this.store.dispatch(neighborhoodActions.loadNeighborhoodProperties());
    }

    public searchByPattern(searchPattern: string): void {
        this.store.dispatch(patternSearchActions.searchByPattern({ searchPattern }));
    }

    public patternResultChanged(patternResults: PatternSearchResult[]): void {
        this.store.dispatch(patternSearchActions.patternResultChanged({ patternResults }));
    }

    public changeSearchOptions<Key extends keyof ListingSearchOptions>(key: Key, value: ListingSearchOptions[Key]): void {
        this.store.dispatch(searchCriteriaActions.changeSearchOptions({ searchOptions: { [key]: value } }));
    }

    public setMapShapesSearchOptions(mapOptions: MapShapesSearchOptions): void {
        this.store.dispatch(searchCriteriaActions.setMapShapesSearchOptions({ mapOptions }));
    }

    public setSelectedMapNeighborhoods(selectedNeighborhoodIds: number[]): void {
        this.store.dispatch(searchCriteriaActions.setSelectedMapNeighborhoods({ selectedNeighborhoodIds }));
    }

    public clearSearchCriteria(): void {
        this.store.dispatch(searchCriteriaActions.clearSearchCriteria({ categoryId: null }));
    }

    public setSearchRunMode(mode: SearchRunMode): void {
        this.store.dispatch(searchCriteriaActions.setSearchRunMode({ mode }));
    }

    public search(canRunWithEmptyCriteria = false): void {
        this.store.dispatch(searchCriteriaActions.search({ searchOptions: null, canRunWithEmptyCriteria, shouldSetLoading: true }));
    }
}