import { createFeatureSelector, createSelector } from '@ngrx/store';
import uniqBy from 'lodash-es/uniqBy';

import * as userSelector from '@auth/store/selectors/user.selector';
import { CreateOperationListingComment, ListingComment } from '@comments/models/comments/listing-comment';
import { CommentsState } from '@comments/store/states/comments.state';
import * as listingsSelector from '@listings/store/selectors/listings.selector';
import { MessageInfo } from '@comments/store/models/message-info';

import * as usersSelectors from '@users/store/selectors/users.selectors';
import { UsersService } from '@users/services/users.service';

const commentsStateSelector = createFeatureSelector<CommentsState>('comments');

export const selectCommentError = createSelector(
    commentsStateSelector,
    (state) => state?.error
);

export const selectIsSingleCommentLoaded = createSelector(
    commentsStateSelector,
    (commentsState) => commentsState.isSingleCommentLoaded
);

export const selectCommentsLodaded = createSelector(
    commentsStateSelector,
    commentsState => commentsState == null ? false : commentsState.commentsLoaded
);

export const selectIsCommentsLoading = createSelector(commentsStateSelector, commentsState => commentsState.isCommentsLoading);

export const selectListingsComments = createSelector(commentsStateSelector, commentsState => commentsState?.listingsComments ?? []);

export const selectSortedMessages = createSelector(
    selectListingsComments,
    userSelector.selectUser,
    usersSelectors.selectCustomersAndAgents,
    (comments, user, customersAndAgents) => {
        return Object.values(comments).flatMap(x => x)
            .map((x: ListingComment | CreateOperationListingComment) => {
                const creator = UsersService.getUserInfoOrRemovedAccount(customersAndAgents, x.createId);

                return ({ ...x, isMine: x.createId === user?.customerId, createName: creator.fullName, profilePictureUrl: creator.profileImage });
            })
            .sort((firstComment, secondComment) => new Date(secondComment.dateCreated).getTime() - new Date(firstComment.dateCreated).getTime());
    }
);

export const selectSortedUnreadMessagesWithoutMineInfo = createSelector(
    selectSortedMessages,
    listingsSelector.selectCustomerListings,
    (messages, listings) => {
        return messages.reduce((messagesInfo, message) => {
            const listing = listings.find(x => x.hashCode === message.listingIdHashCode);

            return message.isMine || message.viewed ? messagesInfo : [...messagesInfo, { message, listing }];
        }, [] as MessageInfo[]);
    }
);

// TODO: rewrite without parameter - put listingIdHashCode in state or get it from router-state (need to implement)
export const selectListingComments = (listingIdHashCode: number) => createSelector(
    selectListingsComments,
    userSelector.selectUser,
    usersSelectors.selectCustomersAndAgents,
    (listingsComments, user, customersAndAgents) => {
        const comments = listingsComments[listingIdHashCode] ?? [];

        return uniqBy(comments, x => x.id || (x as CreateOperationListingComment).operationId)
            .map(x => {
                const creator = UsersService.getUserInfoOrRemovedAccount(customersAndAgents, x.createId);

                return { ...x, isMine: x.createId === user?.customerId, createName: creator.fullName, profilePictureUrl: creator.profileImage };
            })
            .sort((firstComment, secondComment) =>
                new Date(secondComment.dateCreated).getTime() - new Date(firstComment.dateCreated).getTime()
            );
    }
);

export const selectListingCommentsCount = (listingIdHashCode: number) => createSelector(
    selectListingComments(listingIdHashCode),
    comments => comments.length
);

export const selectListingNewCommentsCount = (listingIdHashCode: number) => createSelector(
    selectListingComments(listingIdHashCode),
    comments => comments.filter(comment => !comment.isMine && !comment.viewed).length
);

export const selectLastComment = (listingHashCode: number) => createSelector(
    selectListingComments(listingHashCode),
    comments => comments[0]
);

