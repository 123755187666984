import { createReducer, on } from '@ngrx/store';

import { FolderActionErrors } from '@folders/enums/folder-action-errors';
import { FolderManagementAction } from '@folders/enums/folder-management-action.enum';
import { Folder } from '@folders/models/folder';
import { FolderSavedSearch } from '@folders/models/folder-saved-search';
import * as foldersActions from '../actions/folders.actions';
import { ListingFolderIdsMapHelper } from '../helpers/listing-folder-ids-map.helper';
import { FoldersState } from '../states/folders.state';

const createFolder = (folder: Folder): Folder => {
    return new Folder(
        folder.id,
        folder.name,
        folder.createId,
        folder.createDateTime,
        folder.updateDateTime,
    );
};

const initialState = new FoldersState();

export const foldersReducer = createReducer(
    initialState,
    on(foldersActions.loadFolders, (state, { shouldSetLoading }) => ({ ...state, areFoldersLoaded: false, isLoading: shouldSetLoading })),
    on(foldersActions.loadFoldersFailed, (state) => ({ ...state, areFoldersLoaded: false, isLoading: false })),
    on(foldersActions.selectFolder, (state, { folderId }) => ({ ...state, selectedFolderId: folderId })),
    on(foldersActions.unselectFolder, (state) => ({ ...state, selectedFolderId: null })),
    on(foldersActions.loadFoldersSuccess, (state, action) => {
        return { ...state, folders: action.folders.map(createFolder), areFoldersLoaded: true, isLoading: false };
    }),
    on(foldersActions.updateSuccess, (state, { folderData }) => {
        return {
            ...state,
            folders: state.folders
                .map(folder => folder.id === folderData.id
                    ? createFolder({ ...folder, name: folderData.name ?? folder.name, updateDateTime: new Date() })
                    : createFolder(folder)
                )
        };
    }),
    on(foldersActions.updateFailed, (state, error) => {
        return {
            ...state,
            error: { error: error.errorKey as FolderActionErrors }
        };
    }),
    on(foldersActions.createSuccess, (state, { id, name, customerId }) => {
        return {
            ...state,
            folders: state.folders.concat([new Folder(id, name, customerId, new Date(), null)])
        };
    }),
    on(foldersActions.createFailed, (state, error) => {
        return {
            ...state,
            error: { error: error.errorKey as FolderActionErrors }
        };
    }),
    on(
        foldersActions.loadListingFolderIdsMappingsSuccess,
        (state, { listingFolderIdsMap }) => ({ ...state, listingFolderIdsMap })
    ),
    on(foldersActions.resetState, () => ({ ...initialState })),
    on(foldersActions.remove, (state, { model }) => {
        return {
            ...state,
            folders: state.folders.filter(folder => folder.id !== model.id)
        };
    }),
    on(foldersActions.removeFailed, (state, { error, folders }) => {
        return {
            ...state,
            error: { error: error.errorKey as FolderActionErrors },
            folders
        };
    }),
    on(foldersActions.manageListingsFolders, (state, { models }) => ({
        ...state,
        listingFolderIdsMap: ListingFolderIdsMapHelper.updateListingFolderIdsMap(
            state.listingFolderIdsMap,
            models.map(model => ({ ...model, listingsHashCodes: model.listings.map(({ hashCode }) => hashCode) })),
            false
        )
    })),
    on(foldersActions.manageListingsFoldersFailed, (state, { models }) => ({
        ...state,
        listingFolderIdsMap: ListingFolderIdsMapHelper.updateListingFolderIdsMap(
            state.listingFolderIdsMap,
            models.map(model => ({ ...model, listingsHashCodes: model.listings.map(({ hashCode }) => hashCode) })),
            true
        )
    })),
    on(foldersActions.updateFolderSavedSearch, (state, { currentFolderId, savedSearchId, savedSearchName }) => {
        const index = state.foldersSavedSearches.findIndex(x => x.savedSearchId === savedSearchId);

        if (index === -1) {
            const foldersSavedSearches = [...state.foldersSavedSearches, new FolderSavedSearch(currentFolderId, savedSearchId, savedSearchName)];

            return { ...state, foldersSavedSearches };
        }

        const foldersSavedSearches = [...state.foldersSavedSearches];

        foldersSavedSearches[index] = new FolderSavedSearch(currentFolderId, savedSearchId, savedSearchName);

        return { ...state, foldersSavedSearches };
    }),
    on(foldersActions.deleteSavedSearchFolders, (state, { savedSearchId }) => {
        const foldersSavedSearches = state.foldersSavedSearches.filter(x => x.savedSearchId !== savedSearchId);

        return { ...state, foldersSavedSearches };
    }),
    on(foldersActions.attachListingsToFolder, (state, { folderId, listingsIds }) => ({
        ...state,
        listingFolderIdsMap: ListingFolderIdsMapHelper.updateListingFolderIdsMap(
            state.listingFolderIdsMap,
            [{ folderId, listingsHashCodes: listingsIds, action: FolderManagementAction.Attach }],
            false
        )
    })),
    on(foldersActions.detachListingsFromFolder, (state, { folderId, listingsIds }) => ({
        ...state,
        listingFolderIdsMap: ListingFolderIdsMapHelper.updateListingFolderIdsMap(
            state.listingFolderIdsMap,
            [{ folderId, listingsHashCodes: listingsIds, action: FolderManagementAction.Detach }],
            false
        )
    })),
    on(foldersActions.setFolderMappings, (state, { updatedListingsFolderIdsMapping, updatedFolderNewMatchIds }) => {
        return {
            ...state,
            listingFolderIdsMap: updatedListingsFolderIdsMapping,
            folderNewMatches: updatedFolderNewMatchIds
        };
    }),
    on(foldersActions.setListingFolderMapping, (state, { listingFolderIdsMap }) => ({ ...state, listingFolderIdsMap })),
    on(foldersActions.removeSuccess, (state, { model: { id } }) => ({
        ...state,
        listingFolderIdsMap: state.listingFolderIdsMap.filter(({ folderId }) => folderId !== id),
        folderNewMatches: state.folderNewMatches.filter(folder => folder.id !== id)
    })),
    on(foldersActions.loadFolderSavedSearchesSuccess, (state, { folderSavedSearches }) => ({ ...state, foldersSavedSearches: folderSavedSearches })),
    on(foldersActions.loadFolderNewMatchesSuccess, (state, { folderNewMatches }) => ({ ...state, folderNewMatches })),
);