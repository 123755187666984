<div class="f-item" [ngClass]="{ 'f-item--active': checkboxState === 'checked', 'f-item--edit': isEditModeEnabled}">
    <rpc-icon class="f-item-icon" iconName="folder"></rpc-icon>
    <div class="f-item-title text-truncate" [title]="folder.name">{{ folder.name }}</div>
    <rpc-icon-button *ngIf="!isEditButtonHidden" class="f-item-btn" color="accent" title="FOLDER_SELECTION.TITLES.EDIT"
        iconName="edit" (clicked)="onEditClicked()">
    </rpc-icon-button>
    <rpc-checkbox class="f-item-checkbox" [state]="checkboxState" [canBeChangedToIndeterminate]="areSomeListingsInFolder"
        (stateChange)="onCheckboxChange($event)">
    </rpc-checkbox>
    <div class="f-item-edit" *ngIf="isEditModeEnabled">
        <add-edit-folder-form submitButtonText="FOLDER_SELECTION.TITLES.RENAME" [id]="folder.id" [name]="folder.name"
            (manageFolderClicked)="onRenameClicked($event)" (canceled)="onCancelClicked()">
        </add-edit-folder-form>
    </div>
</div>