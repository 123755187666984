import { Component, ComponentRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { DynamicComponentHostDirective } from '@core-directives/dynamic-component-host/dynamic-component-host';
import { SimpleChanges } from '@core-models/utilities/generic-simple-changes';
import { RpcTooltipDirective } from '@core-utils/rpc-tooltip/directive/rpc-tooltip.directive';
import { ActivitiesTooltipComponent } from '@listings/components/activities-tooltip/activities-tooltip.component';
import { ListingActivityConstants, ListingActivityDescription } from '@listings/constants/listing-activity-constants';
import { ListingActivities } from '@listings/enums/listing-activities.enum';
import { ListingActivity } from '@listings/models/listing/listing-activity';
import { MatchMediaService } from '@media/services/match-media.service';

@Component({
    selector: 'activity-button',
    templateUrl: './activity-button.component.html',
    styleUrls: ['./activity-button.component.scss']
})
export class ActivityButtonComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChildren(RpcTooltipDirective) public tooltipDirective: QueryList<RpcTooltipDirective>;

    @Input() public activities: ListingActivity[];
    @Input() public iconName?: string;
    @Input() public actionClass?: string;
    @Input() public actionTrigger?: boolean;
    @Input() public badgeIcon?: boolean;
    @Input() public enableCustomBackground?: boolean;
    @Input() public disabled: boolean;

    @Output() public activityClicked = new EventEmitter<ListingActivityDescription>();
    @Output() public activitiesClicked = new EventEmitter<ListingActivityDescription[]>();

    public calculatedActivities: {
        isPickListed: boolean,
        isLiked: boolean,
        isDisliked: boolean,
        isShown: boolean
    };

    public activityClass: string;
    public iconTitle: string;
    public listingActivities = ListingActivities;
    public isMobile?: boolean;
    public tooltipComponent = ActivitiesTooltipComponent;
    public tooltipData: { isLiked: boolean, isDisliked: boolean, isShown: boolean };
    public tooltip?: ActivitiesTooltipComponent;

    private readonly unsubscribe$ = new Subject<void>();

    constructor(private readonly matchMediaService: MatchMediaService) { }

    public ngOnInit(): void {
        this.matchMediaService.onMediaChange
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(mediaQuery => this.isMobile = mediaQuery === 'xs' || mediaQuery === 'sm' || mediaQuery === 'md');
    }

    public ngOnChanges(changes: SimpleChanges<ActivityButtonComponent>): void {
        if (changes.activities != null && changes.activities.currentValue != null) {
            const activities = changes.activities.currentValue;

            this.calculatedActivities = {
                isPickListed: activities != null
                    ? activities.some(listingActivity => listingActivity.id === ListingActivityConstants.PickListed.id)
                    : false,
                isLiked: activities != null
                    ? activities.some(listingActivity => listingActivity.id === ListingActivityConstants.Liked.id)
                    : false,
                isDisliked: activities != null
                    ? activities.some(listingActivity => listingActivity.id === ListingActivityConstants.Disliked.id)
                    : false,
                isShown: activities != null
                    ? activities.some(listingActivity => listingActivity.id === ListingActivityConstants.Shown.id)
                    : false
            };

            this.tooltipData = { ...this.calculatedActivities };

            if (this.tooltip != null) {
                this.tooltip.isLiked = this.tooltipData.isLiked;
                this.tooltip.isDisliked = this.tooltipData.isDisliked;
                this.tooltip.isShown = this.tooltipData.isShown;
            }

            this.iconName = this.getIconName();
            this.activityClass = this.getActivityClass();
            this.badgeIcon = this.hasBadgeIcon();
        }
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public tooltipInitialized(component: ComponentRef<DynamicComponentHostDirective>): void {
        this.tooltip = component.instance as unknown as ActivitiesTooltipComponent;
        this.tooltip?.activityClicked
            ?.pipe(takeUntil(this.unsubscribe$))
            ?.subscribe(activity => {
                this.activityClicked.emit(activity);
                this.tooltipDirective.forEach(t => t.hide(true));
            });
    }

    public onClick(): void {
        if (!this.calculatedActivities?.isLiked && !this.calculatedActivities?.isDisliked && !this.calculatedActivities?.isShown) {
            this.activitiesClicked.emit([ListingActivityConstants.Liked]);

            return;
        }

        const activities = [];

        if (this.calculatedActivities?.isLiked) {
            activities.push(ListingActivityConstants.Liked);
        }

        if (this.calculatedActivities?.isDisliked) {
            activities.push(ListingActivityConstants.Disliked);
        }

        if (this.calculatedActivities?.isShown) {
            activities.push(ListingActivityConstants.Shown);
        }

        this.activitiesClicked.emit(activities);
    }

    public onDoubleClick(): void {
        this.tooltipDirective.forEach(t => t.show());
    }

    public onPickListedClicked(): void {
        this.activityClicked.emit(ListingActivityConstants.PickListed);
    }

    public onLikedClicked(): void {
        this.activityClicked.emit(ListingActivityConstants.Liked);
    }

    public onDislikedClicked(): void {
        this.activityClicked.emit(ListingActivityConstants.Disliked);
    }

    public onShownClicked(): void {
        this.activityClicked.emit(ListingActivityConstants.Shown);
    }

    private getIconName(): string {
        return this.calculatedActivities?.isLiked
            ? 'like'
            : this.calculatedActivities?.isDisliked
                ? 'dislike'
                : this.calculatedActivities?.isShown && !this.hasAnyIntersectingActivity()
                    ? 'eye'
                    : 'like';
    }

    private getActivityClass(): string {
        return this.calculatedActivities?.isDisliked
            ? 'disliked'
            : this.calculatedActivities?.isLiked
                ? 'liked'
                : this.calculatedActivities?.isShown && !this.hasAnyIntersectingActivity()
                    ? 'shown'
                    : 'activity';
    }

    private hasAnyIntersectingActivity(): boolean {
        return this.calculatedActivities?.isLiked || this.calculatedActivities?.isDisliked;
    }

    private hasBadgeIcon(): boolean {
        return this.calculatedActivities?.isShown && this.hasAnyIntersectingActivity();
    }
}