import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { CompanyConfiguration } from '@app-config/models/company-configuration';
import * as appActions from '@core-layout/app/store/actions/app.actions';
import { ApiHttpClient } from '@core-services/api-http-client.service';
import { ApiError } from '@error/models/api-error';
import { AppConfigurationService } from '@app-config/services/app-configuration.service';
import { ApiDataResult } from '@core-models/api/api-result';
import { UserStoreService } from '@auth/store/services/user-store.service';
import * as invitationActions from '@auth/store/actions/invitation.actions';
import { SettingsStoreService } from '@settings/store/services/settings-store.service';

@Injectable()
export class AppEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly http: ApiHttpClient,
        private readonly appConfigurationService: AppConfigurationService,
        private readonly userStoreService: UserStoreService,
        private readonly settingsStoreService: SettingsStoreService,
    ) { }

    public readonly loadDomainCompanyConfiguration$ = createEffect(() => this.actions$.pipe(
        ofType(appActions.loadDomainCompanyConfiguration),
        tap(() => this.appConfigurationService.setInternalCompanyConfigurationLoaded(false)),
        switchMap(() => this.http.get('configuration/domain-company').pipe(
            map((companyConfiguration: CompanyConfiguration) => appActions.loadCompanyConfigurationSuccess({ companyConfiguration })),
            catchError((errorResponse: HttpErrorResponse) => of(appActions.loadCompanyConfigurationFailed(errorResponse.error as ApiError)))
        )),
    ));

    public readonly loadCompanyConfigurationByInternalCompanyId$ = createEffect(() => this.actions$.pipe(
        ofType(appActions.loadCompanyConfigurationByInternalId),
        tap(() => this.appConfigurationService.setInternalCompanyConfigurationLoaded(false)),
        switchMap(({ internalCompanyId }) => this.http.get(`company/${internalCompanyId}/configuration`).pipe(
            tap(() => this.appConfigurationService.setInternalCompanyConfigurationLoaded(true)),
            map((response: ApiDataResult<CompanyConfiguration>) => {
                return appActions.loadCompanyConfigurationSuccess({ companyConfiguration: response.result, companyId: internalCompanyId });
            }),
            catchError((errorResponse: HttpErrorResponse) => of(appActions.loadCompanyConfigurationFailed(errorResponse.error as ApiError)))
        )),
    ));

    public readonly loadCompanyConfigurationSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(appActions.loadCompanyConfigurationSuccess),
        tap(({ companyConfiguration }) => this.appConfigurationService.setCompany(companyConfiguration)),
        map(({ companyConfiguration, companyId }) => appActions.loadCompanyLogo({ companyConfiguration, companyId }))
    ));

    public readonly loadCompanyLogo$ = createEffect(() => this.actions$.pipe(
        ofType(appActions.loadCompanyLogo),
        concatLatestFrom(() => this.userStoreService.getCompanyId()),
        switchMap(([{ companyConfiguration, companyId },]) => {
            if (companyConfiguration.internalId != null) {
                return [appActions.setCompanyLogo({ companyLogo: null })];
            }

            return companyId == null
                ? EMPTY
                : this.http.get(`company/${companyId}/logo`).pipe(
                    map((response: ApiDataResult<string>) => appActions.setCompanyLogo({ companyLogo: response.result })),
                    catchError(() => of())
                );
        }),
    ));

    public readonly setCompanyLogo$ = createEffect(
        () => this.actions$.pipe(
            ofType(appActions.setCompanyLogo),
            concatLatestFrom(() => [this.appConfigurationService.configuration$, this.settingsStoreService.isDarkMode$]),
            tap(([{ companyLogo }, configuration, isDarkMode]) => this.appConfigurationService.setCompanyLogo(configuration.company, isDarkMode, companyLogo))
        ),
        { dispatch: false }
    );

    public readonly invitationGotSuccessful$ = createEffect(() => this.actions$.pipe(
        ofType(invitationActions.invitationGotSuccessful),
        concatLatestFrom(() => this.appConfigurationService.configuration$),
        switchMap(([{ agent, invitatingAgent }, { company }]) => {
            const internalCompanyId = invitatingAgent?.companyId ?? agent?.companyId;

            return company.internalId != null || internalCompanyId == null
                ? EMPTY
                : [appActions.loadCompanyConfigurationByInternalId({ internalCompanyId })];
        }))
    );
}