export class RemoveFolderListingsModel {
    public id: number;
    public isRemoveListings?: boolean;
    public listingIds: string[];

    constructor(
        id: number,
        listingIds: string[],
        isRemoveListings?: boolean
    ) {
        this.id = id;
        this.listingIds = listingIds;
        this.isRemoveListings = isRemoveListings;
    }

}