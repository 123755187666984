import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { OnboardingDialogComponent } from "./components/onboarding-dialog/onboarding-dialog.component";
import { TranslationLoaderService } from "@core-services/translation-loader.service";
import { TranslateModule } from "@ngx-translate/core";
import { locale as english } from './i18n/en';
import { RpcControlsModule } from "@core-controls/controls.module";
import { StoreModule } from "@ngrx/store";
import { onboardingReducer } from "./store/reducers/onboarding.reducer";
import { EffectsModule } from "@ngrx/effects";
import { OnboardingEffects } from "./store/effects/onboarding.effects";
import { MediaModule } from "../media/media.module";
import { NgLetModule } from "ng-let";

@NgModule({
    declarations: [OnboardingDialogComponent],
    imports: [
        CommonModule,
        TranslateModule,
        RpcControlsModule,
        MediaModule,
        NgLetModule,
        StoreModule.forFeature('onboarding', onboardingReducer),
        EffectsModule.forFeature([OnboardingEffects]),
    ]
})
export class OnboardingModule {
    constructor(private readonly translationLoaderService: TranslationLoaderService) {
        this.translationLoaderService.loadTranslations(english);
    }
}