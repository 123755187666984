import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

import { ListingApiService } from '@listings/store/services/listing-api.service';
import * as recentlyViewedListingsActions from '@listings/store/actions/recently-viewed-listings.actions';
import * as listingsActions from '@listings/store/actions/listings.actions';
import { ListingsStoreService } from '../services/listings-store.service';
import * as listingPriceChangesActions from '../actions/listing-price-changes.actions';

@Injectable()
export class RecentlyViewedListingsEffects {

    constructor(
        private readonly actions$: Actions,
        private readonly listingApiService: ListingApiService,
        private readonly listingsStoreService: ListingsStoreService

    ) { }

    public readonly loadRecentlyViewedListing$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(recentlyViewedListingsActions.loadRecentlyViewedListing),
                concatLatestFrom(() => this.listingsStoreService.areRecentlyViewedListingsLoaded$),
                switchMap(([{ countToLoad }, areRecentlyViewedListingsLoaded]) => {
                    return areRecentlyViewedListingsLoaded ? EMPTY : this.listingApiService.loadRecentlyViewedListings(countToLoad);
                })
            ));

    public readonly loadRecentlyViewedListingSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(recentlyViewedListingsActions.loadRecentlyViewedListingSuccess),
                concatLatestFrom(() => this.listingsStoreService.getAllListingsMedia()),
                switchMap(([{ recentlyViewedlistings }, medias]) => {
                    const { ids: listingIds, hashCodes } = recentlyViewedlistings.reduce(
                        (acc, { id, hashCode }) => {
                            const ids = medias[id] == null ? [...acc.ids, id] : acc.ids;

                            return { ids, hashCodes: [...acc.hashCodes, hashCode] };
                        },
                        { ids: [], hashCodes: [] } as { ids: string[], hashCodes: number[] }
                    );

                    return [
                        listingsActions.loadListingsMedia({ listingIds }),
                        listingPriceChangesActions.loadListingsPriceChanges({ hashCodes })
                    ];
                })
            ));
}