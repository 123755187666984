export const locale = {
    lang: 'en',
    data: {
        LOGIN: {
            TITLES: {
                WELCOME: 'Welcome to ',
                EXPLANATION: 'Enter your email and password please.',
                USERNAME: 'Email',
                PASSWORD: 'Password',
                LOGIN: 'LOG IN',
                LOGIN_FORM: 'Login',
                FORGOT_PASSWORD: 'Forgot password?',
                AGREE_TERMS: 'By logging in you agree to the',
                TERMS_CONDITIONS: 'Terms & Conditions',
                AND: 'and',
                PRIVACY_POLICY: 'Privacy Policy'
            },
            VALIDATION: {
                PASSWORD_REQUIRED_VALIDATION: 'Password is required',
                USERNAME_VALIDATION: 'Please enter a valid username',
                USERNAME_REQUIRED_VALIDATION: 'User name is required'
            },
            ERRORS: {
                SERVER: {
                    WRONG_CREDENTIALS: 'Username or password is wrong.'
                }
            }
        }
    }
};