import { createAction, props } from '@ngrx/store';

import { ApiError } from '@error/models/api-error';
import { ChildAccount } from '../../../profile-base/interfaces/child-account/child-account';
import { NewAccountDataModel, OldAccountDataModel } from '../../../profile-base/interfaces/customer-info/account-data-model';
import { CustomerInfo } from '../../../profile-base/interfaces/customer-info/customer-info';
import { UpdateCustomerInfoResponse } from '../../../profile-base/interfaces/customer-info/update-cuctomer-info-response';
import { UpdateCustomerInfoRequest } from '../../../profile-base/interfaces/customer-info/update-customer-info-request';
import { UpdatePasswordRequest } from '../../../profile-base/interfaces/customer-settings/update-password-request';

export const loadProfileData = createAction('[Profile] Load Profile Data');
export const loadProfileDataSuccessful = createAction('[Profile] Load Profile Data Successful', props<{ customerInfo: CustomerInfo }>());
export const loadProfileDataFailed = createAction('[Profile] Load Profile Data Failed', (error: ApiError) => error);

export const updateCustomerInfo = createAction('[Profile] Update Customer info', props<{ customerInfo: CustomerInfo }>());
export const customerInfoUpdating = createAction('[Profile] Customer info Updating', props<{ customerInfoChanges: UpdateCustomerInfoRequest, previousCustomerInfo: CustomerInfo }>());
export const updateCustomerInfoSuccessful = createAction('[Profile] Update Customer info Successful', props<{ customerInfo: UpdateCustomerInfoResponse }>());
export const updateCustomerInfoFailed = createAction('[Profile] Update Customer info Failed', props<{ error: ApiError, previousCustomerInfo: CustomerInfo }>());

export const updateProfileImage = createAction('[Profile] Update Profile Image', props<{ image: File }>());
export const updateProfileImageSuccessful = createAction('[Profile] Update Profile Image Successful');
export const updateProfileImageFailed = createAction('[Profile] Update Profile Image Failed', (error: ApiError) => error);

export const deleteProfileImage = createAction('[Profile] Delete Profile Image');
export const deleteProfileImageSuccessful = createAction('[Profile] Delete Profile Image Successful');
export const deleteProfileImageFailed = createAction('[Profile] Delete Profile Image Failed', (error: ApiError) => error);

export const updateAccountData = createAction(
    '[Profile] Update Account Data',
    props<{ newData: NewAccountDataModel, oldData: OldAccountDataModel }>()
);
export const updateAccountDataSuccessful = createAction('[Profile] Update Account Data Successful', props<{ firstName: string, lastName: string }>());
export const updateAccountDataFailed = createAction(
    '[Profile] Update Account Date Failed',
    props<{ error: ApiError, oldData: OldAccountDataModel }>()
);

export const updatePassword = createAction('[Profile] Update Password', props<UpdatePasswordRequest>());
export const updatePasswordSuccessful = createAction('[Profile] Update Password Successful');
export const updatePasswordFailed = createAction('[Profile] Update Password Failed', (error: ApiError) => error);

export const deactivateAccount = createAction('[Profile] Deactivate Account');
export const deactivateAccountSuccessful = createAction('[Profile] Deactivate Account Successful');
export const deactivateAccountFailed = createAction('[Profile] Deactivate Account Failed', (error: ApiError) => error);

export const additionalProfileDetailsOpening = createAction('[Profile] Additional Profile Details Opening', props<{ profile: ChildAccount }>());