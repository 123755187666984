import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

import * as appointmentsActions from '@appointments/store/actions/appointments.actions';
import * as listingActions from '@listings/store/actions/listings.actions';
import { ListingApiService } from '@listings/store/services/listing-api.service';
import { ListingsStoreService } from '../services/listings-store.service';

@Injectable()
export class RestoreListingEffects {

    constructor(
        private readonly actions$: Actions,
        private readonly listingApiService: ListingApiService,
        private readonly listingsStoreService: ListingsStoreService,
    ) { }

    public readonly restoreListingsRequested$ = createEffect(() => this.actions$.pipe(
        ofType(listingActions.restoreRequeted),
        concatLatestFrom(() => [
            this.listingsStoreService.baseListings$,
            this.listingsStoreService.getListings(),
            this.listingsStoreService.listingsActivities$,
            this.listingsStoreService.listingsNewMatches$
        ]),
        map((([{ listingsIds }, listings, listingsInfo, listingsActivities, listingsNewMatches]) => {
            return listingActions.restore({ listingIds: listingsIds.filter(id => listingsInfo[id].isDeleted), listings, listingsActivities, listingsNewMatches });
        }))
    ));

    public readonly restore$ = createEffect(() => this.actions$.pipe(
        ofType(listingActions.restore),
        switchMap((({ listingIds, isFromStateOnly, listings, listingsActivities, listingsNewMatches }) => {
            return isFromStateOnly ? EMPTY : this.listingApiService.restore(listingIds, listings, listingsActivities, listingsNewMatches);
        }))
    ));

    public readonly restoreSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(listingActions.restoreSuccess),
        map(() => appointmentsActions.loadListingsAppointments())
    ));
}
