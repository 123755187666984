import { CreateOperationListingComment } from '@comments/models/comments/listing-comment';

export class CommentCreateActionModel {
    public readonly listingId: string;
    public readonly listingAddress: string;
    public readonly createOperationListingComment: CreateOperationListingComment;
    public readonly isNewMatch: boolean;

    constructor(
        listingId: string,
        listingAddress: string,
        createOperationListingComment: CreateOperationListingComment,
        isNewMatch = false
    ) {
        this.listingId = listingId;
        this.listingAddress = listingAddress;
        this.createOperationListingComment = createOperationListingComment;
        this.isNewMatch = isNewMatch;
    }
}