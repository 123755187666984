import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationExtras, NavigationStart, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

import { RpcRoute } from '../models/rpc-route';

/**
 * Type describes all possible values available for data property during Routes declaration.
 */
export type RouteDataProperty = 'title' | 'hasThemeSidebar' | 'hideToolbarLoginRedirect' | 'applyDefaultLayout';

@Injectable({ providedIn: 'root' })
export class RouteService {

    private readonly RETURN_URL_PATTERN = /^(?<returnUrl>.*)\?tab=(?<tab>.*)/;
    private readonly RETURN_URL_QUERY_PARAMETER_NAME = 'returnUrl';
    private readonly TAB_QUERY_PARAMETER_NAME = 'tab';

    private lastSavedNavigatedRoute: RpcRoute;

    public get lastManuallySavedNavigatedRoute(): RpcRoute {
        return this.lastSavedNavigatedRoute;
    }

    constructor(
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute
    ) { }

    public navigate(rpcRoute: RpcRoute, extras?: NavigationExtras, returnRoute: RpcRoute = null, ...params: string[]): Promise<boolean> {
        if (returnRoute != null) {
            this.lastSavedNavigatedRoute = returnRoute;
        }

        extras = extras == null ? { skipLocationChange: false } : extras;

        return this.router.navigate([RouteService.getNavigationRoute(rpcRoute, ...params)], extras);
    }

    public navigateToReturnUrl(): Promise<boolean> {
        const { queryParams } = this.activatedRoute.snapshot;

        if (queryParams[this.RETURN_URL_QUERY_PARAMETER_NAME] != null) {
            return this.router.navigate([queryParams[this.RETURN_URL_QUERY_PARAMETER_NAME]], { state: { tab: queryParams?.tab } });
        }

        return this.navigate(RpcRoute.FindHome);
    }

    public getNavigationState<TState>(): TState {
        const navigation = this.router.getCurrentNavigation();

        return navigation.extras.state as TState;
    }

    public getCurrentRouteDataProperty<TResult>(routeDataProperty: RouteDataProperty, routerEvent: Function = NavigationEnd): Observable<TResult> {
        return this.getNavigateEndCurrentRoute(routerEvent)
            .pipe(
                mergeMap(route => route.data),
                map(data => {
                    if (data != null && data[routeDataProperty] != null) {
                        return data[routeDataProperty];
                    }

                    return null;

                })
            );
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    public getNavigateEndCurrentRoute(routerEvent: Function = NavigationEnd): Observable<ActivatedRoute> {
        return this.router.events
            .pipe(
                filter(event => event instanceof routerEvent),
                map(() => this.activatedRoute),
                map(route => {
                    while (route.firstChild != null) {
                        route = route.firstChild;
                    }

                    return route;
                }),
                filter(route => route.outlet === 'primary')
            );
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    public getNavigateStartCurrentRoute(routerEvent: Function = NavigationStart): Observable<ActivatedRoute> {
        return this.router.events
            .pipe(
                filter(event => event instanceof routerEvent),
                map(() => this.activatedRoute),
                map(route => {
                    while (route.firstChild != null) {
                        route = route.firstChild;
                    }

                    return route;
                }),
                filter(route => route.outlet === 'primary')
            );
    }

    public formReturnUrlNavigationExtras(state: RouterStateSnapshot): NavigationExtras {
        const match = this.RETURN_URL_PATTERN.exec(state.url);
        const returnUrl = match?.groups[this.RETURN_URL_QUERY_PARAMETER_NAME] ?? state.url;
        const tab = match?.groups[this.TAB_QUERY_PARAMETER_NAME];

        return { queryParams: { returnUrl, tab } };
    }

    private static getNavigationRoute(route: RpcRoute, ...params: string[]): string {
        let index = -1;

        return route.replace(/:[a-zA-Z?]+/g, match => {
            index += 1;

            return params != null && params.length > index && params[index] != null ? params[index] : match;
        });
    }

}