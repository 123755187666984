import { ActionCreator, ReducerTypes, on } from '@ngrx/store';

import { ListingsState } from '@listings/store/states/listings.state';
import * as newMatchesActions from '../actions/new-matches.actions';

export const newMatchesReducerTypes: ReducerTypes<ListingsState, readonly ActionCreator[]>[] = [
    on(newMatchesActions.loadListingsNewMatches, (state, { shouldSetLoading }) => {
        return ({ ...state, newMatchesLoading: shouldSetLoading ?? true, newMatchesLoaded: false });
    }),
    on(newMatchesActions.loadListingsNewMatchesSuccess, (state, { shouldSetLoaded }) => {
        return ({ ...state, newMatchesLoading: false, newMatchesLoaded: shouldSetLoaded ?? true });
    }),
    on(newMatchesActions.loadListingsNewMatchesFailed, state => {
        return ({ ...state, newMatchesLoading: false, newMatchesLoaded: false });
    }),
    on(newMatchesActions.setListingsNewMatches, (state, { newMatches }) => ({ ...state, listingsNewMatches: newMatches })),
];