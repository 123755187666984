import { Injectable } from '@angular/core';
import { combineLatest, filter, Subject, take } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import { OnboardingDialogComponent } from '../components/onboarding-dialog/onboarding-dialog.component';
import { OnboardingStoreService } from '../store/services/onboarding-store.service';

@Injectable({ providedIn: 'root' })
export class OnboardingDialogPopupService {
    private readonly unsubscribe$ = new Subject<void>();

    constructor(
        private readonly matDialog: MatDialog,
        private readonly onboardingStoreService: OnboardingStoreService,
    ) { }

    public openDialog(): void {
        this.onboardingStoreService.loadOnboardingData();

        combineLatest([
            this.onboardingStoreService.itemToDisplay$,
            this.onboardingStoreService.isLoading$
        ]).pipe(
            filter(([, loading]) => !loading),
            take(1),
        ).subscribe(([item, loading]) => {
            if (item != null && !loading) {
                const options = {
                    autoFocus: false,
                    restoreFocus: false,
                    panelClass: 'rpc-onboarding-modal'
                };

                this.matDialog.open(OnboardingDialogComponent, options);
            }
            else {
                this.onboardingStoreService.resetState();
            }
        });
    }
}