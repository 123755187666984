import { createReducer, on } from '@ngrx/store';

import { COMMENT_ERRORS_MAP } from '@comments/constants/comment-errors.constants';
import { CreateOperationListingComment, ListingComment } from '@comments/models/comments/listing-comment';
import * as commentsActions from '@comments/store/actions/comments.actions';
import { CommentsState } from '@comments/store/states/comments.state';
import { CommentActionErrors } from '../enums/comment-action-errors';

const createListingComment = (comment: ListingComment | CreateOperationListingComment): ListingComment => {
    return new ListingComment(
        comment.listingIdHashCode,
        comment.comment,
        comment.dateCreated,
        comment.createId,
        comment.createName,
        comment.profilePictureUrl,
        comment.viewed,
        comment.dateUpdated,
        comment.id,
    );
};

const initialState = new CommentsState();

export const commentsReducer = createReducer(
    initialState,
    on(commentsActions.loadListingsComments, (state, action) => {
        return { ...state, isSingleCommentLoaded: false, commentsLoaded: false, isCommentsLoading: true };
    }),
    on(commentsActions.loadListingsCommentsSuccess, (state, action) => {
        return { ...state, listingsComments: action.comments, isSingleCommentLoaded: true, commentsLoaded: true, isCommentsLoading: false };
    }),
    on(commentsActions.loadListingsCommentsFailed, (state, action) => {
        return {
            ...state,
            error: { error: CommentActionErrors.ListingsCommentsLoading },
            isSingleCommentLoaded: false,
            commentsLoaded: false,
            isCommentsLoading: false
        };
    }),
    on(commentsActions.loadListingComments, (state, action) => {
        return { ...state, isSingleCommentLoaded: false };
    }),
    on(commentsActions.loadListingCommentsSuccess, (state, action) => {
        const listingsComments = { ...state.listingsComments };

        if (action.comments != null && action.comments.length > 0) {
            listingsComments[action.comments[0].listingIdHashCode] = action.comments.map(createListingComment);
        }

        return { ...state, listingsComments, isSingleCommentLoaded: true };
    }),
    on(commentsActions.loadListingCommentsFailed, (state, action) => {
        return { ...state, error: { error: CommentActionErrors.ListingCommentsLoading }, isSingleCommentLoaded: false };
    }),
    on(commentsActions.createListingComment, (state, { createOperationListingComment }) => {
        const listingsComments = { ...state.listingsComments };
        const { listingIdHashCode, comment, dateCreated, createId, createName, operationId } = createOperationListingComment;

        const listingComments = listingsComments[listingIdHashCode];
        const updatedComments: (ListingComment | CreateOperationListingComment)[] = [];

        if (listingComments != null) {
            updatedComments.push(...listingComments);
        }

        // Insert temporary comment with operation id
        updatedComments.push(new CreateOperationListingComment(listingIdHashCode, comment, dateCreated, createId, createName, operationId));

        listingsComments[listingIdHashCode] = updatedComments;

        return { ...state, listingsComments };
    }),
    on(commentsActions.createListingCommentSuccess, (state, action) => {
        const listingsComments = { ...state.listingsComments };
        const { listingIdHashCode, operationId, createName } = action.request.createOperationListingComment;
        const listingComments = listingsComments[listingIdHashCode];

        const updatedComments = listingComments == null ? [] : listingComments.map(comment => {
            // Replace temporary comment with operation id to store db comment id when request completed
            if (operationId === (comment as CreateOperationListingComment).operationId) {

                return createListingComment({ ...action.comment, createName });
            }

            return { ...comment };
        });

        listingsComments[listingIdHashCode] = updatedComments;

        return { ...state, listingsComments };
    }),
    on(commentsActions.createListingCommentFailed, (state, action) => {
        const listingsComments = { ...state.listingsComments };
        const listingComments = listingsComments[action.request.createOperationListingComment.listingIdHashCode];

        const updatedComments = listingComments
            // Filter temporary comment with operation id as request failed
            .filter(comment =>
                action.request.createOperationListingComment.operationId !== (comment as CreateOperationListingComment).operationId)
            .map(comment => ({ ...comment }));

        listingsComments[action.request.createOperationListingComment.listingIdHashCode] = updatedComments;
        const error = COMMENT_ERRORS_MAP.has(action.error.errorKey)
            ? COMMENT_ERRORS_MAP.get(action.error.errorKey)
            : CommentActionErrors.CommentCreation;

        return { ...state, listingsComments, error: { error } };
    }),
    on(commentsActions.markCommentAsViewedSuccess, (state, { listingIdHashCode, commentIds }) => {
        const comments = { ...state.listingsComments };
        const listingComments = comments[listingIdHashCode] ?? [];

        const updatedComments = listingComments.map(comment => {
            if (commentIds.includes(comment.id)) {
                return new ListingComment(
                    comment.listingIdHashCode,
                    comment.comment,
                    comment.dateCreated,
                    comment.createId,
                    comment.createName,
                    comment.profilePictureUrl,
                    true,
                    comment.dateUpdated,
                    comment.id,
                );
            }

            return createListingComment(comment);

        });

        comments[listingIdHashCode] = updatedComments;

        return { ...state, listingsComments: comments };
    }),
    on(commentsActions.markCommentAsViewedFailed, (state, action) => {
        const { listingIdHashCode, commentIds } = action.request;
        const comments = { ...state.listingsComments };
        const listingComments = comments[listingIdHashCode] ?? [];

        const updatedComments = listingComments.map(comment => {
            if (commentIds.includes(comment.id)) {
                return new ListingComment(
                    comment.listingIdHashCode,
                    comment.comment,
                    comment.dateCreated,
                    comment.createId,
                    comment.createName,
                    comment.profilePictureUrl,
                    false,
                    comment.dateUpdated,
                    comment.id,
                );
            }

            return createListingComment(comment);

        });

        comments[listingIdHashCode] = updatedComments;

        return { ...state, listingsComments: comments, error: { error: CommentActionErrors.MarkAsViewed } };
    }),
    on(commentsActions.updateListingComment, (state, action) => {
        const listingsComments = { ...state.listingsComments };
        const listingComments = listingsComments[action.listingIdHashCode];
        const updatedComments = listingComments.map(comment => {
            if (comment.id === action.oldListingComment.id) {
                return new ListingComment(
                    comment.listingIdHashCode,
                    action.newComment,
                    comment.dateCreated,
                    comment.createId,
                    comment.createName,
                    comment.profilePictureUrl,
                    false,
                    new Date(),
                    comment.id,
                );
            }

            return createListingComment(comment);

        });

        listingsComments[action.listingIdHashCode] = updatedComments;

        return { ...state, listingsComments };
    }),
    on(commentsActions.updateListingCommentFailed, (state, action) => {
        const listingsComments = { ...state.listingsComments };
        const listingComments = listingsComments[action.request.listingIdHashCode];
        const updatedComments = listingComments.map(comment => {
            if (comment.id === action.request.oldListingComment.id) {
                return new ListingComment(
                    action.request.listingIdHashCode,
                    action.request.oldListingComment.comment,
                    action.request.oldListingComment.dateCreated,
                    action.request.oldListingComment.createId,
                    action.request.oldListingComment.createName,
                    action.request.oldListingComment.profilePictureUrl,
                    action.request.oldListingComment.viewed,
                    action.request.oldListingComment.dateUpdated,
                    action.request.oldListingComment.id,
                );
            }

            return createListingComment(comment);

        });

        listingsComments[action.request.listingIdHashCode] = updatedComments;
        const error = COMMENT_ERRORS_MAP.has(action.error.errorKey)
            ? COMMENT_ERRORS_MAP.get(action.error.errorKey)
            : CommentActionErrors.CommentUpdate;

        return { ...state, listingsComments, error: { error } };
    }),
    on(commentsActions.loadCommentSuccess, (state, { comment }) => {
        const listingsComments = { ...state.listingsComments };
        const updatedListingComments = [...(listingsComments[comment.listingIdHashCode] ?? []).filter(x => x.id !== comment.id), comment];

        listingsComments[comment.listingIdHashCode] = updatedListingComments;

        return { ...state, listingsComments };
    }),
    on(commentsActions.resetState, () => ({ ...initialState }))
);