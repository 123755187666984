import { createFeatureSelector, createSelector } from '@ngrx/store';

import { InitialFolderStateMode } from '@folders/enums/initial-folder-state-mode.enum';
import { MyListingsState } from '@my-listings/store/states/my-listings.state';
import { LayoutFilterSettingsConstants } from '@settings/store/constants.ts/layout-filter-settings.constants';
import * as usersSelectors from '@users/store/selectors/users.selectors';
import { removeChildFiltersWithoutParent } from './helpers/my-listings-selector.helper';
import { MyListingsFilters } from '@my-listings/models/filter/my-listings-filters.enum';

export const myListingsStateSelector = createFeatureSelector<MyListingsState>('my-listings');

export const selectSort = createSelector(
    myListingsStateSelector,
    listingState => listingState == null ? null : listingState.sort
);

export const selectFilter = createSelector(
    myListingsStateSelector,
    listingState => listingState == null ? null : listingState.filter
);

export const selectFilterVisibilityMap = createSelector(
    myListingsStateSelector,
    listingState => listingState == null ? null : listingState.filtersVisibilityMap
);

export const selectAppliedHiddenFiltersCount = createSelector(
    selectFilter,
    selectFilterVisibilityMap,
    (filter, selectFilterVisibilityMap) => {
        const {
            category,
            usersFilter,
            upcomingOpenHouseStartDate,
            upcomingOpenHouseEndDate,
            upcomingAppointmentStartDate,
            upcomingAppointmentEndDate,
            ...otherFilters
        } = filter;
        const isRangeFilterApplied = <T>(start: T, end: T): boolean => Boolean(start) || Boolean(end);

        const allHiddenFiltersKeys = Object.keys(selectFilterVisibilityMap).filter(key => !selectFilterVisibilityMap[key]);

        const nestedFiltersMap = new Map<MyListingsFilters, MyListingsFilters[]>([
            [MyListingsFilters.hasComments, [MyListingsFilters.hasReadComments, MyListingsFilters.hasUnreadComments]],
            [MyListingsFilters.hasAppointments, [MyListingsFilters.hasUpcomingAppointments, MyListingsFilters.hasPastOrDeclinedAppointments]]
        ]);

        const hiddenOtherFilters = removeChildFiltersWithoutParent(allHiddenFiltersKeys, nestedFiltersMap).map(key => otherFilters[key]);

        const allAppliedHiddenFiltersCount = [
            isRangeFilterApplied(upcomingOpenHouseStartDate, upcomingOpenHouseEndDate),
            isRangeFilterApplied(upcomingAppointmentStartDate, upcomingAppointmentEndDate),
            usersFilter !== LayoutFilterSettingsConstants.allUsers,
            ...hiddenOtherFilters,
        ].filter(Boolean).length;

        return allAppliedHiddenFiltersCount;
    }
);

export const selectSelectedUserFilter = createSelector(
    myListingsStateSelector,
    listingState => listingState == null ? null : listingState.selectedUsersFilter
);

export const selectSelectedViewMode = createSelector(
    myListingsStateSelector,
    listingState => listingState == null ? null : listingState.viewMode
);

export const selectMapSizePercent = createSelector(
    myListingsStateSelector,
    listingState => listingState == null ? null : listingState.mapSizePercent
);

export const selectedViewType = createSelector(
    myListingsStateSelector,
    listingState => listingState == null ? null : listingState.viewType
);

export const selectSelectedUsersCountForTitle = createSelector(
    usersSelectors.selectCustomersAndAgents,
    selectSelectedUserFilter,
    (allUsers, selectedUsers) => {
        if (allUsers.length === selectedUsers.length || selectedUsers.length === 0) {
            return null;
        }
        else if (selectedUsers.length === 1) {
            return 1;
        }

        return selectedUsers.length;
    }
);

export const selectUsersAvatarsInfo = createSelector(
    usersSelectors.selectCustomersAndAgents,
    selectSelectedUserFilter,
    (allUsers, selectedUsers) => {
        if (allUsers.length === selectedUsers.length || selectedUsers.length === 0) {
            return null;
        }
        else if (selectedUsers.length === 1) {
            return allUsers.filter(x => x.customerId === selectedUsers[0]);
        }

        return allUsers.filter(user => selectedUsers.includes(user.customerId));
    }
);

export const selectUsersFilterOptionsCount = createSelector(
    usersSelectors.selectCustomersAndAgents, (sharedPortfolio) => sharedPortfolio.length
);

export const selectInitialFolderStateMode = createSelector(
    myListingsStateSelector,
    state => state?.initialFolderStateMode ?? InitialFolderStateMode.Default
);