import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { catchError, map, Observable, of } from 'rxjs';

import { ApiDataResult } from '@core-models/api/api-result';
import { ApiHttpClient } from '@core-services/api-http-client.service';
import { ApiError } from '@error/models/api-error';
import * as onboardingActions from '../actions/onboarding.actions';
import { OnboardingData } from '../../models/onboarding-data';

@Injectable({ providedIn: 'root' })
export class OnboardingApiService {
    constructor(private readonly http: ApiHttpClient) { }

    public loadOnboardingData(): Observable<Action> {
        return this.http.get('company/onboarding-data').pipe(
            map((response: ApiDataResult<OnboardingData[]>) => {
                return response.error != null
                    ? onboardingActions.loadOnboardingDataFailed({ error: response.error as ApiError })
                    : onboardingActions.loadOnboardingDataSuccess({ data: response.result });
            }),
            catchError((errorResponse: HttpErrorResponse) =>
                of(onboardingActions.loadOnboardingDataFailed({ error: errorResponse.error as ApiError }))
            )
        );
    }

    public markAsViewed(slideIds: number[]): Observable<Action> {
        return this.http.post('profile/mark-onboarding-slides-viewed', { slideIds }).pipe(
            map(() => onboardingActions.markAsViewedSuccess()),
            catchError((error) => {
                return of(onboardingActions.markAsViewedFailed(error));
            })
        );
    }
}