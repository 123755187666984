import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';

import * as usersActions from '../actions/users.actions';
import { UsersApiService } from '../services/users-api.service';
import * as profileActions from '@profile/store/actions/profile.actions';
import { UsersStoreService } from '../services/users-store.service';
import { UserStoreService } from '@auth/store/services/user-store.service';
import { UserBaseInfo } from '@users/models/user-base-info';

@Injectable()
export class UsersEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly usersApiService: UsersApiService,
        private readonly usersStoreService: UsersStoreService,
        private readonly userStoreService: UserStoreService,
    ) { }

    public readonly loadCustomers$ = createEffect(() => this.actions$.pipe(
        ofType(usersActions.loadCustomers),
        switchMap(() => this.usersApiService.loadCustomers())
    ));

    public readonly updateAccountDataSuccessful$ = createEffect(() => this.actions$.pipe(
        ofType(profileActions.updateAccountDataSuccessful),
        concatLatestFrom(() => [this.usersStoreService.customers$, this.userStoreService.getUser()]),
        map(([{ firstName, lastName }, currentCustomers, user]) => {
            const customers = currentCustomers.map((x): UserBaseInfo => {
                return x.customerId === user.customerId ? { ...x, fullName: `${firstName} ${lastName}` } : x;
            });

            return usersActions.setCustomers({ customers });
        })
    ));
}