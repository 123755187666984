<div class="saved-search-info" [class.saved-search-info--editable]="isEditable"
    [class.saved-search-info--active]="isActive" (click)="onSavedSearchClick()">

    <div class="saved-search-info-icon-wrap">

        <rpc-icon class="saved-search-info-icon" [iconName]="'saved-search'">
        </rpc-icon>

    </div>

    <div class="saved-search-info-title">

        <div class="saved-search-info-title-text" [title]="savedSearch.name">{{savedSearch.name}}</div>

        <div class="saved-search-info-title-label"
            *ngLet="savedSearch.criteria.categoryId === listingCategories.Sales as isSales"
            [class.saved-search-info-title-label--sale]="isSales"
            [class.saved-search-info-title-label--rental]="!isSales"
            [title]="(isSales ? 'SAVED_SEARCH_INFO.TITLES.BUY' : 'SAVED_SEARCH_INFO.TITLES.RENT') | translate">


            <div class="saved-search-info-title-label-icon">
                {{isSales ? 'B' : 'R'}}
            </div>

            <div class="saved-search-info-title-label-text">
                {{(isSales ? 'SAVED_SEARCH_INFO.TITLES.BUY' : 'SAVED_SEARCH_INFO.TITLES.RENT') | translate}}
            </div>

        </div>

    </div>

    <div class="saved-search-info-subtitle" [class.saved-search-info-subtitle--folder]="savedSearch.folderName != null">

        <div class="saved-search-info-subtitle-item saved-search-info-subtitle-item--creator"
            [title]="savedSearch.creator.fullName">

            <rpc-icon class="saved-search-info-subtitle-item-icon" [iconName]="'person'">
            </rpc-icon>

            <div class="saved-search-info-subtitle-item-text">{{savedSearch.creator.fullName}}</div>

        </div>

        <div class="saved-search-info-subtitle-item saved-search-info-subtitle-item--folder"
            *ngIf="savedSearch.folderName !=null" [title]="savedSearch.folderName">

            <rpc-icon class="saved-search-info-subtitle-item-icon" [iconName]="'folder'">
            </rpc-icon>

            <div class="saved-search-info-subtitle-item-text">{{ savedSearch.folderName }}</div>

        </div>

    </div>

    <rpc-icon-button class="saved-search-info-edit" *ngIf="isEditable" [iconName]="'edit'" [title]="'Edit'"
        (clicked)="onEditClick($event)">
    </rpc-icon-button>

</div>