<div class="default-image-wrap" [class.default-background-image]="useBackgroundImage"
    [class.default-image-wrap--zoom]="enableZoom && !catchedError && !isDummy && (!!image || imageSrc != null)"
    [class.default-image-wrap--error]="image == null || catchedError">

    <img *ngIf="catchedError" class="broken-image" [src]="defaultImageUrl" alt="">

    <img *ngIf="imageSrc != null && !catchedError" class="main-image" [class.main-image--default]="isDummy"
        [src]="imageSrc" alt="" rpcOnLoad (loaded)="onImageLoaded()" (error)="onError($event)">

    <div *ngIf="catchedError && useBackgroundImage" class="broken-background-image"
        [style]="{ 'background-image': 'url(' + defaultImageUrl + ')' }">
    </div>

    <div *ngIf="!enableZoom && !!image && useBackgroundImage && !catchedError" class="main-background-image"
        [class.main-background-image--default]="isDummy" [style]="{ 'background-image': 'url(' + image + ')' }">
    </div>

    <div *ngIf="hasBackdrop && !catchedError" class="default-image-backdrop"
        [style.background-image]="'url(' + image + ')'">
    </div>

    <ng-container *ngIf="enableZoom && !catchedError && !isDummy && (!!image || imageSrc != null)">

        <pinch-zoom class="main-zoom-img-wrap" *ngIf="useBackgroundImage" [disablePan]="true">

            <div class="main-background-image" [style]="{ 'background-image': 'url(' + image + ')' }">
                {{ image }}
            </div>

        </pinch-zoom>

        <pinch-zoom class="main-zoom-img-wrap" *ngIf="!useBackgroundImage" [disablePan]="true">

            <img class="main-zoom-img" [src]="imageSrc" alt="">

        </pinch-zoom>

    </ng-container>

    <custom-spinner *ngIf="enableSpinner && isImageLoading" class="default-image-spinner" [showImage]="false">
    </custom-spinner>

</div>