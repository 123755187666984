export const locale = {
    lang: 'en',
    data: {
        TOOLBAR: {
            TITLES: {
                SHOW_MENU: 'Show Menu',
                SHOW_NOTIFICATIONS: 'Show Notifications',
                MY_LISTINGS: 'Portfolio',
                FIND_HOME: 'Find Your Home',
                MY_APPOINTMENTS: 'Appointments',
                PROFILE: 'My Profile',
                SETTINGS: 'Settings',
                INVITE_USER: 'Invite User',
                LOGOUT: 'Logout',
                CHANGE_AGENT: 'Change Agent',
                CONTACT_AGENT: 'Contact Agent',
                KNOWLEDGE_BASE: 'Knowledge Base',
                SAVED_SEARCHES: 'Saved Searches',
            },
            TOOLTIPS: {
                LISTINGS_IN_PORTFOLIO: '{{count}} Listing(s) in your Portfolio',
                NEW_LISTINGS_IN_PORTFOLIO: '{{count}} New Listing(s) in your Portfolio',
                APPOINTMENTS_IN_PORTFOLIO: '{{count}} Upcoming Appointment(s)',
                NEW_UPCOMING_APPOINTMENTS_IN_PORTFOLIO: '{{count}} New Upcoming Appointment(s)',
            }
        }
    }
};