import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PlatformService {
    constructor(
        private readonly platform: Platform) { }

    public isMobile() {
        return this.platform.ANDROID || this.platform.IOS;
    }
}