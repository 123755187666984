<div class="mobile-side-wrap">
    <div *ngLet="{customerFullName: customerFullName$ | async, customerProfileImage: customerProfileImage$ | async} as customer"
        class="mobile-customer-info">
        <avatar-initials mat-icon-button class="mobile-customer-info-avatar" [userFullName]="customer.customerFullName"
            [userProfileImageUrl]="customer.customerProfileImage">
        </avatar-initials>
        <div class="mobile-customer-info-name">{{ customer.customerFullName }}</div>
    </div>

    <ng-container *ngIf="(isSettingsLoading$ | async) === false">

        <div class="mobile-nav">

            <ng-container *ngIf="canSearchForListings$ | async">

                <rpc-button class="mobile-nav-item" [title]="'SIDE_MENU.TITLES.FIND_HOME'"
                    [link]="RpcRoute.SearchListings" [routerLinkActive]="'active'">
                </rpc-button>

                <ng-container
                    *ngLet="{savedSearchesInfo: savedSearchesInfo$ | async, activeSavedSearchId:activeSavedSearchId$ | async} as savedSearchesData">

                    <rpc-menu-button class="mobile-nav-item" #savedSearchesMenuButton
                        [title]="'TOOLBAR.TITLES.SAVED_SEARCHES'" [menuActionClass]="'saved-search-info-dropdown'"
                        [disabled]="savedSearchesData.savedSearchesInfo.length === 0">

                        <ng-container
                            *ngFor="let savedSearchInfo of savedSearchesData.savedSearchesInfo; let index = index;">

                            <saved-search-info class="saved-search-info-item" [savedSearch]="savedSearchInfo"
                                [isEditable]="savedSearchInfo.isEditable"
                                [isActive]="savedSearchInfo.id === savedSearchesData.activeSavedSearchId"
                                (clicked)="onSavedSearchSelected(savedSearchInfo.id)"
                                (edit)="onEditSavedSearch(savedSearchInfo.id)">
                            </saved-search-info>

                            <mat-divider class="saved-search-info-divider"
                                *ngIf="index < (savedSearchesData.savedSearchesInfo.length - 1)">
                            </mat-divider>

                        </ng-container>

                    </rpc-menu-button>

                </ng-container>

            </ng-container>

            <rpc-button *ngLet="unviewedListingsCount$ | async as unviewedListingsCount" class="mobile-nav-item"
                [link]="portfolioRouterLink$ | async" [routerLinkActive]="'active'"
                [title]="'SIDE_MENU.TITLES.MY_LISTINGS'" [badgeCountAlert]="unviewedListingsCount" [alt]="unviewedListingsCount > 0
                    ? ('TOOLBAR.TOOLTIPS.NEW_LISTINGS_IN_PORTFOLIO'  | translate : {count: unviewedListingsCount})
                    : 'TOOLBAR.TITLES.MY_LISTINGS'">
            </rpc-button>
            <rpc-button
                *ngLet="{unviewedAppointmentsCount: unviewedAppointmentsCount$ | async, appointmentsCount: appointmentsCount$ | async }as appointmentCounts"
                class="mobile-nav-item" [link]="RpcRoute.Appointments" [routerLinkActive]="'active'"
                [title]="'SIDE_MENU.TITLES.MY_APPOINTMENTS'" [badgeCount]="appointmentCounts.appointmentsCount"
                [badgeCountAlert]="appointmentCounts.unviewedAppointmentsCount" [alt]="appointmentCounts.unviewedAppointmentsCount > 0 
                ? ('TOOLBAR.TOOLTIPS.NEW_UPCOMING_APPOINTMENTS_IN_PORTFOLIO'  | translate : {count: (appointmentCounts.unviewedAppointmentsCount)})
                : appointmentCounts.appointmentsCount > 0
                    ? ('TOOLBAR.TOOLTIPS.APPOINTMENTS_IN_PORTFOLIO'  | translate : {count: appointmentCounts.appointmentsCount})
                    : 'TOOLBAR.TITLES.MY_APPOINTMENTS'">
            </rpc-button>
        </div>

    </ng-container>

    <mat-divider class="divider"></mat-divider>
    <div class="mobile-nav-secondary">
        <rpc-button [title]="'SIDE_MENU.TITLES.PROFILE'" iconName="user" [routerLinkActive]="'active'"
            (clicked)="onMyProfileClicked()"></rpc-button>
        <rpc-button *ngIf="canSeeAdditionalProfiles$ | async" [title]="'TOOLBAR.TITLES.INVITE_USER'"
            iconName="invite-user" (clicked)="onInviteUser()" [disabled]="!(canAddAdditionalProfile$ | async)">
        </rpc-button>
        <rpc-button [title]="'SIDE_MENU.TITLES.SETTINGS'" iconName="cog" [routerLinkActive]="'active'"
            (clicked)="onSettingsClicked()"></rpc-button>
        <rpc-button *ngIf="hasMultipleCollaborationSpaces$ | async" [title]="'SIDE_MENU.TITLES.CHANGE_AGENT'"
            iconName="agent-switch" [routerLinkActive]="'active'" (clicked)="onChangeAgentClicked()"></rpc-button>
        <rpc-button [title]="'SIDE_MENU.TITLES.KNOWLEDGE_BASE'" [iconName]="'question'"
            (clicked)="onKnowledgeBaseClicked()"></rpc-button>
        <rpc-button [title]="'SIDE_MENU.TITLES.LOGOUT'" [iconName]="'sign-out-square-fill'"
            (clicked)="onLogoutClicked()"></rpc-button>
    </div>

    <mat-divider class="divider"></mat-divider>

    <portfolio-agents-list class="mobile-side-agents" [agents]="agents$ | async"></portfolio-agents-list>

    <mat-divider class="divider" *ngIf="showInstallationButton">
    </mat-divider>

    <rpc-button class="mobile-side-pwa" *ngIf="showInstallationButton" [title]="'SIDE_MENU.TITLES.INSTALLATION'"
        [iconName]="'download'" [appearanceType]="'flat'" [color]="'accent'" (clicked)="addToHomeScreen()">
    </rpc-button>

</div>