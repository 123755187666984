import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NotAuthorizedOnlyGuard } from '@auth/guards/not-authorized-only.guard';
import { ForgotPasswordComponent } from '@auth/pages/forgot-password/forgot-password.component';
import { LoginComponent } from '@auth/pages/login/login.component';
import { RegistrationComponent } from '@auth/pages/registration/registration.component';
import { RestorePasswordComponent } from '@auth/pages/restore-password/restore-password.component';
import { RpcRoute } from '@core-layout/app/models/rpc-route';
import { registrationMatcher } from '@core-matchers/registration.matcher';
import { RegistrationGuard } from './guards/registration.guard';

const routes: Routes = [
    {
        path: RpcRoute.ForgotPassword,
        component: ForgotPasswordComponent,
        data: { title: 'Forgot Password', applyDefaultLayout: true },
        canActivate: [NotAuthorizedOnlyGuard]
    },
    {
        path: RpcRoute.Login,
        component: LoginComponent,
        data: { title: 'Login', hasThemeSidebar: true, hideToolbarLoginRedirect: true, applyDefaultLayout: true },
        canActivate: [NotAuthorizedOnlyGuard]
    },
    {
        path: RpcRoute.RestorePassword,
        component: RestorePasswordComponent,
        data: { title: 'Restore Password', applyDefaultLayout: true },
        canActivate: [NotAuthorizedOnlyGuard]
    },
    {
        matcher: registrationMatcher,
        component: RegistrationComponent,
        data: { title: 'Registration', applyDefaultLayout: true },
        canActivate: [RegistrationGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthRoutingModule { }