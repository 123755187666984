import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { LogoComponent } from './logo.component';
import { TranslationLoaderService } from '@core-services/translation-loader.service';
import { locale as english } from '@core-layout/logo/i18n/en';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule
    ],
    declarations: [LogoComponent],
    exports: [LogoComponent]
})
export class LogoModule {
    constructor(private readonly translationLoaderService: TranslationLoaderService) {
        this.translationLoaderService.loadTranslations(english);
    }
}
