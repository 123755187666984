import { createSelector } from '@ngrx/store';
import * as moment from 'moment';

import { AppointmentService } from '@appointments/services/appointment.service';
import * as userSelector from '@auth/store/selectors/user.selector';
import { ListingHelperService } from '@listings/services/listing-helper.service';
import * as listingsSelector from '@listings/store/selectors/listings.selector';
import * as userAppointmentSelectors from '@portfolio/store/selectors/appointment-participants.selectors';
import * as settingsSelector from '@settings/store/selectors/settings.selector';
import { AppointmentBaseInfo, AppointmentInfo } from '../models/appointment-info';
import { AppointmentActionService } from '../services/appointment-action.service';
import { AppointmentLinkService } from '../services/appointment-link.service';
import { AppointmentStatusService } from '../services/appointment-status.service';
import * as appointmentsSelectors from './appointments.selectors';

export const selectFlatListingsAppointmentsBaseInfo = createSelector(
    appointmentsSelectors.selectFlatListingsAppointments,
    listingsSelector.selectCustomerListings,
    userSelector.selectUser,
    settingsSelector.selectHideOpenRentals,
    (listingsAppointments, listings, user, isHideOpenRentals) => {
        return listingsAppointments.map((appointment): AppointmentBaseInfo => {
            const listing = listings.find(({ hashCode }) => hashCode === appointment.listingIdHashCode);
            const listingAddress = listing != null ? ListingHelperService.getListingFullAddress(listing, isHideOpenRentals) : '';

            const status = AppointmentStatusService.calculateTimeDependentStatus(appointment);
            const statusDetails = AppointmentStatusService.getStatusDetails(appointment, status);
            const actionOptions = AppointmentActionService.getAppointmentActionOptions(appointment, user?.customerId, user?.agentId, listing?.isDeleted, status);
            const links = AppointmentLinkService.getAppointmentLinks(appointment, listingAddress, actionOptions.canAddToCalendar);

            return { appointment, status, actionOptions, statusDetails, links, listing };
        });
    }
);

export const selectFlatListingsAppointmentsInfo = createSelector(
    selectFlatListingsAppointmentsBaseInfo,
    userAppointmentSelectors.selectAppointmentParticipantsMap,
    (listingsAppointmentsBaseInfo, appointmentParticipantsMap): AppointmentInfo[] => {
        return listingsAppointmentsBaseInfo.map(appointmentBaseInfo => ({
            ...appointmentBaseInfo,
            ...appointmentParticipantsMap.get(appointmentBaseInfo.appointment.id)
        }));
    }
);

export const selectListingAppointmentsInfo = (listingHashCode: number) => createSelector(
    selectFlatListingsAppointmentsInfo,
    appointmentsInfo => {
        return appointmentsInfo.filter(({ appointment }) => appointment.listingIdHashCode === listingHashCode);
    }
);

export const selectOrderedListingAppointmentsInfo = (listingHashCode: number) => createSelector(
    selectListingAppointmentsInfo(listingHashCode),
    listingAppointmentsInfo => {
        const getInitialAccumulator = <T>() => ({
            pendingAgent: [] as T[], pendingCustomer: [] as T[], confirmed: [] as T[], declined: [] as T[], shown: [] as T[]
        });

        const { pendingAgent, pendingCustomer, confirmed, declined, shown } = listingAppointmentsInfo.reduce(
            (result, info) => {
                return {
                    pendingAgent: info.statusDetails.isPendingCreatedByAgent ? [...result.pendingAgent, info] : result.pendingAgent,
                    pendingCustomer: info.statusDetails.isPendingCreatedByCustomer ? [...result.pendingCustomer, info] : result.pendingCustomer,
                    confirmed: info.statusDetails.isConfirmed ? [...result.confirmed, info] : result.confirmed,
                    declined: info.statusDetails.isDeclined ? [...result.declined, info] : result.declined,
                    shown: info.statusDetails.isShown ? [...result.shown, info] : result.shown,
                };
            },
            getInitialAccumulator<AppointmentInfo>()
        );

        return [...pendingAgent, ...pendingCustomer, ...confirmed, ...declined, ...shown];
    }
);

export const selectLastListingAppointmentInfo = (listingHashCode: number) => createSelector(
    selectListingAppointmentsInfo(listingHashCode),
    appointments => appointments != null
        ? AppointmentService.sort(appointments)[appointments.length - 1]
        : null
);

export const selectUpcomingNotDeclinedAppointmentsInfo = createSelector(
    selectFlatListingsAppointmentsInfo,
    listingAppointmentsInfo => {
        const upcomingAppointments = listingAppointmentsInfo.filter(({ appointment: { startDateTime }, statusDetails: { isConfirmed, isPending } }) => {
            return moment(startDateTime).isAfter(new Date(), 'minute') && (isConfirmed || isPending);
        });

        return AppointmentService.sort(upcomingAppointments);
    }
);