import { Injectable } from "@angular/core";
import { mergeMap } from "rxjs";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import partition from "lodash-es/partition";

import { UserStoreService } from "@auth/store/services/user-store.service";
import * as notificationsActions from "../actions/notifications.actions";
import { NotificationEventEntity } from "@notifications/enums/notification-event-entity";
import { NotificationEventAction } from "@notifications/enums/notification-event-action";
import * as commentsActions from "@comments/store/actions/comments.actions";
import * as appointmentsActions from "@appointments/store/actions/appointments.actions";
import * as listingsActions from "@listings/store/actions/listings.actions";
import { NotificationsService } from "../services/notifications.service";
import { NotificationsStoreService } from "../services/notifications-store.service";
import * as foldersActions from "@folders/store/actions/folders.actions";
import * as externalListingsActions from "@external-listings/store/actions/external-listings.actions";
import { ListingsStoreService } from "@listings/store/services/listings-store.service";
import { FoldersStoreReadService } from "@folders/store/services/folders-store-read.service";
import { UsersStoreService } from "@users/store/services/users-store.service";
import * as usersActions from "@users/store/actions/users.actions";
import * as agentsActions from "@agents/store/actions/agents.actions";
import { AppointmentsStoreService } from "@appointments/store/services/appointments-store.service";
import { ListingHelperService } from "@listings/services/listing-helper.service";
import * as settingsActions from "@settings/store/actions/settings.actions";
import { SettingsHelper } from "@settings/settings.helper";
import { SettingsStoreService } from "@settings/store/services/settings-store.service";
import { NOT_SHOWN_NOTIFICATIONS, NotShownNotifications } from "@notifications/constants/notifications.constants";
import * as notesActions from "@notes/store/actions/notes.actions";
import * as listingActivityActions from "@listings/store/actions/listing-activity.actions";
import * as newMatchesActions from "@listings/store/actions/new-matches.actions";

@Injectable()
export class NotificationAddedEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly userStoreService: UserStoreService,
        private readonly notificationsStoreService: NotificationsStoreService,
        private readonly listingsStoreService: ListingsStoreService,
        private readonly foldersStoreReadService: FoldersStoreReadService,
        private readonly usersStoreService: UsersStoreService,
        private readonly appointmentsStoreService: AppointmentsStoreService,
        private readonly settingsStoreService: SettingsStoreService,
    ) { }

    public readonly notificationAdded$ = createEffect(() => this.actions$.pipe(
        ofType(notificationsActions.notificationAdded),
        concatLatestFrom(() => [
            this.notificationsStoreService.flatNotifications$,
            this.userStoreService.customerId$,
            this.userStoreService.getAgent(),
            this.usersStoreService.customersAndAgentsIds$,
        ]),
        mergeMap(([{ notification }, currentNotifications, customerId, agent, customersAndAgentsIds]) => {
            const actions = new Map<NotificationEventEntity, Action[]>([
                [NotificationEventEntity.Comment, [notificationsActions.commentNotificationAdded({ notification })]],
                [NotificationEventEntity.Appointment, [notificationsActions.appointmentNotificationAdded({ notification })]],
                [NotificationEventEntity.Listing, [notificationsActions.listingNotificationAdded({ notification })]],
                [NotificationEventEntity.ListingActivity, [notificationsActions.listingActivityNotificationAdded({ notification })]],
                [NotificationEventEntity.Folder, [notificationsActions.folderNotificationAdded({ notification })]],
                [NotificationEventEntity.ExternalListing, [externalListingsActions.loadListings({ shouldSetLoading: false })]],
                [NotificationEventEntity.NewMatch, []],
            ]).get(notification.entityType);

            if (NOT_SHOWN_NOTIFICATIONS.includes(notification.action as NotShownNotifications)) {
                return actions;
            }

            const notificationItem = NotificationsService.mapToNotificationBaseModel(notification, customerId, agent.id);
            const notifications = NotificationsService.getNotificationsWithoutOutdated([...currentNotifications, notificationItem]);
            const notificationCreator = customersAndAgentsIds.find(id => id === notification.createId);

            return [
                ...actions,
                notificationsActions.showPushNotification({ notification }),
                notificationsActions.setNotifications({ notifications }),
                ...(notificationCreator == null ? [usersActions.loadCustomers(), agentsActions.loadAgents()] : [])
            ];
        })
    ));

    public readonly listingNotificationAdded$ = createEffect(() => this.actions$.pipe(
        ofType(notificationsActions.listingNotificationAdded),
        concatLatestFrom(() => [
            this.listingsStoreService.getCustomerListings(),
            this.listingsStoreService.baseListings$,
            this.foldersStoreReadService.getListingsFolderIdsMapping$,
            this.notificationsStoreService.flatNotifications$,
            this.listingsStoreService.listingsActivities$,
            this.listingsStoreService.listingsNewMatches$,
        ]),
        mergeMap(([{ notification }, customerListings, listings, listingsFolderIdsMapping, allNotifications, listingsActivities, listingsNewMatches]) => {
            const listing = customerListings.find(x => x.hashCode === notification.entityId);
            const listingFolderIdsMap = listingsFolderIdsMapping.filter(x => x.listingId !== notification.entityId);
            const notifications = allNotifications.filter(x => x.listingId !== notification.entityId);

            const hardDeleteActions = listing == null
                ? [notificationsActions.setNotifications({ notifications })]
                : [
                    listingsActions.hardDelete({ listingIds: [listing?.id], hashCodes: [listing.hashCode], isFromStateOnly: true }),
                    commentsActions.loadListingsComments(),
                    appointmentsActions.loadListingsAppointments(),
                    notesActions.deleteNote({ listingHashCodes: [listing.hashCode] }),
                    foldersActions.setListingFolderMapping({ listingFolderIdsMap }),
                    notificationsActions.setNotifications({ notifications })
                ];

            const getRestoreListingsActions = () => [
                listingsActions.restore({ listingIds: [listing.id], listings, listingsActivities, listingsNewMatches, isFromStateOnly: true }),
                appointmentsActions.loadListingsAppointments()
            ];

            return new Map<NotificationEventAction, Action[]>([
                [NotificationEventAction.ListingHardDeleted, hardDeleteActions],
                [NotificationEventAction.ListingSoftDeleted, listing != null ? [listingsActions.softDeleteRequested({ listingIds: [listing.id], isFromStateOnly: true })] : []],
                [NotificationEventAction.ListingRestored, listing != null ? getRestoreListingsActions() : []],
            ]).get(notification.action);
        })
    ));

    public readonly listingActivityNotificationAdded$ = createEffect(() => this.actions$.pipe(
        ofType(notificationsActions.listingActivityNotificationAdded),
        concatLatestFrom(() => this.listingsStoreService.getCustomerListings()),
        mergeMap(([{ notification }, listings]) => {
            const isListingAlreadyInPortfolio = listings.some(x => x.hashCode === notification.listingId);

            const action = isListingAlreadyInPortfolio
                ? newMatchesActions.removeNewMatches({ hashCodes: [notification.listingId] })
                : listingsActions.loadListinsgByHashCodes({ hashCodes: [notification.listingId] });

            return [
                action,
                listingActivityActions.loadListingsActivities({ hashCodes: [notification.listingId], shouldSetLoading: false }),
            ];
        }))
    );

    public readonly folderNotificationAdded$ = createEffect(() => this.actions$.pipe(
        ofType(notificationsActions.folderNotificationAdded),
        concatLatestFrom(() => [
            this.foldersStoreReadService.getListingsFolderIdsMapping$,
            this.settingsStoreService.getSettings(),
        ]),
        mergeMap(([{ notification }, listingsFolderIdsMapping, currentSettings]) => {
            const [listingFolderIdsMap, notificationFolderIdsMap] = partition(listingsFolderIdsMapping, x => x.folderId !== notification.entityId);
            const foldersSettings = currentSettings.layoutSettings.foldersSettings.filter(folderSettings => folderSettings.folderId !== notification.entityId);
            const settings = SettingsHelper.updateWithFoldersSettings(currentSettings, foldersSettings, currentSettings.layoutSettings.defaultFolderId === notification.entityId);
            const hashCodes = notificationFolderIdsMap.map(x => x.listingId);

            const removeFolderActions = [
                listingsActions.loadListinsgByHashCodes({ hashCodes }),
                listingActivityActions.loadListingsActivities({ hashCodes, shouldSetLoading: false }),
                newMatchesActions.loadListingsNewMatches({ hashCodes, shouldSetLoading: false }),
                foldersActions.setListingFolderMapping({ listingFolderIdsMap }),
                foldersActions.loadFolderNewMatches(),
                foldersActions.remove({ model: { id: notification.entityId }, isFromStateOnly: true }),
                settingsActions.setSettings({ settings }),
            ];

            return new Map<NotificationEventAction, Action[]>([
                [NotificationEventAction.FolderAdded, [foldersActions.loadFolders({ shouldSetLoading: false })]],
                [NotificationEventAction.FolderUpdated, [foldersActions.loadFolders({ shouldSetLoading: false })]],
                [NotificationEventAction.FolderRemoved, removeFolderActions],
                [NotificationEventAction.FolderListingsChanged, [foldersActions.loadListingFolderIdsMappings(), foldersActions.loadFolderNewMatches()]],
            ]).get(notification.action);
        })
    ));

    public readonly commentNotificationAdded$ = createEffect(() => this.actions$.pipe(
        ofType(notificationsActions.commentNotificationAdded),
        concatLatestFrom(() => this.listingsStoreService.getCustomerListings()),
        mergeMap(([{ notification }, listings]) => {
            const actions: Action[] = [commentsActions.loadComment({ id: notification.entityId })];

            const isNewMatch = listings.some(x => x.hashCode === notification.listingId && x.isNewMatch);

            if (isNewMatch) {
                actions.push(
                    newMatchesActions.removeNewMatches({ hashCodes: [notification.listingId] }),
                    listingActivityActions.loadListingsActivities({ hashCodes: [notification.listingId], shouldSetLoading: false })
                );
            }

            return actions;
        })
    ));

    public readonly appointmentNotificationAdded$ = createEffect(() => this.actions$.pipe(
        ofType(notificationsActions.appointmentNotificationAdded),
        concatLatestFrom(() => this.listingsStoreService.getCustomerListings()),
        mergeMap(([{ notification }, listings]) => {
            const actions: Action[] = notification.action === NotificationEventAction.AppointmentDeleted
                ? [notificationsActions.appointmentDeletedNotificationAdded({ notification })]
                : [appointmentsActions.loadAppointment({ id: notification.entityId })];

            const isNewMatch = listings.some(x => x.hashCode === notification.listingId && x.isNewMatch);

            if (isNewMatch) {
                actions.push(
                    newMatchesActions.removeNewMatches({ hashCodes: [notification.listingId] }),
                    listingActivityActions.loadListingsActivities({ hashCodes: [notification.listingId], shouldSetLoading: false })
                );
            }

            return actions;
        })
    ));

    public readonly appointmentDeletedNotificationAdded$ = createEffect(() => this.actions$.pipe(
        ofType(notificationsActions.appointmentDeletedNotificationAdded),
        concatLatestFrom(() => [
            this.notificationsStoreService.flatNotifications$,
            this.appointmentsStoreService.getFlatListingsAppointments(),
            this.listingsStoreService.getCustomerListings()
        ]),
        mergeMap(([{ notification }, currentNotifications, appointments, listings]) => {
            const appointmentNotification = currentNotifications.find(x => x.entityId === notification.entityId);
            const appointment = appointments.find(x => x.id === notification.entityId);
            const listing = listings.find(x => x.hashCode === appointmentNotification.listingId);
            const notifications = currentNotifications.filter(x => x.entityId !== notification.entityId);
            const model = {
                appointmentId: notification.entityId,
                listingId: listing?.id,
                listingIdHashCode: appointmentNotification.listingId,
                listingAddress: listing != null ? ListingHelperService.getListingFullAddress(listing, false) : '',
                listingAppointment: appointment
            };

            return [
                appointmentsActions.deleteListingAppointment({ model, isFromStateOnly: true }),
                notificationsActions.setNotifications({ notifications })
            ];
        })
    ));
}