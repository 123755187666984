export const locale = {
    lang: 'en',
    data: {
        MEDIA_VIEWER: {
            TITLES: {
                ALL: 'All',
                LISTING_PHOTOS: 'Listing Photos',
                BUILDING_PHOTOS: 'Building Photos',
                VIDEOS: 'Videos',
                TOURS: '3D Tours',
                VIDEO_INDEX: 'Video {{index}}',
                TOUR_INDEX: '3D Tour {{index}}',
                IMAGES: 'Images',
                FLOORPLANS: 'Floorplans',
                COLLAPSE_SECTION: 'Collapse Section',
                SHOW_SECTION: 'Show Section',
                CLOSE: 'Close',
                PREVIOUS_PHOTO: 'Previous',
                NEXT_PHOTO: 'Next',
                NEXT: 'Next',
                PREVIOUS: 'Previous',
                LISTING_FLOORPLANS: 'Listing Floorplans',
                BUILDING_FLOORPLANS: 'Building Floorplans'
            }
        }
    }
};