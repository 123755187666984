import partition from 'lodash-es/partition';

import { Listings } from '@listings/models/listing/listings';
import { ListingHelperService } from '@listings/services/listing-helper.service';
import { ListingsActivityInfo } from '@listings/models/listing/listings-activity-info';
import { NewMatch } from '@listings/models/listing/new-match';
import { NewMatchService } from '@listings/services/new-match.service';
import { ListingCardInfo } from '@listings/models/listing/listing-card-info';

export class RemoveRestoreListingHelper {
    public static softDelete(
        currentListings: Listings,
        listingIds: string[],
        currentListingsActivities: Record<number, ListingsActivityInfo>,
        currentListingsNewMatches: Record<number, NewMatch[]>
    ): { listings: Listings, listingsActivities: Record<number, ListingsActivityInfo>, listingsNewMatches: Record<number, NewMatch[]> } {
        const hashCodes = listingIds.map(id => currentListings[id].hashCode);

        const [newMatchListingsWithoutActivities, listings] = partition(hashCodes, hashCode => {
            const activities = currentListingsActivities[hashCode]?.activities ?? [];
            const isNewMatch = currentListingsNewMatches[hashCode]?.length > 0;

            return isNewMatch && activities.length === 0;
        });

        const updatedListings = RemoveRestoreListingHelper.hardDelete(Object.values(currentListings), newMatchListingsWithoutActivities);
        const listingsActivities = RemoveRestoreListingHelper.removeRestoreListings(currentListingsActivities, listings, true);
        const listingsNewMatches = NewMatchService.removeNewMatches(currentListingsNewMatches, hashCodes);

        return { listings: ListingHelperService.mapToListings(updatedListings), listingsActivities, listingsNewMatches };
    }

    public static removeRestoreListings(listingsActivities: Record<number, ListingsActivityInfo>, hashcodes: number[], isDeleted: boolean): Record<number, ListingsActivityInfo> {
        return Object.entries(listingsActivities).reduce(
            (acc, [hashcode, activitiesInfo]) => ({ ...acc, [hashcode]: { ...activitiesInfo, isDeleted: hashcodes.includes(+hashcode) ? isDeleted : activitiesInfo.isDeleted } }),
            {} as Record<number, ListingsActivityInfo>
        );
    }

    public static hardDelete(listings: ListingCardInfo[], hashCodes: number[]): ListingCardInfo[] {
        const result = listings.map((listing): ListingCardInfo => {
            return hashCodes.includes(listing.hashCode) ? { ...listing, isMarketListing: true } : listing;
        });

        return result;
    }
}