<form class="folder-edit-form" [class]="additionalFormClass" [formGroup]="manageFolderFormGroup">

    <rpc-input [label]="'MANAGE_FOLDER_FORM.FIELDS.NAME'" [type]="'text'" [appearance]="'outline'"
        [options]="nameOptions">
    </rpc-input>

    <div class="folder-edit-action-wrap">

        <rpc-button class="folder-edit-action folder-edit-action--cancel" [appearanceType]="'stroke'" [color]="'accent'"
            [mainButton]="true" [title]="'MANAGE_FOLDER_FORM.BUTTONS.CANCEL'" (click)="cancel()" type="button">
        </rpc-button>

        <rpc-button class="folder-edit-action folder-edit-action--action" [appearanceType]="'flat'" [color]="'accent'"
            [mainButton]="true" [title]="submitButtonText" [disabled]="manageFolderFormGroup.invalid"
            (click)="onManageFolderClicked()">
        </rpc-button>

    </div>

</form>