import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as appointmentsSelectors from '@appointments/store/selectors/appointments.selectors';
import * as userSelectors from '@auth/store/selectors/user.selector';
import * as commentsSelectors from '@comments/store/selectors/comments.selectors';
import { SortOptionFirstLevel } from '@core-models/sort/sort-option-first-level';
import { nameof } from '@core-models/utilities/nameof';
import { SortCollectionHelper } from '@core-pipes/sort-collection/sort-collection.helper';
import { SYSTEM_FOLDERS, SYSTEM_FOLDERS_INFO } from '@folders/constants/folder.constants';
import { Folder } from '@folders/models/folder';
import { FolderCover } from '@folders/models/folder-cover';
import { FolderDetails } from '@folders/models/folder-details';
import { ListingFolderManagerInfo } from '@folders/models/folder-management/listing-folder-manager-info';
import * as listingsSelector from '@listings/store/selectors/listings.selector';
import { Image } from '@media/models/image';
import * as settingsSelector from '@settings/store/selectors/settings.selector';
import { DELETED_USER_BASE_INFO } from '@users/constants/deleted-user.constants';
import * as usersSelectors from '@users/store/selectors/users.selectors';
import { FolderActivityPanelBuilder } from '../helpers/folder-activity-panel.builder';
import { FolderCoverBuilder } from '../helpers/folder-cover.builder';
import { ListingFolderIdsMapHelper } from '../helpers/listing-folder-ids-map.helper';
import { FoldersState } from '../states/folders.state';
import { ListingInfo } from '@listings/models/listing/listing-info';

const foldersStateSelector = createFeatureSelector<FoldersState>('folders');

export const selectCustomFolders = createSelector(foldersStateSelector, state => state?.folders);

export const selectSelectedFolderId = createSelector(foldersStateSelector, state => state?.selectedFolderId);

export const selectAreFoldersLoaded = createSelector(foldersStateSelector, state => state?.areFoldersLoaded);

export const selectIsLoading = createSelector(foldersStateSelector, state => state?.isLoading);

export const selectFoldersSort = createSelector(foldersStateSelector, state => state?.sort);

export const selectSystemFolders = createSelector(foldersStateSelector, _ => SYSTEM_FOLDERS);

export const selectFoldersError = createSelector(foldersStateSelector, state => state?.error);

export const selectListingsFolders = createSelector(foldersStateSelector, state => state?.listingFolderIdsMap);

export const selectNewMatchFolders = createSelector(foldersStateSelector, state => state?.folderNewMatches);

export const selectFoldersListings = createSelector(
    listingsSelector.selectCustomerListings,
    selectListingsFolders,
    (listings, listingsFolders) => {
        return listings.reduce(
            (map, listing) => ListingFolderIdsMapHelper.organizeListingsPerFolder(map, listing, listingsFolders),
            new Map<number, ListingInfo[]>()
        );
    }
);

export const selectFolderListings = createSelector(
    selectFoldersListings,
    selectSelectedFolderId,
    (foldersListings, selectedFolderId) => foldersListings.has(selectedFolderId) ? foldersListings.get(selectedFolderId) : []
);

export const selectListingsByFolderId = (folderId: number) => createSelector(
    selectFoldersListings,
    foldersListings => foldersListings.has(folderId) ? foldersListings.get(folderId) : []
);

export const selectVisibleSystemFolders = createSelector(
    selectFoldersListings,
    selectSystemFolders,
    (foldersListings, systemFolders) => {
        const foldersRulesToExclude: [folderId: number, condition: boolean][] = [
            [SYSTEM_FOLDERS_INFO.ADDED_BY_AGENT.id, !foldersListings.has(SYSTEM_FOLDERS_INFO.ADDED_BY_AGENT.id)],
            [SYSTEM_FOLDERS_INFO.NEW_FROM_SAVED_SEARCHES.id, !foldersListings.has(SYSTEM_FOLDERS_INFO.NEW_FROM_SAVED_SEARCHES.id)],
            [SYSTEM_FOLDERS_INFO.OPEN_HOUSES.id, !foldersListings.has(SYSTEM_FOLDERS_INFO.OPEN_HOUSES.id)]
        ];

        const foldersToExclude = foldersRulesToExclude.reduce(
            (set, [folderId, condition]) => condition ? set.add(folderId) : set,
            new Set<number>()
        );

        return systemFolders.filter(({ id }) => !foldersToExclude.has(id));
    }
);

export const selectCustomFolderDetails = createSelector(
    selectCustomFolders,
    usersSelectors.selectCustomersAndAgents,
    (folderDetails, users) => {
        return folderDetails.map((folder): FolderDetails => {
            return {
                ...folder,
                isSystemFolder: false,
                creatorInfo: users?.find(({ customerId }) => customerId === folder.createId) ?? DELETED_USER_BASE_INFO
            };
        });
    }
);

const selectFoldersActivityPanelInfoMap = createSelector(
    selectSystemFolders,
    selectCustomFolderDetails,
    selectFoldersListings,
    commentsSelectors.selectListingsComments,
    appointmentsSelectors.selectListingsAppointments,
    userSelectors.selectUser,
    (
        systemFolders,
        customFolderDetails,
        foldersListings,
        listingsComments,
        listingsAppointments,
        user
    ) => {
        return FolderActivityPanelBuilder.getFoldersActivityPanelInfoMap(
            customFolderDetails.concat(systemFolders),
            foldersListings,
            listingsComments,
            listingsAppointments,
            user?.customerId
        );
    }
);

export const selectFolderMediaMap = createSelector(
    selectFoldersListings,
    selectVisibleSystemFolders,
    selectCustomFolders,
    listingsSelector.selectAllListingsMedia,
    (foldersListings, systemFolders, customFolders, media) => {
        return [...customFolders, ...systemFolders].reduce(
            (map, { id }) => FolderCoverBuilder.getFolderCoverImages(id, media, foldersListings, map),
            new Map<number, Image[]>()
        );
    }
);

export const selectSystemFoldersCover = createSelector(
    selectVisibleSystemFolders,
    selectFoldersActivityPanelInfoMap,
    selectFolderMediaMap,
    (allowedSystemFolders, foldersActivityPanelInfoMap, folderMediaMap) => {
        return FolderCoverBuilder.getFolderCovers(allowedSystemFolders, foldersActivityPanelInfoMap, folderMediaMap);
    }
);

export const selectCustomFoldersCover = createSelector(
    selectCustomFolderDetails,
    selectFoldersActivityPanelInfoMap,
    selectFolderMediaMap,
    (customerFolderDetails, folderActivityPanelInfoMap, folderMediaMap) => {
        return FolderCoverBuilder.getFolderCovers(customerFolderDetails, folderActivityPanelInfoMap, folderMediaMap);
    }
);

export const selectSortedCustomFoldersCover = createSelector(
    selectCustomFoldersCover,
    selectFoldersSort,
    (customFoldersCover, foldersSort) => {
        const alternativeSort = foldersSort.propertyName === nameof<FolderCover>('updateDateTime') ? new SortOptionFirstLevel<FolderCover>('createDateTime') : null;
        const sortedCustomFoldersCover = SortCollectionHelper.sort(customFoldersCover, foldersSort, alternativeSort, foldersSort.propertyName === nameof<FolderCover>('name'));

        return [...sortedCustomFoldersCover];
    }
);

export const selectAllFolders = createSelector(
    selectSystemFolders,
    selectCustomFolderDetails,
    (systemFolders, customFolderDetails) => customFolderDetails.concat(systemFolders)
);

export const selectSelectedFolder = createSelector(
    selectAllFolders,
    selectSelectedFolderId,
    (folders, selectedFolderId) => folders.find(({ id }) => id === selectedFolderId)
);

export const selectIsNewMatchFolder = createSelector(
    selectSelectedFolderId,
    id => id === SYSTEM_FOLDERS_INFO.NEW_FROM_SAVED_SEARCHES.id
);

export const selectFoldersByListingId = (id: number) => createSelector(
    selectListingsFolders,
    selectCustomFolders,
    (listingFolderIdsMap, listingsFolders) => {
        const folders = listingFolderIdsMap.reduce(
            (foldersSet, { listingId, folderId }) => listingId === id ? foldersSet.add(folderId) : foldersSet,
            new Set<number>()
        );

        return listingsFolders.filter(folder => folders.has(folder.id));
    }
);

export const selectSelectedListingsFolderManagerInfo = createSelector(
    listingsSelector.selectListings,
    listingsSelector.selectAllListingsMedia,
    listingsSelector.selectSelectedListingIds,
    selectListingsFolders,
    settingsSelector.selectPermissionSettings,
    (listings, listingsMedia, selectedListingsIds, listingFolderIdsMap, permissionSettings) => {
        const getListingsWithoutRemoved = (filteredListings: ListingFolderManagerInfo[], listingId: string): ListingFolderManagerInfo[] => {
            return listings[listingId].isDeleted
                ? filteredListings
                : [
                    ...filteredListings,
                    new ListingFolderManagerInfo(listings[listingId], listingsMedia[listingId], listingFolderIdsMap, permissionSettings.hideOpenRentals),
                ];
        };

        return selectedListingsIds.reduce(getListingsWithoutRemoved, new Array<ListingFolderManagerInfo>());
    }
);

export const selectListingFolderManagerInfoById = (listingId: string) => createSelector(
    listingsSelector.selectListing(listingId),
    listingsSelector.selectListingMedia(listingId),
    selectListingsFolders,
    settingsSelector.selectPermissionSettings,
    (listing, listingMedia, listingFolderIdsMap, permissionSettings) => {
        return listing != null ? new ListingFolderManagerInfo(listing, listingMedia, listingFolderIdsMap, permissionSettings.hideOpenRentals) : null;
    }
);

export const selectCustomFoldersSortedByDate = createSelector(
    selectCustomFolders,
    (customFolders) => {
        const sortedCustomFolders = SortCollectionHelper.sort(
            customFolders,
            new SortOptionFirstLevel<Folder>('updateDateTime'),
            new SortOptionFirstLevel<Folder>('createDateTime'));

        return sortedCustomFolders;
    }
);

export const selectListingsIdsAttachedOnlyToThisFolder = (id: number) => createSelector(
    selectListingsFolders,
    selectListingsByFolderId(id),
    (listingsFolders, listingsInFolder) => {
        const foldersListingAttachedTo = listingsFolders.reduce(
            (listingFoldersCountMap, { listingId, folderId }) => {
                return listingFoldersCountMap.set(listingId, [...(listingFoldersCountMap.get(listingId) ?? []), folderId]);
            },
            new Map<number, number[]>()
        );

        return listingsInFolder.reduce(
            (listingIdsAttachedOnlyToThisFolder, listing) => {
                const foldersIds = foldersListingAttachedTo.get(listing.hashCode) ?? [];

                return foldersIds.length === 1 && foldersIds[0] === id
                    ? [...listingIdsAttachedOnlyToThisFolder, listing.id]
                    : listingIdsAttachedOnlyToThisFolder;
            },
            new Array<string>()
        );
    }
);

export const selectListingsIdsByFolderId = (folderId: number) => createSelector(
    selectListingsByFolderId(folderId),
    listings => listings.map(listing => listing.id)
);

export const selectHasListingsByFolderId = (folderId: number) => createSelector(
    selectListingsIdsByFolderId(folderId),
    listings => listings.length > 0
);

export const selectNewMatchesIdsNotInFolder = (savedSearchId: number, folderId: number) => createSelector(
    listingsSelector.selectNewMatchListingsBySavedSearchId(savedSearchId),
    selectListingsFolders,
    (newMatches, listingFolderIdsMap) => {
        if (folderId == null) {
            return Array.of<number>();
        }

        const folderListingsIds = listingFolderIdsMap.filter(x => x.folderId === folderId);

        return newMatches.reduce(
            (ids, { hashCode }) => folderListingsIds.every(({ listingId }) => listingId !== hashCode)
                ? [...ids, hashCode]
                : ids,
            Array.of<number>()
        );
    }
);

export const selectFolderById = (folderId: number) => createSelector(
    selectCustomFolders,
    folders => folders.find(({ id }) => id === folderId)
);