import { SectionName } from "@landing/enums/section-name.enum";
import { SectionConfig } from "@landing/models/section-config";

export const SECTION_CARDS_TO_SHOW = 4;

const LAST_VIEWED: SectionConfig = {
    id: SectionName.LastViewed,
    title: 'LANDING_SECTION.LAST_VIEWED.TITLE',
    hasButton: false,
    buttonText: null,
    bannerConfig: {
        isImageLeft: false,
        hasButton: false,
        title: 'LANDING_BANNER.LAST_VIEWED.TITLE',
        subtitle: 'LANDING_BANNER.LAST_VIEWED.SUBTITLE',
        buttonText: 'LANDING_BANNER.LAST_VIEWED.BUTTON_TEXT',
        imageUrl: '/assets/images/decoration/landing/last-viewed.webp',
    }
};

const APPOINTMENTS: SectionConfig = {
    id: SectionName.Appointments,
    title: 'LANDING_SECTION.APPOINTMENTS.TITLE',
    hasButton: true,
    buttonText: 'LANDING_SECTION.APPOINTMENTS.BUTTON_TEXT',
    bannerConfig: {
        isImageLeft: true,
        hasButton: true,
        title: 'LANDING_BANNER.APPOINTMENTS.TITLE',
        subtitle: 'LANDING_BANNER.APPOINTMENTS.SUBTITLE',
        buttonText: 'LANDING_BANNER.APPOINTMENTS.BUTTON_TEXT',
        imageUrl: '/assets/images/decoration/landing/appointments.webp',
    }
};

const MESSAGES: SectionConfig = {
    id: SectionName.Messages,
    title: 'LANDING_SECTION.MESSAGES.TITLE',
    hasButton: true,
    buttonText: 'LANDING_SECTION.MESSAGES.BUTTON_TEXT',
    bannerConfig: {
        isImageLeft: false,
        hasButton: false,
        title: 'LANDING_BANNER.MESSAGES.TITLE',
        subtitle: 'LANDING_BANNER.MESSAGES.SUBTITLE',
        buttonText: null,
        imageUrl: '/assets/images/decoration/landing/messages.webp',
    }
};

const OPEN_HOUSES: SectionConfig = {
    id: SectionName.OpenHouses,
    hasButton: true,
    title: 'LANDING_SECTION.OPEN_HOUSES.TITLE',
    buttonText: 'LANDING_SECTION.OPEN_HOUSES.BUTTON_TEXT',
    bannerConfig: null
};

export const SECTION_CONFIGS = { LAST_VIEWED, APPOINTMENTS, MESSAGES, OPEN_HOUSES };