import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, share, switchMap, tap } from 'rxjs/operators';

import { UserStoreService } from '@auth/store/services/user-store.service';
import { ListingComment } from '@comments/models/comments/listing-comment';
import { ListingsComments } from '@comments/models/comments/listings-comments';
import { CommentErrorsService } from '@comments/services/comment-errors.service';
import * as commentsActions from '@comments/store/actions/comments.actions';
import { ApiDataResult } from '@core-models/api/api-result';
import { ApiHttpClient } from '@core-services/api-http-client.service';
import { CustomerActivityService } from '@customer-activity/customer-activity.service';
import { ListingActivityType } from '@customer-activity/enums/listing-activity-type.enum';
import { ApiError } from '@error/models/api-error';
import { ListingActivityHelper } from '@listings/services/listing-activity.helper';
import * as listingActivityActions from '@listings/store/actions/listing-activity.actions';
import { ListingsStoreService } from '@listings/store/services/listings-store.service';
import { CommentsStoreService } from '../services/comments-store.service';
import { CommentsApiService } from '../services/comments-api.service';
import { SavedSearchStoreService } from '@saved-search/store/services/saved-search-store.service';

@Injectable()
export class CommentsEffects {

    constructor(
        private readonly actions$: Actions,
        private readonly http: ApiHttpClient,
        private readonly customerActivityService: CustomerActivityService,
        private readonly savedSearchStoreService: SavedSearchStoreService,
        private readonly commentsStoreService: CommentsStoreService,
        private readonly commentErrorsService: CommentErrorsService,
        private readonly userStoreService: UserStoreService,
        private readonly listingsStoreService: ListingsStoreService,
        private readonly commentsApiService: CommentsApiService,
    ) { }

    loadlListingsComments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(commentsActions.loadListingsComments),
            switchMap((requestParams) =>
                this.http
                    .get('comments/all')
                    .pipe(
                        map((response: ApiDataResult<ListingsComments>) => {
                            return response.error != null
                                ? commentsActions.loadListingsCommentsFailed(response.error)
                                : commentsActions.loadListingsCommentsSuccess({ comments: response.result });
                        }),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(commentsActions.loadListingsCommentsFailed(errorResponse.error)))
                    )
            ),
            share()
        )
    );

    loadListingComments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(commentsActions.loadListingComments),
            switchMap((requestParams) =>
                this.http
                    .get('comments/getListingComments', { params: { listingId: requestParams.listingId } })
                    .pipe(
                        map((comments: ListingComment[]) =>
                            commentsActions.loadListingCommentsSuccess({ comments })
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(commentsActions.loadListingCommentsFailed(errorResponse.error as ApiError)))
                    )
            ),
            share()
        )
    );

    public readonly loadComment$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(commentsActions.loadComment),
            switchMap(({ id }) => this.commentsApiService.loadComment(id)));
    });

    createListingComment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(commentsActions.createListingComment),
            mergeMap((requestParams) => {
                return this.http
                    .post('comments/addComment', {
                        listingId: requestParams.listingId,
                        listingAddress: requestParams.listingAddress,
                        comment: requestParams.createOperationListingComment.comment,
                        isNewMatch: requestParams.isNewMatch
                    })
                    .pipe(
                        map((comment: ListingComment) =>
                            commentsActions.createListingCommentSuccess({ comment, request: requestParams })),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(commentsActions.createListingCommentFailed(errorResponse.error as ApiError, requestParams)))
                    );
            }),
            share()
        )
    );

    markCommentAsViewed$ = createEffect(() =>
        this.actions$.pipe(
            ofType(commentsActions.markCommentAsViewed),
            mergeMap((requestParams) =>
                this.http
                    .post('comments/markCommentAsViewed', { ids: requestParams.commentIds })
                    .pipe(
                        map(() => commentsActions.markCommentAsViewedSuccess(requestParams)),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(commentsActions.markCommentAsViewedFailed(errorResponse.error as ApiError, requestParams))
                        )
                    )
            ),
            share()
        )
    );

    updateListingComment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(commentsActions.updateListingComment),
            mergeMap(request =>
                this.http
                    .post('comments/updateComment', {
                        listingId: request.listingId,
                        listingAddress: request.listingAddress,
                        commentId: request.oldListingComment.id,
                        newComment: request.newComment,
                        oldComment: request.oldListingComment.comment
                    })
                    .pipe(
                        map(() => commentsActions.updateListingCommentSuccess(request)),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(commentsActions.updateListingCommentFailed(errorResponse.error as ApiError, request))
                        )
                    )
            ),
            share()
        )
    );

    public readonly createListingCommentSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(commentsActions.createListingCommentSuccess),
                concatLatestFrom(() => [
                    this.savedSearchStoreService.activeSavedSearchId$,
                    this.listingsStoreService.getListings(),
                    this.listingsStoreService.resentlyViewedListings$,
                    this.userStoreService.getUser(),
                    this.userStoreService.getAgent()
                ]),
                switchMap(([{ request }, activeSavedSearchId, allListings, resentlyViewedListings, { customerId }, agent]) => {
                    const { id: listingId, hashCode, category } = allListings[request.listingId];

                    this.customerActivityService.addListingSessionActivity(ListingActivityType.CommentAdded, [{ listingId, category }], activeSavedSearchId).subscribe();

                    if (!request.isNewMatch) {
                        return [];
                    }

                    return [listingActivityActions.addListingsToPickedList({
                        listingCandidates: ListingActivityHelper.getActivityListingsCandidates([hashCode], allListings, resentlyViewedListings),
                        customerId,
                        agentId: agent.id
                    })];
                })
            )
    );

    public readonly showCommentError$ = createEffect(
        () => this.actions$.pipe(
            ofType(
                commentsActions.loadListingsCommentsFailed,
                commentsActions.loadListingCommentsFailed,
                commentsActions.markCommentAsViewedFailed,
                commentsActions.createListingCommentFailed,
                commentsActions.updateListingCommentFailed
            ),
            concatLatestFrom(() => this.commentsStoreService.getCommentError()),
            tap(([_, error]) => this.commentErrorsService.showError(error))
        ),
        { dispatch: false }
    );
}