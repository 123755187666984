import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as userSelectors from '@auth/store/selectors/user.selector';
import * as searchCriteriaSelectors from '@search/store/selectors/search-criteria.selectors';
import { SavedSearchState } from '../state/saved-search.state';
import { selectFoldersSavedSearches } from '@folders/store/selectors/folder-saved-search.selectors';
import * as usersSelectors from '@users/store/selectors/users.selectors';
import { SavedSearchInfo } from '@saved-search/models/saved-search-info';
import { UsersService } from '@users/services/users.service';
import * as foldersSelectors from '@folders/store/selectors/folders.selectors';

const selectSavedSearchState = createFeatureSelector<SavedSearchState>('saved-search');

export const selectSavedSearches = createSelector(selectSavedSearchState, ({ savedSearches }) => savedSearches);

export const selectActiveSavedSearchId = createSelector(selectSavedSearchState, ({ activeSavedSearchId }) => activeSavedSearchId);

export const selectActiveSavedSearch = createSelector(
    selectSavedSearches,
    selectActiveSavedSearchId,
    (savedSearches, activeSavedSearchId) => {
        return savedSearches.find(({ id }) => id === activeSavedSearchId);
    }
);

export const selectSavedSearch = (savedSearchId: number) => createSelector(
    selectSavedSearches,
    savedSearches => savedSearches.find(({ id }) => id === savedSearchId)
);

export const selectIsZeroSearchState = createSelector(
    searchCriteriaSelectors.selectIsCriteriaEmpty,
    selectActiveSavedSearchId,
    (isCriteriaEmpty, activeSavedSearchId) => activeSavedSearchId == null && isCriteriaEmpty
);

export const selectActiveSavedSearchFolderId = createSelector(
    selectActiveSavedSearchId,
    selectFoldersSavedSearches,
    (activeSavedSearchId, foldersSavedSearches) => foldersSavedSearches.find(x => x.savedSearchId === activeSavedSearchId)?.folderId
);

export const selectSavedSearchesInfo = createSelector(
    selectSavedSearches,
    userSelectors.selectUser,
    usersSelectors.selectCustomersAndAgents,
    selectFoldersSavedSearches,
    foldersSelectors.selectCustomFolders,
    (savedSearches, user, selectCustomersAndAgents, foldersSavedSearches, folders) => {
        return savedSearches.map((savedSearch): SavedSearchInfo => {
            const folderId = foldersSavedSearches.find(x => x.savedSearchId === savedSearch.id)?.folderId;
            const isEditable = savedSearch.customerId === user.customerId;
            const creator = UsersService.getUserInfoOrRemovedAccount(selectCustomersAndAgents, savedSearch.customerId);
            const folderName = folderId != null ? folders.find(x => x.id === folderId)?.name : null;

            return { ...savedSearch, folderId, folderName, isEditable, creator };
        });
    }
);

export const selectSavedSearchesToModify = createSelector(
    selectSavedSearchesInfo,
    savedSearches => savedSearches.filter(savedSearch => savedSearch.isEditable)
);