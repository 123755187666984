import { AppointmentStatus } from "@appointments/enums/appointment-status.enum";
import { Creator } from "@appointments/enums/creator.enum";

export class AppointmentNotification {
    readonly customerStatus: AppointmentStatus;
    readonly agentStatus: AppointmentStatus;
    readonly startDateTime: Date;
    readonly endDateTime: Date;
    readonly comment: string | null;
    readonly creator: Creator;
    readonly createId: number;
    readonly updateId: number | null;
    readonly customerId: number;
    readonly agentId: number;
}

export class CommentNotification {
    readonly text: string;
}

export class ListingNotification {
    readonly listingUrl: string;
}