export const locale = {
    lang: 'en',
    data: {
        CHECKBOX_FILTER: {
            TITLES: {
                ANY: 'Any',
                EXACT_MATCH: 'Exact Match'
            }
        }
    }
};