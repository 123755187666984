import { Folder } from '@folders/models/folder';
import { FolderDetails } from '@folders/models/folder-details';
import { CustomerSettings } from '@settings/models/settings/customer-settings';
import { SettingsHelper } from '@settings/settings.helper';

export class FolderSettingsHelper {
    public static fillWithDefaultFoldersSettings(currentSettings: CustomerSettings, folders: Folder[], systemFolders: FolderDetails[]): CustomerSettings {
        const getId = (item: Folder | FolderDetails): number => item.id;

        const { foldersSettings } = currentSettings.layoutSettings;
        const settings = systemFolders.map(getId)
            .concat(folders.map(getId))
            .map(id => foldersSettings.find(({ folderId }) => folderId === id) ?? SettingsHelper.createDefaultFolderSettings(id));

        return SettingsHelper.updateWithFoldersSettings(currentSettings, settings);
    }
}