import { Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';

import { AnimationService } from '@core-animations/services/animation.service';
import { AnimationConfig } from '@core-controls/models/animation-config';
import { SimpleChanges } from '@core-models/utilities/generic-simple-changes';

@Directive({ selector: '[rpcBadge]' })
export class RpcBadgeDirective implements OnChanges {

    private static readonly BadgeHostClass = 'badge-wrap';
    private static readonly BadgeClass = 'badge-count';
    private static readonly BadgeAlertClass = 'badge-count--new';
    private static readonly BadgeElementTypeName = 'span';

    @Input('rpcBadge') public badgeCount: number;
    @Input('rpcBadgeAlert') public badgeCountAlert: number;
    @Input('rpcBadgeCountAlertLimit') public badgeCountAlertLimit: number;
    @Input() public animationConfig: AnimationConfig = null;

    constructor(
        private readonly renderer: Renderer2,
        private readonly elementRef: ElementRef<HTMLElement>,
        private readonly animationService: AnimationService,
    ) { }

    // eslint-disable-next-line complexity
    public ngOnChanges(changes: SimpleChanges<RpcBadgeDirective>): void {
        const child = this.elementRef.nativeElement.querySelector(
            `${RpcBadgeDirective.BadgeElementTypeName}.${RpcBadgeDirective.BadgeClass}`);

        if (child != null) {
            this.renderer.removeChild(this.elementRef.nativeElement, child, false);
        }

        this.renderer.removeClass(this.elementRef.nativeElement, RpcBadgeDirective.BadgeHostClass);

        const badgeCount = changes.badgeCount != null
            ? changes.badgeCount.currentValue : this.badgeCount != null ? this.badgeCount : 0;
        const badgeAlertCount = changes.badgeCountAlert != null
            ? changes.badgeCountAlert.currentValue : this.badgeCountAlert != null ? this.badgeCountAlert : 0;

        if (badgeCount > 0 || badgeAlertCount > 0) {
            this.renderer.addClass(this.elementRef.nativeElement, RpcBadgeDirective.BadgeHostClass);
        }

        if (badgeAlertCount > 0) {
            const badgeAlertCountTitle = this.badgeCountAlertLimit > 0 && badgeAlertCount > this.badgeCountAlertLimit
                ? `${this.badgeCountAlertLimit}+`
                : badgeAlertCount.toString();

            const badgeAlertElement = this.createChildElement(badgeAlertCountTitle, true);
            const alertCountChanged = changes?.badgeCountAlert?.currentValue > changes?.badgeCountAlert?.previousValue;

            if (alertCountChanged && this.animationConfig != null) {
                this.animationService.playAnimation(badgeAlertElement, this.animationConfig.animations, this.animationConfig.repeatTimes);
            }

            return;
        }

        if (badgeCount > 0) {
            this.createChildElement(badgeCount.toString());
        }
    }

    private createChildElement(countTitle: string, isAlert = false): HTMLSpanElement {
        const child = document.createElement('span');

        child.classList.add(RpcBadgeDirective.BadgeClass);

        if (isAlert) {
            child.classList.add(RpcBadgeDirective.BadgeAlertClass);
        }

        child.innerText = countTitle;

        this.renderer.appendChild(this.elementRef.nativeElement, child);

        return child;
    }
}