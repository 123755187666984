import { Injectable } from '@angular/core';
import { fromEvent } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { LocalStorageConstants } from '@auth/constants/local-storage-constants';
import { User } from '@auth/models/user';
import { TokenService } from '@auth/services/token.service';
import { UserStoreService } from '@auth/store/services/user-store.service';
import { RpcRoute } from '@core-layout/app/models/rpc-route';
import { RouteService } from '@core-layout/app/services/route.service';

@Injectable({ providedIn: 'root' })
export class SyncLoginStateService {

    private readonly TOKEN_KEY: string = LocalStorageConstants.TokenKey;
    private isInit = false;

    constructor(
        private readonly routeService: RouteService,
        private readonly userStoreService: UserStoreService
    ) { }

    public initialize(): void {
        if (this.isInit) {
            return;
        }

        this.isInit = true;

        fromEvent<StorageEvent>(window, 'storage').pipe(
            filter(event => event.key === this.TOKEN_KEY)
        ).subscribe(event => {
            this.syncLoginState(event.newValue);
        });
    }

    private syncLoginState(token: string): void {
        if (token === null) {
            this.userStoreService.logout(true, true);
        } else {
            this.userStoreService.getIsAuthorized()
                .pipe(take(1))
                .subscribe(isAuthorized => {
                    const user: User = TokenService.restoreCurrentUser();

                    this.userStoreService.confirmLogin(user, true);

                    if (!isAuthorized) {
                        this.routeService.navigate(RpcRoute.FindHome).catch(() => { });
                    }
                });
        }
    }
}