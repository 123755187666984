export const locale = {
    lang: 'en',
    data: {
        RANGE_FILTER: {
            TITLES: {
                ANY: 'Any',
                FROM: 'From',
                TO: 'To'
            }
        }
    }
};