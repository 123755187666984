<mat-form-field [appearance]="appearance" [color]="color" class="rpc-select field-{{appearance}}">
    <mat-select [placeholder]="label | translate" [formControl]="formControl" (selectionChange)="onChanged($event)"
        (closed)="onClosed()">
        <mat-select-trigger *ngIf="selectedItem != null" class="rpc-selec-box"
            [ngClass]="{'rpc-selec-box-placeholder' : selectedItem.value === -1}">
            <rpc-icon class="rpc-select-icon" *ngIf="selectedItem.iconName != null && selectedItem.iconName !== ''"
                iconName="{{selectedItem.iconName}}"></rpc-icon>
            <span class="rpc-select-text">{{ shouldTranslate ? (selectedItem.title | translate) : selectedItem.title
                }}</span>
        </mat-select-trigger>
        <ng-content></ng-content>
        <mat-option class="rpc-select-option" [ngClass]="{'rpc-select-option--placeholder': option.value === -1}"
            *ngFor="let option of controlOptions.options" [value]="option.value">
            <rpc-icon class="rpc-select-option-icon" *ngIf="option.iconName != null && option.iconName !== ''"
                iconName="{{option.iconName}}"></rpc-icon>
            <span class="rpc-select-option-text">{{ shouldTranslate ? (option.title | translate) : option.title
                }}</span>
        </mat-option>
    </mat-select>
    <mat-error *ngFor="let validationItem of controlOptions.validatorsMap">
        <ng-container *ngTemplateOutlet="showErrorsTemplate, context: {
            $implicit: {
                showError: validationItem.showError,
                message: validationItem.message
            }
        }">
        </ng-container>
    </mat-error>
</mat-form-field>

<ng-template #showErrorsTemplate let-validationItem>
    <div *ngIf="validationItem.showError(formControl)">
        {{ validationItem.message | translate }}
    </div>
</ng-template>