export enum MyListingsFilters {
    category = 'category',
    piked = 'piked',
    liked = 'liked',
    disliked = 'disliked',
    shown = 'shown',
    newMatch = 'newMatch',
    newlyAdded = 'newlyAdded',
    upcomingOpenHouseStartDate = 'upcomingOpenHouseStartDate',
    upcomingOpenHouseEndDate = 'upcomingOpenHouseEndDate',
    upcomingAppointmentStartDate = 'upcomingAppointmentStartDate',
    upcomingAppointmentEndDate = 'upcomingAppointmentEndDate',
    usersFilter = 'usersFilter',
    hasAppointments = 'hasAppointments',
    hasComments = 'hasComments',
    hasUnreadComments = 'hasUnreadComments',
    hasReadComments = 'hasReadComments',
    hasUpcomingAppointments = 'hasUpcomingAppointments',
    hasPastOrDeclinedAppointments = 'hasPastOrDeclinedAppointments',
}