export const locale = {
    lang: 'en',
    data: {
        FOLDER_SELECTION: {
            TITLES: {
                EDIT: 'Edit',
                RENAME: 'Rename'
            }
        }
    }
};