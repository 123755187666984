import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { IManageFolderModel } from '@folders/models/manage-folder-model';

@Component({
    selector: 'add-edit-folder-dialog',
    templateUrl: './add-edit-folder-dialog.component.html',
    styleUrls: ['./add-edit-folder-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddEditFolderDialogComponent {

    constructor(
        private readonly dialogRef: MatDialogRef<IManageFolderModel>,
        @Inject(MAT_DIALOG_DATA) public manageFolderDialogData: { name?: string, id?: number }
    ) { }

    public manageFolder(manageFolderModel: IManageFolderModel): void {
        this.dialogRef.close(manageFolderModel);
    }

    public close(): void {
        this.dialogRef.close();
    }
}
