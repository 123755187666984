import { createReducer, on } from '@ngrx/store';

import * as onboardingActions from '../actions/onboarding.actions';
import { getOnboardingInitState } from '../states/onboarding.state';
import { OnboardingData } from '../../models/onboarding-data';

export const onboardingReducer = createReducer(
    getOnboardingInitState(),
    on(onboardingActions.loadOnboardingDataSuccess, (state, action) => (
        {
            ...state,
            data: markAsViewed(action.data, 0),
            itemsCount: action.data.length,
            isLoading: false
        }
    )),
    on(onboardingActions.loadOnboardingDataFailed, (state) => ({ ...state, loadSettings: false })),
    on(onboardingActions.setNextSelectedIndex, (state) => {
        const nextIndex = state.selectedItemIndex === state.itemsCount - 1
            ? 0
            : state.selectedItemIndex + 1;

        return {
            ...state,
            selectedItemIndex: nextIndex,
            data: markAsViewed(state.data, nextIndex)
        };
    }),

    on(onboardingActions.setPreviousSelectedIndex, (state) => {
        const previousIndex = state.selectedItemIndex === 0
            ? state.itemsCount - 1
            : state.selectedItemIndex - 1;

        return {
            ...state,
            selectedItemIndex: previousIndex,
            data: markAsViewed(state.data, previousIndex)
        };
    }),
    on(onboardingActions.resetState, () => ({ ...getOnboardingInitState() }))
);

function markAsViewed(data: OnboardingData[], index: number): OnboardingData[] {
    return data.map((item, idx) => {
        if (idx === index) {
            return { ...item, viewed: true };
        }

        return item;
    });
}