import { ChangeDetectionStrategy, Component } from '@angular/core';
import { map } from 'rxjs';

import { AppConfigurationService } from '@app-config/services/app-configuration.service';

@Component({
    selector: 'additional-footer',
    templateUrl: './additional-footer.component.html',
    styleUrls: ['./additional-footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdditionalFooterComponent {
    public readonly companyConfiguration$ = this.configurationService.configuration$.pipe(map(configuration => configuration.company));
    public readonly companyLogo$ = this.configurationService.getCompanyLogo();
    public readonly internalCompanyConfigurationLoaded$ = this.configurationService.getInternalCompanyConfigurationLoaded();

    constructor(private readonly configurationService: AppConfigurationService) { }
}
