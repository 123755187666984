import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { SimpleChanges } from '@core-models/utilities/generic-simple-changes';
import { MediaConstants } from '@media/constants/media.constants';

@Component({
    selector: 'default-image',
    templateUrl: './default-image.component.html',
    styleUrls: ['./default-image.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultImageComponent implements OnInit, OnChanges {

    @Input() image: string;
    @Input() useBackgroundImage: boolean;
    @Input() enableZoom = false;
    @Input() enableSpinner = false;
    @Input() isDummy = false;
    @Input() public hasBackdrop = false;

    @Output() public imageLoaded = new EventEmitter<void>();

    public readonly defaultImageUrl = MediaConstants.UnavailableImageUrl;

    public imageSrc: SafeUrl;
    public catchedError = false;
    public isImageLoading = false;

    constructor(private readonly sanitizer: DomSanitizer) { }

    public ngOnInit(): void {
        this.imageSrc = this.getSantizeUrl(this.image);
        this.isImageLoading = true;
    }

    public ngOnChanges(changes: SimpleChanges<DefaultImageComponent>): void {
        if (changes.image != null && changes.image.currentValue !== changes.image.previousValue) {
            this.imageSrc = this.getSantizeUrl(changes.image.currentValue);
            this.catchedError = false;
            this.isImageLoading = true;
        }
    }

    public onImageLoaded(): void {
        this.imageLoaded.emit();
        this.isImageLoading = false;
    }

    public onError($event): void {
        this.catchedError = true;
        this.isImageLoading = false;
        this.imageLoaded.emit();
    }

    private getSantizeUrl(url: string): SafeUrl {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }
}