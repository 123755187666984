import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as moment from 'moment';

import { ListingActivityConstants } from '@listings/constants/listing-activity-constants';
import { ListingInfo } from '@listings/models/listing/listing-info';
import { ListingsMedia } from '@listings/models/listing/listings-media';
import { ListingHelperService } from '@listings/services/listing-helper.service';
import { ListingsState } from '@listings/store/states/listings.state';
import { FormattedMedia } from '@media/models/formatted-media';
import * as settingsSelector from '@settings/store/selectors/settings.selector';
import { selectRouteParams } from '@core-layout/app/store/selectors/router.selectors';
import { OpenHouse } from '@listings/models/listing/openhouse';
import { selectAgent } from '@auth/store/selectors/user.selector';
import { selectAgents } from '@agents/store/selectors/agents.selectors';
import { ListingListItem } from '@listings/models/listing/listing-list-item';
import { ListingCardInfo } from '@listings/models/listing/listing-card-info';

const selectListingsState = createFeatureSelector<ListingsState>('listings');

export const selectBaseListings = createSelector(
    selectListingsState,
    listingState => listingState == null || listingState.listings == null ? {} : listingState.listings
);

export const selectAllListingsMedia = createSelector(
    selectListingsState,
    listingState => listingState == null || listingState.media == null ? new ListingsMedia() : listingState.media
);

export const selectIsListingsLoading = createSelector(selectListingsState, listingState => listingState?.isListingsLoading);
export const selectIsListingLoading = createSelector(selectListingsState, listingState => listingState?.isListingLoading);

export const selectListingsPriceChanges = createSelector(selectListingsState, listingState => listingState?.priceChanges ?? {});

export const selectListingsActivities = createSelector(selectListingsState, listingState => listingState?.listingsActivities);
export const selectListingsActivitiesLoading = createSelector(selectListingsState, listingState => listingState?.listingsActivitiesLoading);
export const selectListingsActivitiesLoaded = createSelector(selectListingsState, listingState => listingState?.listingsActivitiesLoaded);
export const selectListingsNewMatches = createSelector(selectListingsState, listingState => listingState?.listingsNewMatches);
export const selectNewMatchesLoading = createSelector(selectListingsState, listingState => listingState?.newMatchesLoading);
export const selectNewMatchesLoaded = createSelector(selectListingsState, listingState => listingState?.newMatchesLoaded);

export const selectBaseRecentlyViewed = createSelector(
    selectListingsState,
    listingState => listingState == null || listingState.recentlyViewedListings == null ? [] as ListingCardInfo[] : listingState.recentlyViewedListings
);

export const selectListingsDetails = createSelector(selectListingsState, state => state.listingsDetails);
export const selectListingDetails = (hashCode: number) => createSelector(selectListingsDetails, listingsDetails => listingsDetails[hashCode]);

export const selectListings = createSelector(
    selectBaseListings,
    selectListingsActivities,
    selectListingsNewMatches,
    selectAgent,
    selectAgents,
    (listings, listingsActivities, listingsNewMatches, agent, agents) => {
        const agentsIds = new Set(agents.map(x => x.agentId));

        return Object.entries(listings).reduce((acc, [id, listing]) => {
            return { ...acc, [id]: ListingHelperService.createListingInfo(listing, listingsActivities, listingsNewMatches, agentsIds, agent?.id) };
        }, {} as Record<string, ListingInfo>);
    }
);

export const selectRecentlyViewed = createSelector(
    selectBaseRecentlyViewed,
    selectListingsActivities,
    selectListingsNewMatches,
    selectAgent,
    selectAgents,
    (recentlyViewedListings, listingsActivities, listingsNewMatches, agent, agents) => {
        const agentsIds = new Set(agents.map(x => x.agentId));

        return recentlyViewedListings.map(listing => {
            return ListingHelperService.createListingInfo(listing, listingsActivities, listingsNewMatches, agentsIds, agent?.id);
        });
    }
);

export const selectCustomerListings = createSelector(
    selectListings,
    (listings) => Object.values(listings).filter(commonListing => commonListing.isNewMatch || commonListing.activities.length > 0));

export const selectListingsMedia = (listingIds: string[]) => createSelector(
    selectAllListingsMedia,
    media => listingIds.map(listingId => {
        const listingMedia = media[listingId];

        if (listingMedia == null) {
            return FormattedMedia.notLoaded;
        }

        return listingMedia;
    })
);

export const selectListingMedia = (listingId: string) => createSelector(
    selectListingsMedia([listingId]),
    media => media[0]
);

export const selectIsMediaLoadedForListing = (listingId: string) => createSelector(
    selectListingMedia(listingId),
    ({ originalImages, videos, tours }) => [originalImages, videos, tours].some(x => x == null || x.length > 0)
);

export const selectMarketListings = createSelector(
    selectListings,
    commonListings => Object.values(commonListings).filter(commonListing => commonListing.isMarketListing)
);

export const selectListing = (listingId: string) => createSelector(
    selectListings,
    selectRecentlyViewed,
    (listings, recentlyViewedListings) => listings[listingId] ?? recentlyViewedListings?.find(l => l.id === listingId) ?? null
);

export const selectSelectedListingIds = createSelector(
    selectListingsState,
    listingState => listingState == null || listingState.selectedListingIds == null ? [] : listingState.selectedListingIds
);

export const selectCustomerListingsLodaded = createSelector(
    selectListingsState,
    listingState => listingState == null ? false : listingState.customerListingsLoaded
);

export const selectMarketListingsLoading = createSelector(
    selectListingsState,
    listingState => listingState == null ? false : listingState.marketListingsLoading
);

export const selectRecentlyViewedListingsLoaded = createSelector(
    selectListingsState,
    state => state.recentlyViewedListingsLoaded
);

export const selectIsRecentlyViewedListingsLoading = createSelector(
    selectListingsState,
    state => state.isRecentlyViewedListingsLoading
);

export const selectActivitiesOrNewMatchesInLoading = createSelector(
    selectNewMatchesLoading,
    selectListingsActivitiesLoading,
    (newMatchesLoading, listingsActivitiesLoading) => newMatchesLoading || listingsActivitiesLoading
);

export const selectSelectedListingsHashcodes = createSelector(
    selectBaseListings,
    selectSelectedListingIds,
    (listings, selectedListingIds) => selectedListingIds.map(id => listings[id].hashCode)
);

export const selectSelectedListingsInfo = createSelector(
    selectListings,
    selectAllListingsMedia,
    selectSelectedListingIds,
    settingsSelector.selectPermissionSettings,
    (listings, listingsMedia, selectedListingsIds, permissionSettings) => selectedListingsIds.filter(listingId => listings[listingId] != null).map(
        listingId => new ListingListItem(listings[listingId], listingsMedia[listingId], permissionSettings.hideOpenRentals))
);

export const selectPortfolioSelectedListingsInfo = createSelector(
    selectListings,
    selectSelectedListingsInfo,
    (listings, selectedListingsInfo) => selectedListingsInfo.filter(listing =>
        !listing.isMarketListing && (listings[listing.listingId].activities?.length > 0 || listings[listing.listingId].isNewMatch))
);

export const hasAnySelectedPortfolioListings = createSelector(
    selectPortfolioSelectedListingsInfo,
    (listings) => listings.length !== 0
);

export const hasNotRemovedSelectedPortfolioListings = createSelector(
    selectPortfolioSelectedListingsInfo,
    (listings) => listings.filter(listing => !listing.isDeleted).length !== 0
);

export const selectListingFolderManagerInfoById = (listingId: string) => createSelector(
    selectListing(listingId),
    selectListingMedia(listingId),
    settingsSelector.selectPermissionSettings,
    (listing, listingMedia, permissionSettings) => {
        return listing != null ? new ListingListItem(listing, listingMedia, permissionSettings.hideOpenRentals) : null;
    }
);

export const selectIsAllSelectedListingsAreRemoved = createSelector(
    selectSelectedListingIds,
    selectListings,
    (selectedListingIds, listings) => {
        return Object.values(listings)
            .filter(({ id, isDeleted }) => isDeleted && selectedListingIds.includes(id)).length === selectedListingIds.length;
    }
);

export const selectIsAnySelectedListingRemoved = createSelector(
    selectSelectedListingIds,
    selectListings,
    (selectedListingIds, listings) => selectedListingIds.some(id => listings[id].isDeleted)
);

export const selectNewMatchListingsBySavedSearchId = (savedSearchId: number) => createSelector(
    selectCustomerListings,
    listings => listings.filter(listing => listing.isNewMatch && listing.newMatches.some(x => x.savedSearchId === savedSearchId))
);

export const selectSavedSearchNewMatches = (savedSearchId: number) => createSelector(
    selectListingsNewMatches,
    listingsNewMatches => Object.values(listingsNewMatches).flat().filter(listing => listing.savedSearchId === savedSearchId)
);

export const selectSelectedMarketListings = createSelector(
    selectMarketListings,
    selectSelectedListingIds,
    (listings, selectedListingIds) => listings.filter(listing => selectedListingIds.includes(listing.id) && !listing.isDeleted)
);

export const selectSelectedMarketListingsAvailableForActivity = (activityId: number) => createSelector(
    selectSelectedMarketListings,
    listings => listings.filter(listing => activityId !== ListingActivityConstants.PickListed.id || listing.activities.length === 0)
);

export const selectSelectedListingsWithoutOpenRentals = createSelector(
    selectListings,
    selectSelectedListingIds,
    settingsSelector.selectPermissionSettings,
    (listings, selectedListingsIds, permissionSettings) => {
        if (listings == null || selectedListingsIds == null || permissionSettings == null) {
            return [];
        }

        return Object.values(listings).filter(listing => permissionSettings.hideOpenRentals
            ? !ListingHelperService.isOpenRental(listing) && selectedListingsIds.includes(listing.id)
            : selectedListingsIds.includes(listing.id));
    }
);

export const selectSelectedListingIdsWithoutOpenRentals = createSelector(
    selectSelectedListingsWithoutOpenRentals,
    listings => listings.map(listing => listing.id)
);

export const selectActiveListing = createSelector(
    selectRouteParams,
    selectListings,
    (routeParams, listings) => routeParams?.id != null && listings[routeParams.id] != null ? listings[routeParams.id] : null
);

export const selectIsActiveListingIsInPortfolio = createSelector(
    selectActiveListing,
    activeListing => activeListing?.activities?.length > 0 || activeListing?.isNewMatch
);

export const selectUnviewedListingsCount = createSelector(
    selectCustomerListings,
    listings => listings.reduce((sum, { isNewViewed }) => !isNewViewed ? sum + 1 : sum, 0)
);

export const selectRecentlyViewedListings = createSelector(
    selectRecentlyViewed,
    selectCustomerListings,
    (recentlyViewed, listings) => recentlyViewed.map(listing => listings.find(l => l.hashCode === listing.hashCode) ?? listing)
);

export const selectUpcomingOpenHousesWithListings = createSelector(
    selectCustomerListings,
    listings => {
        return listings
            .reduce((allOpenHouses, listing) => {
                const upcominOpenHouses = listing.openHouses.filter(oh => moment(oh.startDate).isAfter(new Date(), 'minute'));
                const models = upcominOpenHouses.map(openHouse => ({ listing, openHouse }));

                return upcominOpenHouses.length > 0 ? [...allOpenHouses, ...models] : allOpenHouses;
            }, [] as { listing: ListingInfo, openHouse: OpenHouse }[])
            .sort((a, b) => moment(a.openHouse.startDate).diff(moment(b.openHouse.startDate)));
    }
);
