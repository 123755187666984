import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { tap } from 'rxjs/operators';

import { AgentsStoreService } from '@agents/store/services/agents-store.service';
import { AppointmentsStoreService } from '@appointments/store/services/appointments-store.service';
import { CollaborationSpaceStoreService } from '@auth/store/services/collaboration-space-store.service';
import { UserStoreService } from '@auth/store/services/user-store.service';
import { RpcRoute } from '@core-layout/app/models/rpc-route';
import { RouteService } from '@core-layout/app/services/route.service';
import { ListingsStoreService } from '@listings/store/services/listings-store.service';
import { PwaService } from '@rpc-pwa/services/pwa.service';
import { SettingsStoreService } from '@settings/store/services/settings-store.service';
import { PROFILE_TABS_NAMES } from 'app/modules/user-modules/profile-modules/profile-base/constants/profile-tabs.constants';
import { ProfileBaseStoreService } from 'app/modules/user-modules/profile-modules/profile-base/store/services/profile-base-store.service';
import { PortfolioStoreService } from '@portfolio/store/services/portfolio-store.service';
import { SavedSearchStoreService } from '@saved-search/store/services/saved-search-store.service';
import { RpcMenuButtonComponent } from '@core-controls/components/rpc-menu-button/rpc-menu-button.component';
import { SidebarService } from '@core-layout/sidebar/sidebar.service';

@Component({
    selector: 'side-menu',
    templateUrl: './side-menu.component.html',
    styleUrls: ['./side-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideMenuComponent implements AfterViewInit {
    @ViewChild('savedSearchesMenuButton') public readonly savedSearchesMenuButton: RpcMenuButtonComponent;

    public readonly RpcRoute = RpcRoute;
    public readonly agents$ = this.agentsStoreService.getAgents();

    public readonly customerFullName$ = this.userStoreService.getCustomerFullName();
    public readonly customerProfileImage$ = this.userStoreService.getCustomerProfileImage();
    public readonly unviewedListingsCount$ = this.listingsStoreService.unviewedListingsCount$;
    public readonly appointmentsCount$ = this.appointmentsStoreService.getFlatListingsAppointmentsCount();
    public readonly unviewedAppointmentsCount$ = this.appointmentsStoreService.unviewedAppointmentsCount$;
    public readonly canSearchForListings$ = this.settingsStoreService.canSearchForListings$;
    public readonly canSeeAdditionalProfiles$ = this.userStoreService.canSeeAdditionalProfiles$;
    public readonly canAddAdditionalProfile$ = this.profileBaseStoreService.canAddAdditionalProfile$;
    public showInstallationButton: boolean;

    public readonly hasMultipleCollaborationSpaces$ = this.collaborationSpaceStoreService.hasMultipleCollaborationSpaces$;
    public readonly portfolioRouterLink$ = this.portfolioStoreService.portfolioRouterLink$;

    public readonly savedSearchesInfo$ = this.savedSearchStoreService.savedSearchesInfo$.pipe(tap((savedSearches) => {
        if (savedSearches.length === 0 && this.savedSearchesMenuButton?.menuOpened) {
            this.savedSearchesMenuButton.closeMenu();
        }
    }));

    public readonly activeSavedSearchId$ = this.savedSearchStoreService.activeSavedSearchId$;

    public readonly isSettingsLoading$ = this.settingsStoreService.isLoading$;

    constructor(
        private readonly routeService: RouteService,
        private readonly userStoreService: UserStoreService,
        private readonly listingsStoreService: ListingsStoreService,
        private readonly appointmentsStoreService: AppointmentsStoreService,
        private readonly pwaService: PwaService,
        private readonly settingsStoreService: SettingsStoreService,
        private readonly profileBaseStoreService: ProfileBaseStoreService,
        private readonly agentsStoreService: AgentsStoreService,
        private readonly collaborationSpaceStoreService: CollaborationSpaceStoreService,
        private readonly portfolioStoreService: PortfolioStoreService,
        private readonly savedSearchStoreService: SavedSearchStoreService,
        private readonly sidebarService: SidebarService,
    ) { }

    public ngAfterViewInit(): void {
        this.showInstallationButton = this.pwaService.isPromptAvailable();
    }

    public onMyProfileClicked(): void {
        this.routeService.navigate(RpcRoute.Profile, { state: { tab: PROFILE_TABS_NAMES.GENERAL } }).catch(() => { });
    }

    public onSettingsClicked(): void {
        this.routeService.navigate(RpcRoute.Profile, { state: { tab: PROFILE_TABS_NAMES.SETTINGS } }).catch(() => { });
    }

    public onInviteUser(): void {
        this.profileBaseStoreService.showInviteUserDialog();
    }

    public onLogoutClicked(): void {
        this.userStoreService.logout(false);
    }

    public addToHomeScreen(): void {
        this.pwaService.showPrompt()
            .then(accepted => this.showInstallationButton = !accepted)
            .catch(() => this.showInstallationButton = false);
    }

    public onChangeAgentClicked(): void {
        this.collaborationSpaceStoreService.loadCollaborationSpaces();
    }

    public onKnowledgeBaseClicked(): void {
        this.routeService.navigate(RpcRoute.KnowledgeBase).catch(() => { });
    }

    public onSavedSearchSelected(activeSavedSearchId: number): void {
        this.savedSearchStoreService.runSavedSearch(activeSavedSearchId);

        this.savedSearchesMenuButton.closeMenu();
        this.sidebarService.closeAll();
    }

    public onEditSavedSearch(activeSavedSearchId: number): void {
        this.savedSearchStoreService.showAddEditSavedSearchDialog(activeSavedSearchId);
    }
}