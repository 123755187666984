import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';

import { ListingApiService } from '@listings/store/services/listing-api.service';
import { ListingsStoreService } from '../services/listings-store.service';
import * as newMatchesActions from '../actions/new-matches.actions';
import { NewMatch } from '@listings/models/listing/new-match';
import { NewMatchService } from '@listings/services/new-match.service';

@Injectable()
export class NewMatchesEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly listingApiService: ListingApiService,
        private readonly listingsStoreService: ListingsStoreService,
    ) { }

    public readonly loadListingsNewMatchesRequested$ = createEffect(() => this.actions$.pipe(
        ofType(newMatchesActions.loadListingsNewMatchesRequested),
        concatLatestFrom(() => this.listingsStoreService.areNewMatchesLoaded$),
        switchMap(([{ hashCodes, isForce, shouldSetLoading, shouldSetLoaded }, areNewMatchesLoaded]) => {
            return areNewMatchesLoaded && !isForce ? EMPTY : [newMatchesActions.loadListingsNewMatches({ hashCodes, shouldSetLoading, shouldSetLoaded })];
        })
    ));

    public readonly loadListingsNewMatches$ = createEffect(() => this.actions$.pipe(
        ofType(newMatchesActions.loadListingsNewMatches),
        mergeMap(({ hashCodes, shouldSetLoaded }) => this.listingApiService.loadListingsNewMatches(hashCodes ?? [], shouldSetLoaded))
    ));

    public readonly loadListingsNewMatchesSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(newMatchesActions.loadListingsNewMatchesSuccess),
        concatLatestFrom(() => this.listingsStoreService.listingsNewMatches$),
        map(([{ hashCodes, newMatches }, listingsNewMatches]) => {
            const source = hashCodes.length === 0 ? newMatches : { ...listingsNewMatches, ...newMatches };

            const updatedNewMatches = Object.entries(source).reduce(
                (acc, [hashcode, listingNewMatches]) => ({ ...acc, [hashcode]: (newMatches[+hashcode] ?? listingNewMatches) }),
                {} as Record<number, NewMatch[]>
            );

            return newMatchesActions.setListingsNewMatches({ newMatches: updatedNewMatches });
        })
    ));

    public readonly removeNewMatches$ = createEffect(() => this.actions$.pipe(
        ofType(newMatchesActions.removeNewMatches),
        concatLatestFrom(() => this.listingsStoreService.listingsNewMatches$),
        map(([{ hashCodes, newMatchIds }, listingsNewMatches]) => {
            const newMatches = newMatchIds?.size > 0
                ? NewMatchService.removeNewMatchesByIds(listingsNewMatches, newMatchIds)
                : NewMatchService.removeNewMatches(listingsNewMatches, hashCodes);

            return newMatchesActions.setListingsNewMatches({ newMatches });
        })
    ));
}
