<button mat-icon-button #rpcMenuTrigger="matMenuTrigger" [animationConfig]="animationConfig" [rpcBadge]="badgeCount"
    [rpcBadgeAlert]="badgeCountAlert" [rpcBadgeCountAlertLimit]="badgeCountAlertLimit" [matMenuTriggerFor]="rpcIconMenu"
    class="rpc-menu-icon-btn" title=" {{ alt | translate }}" [ngClass]="[
        actionClass != null && actionClass !== '' && (actionTrigger == null || actionTrigger) ? actionClass : '',
        menuOpened ? 'rpc-menu-icon-btn--opened' : '',
        enableCustomBackground ? 'rpc-menu-icon-btn--custom' : ''
    ]" [disabled]="disabled" [color]="color" [type]="type" (click)="onClick()">

    <rpc-icon class="rpc-menu-icon-btn-icon" [iconName]="iconName"></rpc-icon>

    <span class="rpc-menu-icon-btn-bg" *ngIf="enableCustomBackground"></span>

</button>
<mat-menu #rpcIconMenu="matMenu" [overlapTrigger]="false" class="rpc-menu-wrap {{menuActionClass}}" [ngClass]="[
    menuActionClass != null && menuActionClass !== '' && (menuActionTrigger == null || menuActionTrigger) ? menuActionClass : ''
]" (closed)="onClosed()">
    <div #rpcIconMenuContent (click)="$event.stopPropagation()">
        <ng-content></ng-content>
    </div>
</mat-menu>